<div class="range-select-dialog">
  <section class="title-bar">
    <h2 cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Range Select</h2>
  </section>
  <section class="range-container">
    <div class="range-value">
      <span class="range-label">Min</span>
      <input [min]="minBound" [max]="maxRange" step="1" type="number" [(ngModel)]="minRange" />
      <span class="range-units">keV</span>
    </div>
    <div class="range-value">
      <span class="range-label">Max</span>
      <input [min]="minRange" [max]="maxBound" step="1" type="number" [(ngModel)]="maxRange" />
      <span class="range-units">keV</span>
    </div>
  </section>
  <div class="show-on-chart">
    <!-- <push-button (onClick)="showOnChart()">Show on Chart</push-button> -->
    <span class="label">Target Chart</span>
    <mat-select [(ngModel)]="selectedWidgetId">
      <mat-option *ngFor="let chart of currentTabWidgets" [value]="chart.id">{{ chart.name }}</mat-option>
    </mat-select>
  </div>
  <div class="bottom-buttons">
    <push-button class="left-btn" buttonStyle="outline" title="Copy expression" (onClick)="onCopy()">Copy</push-button>
    <push-button buttonStyle="yellow" title="Show on Chart" (onClick)="showOnChart()">Apply</push-button>
    <push-button buttonStyle="outline" title="Close window" (onClick)="onClose()">Close</push-button>
  </div>
</div>
