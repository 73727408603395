/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { UserGroupList } from "./ownership-access";
import { UserInfo } from "./user";

export const protobufPackage = "";

export enum UserGroupRelationship {
  /** UGR_UNKNOWN - https://protobuf.dev/programming-guides/dos-donts/ says specify an unknown as 0 */
  UGR_UNKNOWN = 0,
  UGR_VIEWER = 1,
  UGR_MEMBER = 2,
  UGR_ADMIN = 3,
  UNRECOGNIZED = -1,
}

export function userGroupRelationshipFromJSON(object: any): UserGroupRelationship {
  switch (object) {
    case 0:
    case "UGR_UNKNOWN":
      return UserGroupRelationship.UGR_UNKNOWN;
    case 1:
    case "UGR_VIEWER":
      return UserGroupRelationship.UGR_VIEWER;
    case 2:
    case "UGR_MEMBER":
      return UserGroupRelationship.UGR_MEMBER;
    case 3:
    case "UGR_ADMIN":
      return UserGroupRelationship.UGR_ADMIN;
    case -1:
    case "UNRECOGNIZED":
    default:
      return UserGroupRelationship.UNRECOGNIZED;
  }
}

export function userGroupRelationshipToJSON(object: UserGroupRelationship): string {
  switch (object) {
    case UserGroupRelationship.UGR_UNKNOWN:
      return "UGR_UNKNOWN";
    case UserGroupRelationship.UGR_VIEWER:
      return "UGR_VIEWER";
    case UserGroupRelationship.UGR_MEMBER:
      return "UGR_MEMBER";
    case UserGroupRelationship.UGR_ADMIN:
      return "UGR_ADMIN";
    case UserGroupRelationship.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** The UserGroup we store in DB */
export interface UserGroupDB {
  /** @gotags: bson:"_id,omitempty" */
  id: string;
  name: string;
  description: string;
  createdUnixSec: number;
  lastUserJoinedUnixSec: number;
  joinable: boolean;
  viewers: UserGroupList | undefined;
  members: UserGroupList | undefined;
  adminUserIds: string[];
}

/** User Group Info, just the basics... */
export interface UserGroupInfo {
  /** @gotags: bson:"_id,omitempty" */
  id: string;
  name: string;
  description: string;
  createdUnixSec: number;
  lastUserJoinedUnixSec: number;
  joinable: boolean;
  /** OPTIONAL (only returned in UserGroupListResp) */
  relationshipToUser: UserGroupRelationship;
}

export interface UserGroupInfoList {
  users: UserInfo[];
  groups: UserGroupInfo[];
}

/**
 * The user group sent out of API, contains "info" items of each user/user-group
 * that's attached to it
 */
export interface UserGroup {
  info: UserGroupInfo | undefined;
  viewers: UserGroupInfoList | undefined;
  members: UserGroupInfoList | undefined;
  adminUsers: UserInfo[];
}

export interface UserGroupJoinSummaryInfo {
  /** @gotags: bson:"_id,omitempty" */
  id: string;
  name: string;
  description: string;
  administrators: UserInfo[];
  datasets: number;
  lastUserJoinedUnixSec: number;
}

/** A request to join a user group - stored in DB */
export interface UserGroupJoinRequestDB {
  /** @gotags: bson:"_id,omitempty" */
  id: string;
  /** Who sent it */
  userId: string;
  /** What they want to join */
  joinGroupId: string;
  /** If false, join request is to join as a viewer only */
  asMember: boolean;
  /** The joining user's info at the time of the request */
  details: UserInfo | undefined;
  createdUnixSec: number;
}

function createBaseUserGroupDB(): UserGroupDB {
  return {
    id: "",
    name: "",
    description: "",
    createdUnixSec: 0,
    lastUserJoinedUnixSec: 0,
    joinable: false,
    viewers: undefined,
    members: undefined,
    adminUserIds: [],
  };
}

export const UserGroupDB = {
  encode(message: UserGroupDB, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(66).string(message.description);
    }
    if (message.createdUnixSec !== 0) {
      writer.uint32(56).uint32(message.createdUnixSec);
    }
    if (message.lastUserJoinedUnixSec !== 0) {
      writer.uint32(72).uint32(message.lastUserJoinedUnixSec);
    }
    if (message.joinable === true) {
      writer.uint32(80).bool(message.joinable);
    }
    if (message.viewers !== undefined) {
      UserGroupList.encode(message.viewers, writer.uint32(42).fork()).ldelim();
    }
    if (message.members !== undefined) {
      UserGroupList.encode(message.members, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.adminUserIds) {
      writer.uint32(34).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserGroupDB {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserGroupDB();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.description = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.createdUnixSec = reader.uint32();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.lastUserJoinedUnixSec = reader.uint32();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.joinable = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.viewers = UserGroupList.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.members = UserGroupList.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.adminUserIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserGroupDB {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      name: isSet(object.name) ? String(object.name) : "",
      description: isSet(object.description) ? String(object.description) : "",
      createdUnixSec: isSet(object.createdUnixSec) ? Number(object.createdUnixSec) : 0,
      lastUserJoinedUnixSec: isSet(object.lastUserJoinedUnixSec) ? Number(object.lastUserJoinedUnixSec) : 0,
      joinable: isSet(object.joinable) ? Boolean(object.joinable) : false,
      viewers: isSet(object.viewers) ? UserGroupList.fromJSON(object.viewers) : undefined,
      members: isSet(object.members) ? UserGroupList.fromJSON(object.members) : undefined,
      adminUserIds: Array.isArray(object?.adminUserIds) ? object.adminUserIds.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: UserGroupDB): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.description !== undefined && (obj.description = message.description);
    message.createdUnixSec !== undefined && (obj.createdUnixSec = Math.round(message.createdUnixSec));
    message.lastUserJoinedUnixSec !== undefined &&
      (obj.lastUserJoinedUnixSec = Math.round(message.lastUserJoinedUnixSec));
    message.joinable !== undefined && (obj.joinable = message.joinable);
    message.viewers !== undefined &&
      (obj.viewers = message.viewers ? UserGroupList.toJSON(message.viewers) : undefined);
    message.members !== undefined &&
      (obj.members = message.members ? UserGroupList.toJSON(message.members) : undefined);
    if (message.adminUserIds) {
      obj.adminUserIds = message.adminUserIds.map((e) => e);
    } else {
      obj.adminUserIds = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserGroupDB>, I>>(base?: I): UserGroupDB {
    return UserGroupDB.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserGroupDB>, I>>(object: I): UserGroupDB {
    const message = createBaseUserGroupDB();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.createdUnixSec = object.createdUnixSec ?? 0;
    message.lastUserJoinedUnixSec = object.lastUserJoinedUnixSec ?? 0;
    message.joinable = object.joinable ?? false;
    message.viewers = (object.viewers !== undefined && object.viewers !== null)
      ? UserGroupList.fromPartial(object.viewers)
      : undefined;
    message.members = (object.members !== undefined && object.members !== null)
      ? UserGroupList.fromPartial(object.members)
      : undefined;
    message.adminUserIds = object.adminUserIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseUserGroupInfo(): UserGroupInfo {
  return {
    id: "",
    name: "",
    description: "",
    createdUnixSec: 0,
    lastUserJoinedUnixSec: 0,
    joinable: false,
    relationshipToUser: 0,
  };
}

export const UserGroupInfo = {
  encode(message: UserGroupInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(42).string(message.description);
    }
    if (message.createdUnixSec !== 0) {
      writer.uint32(24).uint32(message.createdUnixSec);
    }
    if (message.lastUserJoinedUnixSec !== 0) {
      writer.uint32(48).uint32(message.lastUserJoinedUnixSec);
    }
    if (message.joinable === true) {
      writer.uint32(56).bool(message.joinable);
    }
    if (message.relationshipToUser !== 0) {
      writer.uint32(32).int32(message.relationshipToUser);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserGroupInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserGroupInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.createdUnixSec = reader.uint32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.lastUserJoinedUnixSec = reader.uint32();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.joinable = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.relationshipToUser = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserGroupInfo {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      name: isSet(object.name) ? String(object.name) : "",
      description: isSet(object.description) ? String(object.description) : "",
      createdUnixSec: isSet(object.createdUnixSec) ? Number(object.createdUnixSec) : 0,
      lastUserJoinedUnixSec: isSet(object.lastUserJoinedUnixSec) ? Number(object.lastUserJoinedUnixSec) : 0,
      joinable: isSet(object.joinable) ? Boolean(object.joinable) : false,
      relationshipToUser: isSet(object.relationshipToUser)
        ? userGroupRelationshipFromJSON(object.relationshipToUser)
        : 0,
    };
  },

  toJSON(message: UserGroupInfo): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.description !== undefined && (obj.description = message.description);
    message.createdUnixSec !== undefined && (obj.createdUnixSec = Math.round(message.createdUnixSec));
    message.lastUserJoinedUnixSec !== undefined &&
      (obj.lastUserJoinedUnixSec = Math.round(message.lastUserJoinedUnixSec));
    message.joinable !== undefined && (obj.joinable = message.joinable);
    message.relationshipToUser !== undefined &&
      (obj.relationshipToUser = userGroupRelationshipToJSON(message.relationshipToUser));
    return obj;
  },

  create<I extends Exact<DeepPartial<UserGroupInfo>, I>>(base?: I): UserGroupInfo {
    return UserGroupInfo.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserGroupInfo>, I>>(object: I): UserGroupInfo {
    const message = createBaseUserGroupInfo();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.createdUnixSec = object.createdUnixSec ?? 0;
    message.lastUserJoinedUnixSec = object.lastUserJoinedUnixSec ?? 0;
    message.joinable = object.joinable ?? false;
    message.relationshipToUser = object.relationshipToUser ?? 0;
    return message;
  },
};

function createBaseUserGroupInfoList(): UserGroupInfoList {
  return { users: [], groups: [] };
}

export const UserGroupInfoList = {
  encode(message: UserGroupInfoList, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.users) {
      UserInfo.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.groups) {
      UserGroupInfo.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserGroupInfoList {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserGroupInfoList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.users.push(UserInfo.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.groups.push(UserGroupInfo.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserGroupInfoList {
    return {
      users: Array.isArray(object?.users) ? object.users.map((e: any) => UserInfo.fromJSON(e)) : [],
      groups: Array.isArray(object?.groups) ? object.groups.map((e: any) => UserGroupInfo.fromJSON(e)) : [],
    };
  },

  toJSON(message: UserGroupInfoList): unknown {
    const obj: any = {};
    if (message.users) {
      obj.users = message.users.map((e) => e ? UserInfo.toJSON(e) : undefined);
    } else {
      obj.users = [];
    }
    if (message.groups) {
      obj.groups = message.groups.map((e) => e ? UserGroupInfo.toJSON(e) : undefined);
    } else {
      obj.groups = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserGroupInfoList>, I>>(base?: I): UserGroupInfoList {
    return UserGroupInfoList.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserGroupInfoList>, I>>(object: I): UserGroupInfoList {
    const message = createBaseUserGroupInfoList();
    message.users = object.users?.map((e) => UserInfo.fromPartial(e)) || [];
    message.groups = object.groups?.map((e) => UserGroupInfo.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUserGroup(): UserGroup {
  return { info: undefined, viewers: undefined, members: undefined, adminUsers: [] };
}

export const UserGroup = {
  encode(message: UserGroup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.info !== undefined) {
      UserGroupInfo.encode(message.info, writer.uint32(10).fork()).ldelim();
    }
    if (message.viewers !== undefined) {
      UserGroupInfoList.encode(message.viewers, writer.uint32(42).fork()).ldelim();
    }
    if (message.members !== undefined) {
      UserGroupInfoList.encode(message.members, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.adminUsers) {
      UserInfo.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserGroup {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserGroup();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.info = UserGroupInfo.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.viewers = UserGroupInfoList.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.members = UserGroupInfoList.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.adminUsers.push(UserInfo.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserGroup {
    return {
      info: isSet(object.info) ? UserGroupInfo.fromJSON(object.info) : undefined,
      viewers: isSet(object.viewers) ? UserGroupInfoList.fromJSON(object.viewers) : undefined,
      members: isSet(object.members) ? UserGroupInfoList.fromJSON(object.members) : undefined,
      adminUsers: Array.isArray(object?.adminUsers) ? object.adminUsers.map((e: any) => UserInfo.fromJSON(e)) : [],
    };
  },

  toJSON(message: UserGroup): unknown {
    const obj: any = {};
    message.info !== undefined && (obj.info = message.info ? UserGroupInfo.toJSON(message.info) : undefined);
    message.viewers !== undefined &&
      (obj.viewers = message.viewers ? UserGroupInfoList.toJSON(message.viewers) : undefined);
    message.members !== undefined &&
      (obj.members = message.members ? UserGroupInfoList.toJSON(message.members) : undefined);
    if (message.adminUsers) {
      obj.adminUsers = message.adminUsers.map((e) => e ? UserInfo.toJSON(e) : undefined);
    } else {
      obj.adminUsers = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserGroup>, I>>(base?: I): UserGroup {
    return UserGroup.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserGroup>, I>>(object: I): UserGroup {
    const message = createBaseUserGroup();
    message.info = (object.info !== undefined && object.info !== null)
      ? UserGroupInfo.fromPartial(object.info)
      : undefined;
    message.viewers = (object.viewers !== undefined && object.viewers !== null)
      ? UserGroupInfoList.fromPartial(object.viewers)
      : undefined;
    message.members = (object.members !== undefined && object.members !== null)
      ? UserGroupInfoList.fromPartial(object.members)
      : undefined;
    message.adminUsers = object.adminUsers?.map((e) => UserInfo.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUserGroupJoinSummaryInfo(): UserGroupJoinSummaryInfo {
  return { id: "", name: "", description: "", administrators: [], datasets: 0, lastUserJoinedUnixSec: 0 };
}

export const UserGroupJoinSummaryInfo = {
  encode(message: UserGroupJoinSummaryInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    for (const v of message.administrators) {
      UserInfo.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    if (message.datasets !== 0) {
      writer.uint32(40).uint32(message.datasets);
    }
    if (message.lastUserJoinedUnixSec !== 0) {
      writer.uint32(48).uint32(message.lastUserJoinedUnixSec);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserGroupJoinSummaryInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserGroupJoinSummaryInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.administrators.push(UserInfo.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.datasets = reader.uint32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.lastUserJoinedUnixSec = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserGroupJoinSummaryInfo {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      name: isSet(object.name) ? String(object.name) : "",
      description: isSet(object.description) ? String(object.description) : "",
      administrators: Array.isArray(object?.administrators)
        ? object.administrators.map((e: any) => UserInfo.fromJSON(e))
        : [],
      datasets: isSet(object.datasets) ? Number(object.datasets) : 0,
      lastUserJoinedUnixSec: isSet(object.lastUserJoinedUnixSec) ? Number(object.lastUserJoinedUnixSec) : 0,
    };
  },

  toJSON(message: UserGroupJoinSummaryInfo): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.description !== undefined && (obj.description = message.description);
    if (message.administrators) {
      obj.administrators = message.administrators.map((e) => e ? UserInfo.toJSON(e) : undefined);
    } else {
      obj.administrators = [];
    }
    message.datasets !== undefined && (obj.datasets = Math.round(message.datasets));
    message.lastUserJoinedUnixSec !== undefined &&
      (obj.lastUserJoinedUnixSec = Math.round(message.lastUserJoinedUnixSec));
    return obj;
  },

  create<I extends Exact<DeepPartial<UserGroupJoinSummaryInfo>, I>>(base?: I): UserGroupJoinSummaryInfo {
    return UserGroupJoinSummaryInfo.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserGroupJoinSummaryInfo>, I>>(object: I): UserGroupJoinSummaryInfo {
    const message = createBaseUserGroupJoinSummaryInfo();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.administrators = object.administrators?.map((e) => UserInfo.fromPartial(e)) || [];
    message.datasets = object.datasets ?? 0;
    message.lastUserJoinedUnixSec = object.lastUserJoinedUnixSec ?? 0;
    return message;
  },
};

function createBaseUserGroupJoinRequestDB(): UserGroupJoinRequestDB {
  return { id: "", userId: "", joinGroupId: "", asMember: false, details: undefined, createdUnixSec: 0 };
}

export const UserGroupJoinRequestDB = {
  encode(message: UserGroupJoinRequestDB, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.userId !== "") {
      writer.uint32(18).string(message.userId);
    }
    if (message.joinGroupId !== "") {
      writer.uint32(26).string(message.joinGroupId);
    }
    if (message.asMember === true) {
      writer.uint32(32).bool(message.asMember);
    }
    if (message.details !== undefined) {
      UserInfo.encode(message.details, writer.uint32(42).fork()).ldelim();
    }
    if (message.createdUnixSec !== 0) {
      writer.uint32(48).uint32(message.createdUnixSec);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserGroupJoinRequestDB {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserGroupJoinRequestDB();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.joinGroupId = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.asMember = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.details = UserInfo.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.createdUnixSec = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserGroupJoinRequestDB {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      userId: isSet(object.userId) ? String(object.userId) : "",
      joinGroupId: isSet(object.joinGroupId) ? String(object.joinGroupId) : "",
      asMember: isSet(object.asMember) ? Boolean(object.asMember) : false,
      details: isSet(object.details) ? UserInfo.fromJSON(object.details) : undefined,
      createdUnixSec: isSet(object.createdUnixSec) ? Number(object.createdUnixSec) : 0,
    };
  },

  toJSON(message: UserGroupJoinRequestDB): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.userId !== undefined && (obj.userId = message.userId);
    message.joinGroupId !== undefined && (obj.joinGroupId = message.joinGroupId);
    message.asMember !== undefined && (obj.asMember = message.asMember);
    message.details !== undefined && (obj.details = message.details ? UserInfo.toJSON(message.details) : undefined);
    message.createdUnixSec !== undefined && (obj.createdUnixSec = Math.round(message.createdUnixSec));
    return obj;
  },

  create<I extends Exact<DeepPartial<UserGroupJoinRequestDB>, I>>(base?: I): UserGroupJoinRequestDB {
    return UserGroupJoinRequestDB.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserGroupJoinRequestDB>, I>>(object: I): UserGroupJoinRequestDB {
    const message = createBaseUserGroupJoinRequestDB();
    message.id = object.id ?? "";
    message.userId = object.userId ?? "";
    message.joinGroupId = object.joinGroupId ?? "";
    message.asMember = object.asMember ?? false;
    message.details = (object.details !== undefined && object.details !== null)
      ? UserInfo.fromPartial(object.details)
      : undefined;
    message.createdUnixSec = object.createdUnixSec ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
