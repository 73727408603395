<ng-template class="top-toolbar" #overflowSection>
  <widget-configuration-button
    *ngFor="let button of topToolbarButtons"
    [buttonConfiguration]="button"
    [ngStyle]="{ display: button._overflowed ? 'block' : 'none', margin: button.margin }"></widget-configuration-button>
</ng-template>

<ng-template #defaultOverflowSection>
  <div class="default-overflow"></div>
</ng-template>

<div class="widget {{ isWidgetHighlighted ? 'highlight' : '' }} {{ isWidgetTargeted ? 'targeted' : '' }}" [attr.aria-label]="_activeWidget">
  <header>
    <mat-select
      *ngIf="!disableSwitch"
      [(ngModel)]="activeWidget"
      #tooltip="matTooltip"
      [matTooltip]="widgetConfiguration?.description || ''"
      [matTooltipDisabled]="!widgetConfiguration?.description"
      matTooltipPositionAtOrigin="false">
      <mat-option *ngFor="let widget of allWidgetOptions" [value]="widget.id">
        <div class="widget-select">
          <span>{{ widget.name }}</span>
        </div>
      </mat-option>
    </mat-select>
    <div class="widget-title" *ngIf="disableSwitch && title.length > 0" [title]="title">{{ title }}</div>

    <div class="buttons" #buttonsContainer>
      <div class="top-toolbar {{ showTopToolbar ? 'visible' : 'hidden' }}" #topToolbar>
        <widget-configuration-button
          *ngFor="let button of topToolbarButtons"
          [buttonConfiguration]="button"
          [ngStyle]="{ display: button._overflowed ? 'none' : 'block', margin: button.margin }"></widget-configuration-button>
      </div>
      <widget-settings-menu
        *ngIf="settingsMenu || isOverflowed"
        [settingsDialog]="settingsMenu || defaultOverflowSection"
        [overflowSection]="overflowSection"
        #settingsButton>
        <icon-button #tooltip="matTooltip" matTooltip="Show Settings Menu" icon="assets/button-icons/settings.svg"> </icon-button>
      </widget-settings-menu>
    </div>
  </header>
  <div class="visualization {{ bottomToolbarButtons.length > 0 ? '' : 'hide-overflow' }}">
    <div *ngIf="isWidgetDataLoading" class="loading-overlay">
      <wait-spinner #tooltip="matTooltip" [matTooltip]="'Loading...'"></wait-spinner>
    </div>

    <widget-configuration-button
      class="inset-button top-left"
      [ngStyle]="topLeftInsetButton?.margin ? { margin: topLeftInsetButton?.margin } : {}"
      [buttonConfiguration]="topLeftInsetButton"></widget-configuration-button>
    <widget-configuration-button
      class="inset-button top-center"
      [ngStyle]="topCenterInsetButton?.margin ? { margin: topCenterInsetButton?.margin } : {}"
      [buttonConfiguration]="topCenterInsetButton"></widget-configuration-button>
    <widget-configuration-button
      class="inset-button top-right"
      [ngStyle]="topRightInsetButton?.margin ? { margin: topRightInsetButton?.margin } : {}"
      [buttonConfiguration]="topRightInsetButton"></widget-configuration-button>

    <ng-template #currentWidget></ng-template>

    <widget-configuration-button
      class="inset-button bottom-left"
      [ngStyle]="bottomLeftInsetButton?.margin ? { margin: bottomLeftInsetButton?.margin } : {}"
      [buttonConfiguration]="bottomLeftInsetButton"></widget-configuration-button>
    <widget-configuration-button
      class="inset-button bottom-right"
      [ngStyle]="bottomRightInsetButton?.margin ? { margin: bottomRightInsetButton?.margin } : {}"
      [buttonConfiguration]="bottomRightInsetButton"></widget-configuration-button>
  </div>
  <div class="bottom-toolbar {{ showBottomToolbar ? 'visible' : 'hidden' }}" #bottomToolbar>
    <widget-configuration-button
      *ngFor="let button of bottomToolbarButtons"
      [buttonConfiguration]="button"
      [ngStyle]="{ margin: button.margin }"></widget-configuration-button>
  </div>
</div>
