/* eslint-disable */
import * as _m0 from "protobufjs/minimal";

export const protobufPackage = "";

export enum NotificationMethod {
  NOTIF_NONE = 0,
  NOTIF_EMAIL = 1,
  NOTIF_UI = 2,
  NOTIF_BOTH = 3,
  UNRECOGNIZED = -1,
}

export function notificationMethodFromJSON(object: any): NotificationMethod {
  switch (object) {
    case 0:
    case "NOTIF_NONE":
      return NotificationMethod.NOTIF_NONE;
    case 1:
    case "NOTIF_EMAIL":
      return NotificationMethod.NOTIF_EMAIL;
    case 2:
    case "NOTIF_UI":
      return NotificationMethod.NOTIF_UI;
    case 3:
    case "NOTIF_BOTH":
      return NotificationMethod.NOTIF_BOTH;
    case -1:
    case "UNRECOGNIZED":
    default:
      return NotificationMethod.UNRECOGNIZED;
  }
}

export function notificationMethodToJSON(object: NotificationMethod): string {
  switch (object) {
    case NotificationMethod.NOTIF_NONE:
      return "NOTIF_NONE";
    case NotificationMethod.NOTIF_EMAIL:
      return "NOTIF_EMAIL";
    case NotificationMethod.NOTIF_UI:
      return "NOTIF_UI";
    case NotificationMethod.NOTIF_BOTH:
      return "NOTIF_BOTH";
    case NotificationMethod.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** NOTE: Need to ensure topic name (string key in map) is the same across all users of these messages! */
export interface UserNotificationSettings {
  topicSettings: { [key: string]: NotificationMethod };
}

export interface UserNotificationSettings_TopicSettingsEntry {
  key: string;
  value: NotificationMethod;
}

function createBaseUserNotificationSettings(): UserNotificationSettings {
  return { topicSettings: {} };
}

export const UserNotificationSettings = {
  encode(message: UserNotificationSettings, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.topicSettings).forEach(([key, value]) => {
      UserNotificationSettings_TopicSettingsEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserNotificationSettings {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserNotificationSettings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = UserNotificationSettings_TopicSettingsEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.topicSettings[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserNotificationSettings {
    return {
      topicSettings: isObject(object.topicSettings)
        ? Object.entries(object.topicSettings).reduce<{ [key: string]: NotificationMethod }>((acc, [key, value]) => {
          acc[key] = notificationMethodFromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: UserNotificationSettings): unknown {
    const obj: any = {};
    obj.topicSettings = {};
    if (message.topicSettings) {
      Object.entries(message.topicSettings).forEach(([k, v]) => {
        obj.topicSettings[k] = notificationMethodToJSON(v);
      });
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserNotificationSettings>, I>>(base?: I): UserNotificationSettings {
    return UserNotificationSettings.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserNotificationSettings>, I>>(object: I): UserNotificationSettings {
    const message = createBaseUserNotificationSettings();
    message.topicSettings = Object.entries(object.topicSettings ?? {}).reduce<{ [key: string]: NotificationMethod }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = value as NotificationMethod;
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseUserNotificationSettings_TopicSettingsEntry(): UserNotificationSettings_TopicSettingsEntry {
  return { key: "", value: 0 };
}

export const UserNotificationSettings_TopicSettingsEntry = {
  encode(message: UserNotificationSettings_TopicSettingsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== 0) {
      writer.uint32(16).int32(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserNotificationSettings_TopicSettingsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserNotificationSettings_TopicSettingsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.value = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserNotificationSettings_TopicSettingsEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? notificationMethodFromJSON(object.value) : 0,
    };
  },

  toJSON(message: UserNotificationSettings_TopicSettingsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = notificationMethodToJSON(message.value));
    return obj;
  },

  create<I extends Exact<DeepPartial<UserNotificationSettings_TopicSettingsEntry>, I>>(
    base?: I,
  ): UserNotificationSettings_TopicSettingsEntry {
    return UserNotificationSettings_TopicSettingsEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserNotificationSettings_TopicSettingsEntry>, I>>(
    object: I,
  ): UserNotificationSettings_TopicSettingsEntry {
    const message = createBaseUserNotificationSettings_TopicSettingsEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
