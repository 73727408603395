/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { ImageLocations } from "./image-beam-location";

export const protobufPackage = "";

/** requires(NONE) */
export interface ImageBeamLocationsReq {
  imageName: string;
  scanBeamVersions: { [key: string]: number };
  generateForScanId: string;
}

export interface ImageBeamLocationsReq_ScanBeamVersionsEntry {
  key: string;
  value: number;
}

export interface ImageBeamLocationsResp {
  locations: ImageLocations | undefined;
}

/** requires(NONE) */
export interface ImageBeamLocationVersionsReq {
  imageName: string;
}

export interface ImageBeamLocationVersionsResp {
  beamVersionPerScan: { [key: string]: ImageBeamLocationVersionsResp_AvailableVersions };
}

export interface ImageBeamLocationVersionsResp_AvailableVersions {
  versions: number[];
}

export interface ImageBeamLocationVersionsResp_BeamVersionPerScanEntry {
  key: string;
  value: ImageBeamLocationVersionsResp_AvailableVersions | undefined;
}

function createBaseImageBeamLocationsReq(): ImageBeamLocationsReq {
  return { imageName: "", scanBeamVersions: {}, generateForScanId: "" };
}

export const ImageBeamLocationsReq = {
  encode(message: ImageBeamLocationsReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.imageName !== "") {
      writer.uint32(10).string(message.imageName);
    }
    Object.entries(message.scanBeamVersions).forEach(([key, value]) => {
      ImageBeamLocationsReq_ScanBeamVersionsEntry.encode({ key: key as any, value }, writer.uint32(34).fork()).ldelim();
    });
    if (message.generateForScanId !== "") {
      writer.uint32(26).string(message.generateForScanId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImageBeamLocationsReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImageBeamLocationsReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.imageName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          const entry4 = ImageBeamLocationsReq_ScanBeamVersionsEntry.decode(reader, reader.uint32());
          if (entry4.value !== undefined) {
            message.scanBeamVersions[entry4.key] = entry4.value;
          }
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.generateForScanId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ImageBeamLocationsReq {
    return {
      imageName: isSet(object.imageName) ? String(object.imageName) : "",
      scanBeamVersions: isObject(object.scanBeamVersions)
        ? Object.entries(object.scanBeamVersions).reduce<{ [key: string]: number }>((acc, [key, value]) => {
          acc[key] = Number(value);
          return acc;
        }, {})
        : {},
      generateForScanId: isSet(object.generateForScanId) ? String(object.generateForScanId) : "",
    };
  },

  toJSON(message: ImageBeamLocationsReq): unknown {
    const obj: any = {};
    message.imageName !== undefined && (obj.imageName = message.imageName);
    obj.scanBeamVersions = {};
    if (message.scanBeamVersions) {
      Object.entries(message.scanBeamVersions).forEach(([k, v]) => {
        obj.scanBeamVersions[k] = Math.round(v);
      });
    }
    message.generateForScanId !== undefined && (obj.generateForScanId = message.generateForScanId);
    return obj;
  },

  create<I extends Exact<DeepPartial<ImageBeamLocationsReq>, I>>(base?: I): ImageBeamLocationsReq {
    return ImageBeamLocationsReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ImageBeamLocationsReq>, I>>(object: I): ImageBeamLocationsReq {
    const message = createBaseImageBeamLocationsReq();
    message.imageName = object.imageName ?? "";
    message.scanBeamVersions = Object.entries(object.scanBeamVersions ?? {}).reduce<{ [key: string]: number }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = Number(value);
        }
        return acc;
      },
      {},
    );
    message.generateForScanId = object.generateForScanId ?? "";
    return message;
  },
};

function createBaseImageBeamLocationsReq_ScanBeamVersionsEntry(): ImageBeamLocationsReq_ScanBeamVersionsEntry {
  return { key: "", value: 0 };
}

export const ImageBeamLocationsReq_ScanBeamVersionsEntry = {
  encode(message: ImageBeamLocationsReq_ScanBeamVersionsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== 0) {
      writer.uint32(16).uint32(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImageBeamLocationsReq_ScanBeamVersionsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImageBeamLocationsReq_ScanBeamVersionsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.value = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ImageBeamLocationsReq_ScanBeamVersionsEntry {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? Number(object.value) : 0 };
  },

  toJSON(message: ImageBeamLocationsReq_ScanBeamVersionsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = Math.round(message.value));
    return obj;
  },

  create<I extends Exact<DeepPartial<ImageBeamLocationsReq_ScanBeamVersionsEntry>, I>>(
    base?: I,
  ): ImageBeamLocationsReq_ScanBeamVersionsEntry {
    return ImageBeamLocationsReq_ScanBeamVersionsEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ImageBeamLocationsReq_ScanBeamVersionsEntry>, I>>(
    object: I,
  ): ImageBeamLocationsReq_ScanBeamVersionsEntry {
    const message = createBaseImageBeamLocationsReq_ScanBeamVersionsEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseImageBeamLocationsResp(): ImageBeamLocationsResp {
  return { locations: undefined };
}

export const ImageBeamLocationsResp = {
  encode(message: ImageBeamLocationsResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.locations !== undefined) {
      ImageLocations.encode(message.locations, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImageBeamLocationsResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImageBeamLocationsResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.locations = ImageLocations.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ImageBeamLocationsResp {
    return { locations: isSet(object.locations) ? ImageLocations.fromJSON(object.locations) : undefined };
  },

  toJSON(message: ImageBeamLocationsResp): unknown {
    const obj: any = {};
    message.locations !== undefined &&
      (obj.locations = message.locations ? ImageLocations.toJSON(message.locations) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ImageBeamLocationsResp>, I>>(base?: I): ImageBeamLocationsResp {
    return ImageBeamLocationsResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ImageBeamLocationsResp>, I>>(object: I): ImageBeamLocationsResp {
    const message = createBaseImageBeamLocationsResp();
    message.locations = (object.locations !== undefined && object.locations !== null)
      ? ImageLocations.fromPartial(object.locations)
      : undefined;
    return message;
  },
};

function createBaseImageBeamLocationVersionsReq(): ImageBeamLocationVersionsReq {
  return { imageName: "" };
}

export const ImageBeamLocationVersionsReq = {
  encode(message: ImageBeamLocationVersionsReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.imageName !== "") {
      writer.uint32(10).string(message.imageName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImageBeamLocationVersionsReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImageBeamLocationVersionsReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.imageName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ImageBeamLocationVersionsReq {
    return { imageName: isSet(object.imageName) ? String(object.imageName) : "" };
  },

  toJSON(message: ImageBeamLocationVersionsReq): unknown {
    const obj: any = {};
    message.imageName !== undefined && (obj.imageName = message.imageName);
    return obj;
  },

  create<I extends Exact<DeepPartial<ImageBeamLocationVersionsReq>, I>>(base?: I): ImageBeamLocationVersionsReq {
    return ImageBeamLocationVersionsReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ImageBeamLocationVersionsReq>, I>>(object: I): ImageBeamLocationVersionsReq {
    const message = createBaseImageBeamLocationVersionsReq();
    message.imageName = object.imageName ?? "";
    return message;
  },
};

function createBaseImageBeamLocationVersionsResp(): ImageBeamLocationVersionsResp {
  return { beamVersionPerScan: {} };
}

export const ImageBeamLocationVersionsResp = {
  encode(message: ImageBeamLocationVersionsResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.beamVersionPerScan).forEach(([key, value]) => {
      ImageBeamLocationVersionsResp_BeamVersionPerScanEntry.encode({ key: key as any, value }, writer.uint32(10).fork())
        .ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImageBeamLocationVersionsResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImageBeamLocationVersionsResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = ImageBeamLocationVersionsResp_BeamVersionPerScanEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.beamVersionPerScan[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ImageBeamLocationVersionsResp {
    return {
      beamVersionPerScan: isObject(object.beamVersionPerScan)
        ? Object.entries(object.beamVersionPerScan).reduce<
          { [key: string]: ImageBeamLocationVersionsResp_AvailableVersions }
        >((acc, [key, value]) => {
          acc[key] = ImageBeamLocationVersionsResp_AvailableVersions.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: ImageBeamLocationVersionsResp): unknown {
    const obj: any = {};
    obj.beamVersionPerScan = {};
    if (message.beamVersionPerScan) {
      Object.entries(message.beamVersionPerScan).forEach(([k, v]) => {
        obj.beamVersionPerScan[k] = ImageBeamLocationVersionsResp_AvailableVersions.toJSON(v);
      });
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ImageBeamLocationVersionsResp>, I>>(base?: I): ImageBeamLocationVersionsResp {
    return ImageBeamLocationVersionsResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ImageBeamLocationVersionsResp>, I>>(
    object: I,
  ): ImageBeamLocationVersionsResp {
    const message = createBaseImageBeamLocationVersionsResp();
    message.beamVersionPerScan = Object.entries(object.beamVersionPerScan ?? {}).reduce<
      { [key: string]: ImageBeamLocationVersionsResp_AvailableVersions }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = ImageBeamLocationVersionsResp_AvailableVersions.fromPartial(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseImageBeamLocationVersionsResp_AvailableVersions(): ImageBeamLocationVersionsResp_AvailableVersions {
  return { versions: [] };
}

export const ImageBeamLocationVersionsResp_AvailableVersions = {
  encode(
    message: ImageBeamLocationVersionsResp_AvailableVersions,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.versions) {
      writer.uint32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImageBeamLocationVersionsResp_AvailableVersions {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImageBeamLocationVersionsResp_AvailableVersions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.versions.push(reader.uint32());

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.versions.push(reader.uint32());
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ImageBeamLocationVersionsResp_AvailableVersions {
    return { versions: Array.isArray(object?.versions) ? object.versions.map((e: any) => Number(e)) : [] };
  },

  toJSON(message: ImageBeamLocationVersionsResp_AvailableVersions): unknown {
    const obj: any = {};
    if (message.versions) {
      obj.versions = message.versions.map((e) => Math.round(e));
    } else {
      obj.versions = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ImageBeamLocationVersionsResp_AvailableVersions>, I>>(
    base?: I,
  ): ImageBeamLocationVersionsResp_AvailableVersions {
    return ImageBeamLocationVersionsResp_AvailableVersions.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ImageBeamLocationVersionsResp_AvailableVersions>, I>>(
    object: I,
  ): ImageBeamLocationVersionsResp_AvailableVersions {
    const message = createBaseImageBeamLocationVersionsResp_AvailableVersions();
    message.versions = object.versions?.map((e) => e) || [];
    return message;
  },
};

function createBaseImageBeamLocationVersionsResp_BeamVersionPerScanEntry(): ImageBeamLocationVersionsResp_BeamVersionPerScanEntry {
  return { key: "", value: undefined };
}

export const ImageBeamLocationVersionsResp_BeamVersionPerScanEntry = {
  encode(
    message: ImageBeamLocationVersionsResp_BeamVersionPerScanEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      ImageBeamLocationVersionsResp_AvailableVersions.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImageBeamLocationVersionsResp_BeamVersionPerScanEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImageBeamLocationVersionsResp_BeamVersionPerScanEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = ImageBeamLocationVersionsResp_AvailableVersions.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ImageBeamLocationVersionsResp_BeamVersionPerScanEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? ImageBeamLocationVersionsResp_AvailableVersions.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: ImageBeamLocationVersionsResp_BeamVersionPerScanEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined &&
      (obj.value = message.value ? ImageBeamLocationVersionsResp_AvailableVersions.toJSON(message.value) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ImageBeamLocationVersionsResp_BeamVersionPerScanEntry>, I>>(
    base?: I,
  ): ImageBeamLocationVersionsResp_BeamVersionPerScanEntry {
    return ImageBeamLocationVersionsResp_BeamVersionPerScanEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ImageBeamLocationVersionsResp_BeamVersionPerScanEntry>, I>>(
    object: I,
  ): ImageBeamLocationVersionsResp_BeamVersionPerScanEntry {
    const message = createBaseImageBeamLocationVersionsResp_BeamVersionPerScanEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? ImageBeamLocationVersionsResp_AvailableVersions.fromPartial(object.value)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
