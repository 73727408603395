/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { JobStatus } from "./job";
import { QuantCreateParams } from "./quantification-meta";

export const protobufPackage = "";

/** requires(QUANTIFY) */
export interface QuantCreateReq {
  params: QuantCreateParams | undefined;
}

export interface QuantCreateResp {
  /** This returns the job status and updates will follow */
  status: JobStatus | undefined;
}

/**
 * Updates are sent until the job timeout is reached, at which point a final update will
 * be sent saying the job has timed out. Hopefully it completes well before that though
 */
export interface QuantCreateUpd {
  status:
    | JobStatus
    | undefined;
  /** If the req just ran a one-shot quick command, the result is returned here */
  resultData: Uint8Array;
}

function createBaseQuantCreateReq(): QuantCreateReq {
  return { params: undefined };
}

export const QuantCreateReq = {
  encode(message: QuantCreateReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.params !== undefined) {
      QuantCreateParams.encode(message.params, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuantCreateReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantCreateReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.params = QuantCreateParams.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuantCreateReq {
    return { params: isSet(object.params) ? QuantCreateParams.fromJSON(object.params) : undefined };
  },

  toJSON(message: QuantCreateReq): unknown {
    const obj: any = {};
    message.params !== undefined &&
      (obj.params = message.params ? QuantCreateParams.toJSON(message.params) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<QuantCreateReq>, I>>(base?: I): QuantCreateReq {
    return QuantCreateReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuantCreateReq>, I>>(object: I): QuantCreateReq {
    const message = createBaseQuantCreateReq();
    message.params = (object.params !== undefined && object.params !== null)
      ? QuantCreateParams.fromPartial(object.params)
      : undefined;
    return message;
  },
};

function createBaseQuantCreateResp(): QuantCreateResp {
  return { status: undefined };
}

export const QuantCreateResp = {
  encode(message: QuantCreateResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== undefined) {
      JobStatus.encode(message.status, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuantCreateResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantCreateResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.status = JobStatus.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuantCreateResp {
    return { status: isSet(object.status) ? JobStatus.fromJSON(object.status) : undefined };
  },

  toJSON(message: QuantCreateResp): unknown {
    const obj: any = {};
    message.status !== undefined && (obj.status = message.status ? JobStatus.toJSON(message.status) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<QuantCreateResp>, I>>(base?: I): QuantCreateResp {
    return QuantCreateResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuantCreateResp>, I>>(object: I): QuantCreateResp {
    const message = createBaseQuantCreateResp();
    message.status = (object.status !== undefined && object.status !== null)
      ? JobStatus.fromPartial(object.status)
      : undefined;
    return message;
  },
};

function createBaseQuantCreateUpd(): QuantCreateUpd {
  return { status: undefined, resultData: new Uint8Array(0) };
}

export const QuantCreateUpd = {
  encode(message: QuantCreateUpd, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== undefined) {
      JobStatus.encode(message.status, writer.uint32(10).fork()).ldelim();
    }
    if (message.resultData.length !== 0) {
      writer.uint32(18).bytes(message.resultData);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuantCreateUpd {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantCreateUpd();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.status = JobStatus.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.resultData = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuantCreateUpd {
    return {
      status: isSet(object.status) ? JobStatus.fromJSON(object.status) : undefined,
      resultData: isSet(object.resultData) ? bytesFromBase64(object.resultData) : new Uint8Array(0),
    };
  },

  toJSON(message: QuantCreateUpd): unknown {
    const obj: any = {};
    message.status !== undefined && (obj.status = message.status ? JobStatus.toJSON(message.status) : undefined);
    message.resultData !== undefined &&
      (obj.resultData = base64FromBytes(message.resultData !== undefined ? message.resultData : new Uint8Array(0)));
    return obj;
  },

  create<I extends Exact<DeepPartial<QuantCreateUpd>, I>>(base?: I): QuantCreateUpd {
    return QuantCreateUpd.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuantCreateUpd>, I>>(object: I): QuantCreateUpd {
    const message = createBaseQuantCreateUpd();
    message.status = (object.status !== undefined && object.status !== null)
      ? JobStatus.fromPartial(object.status)
      : undefined;
    message.resultData = object.resultData ?? new Uint8Array(0);
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

function bytesFromBase64(b64: string): Uint8Array {
  if (tsProtoGlobalThis.Buffer) {
    return Uint8Array.from(tsProtoGlobalThis.Buffer.from(b64, "base64"));
  } else {
    const bin = tsProtoGlobalThis.atob(b64);
    const arr = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; ++i) {
      arr[i] = bin.charCodeAt(i);
    }
    return arr;
  }
}

function base64FromBytes(arr: Uint8Array): string {
  if (tsProtoGlobalThis.Buffer) {
    return tsProtoGlobalThis.Buffer.from(arr).toString("base64");
  } else {
    const bin: string[] = [];
    arr.forEach((byte) => {
      bin.push(String.fromCharCode(byte));
    });
    return tsProtoGlobalThis.btoa(bin.join(""));
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
