/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { ImageMatchTransform, ScanImage } from "./image";
import { ImageLocations } from "./image-beam-location";

export const protobufPackage = "";

/** requires(NONE) */
export interface ImageListReq {
  scanIds: string[];
  /**
   * If false, returns all images associated with any of the scan IDs
   * otherwise only returns images which are associated with all IDs
   */
  mustIncludeAll: boolean;
}

export interface ImageListResp {
  /** uint32 defaultImageIdx = 2; <-- Now that ImageListReq takes a list of scanIds - which scan does this correspond to? */
  images: ScanImage[];
}

/** If something happens to an image - changed, deleted, metadata changed, etc this gets sent out */
export interface ImageListUpd {
  associatedScanId: string;
  imageName: string;
}

/** requires(NONE) */
export interface ImageGetReq {
  imageName: string;
}

export interface ImageGetResp {
  image: ScanImage | undefined;
}

/** requires(NONE) */
export interface ImageGetDefaultReq {
  scanIds: string[];
}

export interface ImageGetDefaultResp {
  defaultImagesPerScanId: { [key: string]: string };
}

export interface ImageGetDefaultResp_DefaultImagesPerScanIdEntry {
  key: string;
  value: string;
}

/** requires(EDIT_SCAN) */
export interface ImageSetDefaultReq {
  scanId: string;
  defaultImageFileName: string;
}

export interface ImageSetDefaultResp {
}

export interface ImageUploadHttpRequest {
  name: string;
  imageData: Uint8Array;
  /** List of all scan ids which are associated with this image - in other words their points are visible on the image */
  associatedScanIds: string[];
  /** The scan this image came from */
  originScanId: string;
  /**
   * If uploading the optional beam locations, they can be in this field
   * NOTE: locationPerScan.imageFileName must be empty
   */
  locationPerScan?:
    | ImageLocations
    | undefined;
  /**
   * Otherwise, if we're saying this image will use the beam
   * data for another image, put the name of the other image here
   */
  beamImageRef?: ImageMatchTransform | undefined;
}

/** requires(EDIT_SCAN) */
export interface ImageDeleteReq {
  name: string;
}

export interface ImageDeleteResp {
}

/** requires(EDIT_SCAN) */
export interface ImageSetMatchTransformReq {
  imageName: string;
  transform: ImageMatchTransform | undefined;
}

export interface ImageSetMatchTransformResp {
}

function createBaseImageListReq(): ImageListReq {
  return { scanIds: [], mustIncludeAll: false };
}

export const ImageListReq = {
  encode(message: ImageListReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.scanIds) {
      writer.uint32(10).string(v!);
    }
    if (message.mustIncludeAll === true) {
      writer.uint32(16).bool(message.mustIncludeAll);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImageListReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImageListReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.scanIds.push(reader.string());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.mustIncludeAll = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ImageListReq {
    return {
      scanIds: Array.isArray(object?.scanIds) ? object.scanIds.map((e: any) => String(e)) : [],
      mustIncludeAll: isSet(object.mustIncludeAll) ? Boolean(object.mustIncludeAll) : false,
    };
  },

  toJSON(message: ImageListReq): unknown {
    const obj: any = {};
    if (message.scanIds) {
      obj.scanIds = message.scanIds.map((e) => e);
    } else {
      obj.scanIds = [];
    }
    message.mustIncludeAll !== undefined && (obj.mustIncludeAll = message.mustIncludeAll);
    return obj;
  },

  create<I extends Exact<DeepPartial<ImageListReq>, I>>(base?: I): ImageListReq {
    return ImageListReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ImageListReq>, I>>(object: I): ImageListReq {
    const message = createBaseImageListReq();
    message.scanIds = object.scanIds?.map((e) => e) || [];
    message.mustIncludeAll = object.mustIncludeAll ?? false;
    return message;
  },
};

function createBaseImageListResp(): ImageListResp {
  return { images: [] };
}

export const ImageListResp = {
  encode(message: ImageListResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.images) {
      ScanImage.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImageListResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImageListResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.images.push(ScanImage.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ImageListResp {
    return { images: Array.isArray(object?.images) ? object.images.map((e: any) => ScanImage.fromJSON(e)) : [] };
  },

  toJSON(message: ImageListResp): unknown {
    const obj: any = {};
    if (message.images) {
      obj.images = message.images.map((e) => e ? ScanImage.toJSON(e) : undefined);
    } else {
      obj.images = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ImageListResp>, I>>(base?: I): ImageListResp {
    return ImageListResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ImageListResp>, I>>(object: I): ImageListResp {
    const message = createBaseImageListResp();
    message.images = object.images?.map((e) => ScanImage.fromPartial(e)) || [];
    return message;
  },
};

function createBaseImageListUpd(): ImageListUpd {
  return { associatedScanId: "", imageName: "" };
}

export const ImageListUpd = {
  encode(message: ImageListUpd, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.associatedScanId !== "") {
      writer.uint32(10).string(message.associatedScanId);
    }
    if (message.imageName !== "") {
      writer.uint32(18).string(message.imageName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImageListUpd {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImageListUpd();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.associatedScanId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.imageName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ImageListUpd {
    return {
      associatedScanId: isSet(object.associatedScanId) ? String(object.associatedScanId) : "",
      imageName: isSet(object.imageName) ? String(object.imageName) : "",
    };
  },

  toJSON(message: ImageListUpd): unknown {
    const obj: any = {};
    message.associatedScanId !== undefined && (obj.associatedScanId = message.associatedScanId);
    message.imageName !== undefined && (obj.imageName = message.imageName);
    return obj;
  },

  create<I extends Exact<DeepPartial<ImageListUpd>, I>>(base?: I): ImageListUpd {
    return ImageListUpd.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ImageListUpd>, I>>(object: I): ImageListUpd {
    const message = createBaseImageListUpd();
    message.associatedScanId = object.associatedScanId ?? "";
    message.imageName = object.imageName ?? "";
    return message;
  },
};

function createBaseImageGetReq(): ImageGetReq {
  return { imageName: "" };
}

export const ImageGetReq = {
  encode(message: ImageGetReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.imageName !== "") {
      writer.uint32(10).string(message.imageName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImageGetReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImageGetReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.imageName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ImageGetReq {
    return { imageName: isSet(object.imageName) ? String(object.imageName) : "" };
  },

  toJSON(message: ImageGetReq): unknown {
    const obj: any = {};
    message.imageName !== undefined && (obj.imageName = message.imageName);
    return obj;
  },

  create<I extends Exact<DeepPartial<ImageGetReq>, I>>(base?: I): ImageGetReq {
    return ImageGetReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ImageGetReq>, I>>(object: I): ImageGetReq {
    const message = createBaseImageGetReq();
    message.imageName = object.imageName ?? "";
    return message;
  },
};

function createBaseImageGetResp(): ImageGetResp {
  return { image: undefined };
}

export const ImageGetResp = {
  encode(message: ImageGetResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.image !== undefined) {
      ScanImage.encode(message.image, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImageGetResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImageGetResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.image = ScanImage.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ImageGetResp {
    return { image: isSet(object.image) ? ScanImage.fromJSON(object.image) : undefined };
  },

  toJSON(message: ImageGetResp): unknown {
    const obj: any = {};
    message.image !== undefined && (obj.image = message.image ? ScanImage.toJSON(message.image) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ImageGetResp>, I>>(base?: I): ImageGetResp {
    return ImageGetResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ImageGetResp>, I>>(object: I): ImageGetResp {
    const message = createBaseImageGetResp();
    message.image = (object.image !== undefined && object.image !== null)
      ? ScanImage.fromPartial(object.image)
      : undefined;
    return message;
  },
};

function createBaseImageGetDefaultReq(): ImageGetDefaultReq {
  return { scanIds: [] };
}

export const ImageGetDefaultReq = {
  encode(message: ImageGetDefaultReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.scanIds) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImageGetDefaultReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImageGetDefaultReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.scanIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ImageGetDefaultReq {
    return { scanIds: Array.isArray(object?.scanIds) ? object.scanIds.map((e: any) => String(e)) : [] };
  },

  toJSON(message: ImageGetDefaultReq): unknown {
    const obj: any = {};
    if (message.scanIds) {
      obj.scanIds = message.scanIds.map((e) => e);
    } else {
      obj.scanIds = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ImageGetDefaultReq>, I>>(base?: I): ImageGetDefaultReq {
    return ImageGetDefaultReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ImageGetDefaultReq>, I>>(object: I): ImageGetDefaultReq {
    const message = createBaseImageGetDefaultReq();
    message.scanIds = object.scanIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseImageGetDefaultResp(): ImageGetDefaultResp {
  return { defaultImagesPerScanId: {} };
}

export const ImageGetDefaultResp = {
  encode(message: ImageGetDefaultResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.defaultImagesPerScanId).forEach(([key, value]) => {
      ImageGetDefaultResp_DefaultImagesPerScanIdEntry.encode({ key: key as any, value }, writer.uint32(10).fork())
        .ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImageGetDefaultResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImageGetDefaultResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = ImageGetDefaultResp_DefaultImagesPerScanIdEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.defaultImagesPerScanId[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ImageGetDefaultResp {
    return {
      defaultImagesPerScanId: isObject(object.defaultImagesPerScanId)
        ? Object.entries(object.defaultImagesPerScanId).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: ImageGetDefaultResp): unknown {
    const obj: any = {};
    obj.defaultImagesPerScanId = {};
    if (message.defaultImagesPerScanId) {
      Object.entries(message.defaultImagesPerScanId).forEach(([k, v]) => {
        obj.defaultImagesPerScanId[k] = v;
      });
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ImageGetDefaultResp>, I>>(base?: I): ImageGetDefaultResp {
    return ImageGetDefaultResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ImageGetDefaultResp>, I>>(object: I): ImageGetDefaultResp {
    const message = createBaseImageGetDefaultResp();
    message.defaultImagesPerScanId = Object.entries(object.defaultImagesPerScanId ?? {}).reduce<
      { [key: string]: string }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = String(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseImageGetDefaultResp_DefaultImagesPerScanIdEntry(): ImageGetDefaultResp_DefaultImagesPerScanIdEntry {
  return { key: "", value: "" };
}

export const ImageGetDefaultResp_DefaultImagesPerScanIdEntry = {
  encode(
    message: ImageGetDefaultResp_DefaultImagesPerScanIdEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImageGetDefaultResp_DefaultImagesPerScanIdEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImageGetDefaultResp_DefaultImagesPerScanIdEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ImageGetDefaultResp_DefaultImagesPerScanIdEntry {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? String(object.value) : "" };
  },

  toJSON(message: ImageGetDefaultResp_DefaultImagesPerScanIdEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create<I extends Exact<DeepPartial<ImageGetDefaultResp_DefaultImagesPerScanIdEntry>, I>>(
    base?: I,
  ): ImageGetDefaultResp_DefaultImagesPerScanIdEntry {
    return ImageGetDefaultResp_DefaultImagesPerScanIdEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ImageGetDefaultResp_DefaultImagesPerScanIdEntry>, I>>(
    object: I,
  ): ImageGetDefaultResp_DefaultImagesPerScanIdEntry {
    const message = createBaseImageGetDefaultResp_DefaultImagesPerScanIdEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseImageSetDefaultReq(): ImageSetDefaultReq {
  return { scanId: "", defaultImageFileName: "" };
}

export const ImageSetDefaultReq = {
  encode(message: ImageSetDefaultReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.scanId !== "") {
      writer.uint32(10).string(message.scanId);
    }
    if (message.defaultImageFileName !== "") {
      writer.uint32(18).string(message.defaultImageFileName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImageSetDefaultReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImageSetDefaultReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.scanId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.defaultImageFileName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ImageSetDefaultReq {
    return {
      scanId: isSet(object.scanId) ? String(object.scanId) : "",
      defaultImageFileName: isSet(object.defaultImageFileName) ? String(object.defaultImageFileName) : "",
    };
  },

  toJSON(message: ImageSetDefaultReq): unknown {
    const obj: any = {};
    message.scanId !== undefined && (obj.scanId = message.scanId);
    message.defaultImageFileName !== undefined && (obj.defaultImageFileName = message.defaultImageFileName);
    return obj;
  },

  create<I extends Exact<DeepPartial<ImageSetDefaultReq>, I>>(base?: I): ImageSetDefaultReq {
    return ImageSetDefaultReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ImageSetDefaultReq>, I>>(object: I): ImageSetDefaultReq {
    const message = createBaseImageSetDefaultReq();
    message.scanId = object.scanId ?? "";
    message.defaultImageFileName = object.defaultImageFileName ?? "";
    return message;
  },
};

function createBaseImageSetDefaultResp(): ImageSetDefaultResp {
  return {};
}

export const ImageSetDefaultResp = {
  encode(_: ImageSetDefaultResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImageSetDefaultResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImageSetDefaultResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ImageSetDefaultResp {
    return {};
  },

  toJSON(_: ImageSetDefaultResp): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ImageSetDefaultResp>, I>>(base?: I): ImageSetDefaultResp {
    return ImageSetDefaultResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ImageSetDefaultResp>, I>>(_: I): ImageSetDefaultResp {
    const message = createBaseImageSetDefaultResp();
    return message;
  },
};

function createBaseImageUploadHttpRequest(): ImageUploadHttpRequest {
  return {
    name: "",
    imageData: new Uint8Array(0),
    associatedScanIds: [],
    originScanId: "",
    locationPerScan: undefined,
    beamImageRef: undefined,
  };
}

export const ImageUploadHttpRequest = {
  encode(message: ImageUploadHttpRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.imageData.length !== 0) {
      writer.uint32(18).bytes(message.imageData);
    }
    for (const v of message.associatedScanIds) {
      writer.uint32(42).string(v!);
    }
    if (message.originScanId !== "") {
      writer.uint32(50).string(message.originScanId);
    }
    if (message.locationPerScan !== undefined) {
      ImageLocations.encode(message.locationPerScan, writer.uint32(26).fork()).ldelim();
    }
    if (message.beamImageRef !== undefined) {
      ImageMatchTransform.encode(message.beamImageRef, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImageUploadHttpRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImageUploadHttpRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.imageData = reader.bytes();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.associatedScanIds.push(reader.string());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.originScanId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.locationPerScan = ImageLocations.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.beamImageRef = ImageMatchTransform.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ImageUploadHttpRequest {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      imageData: isSet(object.imageData) ? bytesFromBase64(object.imageData) : new Uint8Array(0),
      associatedScanIds: Array.isArray(object?.associatedScanIds)
        ? object.associatedScanIds.map((e: any) => String(e))
        : [],
      originScanId: isSet(object.originScanId) ? String(object.originScanId) : "",
      locationPerScan: isSet(object.locationPerScan) ? ImageLocations.fromJSON(object.locationPerScan) : undefined,
      beamImageRef: isSet(object.beamImageRef) ? ImageMatchTransform.fromJSON(object.beamImageRef) : undefined,
    };
  },

  toJSON(message: ImageUploadHttpRequest): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.imageData !== undefined &&
      (obj.imageData = base64FromBytes(message.imageData !== undefined ? message.imageData : new Uint8Array(0)));
    if (message.associatedScanIds) {
      obj.associatedScanIds = message.associatedScanIds.map((e) => e);
    } else {
      obj.associatedScanIds = [];
    }
    message.originScanId !== undefined && (obj.originScanId = message.originScanId);
    message.locationPerScan !== undefined &&
      (obj.locationPerScan = message.locationPerScan ? ImageLocations.toJSON(message.locationPerScan) : undefined);
    message.beamImageRef !== undefined &&
      (obj.beamImageRef = message.beamImageRef ? ImageMatchTransform.toJSON(message.beamImageRef) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ImageUploadHttpRequest>, I>>(base?: I): ImageUploadHttpRequest {
    return ImageUploadHttpRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ImageUploadHttpRequest>, I>>(object: I): ImageUploadHttpRequest {
    const message = createBaseImageUploadHttpRequest();
    message.name = object.name ?? "";
    message.imageData = object.imageData ?? new Uint8Array(0);
    message.associatedScanIds = object.associatedScanIds?.map((e) => e) || [];
    message.originScanId = object.originScanId ?? "";
    message.locationPerScan = (object.locationPerScan !== undefined && object.locationPerScan !== null)
      ? ImageLocations.fromPartial(object.locationPerScan)
      : undefined;
    message.beamImageRef = (object.beamImageRef !== undefined && object.beamImageRef !== null)
      ? ImageMatchTransform.fromPartial(object.beamImageRef)
      : undefined;
    return message;
  },
};

function createBaseImageDeleteReq(): ImageDeleteReq {
  return { name: "" };
}

export const ImageDeleteReq = {
  encode(message: ImageDeleteReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImageDeleteReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImageDeleteReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ImageDeleteReq {
    return { name: isSet(object.name) ? String(object.name) : "" };
  },

  toJSON(message: ImageDeleteReq): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  create<I extends Exact<DeepPartial<ImageDeleteReq>, I>>(base?: I): ImageDeleteReq {
    return ImageDeleteReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ImageDeleteReq>, I>>(object: I): ImageDeleteReq {
    const message = createBaseImageDeleteReq();
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseImageDeleteResp(): ImageDeleteResp {
  return {};
}

export const ImageDeleteResp = {
  encode(_: ImageDeleteResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImageDeleteResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImageDeleteResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ImageDeleteResp {
    return {};
  },

  toJSON(_: ImageDeleteResp): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ImageDeleteResp>, I>>(base?: I): ImageDeleteResp {
    return ImageDeleteResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ImageDeleteResp>, I>>(_: I): ImageDeleteResp {
    const message = createBaseImageDeleteResp();
    return message;
  },
};

function createBaseImageSetMatchTransformReq(): ImageSetMatchTransformReq {
  return { imageName: "", transform: undefined };
}

export const ImageSetMatchTransformReq = {
  encode(message: ImageSetMatchTransformReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.imageName !== "") {
      writer.uint32(10).string(message.imageName);
    }
    if (message.transform !== undefined) {
      ImageMatchTransform.encode(message.transform, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImageSetMatchTransformReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImageSetMatchTransformReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.imageName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.transform = ImageMatchTransform.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ImageSetMatchTransformReq {
    return {
      imageName: isSet(object.imageName) ? String(object.imageName) : "",
      transform: isSet(object.transform) ? ImageMatchTransform.fromJSON(object.transform) : undefined,
    };
  },

  toJSON(message: ImageSetMatchTransformReq): unknown {
    const obj: any = {};
    message.imageName !== undefined && (obj.imageName = message.imageName);
    message.transform !== undefined &&
      (obj.transform = message.transform ? ImageMatchTransform.toJSON(message.transform) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ImageSetMatchTransformReq>, I>>(base?: I): ImageSetMatchTransformReq {
    return ImageSetMatchTransformReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ImageSetMatchTransformReq>, I>>(object: I): ImageSetMatchTransformReq {
    const message = createBaseImageSetMatchTransformReq();
    message.imageName = object.imageName ?? "";
    message.transform = (object.transform !== undefined && object.transform !== null)
      ? ImageMatchTransform.fromPartial(object.transform)
      : undefined;
    return message;
  },
};

function createBaseImageSetMatchTransformResp(): ImageSetMatchTransformResp {
  return {};
}

export const ImageSetMatchTransformResp = {
  encode(_: ImageSetMatchTransformResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImageSetMatchTransformResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImageSetMatchTransformResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ImageSetMatchTransformResp {
    return {};
  },

  toJSON(_: ImageSetMatchTransformResp): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ImageSetMatchTransformResp>, I>>(base?: I): ImageSetMatchTransformResp {
    return ImageSetMatchTransformResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ImageSetMatchTransformResp>, I>>(_: I): ImageSetMatchTransformResp {
    const message = createBaseImageSetMatchTransformResp();
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

function bytesFromBase64(b64: string): Uint8Array {
  if (tsProtoGlobalThis.Buffer) {
    return Uint8Array.from(tsProtoGlobalThis.Buffer.from(b64, "base64"));
  } else {
    const bin = tsProtoGlobalThis.atob(b64);
    const arr = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; ++i) {
      arr[i] = bin.charCodeAt(i);
    }
    return arr;
  }
}

function base64FromBytes(arr: Uint8Array): string {
  if (tsProtoGlobalThis.Buffer) {
    return tsProtoGlobalThis.Buffer.from(arr).toString("base64");
  } else {
    const bin: string[] = [];
    arr.forEach((byte) => {
      bin.push(String.fromCharCode(byte));
    });
    return tsProtoGlobalThis.btoa(bin.join(""));
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
