/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { DataExpression, DataExpressionExecStats, ExpressionDisplaySettings } from "./expressions";
import { SearchParams } from "./search-params";

export const protobufPackage = "";

/** requires(NONE) */
export interface ExpressionListReq {
  searchParams: SearchParams | undefined;
}

export interface ExpressionListResp {
  expressions: { [key: string]: DataExpression };
}

export interface ExpressionListResp_ExpressionsEntry {
  key: string;
  value: DataExpression | undefined;
}

/** requires(NONE) */
export interface ExpressionGetReq {
  id: string;
}

export interface ExpressionGetResp {
  expression: DataExpression | undefined;
}

/**
 * If id is blank, assume its new and generate an ID to return, otherwise update & return same one
 * requires(EDIT_EXPRESSION)
 */
export interface ExpressionWriteReq {
  expression: DataExpression | undefined;
}

export interface ExpressionWriteResp {
  expression: DataExpression | undefined;
}

/** requires(EDIT_EXPRESSION) */
export interface ExpressionDeleteReq {
  id: string;
}

export interface ExpressionDeleteResp {
}

/** requires(NONE) */
export interface ExpressionWriteExecStatReq {
  id: string;
  stats: DataExpressionExecStats | undefined;
}

export interface ExpressionWriteExecStatResp {
}

/**
 * The user is configuring an expression for their own use, but not editing it
 * requires(EDIT_OWN_USER)
 */
export interface ExpressionDisplaySettingsWriteReq {
  id: string;
  displaySettings: ExpressionDisplaySettings | undefined;
}

export interface ExpressionDisplaySettingsWriteResp {
  displaySettings: ExpressionDisplaySettings | undefined;
}

/** requires(NONE) */
export interface ExpressionDisplaySettingsGetReq {
  id: string;
}

export interface ExpressionDisplaySettingsGetResp {
  displaySettings: ExpressionDisplaySettings | undefined;
}

function createBaseExpressionListReq(): ExpressionListReq {
  return { searchParams: undefined };
}

export const ExpressionListReq = {
  encode(message: ExpressionListReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.searchParams !== undefined) {
      SearchParams.encode(message.searchParams, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExpressionListReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExpressionListReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.searchParams = SearchParams.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExpressionListReq {
    return { searchParams: isSet(object.searchParams) ? SearchParams.fromJSON(object.searchParams) : undefined };
  },

  toJSON(message: ExpressionListReq): unknown {
    const obj: any = {};
    message.searchParams !== undefined &&
      (obj.searchParams = message.searchParams ? SearchParams.toJSON(message.searchParams) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ExpressionListReq>, I>>(base?: I): ExpressionListReq {
    return ExpressionListReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ExpressionListReq>, I>>(object: I): ExpressionListReq {
    const message = createBaseExpressionListReq();
    message.searchParams = (object.searchParams !== undefined && object.searchParams !== null)
      ? SearchParams.fromPartial(object.searchParams)
      : undefined;
    return message;
  },
};

function createBaseExpressionListResp(): ExpressionListResp {
  return { expressions: {} };
}

export const ExpressionListResp = {
  encode(message: ExpressionListResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.expressions).forEach(([key, value]) => {
      ExpressionListResp_ExpressionsEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExpressionListResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExpressionListResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = ExpressionListResp_ExpressionsEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.expressions[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExpressionListResp {
    return {
      expressions: isObject(object.expressions)
        ? Object.entries(object.expressions).reduce<{ [key: string]: DataExpression }>((acc, [key, value]) => {
          acc[key] = DataExpression.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: ExpressionListResp): unknown {
    const obj: any = {};
    obj.expressions = {};
    if (message.expressions) {
      Object.entries(message.expressions).forEach(([k, v]) => {
        obj.expressions[k] = DataExpression.toJSON(v);
      });
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ExpressionListResp>, I>>(base?: I): ExpressionListResp {
    return ExpressionListResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ExpressionListResp>, I>>(object: I): ExpressionListResp {
    const message = createBaseExpressionListResp();
    message.expressions = Object.entries(object.expressions ?? {}).reduce<{ [key: string]: DataExpression }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = DataExpression.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseExpressionListResp_ExpressionsEntry(): ExpressionListResp_ExpressionsEntry {
  return { key: "", value: undefined };
}

export const ExpressionListResp_ExpressionsEntry = {
  encode(message: ExpressionListResp_ExpressionsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      DataExpression.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExpressionListResp_ExpressionsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExpressionListResp_ExpressionsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = DataExpression.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExpressionListResp_ExpressionsEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? DataExpression.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: ExpressionListResp_ExpressionsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value ? DataExpression.toJSON(message.value) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ExpressionListResp_ExpressionsEntry>, I>>(
    base?: I,
  ): ExpressionListResp_ExpressionsEntry {
    return ExpressionListResp_ExpressionsEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ExpressionListResp_ExpressionsEntry>, I>>(
    object: I,
  ): ExpressionListResp_ExpressionsEntry {
    const message = createBaseExpressionListResp_ExpressionsEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? DataExpression.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseExpressionGetReq(): ExpressionGetReq {
  return { id: "" };
}

export const ExpressionGetReq = {
  encode(message: ExpressionGetReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExpressionGetReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExpressionGetReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExpressionGetReq {
    return { id: isSet(object.id) ? String(object.id) : "" };
  },

  toJSON(message: ExpressionGetReq): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  create<I extends Exact<DeepPartial<ExpressionGetReq>, I>>(base?: I): ExpressionGetReq {
    return ExpressionGetReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ExpressionGetReq>, I>>(object: I): ExpressionGetReq {
    const message = createBaseExpressionGetReq();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseExpressionGetResp(): ExpressionGetResp {
  return { expression: undefined };
}

export const ExpressionGetResp = {
  encode(message: ExpressionGetResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.expression !== undefined) {
      DataExpression.encode(message.expression, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExpressionGetResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExpressionGetResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.expression = DataExpression.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExpressionGetResp {
    return { expression: isSet(object.expression) ? DataExpression.fromJSON(object.expression) : undefined };
  },

  toJSON(message: ExpressionGetResp): unknown {
    const obj: any = {};
    message.expression !== undefined &&
      (obj.expression = message.expression ? DataExpression.toJSON(message.expression) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ExpressionGetResp>, I>>(base?: I): ExpressionGetResp {
    return ExpressionGetResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ExpressionGetResp>, I>>(object: I): ExpressionGetResp {
    const message = createBaseExpressionGetResp();
    message.expression = (object.expression !== undefined && object.expression !== null)
      ? DataExpression.fromPartial(object.expression)
      : undefined;
    return message;
  },
};

function createBaseExpressionWriteReq(): ExpressionWriteReq {
  return { expression: undefined };
}

export const ExpressionWriteReq = {
  encode(message: ExpressionWriteReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.expression !== undefined) {
      DataExpression.encode(message.expression, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExpressionWriteReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExpressionWriteReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.expression = DataExpression.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExpressionWriteReq {
    return { expression: isSet(object.expression) ? DataExpression.fromJSON(object.expression) : undefined };
  },

  toJSON(message: ExpressionWriteReq): unknown {
    const obj: any = {};
    message.expression !== undefined &&
      (obj.expression = message.expression ? DataExpression.toJSON(message.expression) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ExpressionWriteReq>, I>>(base?: I): ExpressionWriteReq {
    return ExpressionWriteReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ExpressionWriteReq>, I>>(object: I): ExpressionWriteReq {
    const message = createBaseExpressionWriteReq();
    message.expression = (object.expression !== undefined && object.expression !== null)
      ? DataExpression.fromPartial(object.expression)
      : undefined;
    return message;
  },
};

function createBaseExpressionWriteResp(): ExpressionWriteResp {
  return { expression: undefined };
}

export const ExpressionWriteResp = {
  encode(message: ExpressionWriteResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.expression !== undefined) {
      DataExpression.encode(message.expression, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExpressionWriteResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExpressionWriteResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.expression = DataExpression.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExpressionWriteResp {
    return { expression: isSet(object.expression) ? DataExpression.fromJSON(object.expression) : undefined };
  },

  toJSON(message: ExpressionWriteResp): unknown {
    const obj: any = {};
    message.expression !== undefined &&
      (obj.expression = message.expression ? DataExpression.toJSON(message.expression) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ExpressionWriteResp>, I>>(base?: I): ExpressionWriteResp {
    return ExpressionWriteResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ExpressionWriteResp>, I>>(object: I): ExpressionWriteResp {
    const message = createBaseExpressionWriteResp();
    message.expression = (object.expression !== undefined && object.expression !== null)
      ? DataExpression.fromPartial(object.expression)
      : undefined;
    return message;
  },
};

function createBaseExpressionDeleteReq(): ExpressionDeleteReq {
  return { id: "" };
}

export const ExpressionDeleteReq = {
  encode(message: ExpressionDeleteReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExpressionDeleteReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExpressionDeleteReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExpressionDeleteReq {
    return { id: isSet(object.id) ? String(object.id) : "" };
  },

  toJSON(message: ExpressionDeleteReq): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  create<I extends Exact<DeepPartial<ExpressionDeleteReq>, I>>(base?: I): ExpressionDeleteReq {
    return ExpressionDeleteReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ExpressionDeleteReq>, I>>(object: I): ExpressionDeleteReq {
    const message = createBaseExpressionDeleteReq();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseExpressionDeleteResp(): ExpressionDeleteResp {
  return {};
}

export const ExpressionDeleteResp = {
  encode(_: ExpressionDeleteResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExpressionDeleteResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExpressionDeleteResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ExpressionDeleteResp {
    return {};
  },

  toJSON(_: ExpressionDeleteResp): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ExpressionDeleteResp>, I>>(base?: I): ExpressionDeleteResp {
    return ExpressionDeleteResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ExpressionDeleteResp>, I>>(_: I): ExpressionDeleteResp {
    const message = createBaseExpressionDeleteResp();
    return message;
  },
};

function createBaseExpressionWriteExecStatReq(): ExpressionWriteExecStatReq {
  return { id: "", stats: undefined };
}

export const ExpressionWriteExecStatReq = {
  encode(message: ExpressionWriteExecStatReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.stats !== undefined) {
      DataExpressionExecStats.encode(message.stats, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExpressionWriteExecStatReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExpressionWriteExecStatReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.stats = DataExpressionExecStats.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExpressionWriteExecStatReq {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      stats: isSet(object.stats) ? DataExpressionExecStats.fromJSON(object.stats) : undefined,
    };
  },

  toJSON(message: ExpressionWriteExecStatReq): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.stats !== undefined &&
      (obj.stats = message.stats ? DataExpressionExecStats.toJSON(message.stats) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ExpressionWriteExecStatReq>, I>>(base?: I): ExpressionWriteExecStatReq {
    return ExpressionWriteExecStatReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ExpressionWriteExecStatReq>, I>>(object: I): ExpressionWriteExecStatReq {
    const message = createBaseExpressionWriteExecStatReq();
    message.id = object.id ?? "";
    message.stats = (object.stats !== undefined && object.stats !== null)
      ? DataExpressionExecStats.fromPartial(object.stats)
      : undefined;
    return message;
  },
};

function createBaseExpressionWriteExecStatResp(): ExpressionWriteExecStatResp {
  return {};
}

export const ExpressionWriteExecStatResp = {
  encode(_: ExpressionWriteExecStatResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExpressionWriteExecStatResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExpressionWriteExecStatResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ExpressionWriteExecStatResp {
    return {};
  },

  toJSON(_: ExpressionWriteExecStatResp): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ExpressionWriteExecStatResp>, I>>(base?: I): ExpressionWriteExecStatResp {
    return ExpressionWriteExecStatResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ExpressionWriteExecStatResp>, I>>(_: I): ExpressionWriteExecStatResp {
    const message = createBaseExpressionWriteExecStatResp();
    return message;
  },
};

function createBaseExpressionDisplaySettingsWriteReq(): ExpressionDisplaySettingsWriteReq {
  return { id: "", displaySettings: undefined };
}

export const ExpressionDisplaySettingsWriteReq = {
  encode(message: ExpressionDisplaySettingsWriteReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.displaySettings !== undefined) {
      ExpressionDisplaySettings.encode(message.displaySettings, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExpressionDisplaySettingsWriteReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExpressionDisplaySettingsWriteReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.displaySettings = ExpressionDisplaySettings.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExpressionDisplaySettingsWriteReq {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      displaySettings: isSet(object.displaySettings)
        ? ExpressionDisplaySettings.fromJSON(object.displaySettings)
        : undefined,
    };
  },

  toJSON(message: ExpressionDisplaySettingsWriteReq): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.displaySettings !== undefined && (obj.displaySettings = message.displaySettings
      ? ExpressionDisplaySettings.toJSON(message.displaySettings)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ExpressionDisplaySettingsWriteReq>, I>>(
    base?: I,
  ): ExpressionDisplaySettingsWriteReq {
    return ExpressionDisplaySettingsWriteReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ExpressionDisplaySettingsWriteReq>, I>>(
    object: I,
  ): ExpressionDisplaySettingsWriteReq {
    const message = createBaseExpressionDisplaySettingsWriteReq();
    message.id = object.id ?? "";
    message.displaySettings = (object.displaySettings !== undefined && object.displaySettings !== null)
      ? ExpressionDisplaySettings.fromPartial(object.displaySettings)
      : undefined;
    return message;
  },
};

function createBaseExpressionDisplaySettingsWriteResp(): ExpressionDisplaySettingsWriteResp {
  return { displaySettings: undefined };
}

export const ExpressionDisplaySettingsWriteResp = {
  encode(message: ExpressionDisplaySettingsWriteResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.displaySettings !== undefined) {
      ExpressionDisplaySettings.encode(message.displaySettings, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExpressionDisplaySettingsWriteResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExpressionDisplaySettingsWriteResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.displaySettings = ExpressionDisplaySettings.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExpressionDisplaySettingsWriteResp {
    return {
      displaySettings: isSet(object.displaySettings)
        ? ExpressionDisplaySettings.fromJSON(object.displaySettings)
        : undefined,
    };
  },

  toJSON(message: ExpressionDisplaySettingsWriteResp): unknown {
    const obj: any = {};
    message.displaySettings !== undefined && (obj.displaySettings = message.displaySettings
      ? ExpressionDisplaySettings.toJSON(message.displaySettings)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ExpressionDisplaySettingsWriteResp>, I>>(
    base?: I,
  ): ExpressionDisplaySettingsWriteResp {
    return ExpressionDisplaySettingsWriteResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ExpressionDisplaySettingsWriteResp>, I>>(
    object: I,
  ): ExpressionDisplaySettingsWriteResp {
    const message = createBaseExpressionDisplaySettingsWriteResp();
    message.displaySettings = (object.displaySettings !== undefined && object.displaySettings !== null)
      ? ExpressionDisplaySettings.fromPartial(object.displaySettings)
      : undefined;
    return message;
  },
};

function createBaseExpressionDisplaySettingsGetReq(): ExpressionDisplaySettingsGetReq {
  return { id: "" };
}

export const ExpressionDisplaySettingsGetReq = {
  encode(message: ExpressionDisplaySettingsGetReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExpressionDisplaySettingsGetReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExpressionDisplaySettingsGetReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExpressionDisplaySettingsGetReq {
    return { id: isSet(object.id) ? String(object.id) : "" };
  },

  toJSON(message: ExpressionDisplaySettingsGetReq): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  create<I extends Exact<DeepPartial<ExpressionDisplaySettingsGetReq>, I>>(base?: I): ExpressionDisplaySettingsGetReq {
    return ExpressionDisplaySettingsGetReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ExpressionDisplaySettingsGetReq>, I>>(
    object: I,
  ): ExpressionDisplaySettingsGetReq {
    const message = createBaseExpressionDisplaySettingsGetReq();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseExpressionDisplaySettingsGetResp(): ExpressionDisplaySettingsGetResp {
  return { displaySettings: undefined };
}

export const ExpressionDisplaySettingsGetResp = {
  encode(message: ExpressionDisplaySettingsGetResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.displaySettings !== undefined) {
      ExpressionDisplaySettings.encode(message.displaySettings, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExpressionDisplaySettingsGetResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExpressionDisplaySettingsGetResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.displaySettings = ExpressionDisplaySettings.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExpressionDisplaySettingsGetResp {
    return {
      displaySettings: isSet(object.displaySettings)
        ? ExpressionDisplaySettings.fromJSON(object.displaySettings)
        : undefined,
    };
  },

  toJSON(message: ExpressionDisplaySettingsGetResp): unknown {
    const obj: any = {};
    message.displaySettings !== undefined && (obj.displaySettings = message.displaySettings
      ? ExpressionDisplaySettings.toJSON(message.displaySettings)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ExpressionDisplaySettingsGetResp>, I>>(
    base?: I,
  ): ExpressionDisplaySettingsGetResp {
    return ExpressionDisplaySettingsGetResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ExpressionDisplaySettingsGetResp>, I>>(
    object: I,
  ): ExpressionDisplaySettingsGetResp {
    const message = createBaseExpressionDisplaySettingsGetResp();
    message.displaySettings = (object.displaySettings !== undefined && object.displaySettings !== null)
      ? ExpressionDisplaySettings.fromPartial(object.displaySettings)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
