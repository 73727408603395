/* eslint-disable */
import * as _m0 from "protobufjs/minimal";

export const protobufPackage = "";

export interface QuantCombineItem {
  roiId: string;
  quantificationId: string;
}

export interface QuantCombineItemList {
  roiZStack: QuantCombineItem[];
}

export interface QuantCombineItemListDB {
  /** @gotags: bson:"_id,omitempty" */
  id: string;
  userId: string;
  scanId: string;
  list: QuantCombineItemList | undefined;
}

export interface QuantCombineSummaryRow {
  values: number[];
  roiIds: string[];
  roiNames: string[];
}

export interface QuantCombineSummary {
  detectors: string[];
  weightPercents: { [key: string]: QuantCombineSummaryRow };
}

export interface QuantCombineSummary_WeightPercentsEntry {
  key: string;
  value: QuantCombineSummaryRow | undefined;
}

export interface QuantComparisonTable {
  quantId: string;
  quantName: string;
  elementWeights: { [key: string]: number };
}

export interface QuantComparisonTable_ElementWeightsEntry {
  key: string;
  value: number;
}

function createBaseQuantCombineItem(): QuantCombineItem {
  return { roiId: "", quantificationId: "" };
}

export const QuantCombineItem = {
  encode(message: QuantCombineItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.roiId !== "") {
      writer.uint32(10).string(message.roiId);
    }
    if (message.quantificationId !== "") {
      writer.uint32(18).string(message.quantificationId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuantCombineItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantCombineItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.roiId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.quantificationId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuantCombineItem {
    return {
      roiId: isSet(object.roiId) ? String(object.roiId) : "",
      quantificationId: isSet(object.quantificationId) ? String(object.quantificationId) : "",
    };
  },

  toJSON(message: QuantCombineItem): unknown {
    const obj: any = {};
    message.roiId !== undefined && (obj.roiId = message.roiId);
    message.quantificationId !== undefined && (obj.quantificationId = message.quantificationId);
    return obj;
  },

  create<I extends Exact<DeepPartial<QuantCombineItem>, I>>(base?: I): QuantCombineItem {
    return QuantCombineItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuantCombineItem>, I>>(object: I): QuantCombineItem {
    const message = createBaseQuantCombineItem();
    message.roiId = object.roiId ?? "";
    message.quantificationId = object.quantificationId ?? "";
    return message;
  },
};

function createBaseQuantCombineItemList(): QuantCombineItemList {
  return { roiZStack: [] };
}

export const QuantCombineItemList = {
  encode(message: QuantCombineItemList, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.roiZStack) {
      QuantCombineItem.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuantCombineItemList {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantCombineItemList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.roiZStack.push(QuantCombineItem.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuantCombineItemList {
    return {
      roiZStack: Array.isArray(object?.roiZStack) ? object.roiZStack.map((e: any) => QuantCombineItem.fromJSON(e)) : [],
    };
  },

  toJSON(message: QuantCombineItemList): unknown {
    const obj: any = {};
    if (message.roiZStack) {
      obj.roiZStack = message.roiZStack.map((e) => e ? QuantCombineItem.toJSON(e) : undefined);
    } else {
      obj.roiZStack = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<QuantCombineItemList>, I>>(base?: I): QuantCombineItemList {
    return QuantCombineItemList.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuantCombineItemList>, I>>(object: I): QuantCombineItemList {
    const message = createBaseQuantCombineItemList();
    message.roiZStack = object.roiZStack?.map((e) => QuantCombineItem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseQuantCombineItemListDB(): QuantCombineItemListDB {
  return { id: "", userId: "", scanId: "", list: undefined };
}

export const QuantCombineItemListDB = {
  encode(message: QuantCombineItemListDB, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.userId !== "") {
      writer.uint32(18).string(message.userId);
    }
    if (message.scanId !== "") {
      writer.uint32(26).string(message.scanId);
    }
    if (message.list !== undefined) {
      QuantCombineItemList.encode(message.list, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuantCombineItemListDB {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantCombineItemListDB();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.scanId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.list = QuantCombineItemList.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuantCombineItemListDB {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      userId: isSet(object.userId) ? String(object.userId) : "",
      scanId: isSet(object.scanId) ? String(object.scanId) : "",
      list: isSet(object.list) ? QuantCombineItemList.fromJSON(object.list) : undefined,
    };
  },

  toJSON(message: QuantCombineItemListDB): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.userId !== undefined && (obj.userId = message.userId);
    message.scanId !== undefined && (obj.scanId = message.scanId);
    message.list !== undefined && (obj.list = message.list ? QuantCombineItemList.toJSON(message.list) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<QuantCombineItemListDB>, I>>(base?: I): QuantCombineItemListDB {
    return QuantCombineItemListDB.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuantCombineItemListDB>, I>>(object: I): QuantCombineItemListDB {
    const message = createBaseQuantCombineItemListDB();
    message.id = object.id ?? "";
    message.userId = object.userId ?? "";
    message.scanId = object.scanId ?? "";
    message.list = (object.list !== undefined && object.list !== null)
      ? QuantCombineItemList.fromPartial(object.list)
      : undefined;
    return message;
  },
};

function createBaseQuantCombineSummaryRow(): QuantCombineSummaryRow {
  return { values: [], roiIds: [], roiNames: [] };
}

export const QuantCombineSummaryRow = {
  encode(message: QuantCombineSummaryRow, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.values) {
      writer.float(v);
    }
    writer.ldelim();
    for (const v of message.roiIds) {
      writer.uint32(18).string(v!);
    }
    for (const v of message.roiNames) {
      writer.uint32(26).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuantCombineSummaryRow {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantCombineSummaryRow();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 13) {
            message.values.push(reader.float());

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.values.push(reader.float());
            }

            continue;
          }

          break;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.roiIds.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.roiNames.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuantCombineSummaryRow {
    return {
      values: Array.isArray(object?.values) ? object.values.map((e: any) => Number(e)) : [],
      roiIds: Array.isArray(object?.roiIds) ? object.roiIds.map((e: any) => String(e)) : [],
      roiNames: Array.isArray(object?.roiNames) ? object.roiNames.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: QuantCombineSummaryRow): unknown {
    const obj: any = {};
    if (message.values) {
      obj.values = message.values.map((e) => e);
    } else {
      obj.values = [];
    }
    if (message.roiIds) {
      obj.roiIds = message.roiIds.map((e) => e);
    } else {
      obj.roiIds = [];
    }
    if (message.roiNames) {
      obj.roiNames = message.roiNames.map((e) => e);
    } else {
      obj.roiNames = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<QuantCombineSummaryRow>, I>>(base?: I): QuantCombineSummaryRow {
    return QuantCombineSummaryRow.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuantCombineSummaryRow>, I>>(object: I): QuantCombineSummaryRow {
    const message = createBaseQuantCombineSummaryRow();
    message.values = object.values?.map((e) => e) || [];
    message.roiIds = object.roiIds?.map((e) => e) || [];
    message.roiNames = object.roiNames?.map((e) => e) || [];
    return message;
  },
};

function createBaseQuantCombineSummary(): QuantCombineSummary {
  return { detectors: [], weightPercents: {} };
}

export const QuantCombineSummary = {
  encode(message: QuantCombineSummary, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.detectors) {
      writer.uint32(10).string(v!);
    }
    Object.entries(message.weightPercents).forEach(([key, value]) => {
      QuantCombineSummary_WeightPercentsEntry.encode({ key: key as any, value }, writer.uint32(18).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuantCombineSummary {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantCombineSummary();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.detectors.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          const entry2 = QuantCombineSummary_WeightPercentsEntry.decode(reader, reader.uint32());
          if (entry2.value !== undefined) {
            message.weightPercents[entry2.key] = entry2.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuantCombineSummary {
    return {
      detectors: Array.isArray(object?.detectors) ? object.detectors.map((e: any) => String(e)) : [],
      weightPercents: isObject(object.weightPercents)
        ? Object.entries(object.weightPercents).reduce<{ [key: string]: QuantCombineSummaryRow }>(
          (acc, [key, value]) => {
            acc[key] = QuantCombineSummaryRow.fromJSON(value);
            return acc;
          },
          {},
        )
        : {},
    };
  },

  toJSON(message: QuantCombineSummary): unknown {
    const obj: any = {};
    if (message.detectors) {
      obj.detectors = message.detectors.map((e) => e);
    } else {
      obj.detectors = [];
    }
    obj.weightPercents = {};
    if (message.weightPercents) {
      Object.entries(message.weightPercents).forEach(([k, v]) => {
        obj.weightPercents[k] = QuantCombineSummaryRow.toJSON(v);
      });
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<QuantCombineSummary>, I>>(base?: I): QuantCombineSummary {
    return QuantCombineSummary.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuantCombineSummary>, I>>(object: I): QuantCombineSummary {
    const message = createBaseQuantCombineSummary();
    message.detectors = object.detectors?.map((e) => e) || [];
    message.weightPercents = Object.entries(object.weightPercents ?? {}).reduce<
      { [key: string]: QuantCombineSummaryRow }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = QuantCombineSummaryRow.fromPartial(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseQuantCombineSummary_WeightPercentsEntry(): QuantCombineSummary_WeightPercentsEntry {
  return { key: "", value: undefined };
}

export const QuantCombineSummary_WeightPercentsEntry = {
  encode(message: QuantCombineSummary_WeightPercentsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      QuantCombineSummaryRow.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuantCombineSummary_WeightPercentsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantCombineSummary_WeightPercentsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = QuantCombineSummaryRow.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuantCombineSummary_WeightPercentsEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? QuantCombineSummaryRow.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: QuantCombineSummary_WeightPercentsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined &&
      (obj.value = message.value ? QuantCombineSummaryRow.toJSON(message.value) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<QuantCombineSummary_WeightPercentsEntry>, I>>(
    base?: I,
  ): QuantCombineSummary_WeightPercentsEntry {
    return QuantCombineSummary_WeightPercentsEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuantCombineSummary_WeightPercentsEntry>, I>>(
    object: I,
  ): QuantCombineSummary_WeightPercentsEntry {
    const message = createBaseQuantCombineSummary_WeightPercentsEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? QuantCombineSummaryRow.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseQuantComparisonTable(): QuantComparisonTable {
  return { quantId: "", quantName: "", elementWeights: {} };
}

export const QuantComparisonTable = {
  encode(message: QuantComparisonTable, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.quantId !== "") {
      writer.uint32(10).string(message.quantId);
    }
    if (message.quantName !== "") {
      writer.uint32(18).string(message.quantName);
    }
    Object.entries(message.elementWeights).forEach(([key, value]) => {
      QuantComparisonTable_ElementWeightsEntry.encode({ key: key as any, value }, writer.uint32(26).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuantComparisonTable {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantComparisonTable();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.quantId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.quantName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          const entry3 = QuantComparisonTable_ElementWeightsEntry.decode(reader, reader.uint32());
          if (entry3.value !== undefined) {
            message.elementWeights[entry3.key] = entry3.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuantComparisonTable {
    return {
      quantId: isSet(object.quantId) ? String(object.quantId) : "",
      quantName: isSet(object.quantName) ? String(object.quantName) : "",
      elementWeights: isObject(object.elementWeights)
        ? Object.entries(object.elementWeights).reduce<{ [key: string]: number }>((acc, [key, value]) => {
          acc[key] = Number(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: QuantComparisonTable): unknown {
    const obj: any = {};
    message.quantId !== undefined && (obj.quantId = message.quantId);
    message.quantName !== undefined && (obj.quantName = message.quantName);
    obj.elementWeights = {};
    if (message.elementWeights) {
      Object.entries(message.elementWeights).forEach(([k, v]) => {
        obj.elementWeights[k] = v;
      });
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<QuantComparisonTable>, I>>(base?: I): QuantComparisonTable {
    return QuantComparisonTable.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuantComparisonTable>, I>>(object: I): QuantComparisonTable {
    const message = createBaseQuantComparisonTable();
    message.quantId = object.quantId ?? "";
    message.quantName = object.quantName ?? "";
    message.elementWeights = Object.entries(object.elementWeights ?? {}).reduce<{ [key: string]: number }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = Number(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseQuantComparisonTable_ElementWeightsEntry(): QuantComparisonTable_ElementWeightsEntry {
  return { key: "", value: 0 };
}

export const QuantComparisonTable_ElementWeightsEntry = {
  encode(message: QuantComparisonTable_ElementWeightsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== 0) {
      writer.uint32(21).float(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuantComparisonTable_ElementWeightsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantComparisonTable_ElementWeightsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.value = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuantComparisonTable_ElementWeightsEntry {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? Number(object.value) : 0 };
  },

  toJSON(message: QuantComparisonTable_ElementWeightsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create<I extends Exact<DeepPartial<QuantComparisonTable_ElementWeightsEntry>, I>>(
    base?: I,
  ): QuantComparisonTable_ElementWeightsEntry {
    return QuantComparisonTable_ElementWeightsEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuantComparisonTable_ElementWeightsEntry>, I>>(
    object: I,
  ): QuantComparisonTable_ElementWeightsEntry {
    const message = createBaseQuantComparisonTable_ElementWeightsEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
