/* eslint-disable */
import * as _m0 from "protobufjs/minimal";

export const protobufPackage = "";

/** Diffraction detection algorithm delivers detected peaks via these structures */
export interface DetectedDiffractionPerLocation {
  /** Location ID, in PIXL land this is the PMC */
  id: string;
  /** Any peaks detected for this location */
  peaks: DetectedDiffractionPerLocation_DetectedDiffractionPeak[];
}

export interface DetectedDiffractionPerLocation_DetectedDiffractionPeak {
  peakChannel: number;
  effectSize: number;
  baselineVariation: number;
  globalDifference: number;
  differenceSigma: number;
  peakHeight: number;
  detector: string;
}

/** When a user suspects they see a diffraction peak they can log it using this structure */
export interface ManualDiffractionPeak {
  /** ID could be composed of scanId+pmc+userId+energy (?) */
  id: string;
  scanId: string;
  pmc: number;
  energykeV: number;
  createdUnixSec: number;
  creatorUserId: string;
}

/** Users can mark detected diffraction peaks as valid/invalid, this is stored in DB for those statuses */
export interface DetectedDiffractionPeakStatuses {
  /** @gotags: bson:"_id,omitempty" */
  id: string;
  scanId: string;
  statuses: { [key: string]: DetectedDiffractionPeakStatuses_PeakStatus };
}

export interface DetectedDiffractionPeakStatuses_PeakStatus {
  status: string;
  createdUnixSec: number;
  creatorUserId: string;
}

export interface DetectedDiffractionPeakStatuses_StatusesEntry {
  key: string;
  value: DetectedDiffractionPeakStatuses_PeakStatus | undefined;
}

function createBaseDetectedDiffractionPerLocation(): DetectedDiffractionPerLocation {
  return { id: "", peaks: [] };
}

export const DetectedDiffractionPerLocation = {
  encode(message: DetectedDiffractionPerLocation, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    for (const v of message.peaks) {
      DetectedDiffractionPerLocation_DetectedDiffractionPeak.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DetectedDiffractionPerLocation {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDetectedDiffractionPerLocation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.peaks.push(DetectedDiffractionPerLocation_DetectedDiffractionPeak.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DetectedDiffractionPerLocation {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      peaks: Array.isArray(object?.peaks)
        ? object.peaks.map((e: any) => DetectedDiffractionPerLocation_DetectedDiffractionPeak.fromJSON(e))
        : [],
    };
  },

  toJSON(message: DetectedDiffractionPerLocation): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    if (message.peaks) {
      obj.peaks = message.peaks.map((e) =>
        e ? DetectedDiffractionPerLocation_DetectedDiffractionPeak.toJSON(e) : undefined
      );
    } else {
      obj.peaks = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DetectedDiffractionPerLocation>, I>>(base?: I): DetectedDiffractionPerLocation {
    return DetectedDiffractionPerLocation.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DetectedDiffractionPerLocation>, I>>(
    object: I,
  ): DetectedDiffractionPerLocation {
    const message = createBaseDetectedDiffractionPerLocation();
    message.id = object.id ?? "";
    message.peaks = object.peaks?.map((e) => DetectedDiffractionPerLocation_DetectedDiffractionPeak.fromPartial(e)) ||
      [];
    return message;
  },
};

function createBaseDetectedDiffractionPerLocation_DetectedDiffractionPeak(): DetectedDiffractionPerLocation_DetectedDiffractionPeak {
  return {
    peakChannel: 0,
    effectSize: 0,
    baselineVariation: 0,
    globalDifference: 0,
    differenceSigma: 0,
    peakHeight: 0,
    detector: "",
  };
}

export const DetectedDiffractionPerLocation_DetectedDiffractionPeak = {
  encode(
    message: DetectedDiffractionPerLocation_DetectedDiffractionPeak,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.peakChannel !== 0) {
      writer.uint32(8).int32(message.peakChannel);
    }
    if (message.effectSize !== 0) {
      writer.uint32(21).float(message.effectSize);
    }
    if (message.baselineVariation !== 0) {
      writer.uint32(29).float(message.baselineVariation);
    }
    if (message.globalDifference !== 0) {
      writer.uint32(37).float(message.globalDifference);
    }
    if (message.differenceSigma !== 0) {
      writer.uint32(45).float(message.differenceSigma);
    }
    if (message.peakHeight !== 0) {
      writer.uint32(53).float(message.peakHeight);
    }
    if (message.detector !== "") {
      writer.uint32(58).string(message.detector);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DetectedDiffractionPerLocation_DetectedDiffractionPeak {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDetectedDiffractionPerLocation_DetectedDiffractionPeak();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.peakChannel = reader.int32();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.effectSize = reader.float();
          continue;
        case 3:
          if (tag !== 29) {
            break;
          }

          message.baselineVariation = reader.float();
          continue;
        case 4:
          if (tag !== 37) {
            break;
          }

          message.globalDifference = reader.float();
          continue;
        case 5:
          if (tag !== 45) {
            break;
          }

          message.differenceSigma = reader.float();
          continue;
        case 6:
          if (tag !== 53) {
            break;
          }

          message.peakHeight = reader.float();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.detector = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DetectedDiffractionPerLocation_DetectedDiffractionPeak {
    return {
      peakChannel: isSet(object.peakChannel) ? Number(object.peakChannel) : 0,
      effectSize: isSet(object.effectSize) ? Number(object.effectSize) : 0,
      baselineVariation: isSet(object.baselineVariation) ? Number(object.baselineVariation) : 0,
      globalDifference: isSet(object.globalDifference) ? Number(object.globalDifference) : 0,
      differenceSigma: isSet(object.differenceSigma) ? Number(object.differenceSigma) : 0,
      peakHeight: isSet(object.peakHeight) ? Number(object.peakHeight) : 0,
      detector: isSet(object.detector) ? String(object.detector) : "",
    };
  },

  toJSON(message: DetectedDiffractionPerLocation_DetectedDiffractionPeak): unknown {
    const obj: any = {};
    message.peakChannel !== undefined && (obj.peakChannel = Math.round(message.peakChannel));
    message.effectSize !== undefined && (obj.effectSize = message.effectSize);
    message.baselineVariation !== undefined && (obj.baselineVariation = message.baselineVariation);
    message.globalDifference !== undefined && (obj.globalDifference = message.globalDifference);
    message.differenceSigma !== undefined && (obj.differenceSigma = message.differenceSigma);
    message.peakHeight !== undefined && (obj.peakHeight = message.peakHeight);
    message.detector !== undefined && (obj.detector = message.detector);
    return obj;
  },

  create<I extends Exact<DeepPartial<DetectedDiffractionPerLocation_DetectedDiffractionPeak>, I>>(
    base?: I,
  ): DetectedDiffractionPerLocation_DetectedDiffractionPeak {
    return DetectedDiffractionPerLocation_DetectedDiffractionPeak.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DetectedDiffractionPerLocation_DetectedDiffractionPeak>, I>>(
    object: I,
  ): DetectedDiffractionPerLocation_DetectedDiffractionPeak {
    const message = createBaseDetectedDiffractionPerLocation_DetectedDiffractionPeak();
    message.peakChannel = object.peakChannel ?? 0;
    message.effectSize = object.effectSize ?? 0;
    message.baselineVariation = object.baselineVariation ?? 0;
    message.globalDifference = object.globalDifference ?? 0;
    message.differenceSigma = object.differenceSigma ?? 0;
    message.peakHeight = object.peakHeight ?? 0;
    message.detector = object.detector ?? "";
    return message;
  },
};

function createBaseManualDiffractionPeak(): ManualDiffractionPeak {
  return { id: "", scanId: "", pmc: 0, energykeV: 0, createdUnixSec: 0, creatorUserId: "" };
}

export const ManualDiffractionPeak = {
  encode(message: ManualDiffractionPeak, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.scanId !== "") {
      writer.uint32(18).string(message.scanId);
    }
    if (message.pmc !== 0) {
      writer.uint32(24).int32(message.pmc);
    }
    if (message.energykeV !== 0) {
      writer.uint32(37).float(message.energykeV);
    }
    if (message.createdUnixSec !== 0) {
      writer.uint32(40).uint32(message.createdUnixSec);
    }
    if (message.creatorUserId !== "") {
      writer.uint32(50).string(message.creatorUserId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ManualDiffractionPeak {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseManualDiffractionPeak();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.scanId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.pmc = reader.int32();
          continue;
        case 4:
          if (tag !== 37) {
            break;
          }

          message.energykeV = reader.float();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.createdUnixSec = reader.uint32();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.creatorUserId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ManualDiffractionPeak {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      scanId: isSet(object.scanId) ? String(object.scanId) : "",
      pmc: isSet(object.pmc) ? Number(object.pmc) : 0,
      energykeV: isSet(object.energykeV) ? Number(object.energykeV) : 0,
      createdUnixSec: isSet(object.createdUnixSec) ? Number(object.createdUnixSec) : 0,
      creatorUserId: isSet(object.creatorUserId) ? String(object.creatorUserId) : "",
    };
  },

  toJSON(message: ManualDiffractionPeak): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.scanId !== undefined && (obj.scanId = message.scanId);
    message.pmc !== undefined && (obj.pmc = Math.round(message.pmc));
    message.energykeV !== undefined && (obj.energykeV = message.energykeV);
    message.createdUnixSec !== undefined && (obj.createdUnixSec = Math.round(message.createdUnixSec));
    message.creatorUserId !== undefined && (obj.creatorUserId = message.creatorUserId);
    return obj;
  },

  create<I extends Exact<DeepPartial<ManualDiffractionPeak>, I>>(base?: I): ManualDiffractionPeak {
    return ManualDiffractionPeak.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ManualDiffractionPeak>, I>>(object: I): ManualDiffractionPeak {
    const message = createBaseManualDiffractionPeak();
    message.id = object.id ?? "";
    message.scanId = object.scanId ?? "";
    message.pmc = object.pmc ?? 0;
    message.energykeV = object.energykeV ?? 0;
    message.createdUnixSec = object.createdUnixSec ?? 0;
    message.creatorUserId = object.creatorUserId ?? "";
    return message;
  },
};

function createBaseDetectedDiffractionPeakStatuses(): DetectedDiffractionPeakStatuses {
  return { id: "", scanId: "", statuses: {} };
}

export const DetectedDiffractionPeakStatuses = {
  encode(message: DetectedDiffractionPeakStatuses, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.scanId !== "") {
      writer.uint32(18).string(message.scanId);
    }
    Object.entries(message.statuses).forEach(([key, value]) => {
      DetectedDiffractionPeakStatuses_StatusesEntry.encode({ key: key as any, value }, writer.uint32(26).fork())
        .ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DetectedDiffractionPeakStatuses {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDetectedDiffractionPeakStatuses();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.scanId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          const entry3 = DetectedDiffractionPeakStatuses_StatusesEntry.decode(reader, reader.uint32());
          if (entry3.value !== undefined) {
            message.statuses[entry3.key] = entry3.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DetectedDiffractionPeakStatuses {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      scanId: isSet(object.scanId) ? String(object.scanId) : "",
      statuses: isObject(object.statuses)
        ? Object.entries(object.statuses).reduce<{ [key: string]: DetectedDiffractionPeakStatuses_PeakStatus }>(
          (acc, [key, value]) => {
            acc[key] = DetectedDiffractionPeakStatuses_PeakStatus.fromJSON(value);
            return acc;
          },
          {},
        )
        : {},
    };
  },

  toJSON(message: DetectedDiffractionPeakStatuses): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.scanId !== undefined && (obj.scanId = message.scanId);
    obj.statuses = {};
    if (message.statuses) {
      Object.entries(message.statuses).forEach(([k, v]) => {
        obj.statuses[k] = DetectedDiffractionPeakStatuses_PeakStatus.toJSON(v);
      });
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DetectedDiffractionPeakStatuses>, I>>(base?: I): DetectedDiffractionPeakStatuses {
    return DetectedDiffractionPeakStatuses.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DetectedDiffractionPeakStatuses>, I>>(
    object: I,
  ): DetectedDiffractionPeakStatuses {
    const message = createBaseDetectedDiffractionPeakStatuses();
    message.id = object.id ?? "";
    message.scanId = object.scanId ?? "";
    message.statuses = Object.entries(object.statuses ?? {}).reduce<
      { [key: string]: DetectedDiffractionPeakStatuses_PeakStatus }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = DetectedDiffractionPeakStatuses_PeakStatus.fromPartial(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseDetectedDiffractionPeakStatuses_PeakStatus(): DetectedDiffractionPeakStatuses_PeakStatus {
  return { status: "", createdUnixSec: 0, creatorUserId: "" };
}

export const DetectedDiffractionPeakStatuses_PeakStatus = {
  encode(message: DetectedDiffractionPeakStatuses_PeakStatus, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== "") {
      writer.uint32(10).string(message.status);
    }
    if (message.createdUnixSec !== 0) {
      writer.uint32(16).uint32(message.createdUnixSec);
    }
    if (message.creatorUserId !== "") {
      writer.uint32(26).string(message.creatorUserId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DetectedDiffractionPeakStatuses_PeakStatus {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDetectedDiffractionPeakStatuses_PeakStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.status = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.createdUnixSec = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.creatorUserId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DetectedDiffractionPeakStatuses_PeakStatus {
    return {
      status: isSet(object.status) ? String(object.status) : "",
      createdUnixSec: isSet(object.createdUnixSec) ? Number(object.createdUnixSec) : 0,
      creatorUserId: isSet(object.creatorUserId) ? String(object.creatorUserId) : "",
    };
  },

  toJSON(message: DetectedDiffractionPeakStatuses_PeakStatus): unknown {
    const obj: any = {};
    message.status !== undefined && (obj.status = message.status);
    message.createdUnixSec !== undefined && (obj.createdUnixSec = Math.round(message.createdUnixSec));
    message.creatorUserId !== undefined && (obj.creatorUserId = message.creatorUserId);
    return obj;
  },

  create<I extends Exact<DeepPartial<DetectedDiffractionPeakStatuses_PeakStatus>, I>>(
    base?: I,
  ): DetectedDiffractionPeakStatuses_PeakStatus {
    return DetectedDiffractionPeakStatuses_PeakStatus.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DetectedDiffractionPeakStatuses_PeakStatus>, I>>(
    object: I,
  ): DetectedDiffractionPeakStatuses_PeakStatus {
    const message = createBaseDetectedDiffractionPeakStatuses_PeakStatus();
    message.status = object.status ?? "";
    message.createdUnixSec = object.createdUnixSec ?? 0;
    message.creatorUserId = object.creatorUserId ?? "";
    return message;
  },
};

function createBaseDetectedDiffractionPeakStatuses_StatusesEntry(): DetectedDiffractionPeakStatuses_StatusesEntry {
  return { key: "", value: undefined };
}

export const DetectedDiffractionPeakStatuses_StatusesEntry = {
  encode(message: DetectedDiffractionPeakStatuses_StatusesEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      DetectedDiffractionPeakStatuses_PeakStatus.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DetectedDiffractionPeakStatuses_StatusesEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDetectedDiffractionPeakStatuses_StatusesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = DetectedDiffractionPeakStatuses_PeakStatus.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DetectedDiffractionPeakStatuses_StatusesEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? DetectedDiffractionPeakStatuses_PeakStatus.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: DetectedDiffractionPeakStatuses_StatusesEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined &&
      (obj.value = message.value ? DetectedDiffractionPeakStatuses_PeakStatus.toJSON(message.value) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DetectedDiffractionPeakStatuses_StatusesEntry>, I>>(
    base?: I,
  ): DetectedDiffractionPeakStatuses_StatusesEntry {
    return DetectedDiffractionPeakStatuses_StatusesEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DetectedDiffractionPeakStatuses_StatusesEntry>, I>>(
    object: I,
  ): DetectedDiffractionPeakStatuses_StatusesEntry {
    const message = createBaseDetectedDiffractionPeakStatuses_StatusesEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? DetectedDiffractionPeakStatuses_PeakStatus.fromPartial(object.value)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
