/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { UserGroupList } from "./ownership-access";
import { ROIItem, ROIItemDisplaySettings, ROIItemSummary } from "./roi";
import { SearchParams } from "./search-params";

export const protobufPackage = "";

/** requires(NONE) */
export interface RegionOfInterestListReq {
  searchParams: SearchParams | undefined;
  isMIST: boolean;
}

export interface RegionOfInterestListResp {
  regionsOfInterest: { [key: string]: ROIItemSummary };
  isMIST: boolean;
}

export interface RegionOfInterestListResp_RegionsOfInterestEntry {
  key: string;
  value: ROIItemSummary | undefined;
}

/** requires(NONE) */
export interface RegionOfInterestGetReq {
  id: string;
  isMIST: boolean;
}

export interface RegionOfInterestGetResp {
  regionOfInterest: ROIItem | undefined;
}

/**
 * If id is blank, assume its new and generate an ID to return, otherwise update & return same one
 * requires(EDIT_ROI)
 */
export interface RegionOfInterestWriteReq {
  regionOfInterest: ROIItem | undefined;
  isMIST: boolean;
}

export interface RegionOfInterestWriteResp {
  regionOfInterest: ROIItem | undefined;
}

/**
 * The user is configuring an ROI for their own use, but not editing it
 * requires(EDIT_OWN_USER)
 */
export interface RegionOfInterestDisplaySettingsWriteReq {
  id: string;
  displaySettings: ROIItemDisplaySettings | undefined;
}

export interface RegionOfInterestDisplaySettingsWriteResp {
  displaySettings: ROIItemDisplaySettings | undefined;
}

/** requires(NONE) */
export interface RegionOfInterestDisplaySettingsGetReq {
  id: string;
}

export interface RegionOfInterestDisplaySettingsGetResp {
  displaySettings: ROIItemDisplaySettings | undefined;
}

/** requires(EDIT_ROI) */
export interface RegionOfInterestBulkWriteReq {
  regionsOfInterest: ROIItem[];
  /** if true, then overwrite existing ROIs with same ID */
  overwrite: boolean;
  /** if true, then skip ROIs with same ID */
  skipDuplicates: boolean;
  isMIST: boolean;
  /** List of scan ids to delete all MIST ROIs for */
  mistROIScanIdsToDelete: string[];
  /** List of groups that can edit the ROIs */
  editors:
    | UserGroupList
    | undefined;
  /** List of groups that can view the ROIs */
  viewers: UserGroupList | undefined;
}

export interface RegionOfInterestBulkWriteResp {
  regionsOfInterest: ROIItem[];
}

/** requires(EDIT_ROI) */
export interface RegionOfInterestDeleteReq {
  id: string;
  isMIST: boolean;
}

export interface RegionOfInterestDeleteResp {
}

/** requires(EDIT_ROI) */
export interface RegionOfInterestBulkDuplicateReq {
  ids: string[];
  isMIST: boolean;
}

export interface RegionOfInterestBulkDuplicateResp {
  regionsOfInterest: { [key: string]: ROIItemSummary };
}

export interface RegionOfInterestBulkDuplicateResp_RegionsOfInterestEntry {
  key: string;
  value: ROIItemSummary | undefined;
}

function createBaseRegionOfInterestListReq(): RegionOfInterestListReq {
  return { searchParams: undefined, isMIST: false };
}

export const RegionOfInterestListReq = {
  encode(message: RegionOfInterestListReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.searchParams !== undefined) {
      SearchParams.encode(message.searchParams, writer.uint32(10).fork()).ldelim();
    }
    if (message.isMIST === true) {
      writer.uint32(16).bool(message.isMIST);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RegionOfInterestListReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegionOfInterestListReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.searchParams = SearchParams.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isMIST = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RegionOfInterestListReq {
    return {
      searchParams: isSet(object.searchParams) ? SearchParams.fromJSON(object.searchParams) : undefined,
      isMIST: isSet(object.isMIST) ? Boolean(object.isMIST) : false,
    };
  },

  toJSON(message: RegionOfInterestListReq): unknown {
    const obj: any = {};
    message.searchParams !== undefined &&
      (obj.searchParams = message.searchParams ? SearchParams.toJSON(message.searchParams) : undefined);
    message.isMIST !== undefined && (obj.isMIST = message.isMIST);
    return obj;
  },

  create<I extends Exact<DeepPartial<RegionOfInterestListReq>, I>>(base?: I): RegionOfInterestListReq {
    return RegionOfInterestListReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RegionOfInterestListReq>, I>>(object: I): RegionOfInterestListReq {
    const message = createBaseRegionOfInterestListReq();
    message.searchParams = (object.searchParams !== undefined && object.searchParams !== null)
      ? SearchParams.fromPartial(object.searchParams)
      : undefined;
    message.isMIST = object.isMIST ?? false;
    return message;
  },
};

function createBaseRegionOfInterestListResp(): RegionOfInterestListResp {
  return { regionsOfInterest: {}, isMIST: false };
}

export const RegionOfInterestListResp = {
  encode(message: RegionOfInterestListResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.regionsOfInterest).forEach(([key, value]) => {
      RegionOfInterestListResp_RegionsOfInterestEntry.encode({ key: key as any, value }, writer.uint32(10).fork())
        .ldelim();
    });
    if (message.isMIST === true) {
      writer.uint32(16).bool(message.isMIST);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RegionOfInterestListResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegionOfInterestListResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = RegionOfInterestListResp_RegionsOfInterestEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.regionsOfInterest[entry1.key] = entry1.value;
          }
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isMIST = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RegionOfInterestListResp {
    return {
      regionsOfInterest: isObject(object.regionsOfInterest)
        ? Object.entries(object.regionsOfInterest).reduce<{ [key: string]: ROIItemSummary }>((acc, [key, value]) => {
          acc[key] = ROIItemSummary.fromJSON(value);
          return acc;
        }, {})
        : {},
      isMIST: isSet(object.isMIST) ? Boolean(object.isMIST) : false,
    };
  },

  toJSON(message: RegionOfInterestListResp): unknown {
    const obj: any = {};
    obj.regionsOfInterest = {};
    if (message.regionsOfInterest) {
      Object.entries(message.regionsOfInterest).forEach(([k, v]) => {
        obj.regionsOfInterest[k] = ROIItemSummary.toJSON(v);
      });
    }
    message.isMIST !== undefined && (obj.isMIST = message.isMIST);
    return obj;
  },

  create<I extends Exact<DeepPartial<RegionOfInterestListResp>, I>>(base?: I): RegionOfInterestListResp {
    return RegionOfInterestListResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RegionOfInterestListResp>, I>>(object: I): RegionOfInterestListResp {
    const message = createBaseRegionOfInterestListResp();
    message.regionsOfInterest = Object.entries(object.regionsOfInterest ?? {}).reduce<
      { [key: string]: ROIItemSummary }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = ROIItemSummary.fromPartial(value);
      }
      return acc;
    }, {});
    message.isMIST = object.isMIST ?? false;
    return message;
  },
};

function createBaseRegionOfInterestListResp_RegionsOfInterestEntry(): RegionOfInterestListResp_RegionsOfInterestEntry {
  return { key: "", value: undefined };
}

export const RegionOfInterestListResp_RegionsOfInterestEntry = {
  encode(
    message: RegionOfInterestListResp_RegionsOfInterestEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      ROIItemSummary.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RegionOfInterestListResp_RegionsOfInterestEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegionOfInterestListResp_RegionsOfInterestEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = ROIItemSummary.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RegionOfInterestListResp_RegionsOfInterestEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? ROIItemSummary.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: RegionOfInterestListResp_RegionsOfInterestEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value ? ROIItemSummary.toJSON(message.value) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<RegionOfInterestListResp_RegionsOfInterestEntry>, I>>(
    base?: I,
  ): RegionOfInterestListResp_RegionsOfInterestEntry {
    return RegionOfInterestListResp_RegionsOfInterestEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RegionOfInterestListResp_RegionsOfInterestEntry>, I>>(
    object: I,
  ): RegionOfInterestListResp_RegionsOfInterestEntry {
    const message = createBaseRegionOfInterestListResp_RegionsOfInterestEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? ROIItemSummary.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseRegionOfInterestGetReq(): RegionOfInterestGetReq {
  return { id: "", isMIST: false };
}

export const RegionOfInterestGetReq = {
  encode(message: RegionOfInterestGetReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.isMIST === true) {
      writer.uint32(16).bool(message.isMIST);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RegionOfInterestGetReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegionOfInterestGetReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isMIST = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RegionOfInterestGetReq {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      isMIST: isSet(object.isMIST) ? Boolean(object.isMIST) : false,
    };
  },

  toJSON(message: RegionOfInterestGetReq): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.isMIST !== undefined && (obj.isMIST = message.isMIST);
    return obj;
  },

  create<I extends Exact<DeepPartial<RegionOfInterestGetReq>, I>>(base?: I): RegionOfInterestGetReq {
    return RegionOfInterestGetReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RegionOfInterestGetReq>, I>>(object: I): RegionOfInterestGetReq {
    const message = createBaseRegionOfInterestGetReq();
    message.id = object.id ?? "";
    message.isMIST = object.isMIST ?? false;
    return message;
  },
};

function createBaseRegionOfInterestGetResp(): RegionOfInterestGetResp {
  return { regionOfInterest: undefined };
}

export const RegionOfInterestGetResp = {
  encode(message: RegionOfInterestGetResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.regionOfInterest !== undefined) {
      ROIItem.encode(message.regionOfInterest, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RegionOfInterestGetResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegionOfInterestGetResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.regionOfInterest = ROIItem.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RegionOfInterestGetResp {
    return { regionOfInterest: isSet(object.regionOfInterest) ? ROIItem.fromJSON(object.regionOfInterest) : undefined };
  },

  toJSON(message: RegionOfInterestGetResp): unknown {
    const obj: any = {};
    message.regionOfInterest !== undefined &&
      (obj.regionOfInterest = message.regionOfInterest ? ROIItem.toJSON(message.regionOfInterest) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<RegionOfInterestGetResp>, I>>(base?: I): RegionOfInterestGetResp {
    return RegionOfInterestGetResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RegionOfInterestGetResp>, I>>(object: I): RegionOfInterestGetResp {
    const message = createBaseRegionOfInterestGetResp();
    message.regionOfInterest = (object.regionOfInterest !== undefined && object.regionOfInterest !== null)
      ? ROIItem.fromPartial(object.regionOfInterest)
      : undefined;
    return message;
  },
};

function createBaseRegionOfInterestWriteReq(): RegionOfInterestWriteReq {
  return { regionOfInterest: undefined, isMIST: false };
}

export const RegionOfInterestWriteReq = {
  encode(message: RegionOfInterestWriteReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.regionOfInterest !== undefined) {
      ROIItem.encode(message.regionOfInterest, writer.uint32(10).fork()).ldelim();
    }
    if (message.isMIST === true) {
      writer.uint32(16).bool(message.isMIST);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RegionOfInterestWriteReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegionOfInterestWriteReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.regionOfInterest = ROIItem.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isMIST = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RegionOfInterestWriteReq {
    return {
      regionOfInterest: isSet(object.regionOfInterest) ? ROIItem.fromJSON(object.regionOfInterest) : undefined,
      isMIST: isSet(object.isMIST) ? Boolean(object.isMIST) : false,
    };
  },

  toJSON(message: RegionOfInterestWriteReq): unknown {
    const obj: any = {};
    message.regionOfInterest !== undefined &&
      (obj.regionOfInterest = message.regionOfInterest ? ROIItem.toJSON(message.regionOfInterest) : undefined);
    message.isMIST !== undefined && (obj.isMIST = message.isMIST);
    return obj;
  },

  create<I extends Exact<DeepPartial<RegionOfInterestWriteReq>, I>>(base?: I): RegionOfInterestWriteReq {
    return RegionOfInterestWriteReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RegionOfInterestWriteReq>, I>>(object: I): RegionOfInterestWriteReq {
    const message = createBaseRegionOfInterestWriteReq();
    message.regionOfInterest = (object.regionOfInterest !== undefined && object.regionOfInterest !== null)
      ? ROIItem.fromPartial(object.regionOfInterest)
      : undefined;
    message.isMIST = object.isMIST ?? false;
    return message;
  },
};

function createBaseRegionOfInterestWriteResp(): RegionOfInterestWriteResp {
  return { regionOfInterest: undefined };
}

export const RegionOfInterestWriteResp = {
  encode(message: RegionOfInterestWriteResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.regionOfInterest !== undefined) {
      ROIItem.encode(message.regionOfInterest, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RegionOfInterestWriteResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegionOfInterestWriteResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.regionOfInterest = ROIItem.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RegionOfInterestWriteResp {
    return { regionOfInterest: isSet(object.regionOfInterest) ? ROIItem.fromJSON(object.regionOfInterest) : undefined };
  },

  toJSON(message: RegionOfInterestWriteResp): unknown {
    const obj: any = {};
    message.regionOfInterest !== undefined &&
      (obj.regionOfInterest = message.regionOfInterest ? ROIItem.toJSON(message.regionOfInterest) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<RegionOfInterestWriteResp>, I>>(base?: I): RegionOfInterestWriteResp {
    return RegionOfInterestWriteResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RegionOfInterestWriteResp>, I>>(object: I): RegionOfInterestWriteResp {
    const message = createBaseRegionOfInterestWriteResp();
    message.regionOfInterest = (object.regionOfInterest !== undefined && object.regionOfInterest !== null)
      ? ROIItem.fromPartial(object.regionOfInterest)
      : undefined;
    return message;
  },
};

function createBaseRegionOfInterestDisplaySettingsWriteReq(): RegionOfInterestDisplaySettingsWriteReq {
  return { id: "", displaySettings: undefined };
}

export const RegionOfInterestDisplaySettingsWriteReq = {
  encode(message: RegionOfInterestDisplaySettingsWriteReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.displaySettings !== undefined) {
      ROIItemDisplaySettings.encode(message.displaySettings, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RegionOfInterestDisplaySettingsWriteReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegionOfInterestDisplaySettingsWriteReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.displaySettings = ROIItemDisplaySettings.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RegionOfInterestDisplaySettingsWriteReq {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      displaySettings: isSet(object.displaySettings)
        ? ROIItemDisplaySettings.fromJSON(object.displaySettings)
        : undefined,
    };
  },

  toJSON(message: RegionOfInterestDisplaySettingsWriteReq): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.displaySettings !== undefined && (obj.displaySettings = message.displaySettings
      ? ROIItemDisplaySettings.toJSON(message.displaySettings)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<RegionOfInterestDisplaySettingsWriteReq>, I>>(
    base?: I,
  ): RegionOfInterestDisplaySettingsWriteReq {
    return RegionOfInterestDisplaySettingsWriteReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RegionOfInterestDisplaySettingsWriteReq>, I>>(
    object: I,
  ): RegionOfInterestDisplaySettingsWriteReq {
    const message = createBaseRegionOfInterestDisplaySettingsWriteReq();
    message.id = object.id ?? "";
    message.displaySettings = (object.displaySettings !== undefined && object.displaySettings !== null)
      ? ROIItemDisplaySettings.fromPartial(object.displaySettings)
      : undefined;
    return message;
  },
};

function createBaseRegionOfInterestDisplaySettingsWriteResp(): RegionOfInterestDisplaySettingsWriteResp {
  return { displaySettings: undefined };
}

export const RegionOfInterestDisplaySettingsWriteResp = {
  encode(message: RegionOfInterestDisplaySettingsWriteResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.displaySettings !== undefined) {
      ROIItemDisplaySettings.encode(message.displaySettings, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RegionOfInterestDisplaySettingsWriteResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegionOfInterestDisplaySettingsWriteResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.displaySettings = ROIItemDisplaySettings.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RegionOfInterestDisplaySettingsWriteResp {
    return {
      displaySettings: isSet(object.displaySettings)
        ? ROIItemDisplaySettings.fromJSON(object.displaySettings)
        : undefined,
    };
  },

  toJSON(message: RegionOfInterestDisplaySettingsWriteResp): unknown {
    const obj: any = {};
    message.displaySettings !== undefined && (obj.displaySettings = message.displaySettings
      ? ROIItemDisplaySettings.toJSON(message.displaySettings)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<RegionOfInterestDisplaySettingsWriteResp>, I>>(
    base?: I,
  ): RegionOfInterestDisplaySettingsWriteResp {
    return RegionOfInterestDisplaySettingsWriteResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RegionOfInterestDisplaySettingsWriteResp>, I>>(
    object: I,
  ): RegionOfInterestDisplaySettingsWriteResp {
    const message = createBaseRegionOfInterestDisplaySettingsWriteResp();
    message.displaySettings = (object.displaySettings !== undefined && object.displaySettings !== null)
      ? ROIItemDisplaySettings.fromPartial(object.displaySettings)
      : undefined;
    return message;
  },
};

function createBaseRegionOfInterestDisplaySettingsGetReq(): RegionOfInterestDisplaySettingsGetReq {
  return { id: "" };
}

export const RegionOfInterestDisplaySettingsGetReq = {
  encode(message: RegionOfInterestDisplaySettingsGetReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RegionOfInterestDisplaySettingsGetReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegionOfInterestDisplaySettingsGetReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RegionOfInterestDisplaySettingsGetReq {
    return { id: isSet(object.id) ? String(object.id) : "" };
  },

  toJSON(message: RegionOfInterestDisplaySettingsGetReq): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  create<I extends Exact<DeepPartial<RegionOfInterestDisplaySettingsGetReq>, I>>(
    base?: I,
  ): RegionOfInterestDisplaySettingsGetReq {
    return RegionOfInterestDisplaySettingsGetReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RegionOfInterestDisplaySettingsGetReq>, I>>(
    object: I,
  ): RegionOfInterestDisplaySettingsGetReq {
    const message = createBaseRegionOfInterestDisplaySettingsGetReq();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseRegionOfInterestDisplaySettingsGetResp(): RegionOfInterestDisplaySettingsGetResp {
  return { displaySettings: undefined };
}

export const RegionOfInterestDisplaySettingsGetResp = {
  encode(message: RegionOfInterestDisplaySettingsGetResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.displaySettings !== undefined) {
      ROIItemDisplaySettings.encode(message.displaySettings, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RegionOfInterestDisplaySettingsGetResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegionOfInterestDisplaySettingsGetResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.displaySettings = ROIItemDisplaySettings.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RegionOfInterestDisplaySettingsGetResp {
    return {
      displaySettings: isSet(object.displaySettings)
        ? ROIItemDisplaySettings.fromJSON(object.displaySettings)
        : undefined,
    };
  },

  toJSON(message: RegionOfInterestDisplaySettingsGetResp): unknown {
    const obj: any = {};
    message.displaySettings !== undefined && (obj.displaySettings = message.displaySettings
      ? ROIItemDisplaySettings.toJSON(message.displaySettings)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<RegionOfInterestDisplaySettingsGetResp>, I>>(
    base?: I,
  ): RegionOfInterestDisplaySettingsGetResp {
    return RegionOfInterestDisplaySettingsGetResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RegionOfInterestDisplaySettingsGetResp>, I>>(
    object: I,
  ): RegionOfInterestDisplaySettingsGetResp {
    const message = createBaseRegionOfInterestDisplaySettingsGetResp();
    message.displaySettings = (object.displaySettings !== undefined && object.displaySettings !== null)
      ? ROIItemDisplaySettings.fromPartial(object.displaySettings)
      : undefined;
    return message;
  },
};

function createBaseRegionOfInterestBulkWriteReq(): RegionOfInterestBulkWriteReq {
  return {
    regionsOfInterest: [],
    overwrite: false,
    skipDuplicates: false,
    isMIST: false,
    mistROIScanIdsToDelete: [],
    editors: undefined,
    viewers: undefined,
  };
}

export const RegionOfInterestBulkWriteReq = {
  encode(message: RegionOfInterestBulkWriteReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.regionsOfInterest) {
      ROIItem.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.overwrite === true) {
      writer.uint32(16).bool(message.overwrite);
    }
    if (message.skipDuplicates === true) {
      writer.uint32(24).bool(message.skipDuplicates);
    }
    if (message.isMIST === true) {
      writer.uint32(32).bool(message.isMIST);
    }
    for (const v of message.mistROIScanIdsToDelete) {
      writer.uint32(42).string(v!);
    }
    if (message.editors !== undefined) {
      UserGroupList.encode(message.editors, writer.uint32(50).fork()).ldelim();
    }
    if (message.viewers !== undefined) {
      UserGroupList.encode(message.viewers, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RegionOfInterestBulkWriteReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegionOfInterestBulkWriteReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.regionsOfInterest.push(ROIItem.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.overwrite = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.skipDuplicates = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.isMIST = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.mistROIScanIdsToDelete.push(reader.string());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.editors = UserGroupList.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.viewers = UserGroupList.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RegionOfInterestBulkWriteReq {
    return {
      regionsOfInterest: Array.isArray(object?.regionsOfInterest)
        ? object.regionsOfInterest.map((e: any) => ROIItem.fromJSON(e))
        : [],
      overwrite: isSet(object.overwrite) ? Boolean(object.overwrite) : false,
      skipDuplicates: isSet(object.skipDuplicates) ? Boolean(object.skipDuplicates) : false,
      isMIST: isSet(object.isMIST) ? Boolean(object.isMIST) : false,
      mistROIScanIdsToDelete: Array.isArray(object?.mistROIScanIdsToDelete)
        ? object.mistROIScanIdsToDelete.map((e: any) => String(e))
        : [],
      editors: isSet(object.editors) ? UserGroupList.fromJSON(object.editors) : undefined,
      viewers: isSet(object.viewers) ? UserGroupList.fromJSON(object.viewers) : undefined,
    };
  },

  toJSON(message: RegionOfInterestBulkWriteReq): unknown {
    const obj: any = {};
    if (message.regionsOfInterest) {
      obj.regionsOfInterest = message.regionsOfInterest.map((e) => e ? ROIItem.toJSON(e) : undefined);
    } else {
      obj.regionsOfInterest = [];
    }
    message.overwrite !== undefined && (obj.overwrite = message.overwrite);
    message.skipDuplicates !== undefined && (obj.skipDuplicates = message.skipDuplicates);
    message.isMIST !== undefined && (obj.isMIST = message.isMIST);
    if (message.mistROIScanIdsToDelete) {
      obj.mistROIScanIdsToDelete = message.mistROIScanIdsToDelete.map((e) => e);
    } else {
      obj.mistROIScanIdsToDelete = [];
    }
    message.editors !== undefined &&
      (obj.editors = message.editors ? UserGroupList.toJSON(message.editors) : undefined);
    message.viewers !== undefined &&
      (obj.viewers = message.viewers ? UserGroupList.toJSON(message.viewers) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<RegionOfInterestBulkWriteReq>, I>>(base?: I): RegionOfInterestBulkWriteReq {
    return RegionOfInterestBulkWriteReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RegionOfInterestBulkWriteReq>, I>>(object: I): RegionOfInterestBulkWriteReq {
    const message = createBaseRegionOfInterestBulkWriteReq();
    message.regionsOfInterest = object.regionsOfInterest?.map((e) => ROIItem.fromPartial(e)) || [];
    message.overwrite = object.overwrite ?? false;
    message.skipDuplicates = object.skipDuplicates ?? false;
    message.isMIST = object.isMIST ?? false;
    message.mistROIScanIdsToDelete = object.mistROIScanIdsToDelete?.map((e) => e) || [];
    message.editors = (object.editors !== undefined && object.editors !== null)
      ? UserGroupList.fromPartial(object.editors)
      : undefined;
    message.viewers = (object.viewers !== undefined && object.viewers !== null)
      ? UserGroupList.fromPartial(object.viewers)
      : undefined;
    return message;
  },
};

function createBaseRegionOfInterestBulkWriteResp(): RegionOfInterestBulkWriteResp {
  return { regionsOfInterest: [] };
}

export const RegionOfInterestBulkWriteResp = {
  encode(message: RegionOfInterestBulkWriteResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.regionsOfInterest) {
      ROIItem.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RegionOfInterestBulkWriteResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegionOfInterestBulkWriteResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.regionsOfInterest.push(ROIItem.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RegionOfInterestBulkWriteResp {
    return {
      regionsOfInterest: Array.isArray(object?.regionsOfInterest)
        ? object.regionsOfInterest.map((e: any) => ROIItem.fromJSON(e))
        : [],
    };
  },

  toJSON(message: RegionOfInterestBulkWriteResp): unknown {
    const obj: any = {};
    if (message.regionsOfInterest) {
      obj.regionsOfInterest = message.regionsOfInterest.map((e) => e ? ROIItem.toJSON(e) : undefined);
    } else {
      obj.regionsOfInterest = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RegionOfInterestBulkWriteResp>, I>>(base?: I): RegionOfInterestBulkWriteResp {
    return RegionOfInterestBulkWriteResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RegionOfInterestBulkWriteResp>, I>>(
    object: I,
  ): RegionOfInterestBulkWriteResp {
    const message = createBaseRegionOfInterestBulkWriteResp();
    message.regionsOfInterest = object.regionsOfInterest?.map((e) => ROIItem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseRegionOfInterestDeleteReq(): RegionOfInterestDeleteReq {
  return { id: "", isMIST: false };
}

export const RegionOfInterestDeleteReq = {
  encode(message: RegionOfInterestDeleteReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.isMIST === true) {
      writer.uint32(16).bool(message.isMIST);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RegionOfInterestDeleteReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegionOfInterestDeleteReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isMIST = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RegionOfInterestDeleteReq {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      isMIST: isSet(object.isMIST) ? Boolean(object.isMIST) : false,
    };
  },

  toJSON(message: RegionOfInterestDeleteReq): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.isMIST !== undefined && (obj.isMIST = message.isMIST);
    return obj;
  },

  create<I extends Exact<DeepPartial<RegionOfInterestDeleteReq>, I>>(base?: I): RegionOfInterestDeleteReq {
    return RegionOfInterestDeleteReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RegionOfInterestDeleteReq>, I>>(object: I): RegionOfInterestDeleteReq {
    const message = createBaseRegionOfInterestDeleteReq();
    message.id = object.id ?? "";
    message.isMIST = object.isMIST ?? false;
    return message;
  },
};

function createBaseRegionOfInterestDeleteResp(): RegionOfInterestDeleteResp {
  return {};
}

export const RegionOfInterestDeleteResp = {
  encode(_: RegionOfInterestDeleteResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RegionOfInterestDeleteResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegionOfInterestDeleteResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): RegionOfInterestDeleteResp {
    return {};
  },

  toJSON(_: RegionOfInterestDeleteResp): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<RegionOfInterestDeleteResp>, I>>(base?: I): RegionOfInterestDeleteResp {
    return RegionOfInterestDeleteResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RegionOfInterestDeleteResp>, I>>(_: I): RegionOfInterestDeleteResp {
    const message = createBaseRegionOfInterestDeleteResp();
    return message;
  },
};

function createBaseRegionOfInterestBulkDuplicateReq(): RegionOfInterestBulkDuplicateReq {
  return { ids: [], isMIST: false };
}

export const RegionOfInterestBulkDuplicateReq = {
  encode(message: RegionOfInterestBulkDuplicateReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.ids) {
      writer.uint32(10).string(v!);
    }
    if (message.isMIST === true) {
      writer.uint32(16).bool(message.isMIST);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RegionOfInterestBulkDuplicateReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegionOfInterestBulkDuplicateReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ids.push(reader.string());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isMIST = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RegionOfInterestBulkDuplicateReq {
    return {
      ids: Array.isArray(object?.ids) ? object.ids.map((e: any) => String(e)) : [],
      isMIST: isSet(object.isMIST) ? Boolean(object.isMIST) : false,
    };
  },

  toJSON(message: RegionOfInterestBulkDuplicateReq): unknown {
    const obj: any = {};
    if (message.ids) {
      obj.ids = message.ids.map((e) => e);
    } else {
      obj.ids = [];
    }
    message.isMIST !== undefined && (obj.isMIST = message.isMIST);
    return obj;
  },

  create<I extends Exact<DeepPartial<RegionOfInterestBulkDuplicateReq>, I>>(
    base?: I,
  ): RegionOfInterestBulkDuplicateReq {
    return RegionOfInterestBulkDuplicateReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RegionOfInterestBulkDuplicateReq>, I>>(
    object: I,
  ): RegionOfInterestBulkDuplicateReq {
    const message = createBaseRegionOfInterestBulkDuplicateReq();
    message.ids = object.ids?.map((e) => e) || [];
    message.isMIST = object.isMIST ?? false;
    return message;
  },
};

function createBaseRegionOfInterestBulkDuplicateResp(): RegionOfInterestBulkDuplicateResp {
  return { regionsOfInterest: {} };
}

export const RegionOfInterestBulkDuplicateResp = {
  encode(message: RegionOfInterestBulkDuplicateResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.regionsOfInterest).forEach(([key, value]) => {
      RegionOfInterestBulkDuplicateResp_RegionsOfInterestEntry.encode(
        { key: key as any, value },
        writer.uint32(10).fork(),
      ).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RegionOfInterestBulkDuplicateResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegionOfInterestBulkDuplicateResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = RegionOfInterestBulkDuplicateResp_RegionsOfInterestEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.regionsOfInterest[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RegionOfInterestBulkDuplicateResp {
    return {
      regionsOfInterest: isObject(object.regionsOfInterest)
        ? Object.entries(object.regionsOfInterest).reduce<{ [key: string]: ROIItemSummary }>((acc, [key, value]) => {
          acc[key] = ROIItemSummary.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: RegionOfInterestBulkDuplicateResp): unknown {
    const obj: any = {};
    obj.regionsOfInterest = {};
    if (message.regionsOfInterest) {
      Object.entries(message.regionsOfInterest).forEach(([k, v]) => {
        obj.regionsOfInterest[k] = ROIItemSummary.toJSON(v);
      });
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RegionOfInterestBulkDuplicateResp>, I>>(
    base?: I,
  ): RegionOfInterestBulkDuplicateResp {
    return RegionOfInterestBulkDuplicateResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RegionOfInterestBulkDuplicateResp>, I>>(
    object: I,
  ): RegionOfInterestBulkDuplicateResp {
    const message = createBaseRegionOfInterestBulkDuplicateResp();
    message.regionsOfInterest = Object.entries(object.regionsOfInterest ?? {}).reduce<
      { [key: string]: ROIItemSummary }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = ROIItemSummary.fromPartial(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseRegionOfInterestBulkDuplicateResp_RegionsOfInterestEntry(): RegionOfInterestBulkDuplicateResp_RegionsOfInterestEntry {
  return { key: "", value: undefined };
}

export const RegionOfInterestBulkDuplicateResp_RegionsOfInterestEntry = {
  encode(
    message: RegionOfInterestBulkDuplicateResp_RegionsOfInterestEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      ROIItemSummary.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RegionOfInterestBulkDuplicateResp_RegionsOfInterestEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegionOfInterestBulkDuplicateResp_RegionsOfInterestEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = ROIItemSummary.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RegionOfInterestBulkDuplicateResp_RegionsOfInterestEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? ROIItemSummary.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: RegionOfInterestBulkDuplicateResp_RegionsOfInterestEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value ? ROIItemSummary.toJSON(message.value) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<RegionOfInterestBulkDuplicateResp_RegionsOfInterestEntry>, I>>(
    base?: I,
  ): RegionOfInterestBulkDuplicateResp_RegionsOfInterestEntry {
    return RegionOfInterestBulkDuplicateResp_RegionsOfInterestEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RegionOfInterestBulkDuplicateResp_RegionsOfInterestEntry>, I>>(
    object: I,
  ): RegionOfInterestBulkDuplicateResp_RegionsOfInterestEntry {
    const message = createBaseRegionOfInterestBulkDuplicateResp_RegionsOfInterestEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? ROIItemSummary.fromPartial(object.value)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
