/* eslint-disable */
import * as _m0 from "protobufjs/minimal";

export const protobufPackage = "";

/** requires(QUANTIFY) */
export interface QuantUploadReq {
  scanId: string;
  name: string;
  comments: string;
  /** bool isCombined = 4; */
  csvData: string;
}

export interface QuantUploadResp {
  createdQuantId: string;
}

function createBaseQuantUploadReq(): QuantUploadReq {
  return { scanId: "", name: "", comments: "", csvData: "" };
}

export const QuantUploadReq = {
  encode(message: QuantUploadReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.scanId !== "") {
      writer.uint32(10).string(message.scanId);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.comments !== "") {
      writer.uint32(26).string(message.comments);
    }
    if (message.csvData !== "") {
      writer.uint32(42).string(message.csvData);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuantUploadReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantUploadReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.scanId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.comments = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.csvData = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuantUploadReq {
    return {
      scanId: isSet(object.scanId) ? String(object.scanId) : "",
      name: isSet(object.name) ? String(object.name) : "",
      comments: isSet(object.comments) ? String(object.comments) : "",
      csvData: isSet(object.csvData) ? String(object.csvData) : "",
    };
  },

  toJSON(message: QuantUploadReq): unknown {
    const obj: any = {};
    message.scanId !== undefined && (obj.scanId = message.scanId);
    message.name !== undefined && (obj.name = message.name);
    message.comments !== undefined && (obj.comments = message.comments);
    message.csvData !== undefined && (obj.csvData = message.csvData);
    return obj;
  },

  create<I extends Exact<DeepPartial<QuantUploadReq>, I>>(base?: I): QuantUploadReq {
    return QuantUploadReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuantUploadReq>, I>>(object: I): QuantUploadReq {
    const message = createBaseQuantUploadReq();
    message.scanId = object.scanId ?? "";
    message.name = object.name ?? "";
    message.comments = object.comments ?? "";
    message.csvData = object.csvData ?? "";
    return message;
  },
};

function createBaseQuantUploadResp(): QuantUploadResp {
  return { createdQuantId: "" };
}

export const QuantUploadResp = {
  encode(message: QuantUploadResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.createdQuantId !== "") {
      writer.uint32(10).string(message.createdQuantId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuantUploadResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantUploadResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.createdQuantId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuantUploadResp {
    return { createdQuantId: isSet(object.createdQuantId) ? String(object.createdQuantId) : "" };
  },

  toJSON(message: QuantUploadResp): unknown {
    const obj: any = {};
    message.createdQuantId !== undefined && (obj.createdQuantId = message.createdQuantId);
    return obj;
  },

  create<I extends Exact<DeepPartial<QuantUploadResp>, I>>(base?: I): QuantUploadResp {
    return QuantUploadResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuantUploadResp>, I>>(object: I): QuantUploadResp {
    const message = createBaseQuantUploadResp();
    message.createdQuantId = object.createdQuantId ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
