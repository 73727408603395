/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { Notification } from "./notification";

export const protobufPackage = "";

/** requires(NONE) */
export interface NotificationReq {
}

export interface NotificationResp {
  notification: Notification[];
}

export interface NotificationUpd {
  notification: Notification | undefined;
}

/** requires(NONE) */
export interface NotificationDismissReq {
  id: string;
}

export interface NotificationDismissResp {
}

/**
 * Admin-only feature, to send out a notification to all users
 * requires(PIXLISE_ADMIN)
 */
export interface SendUserNotificationReq {
  userIds: string[];
  groupIds: string[];
  notification: Notification | undefined;
}

export interface SendUserNotificationResp {
}

function createBaseNotificationReq(): NotificationReq {
  return {};
}

export const NotificationReq = {
  encode(_: NotificationReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NotificationReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotificationReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): NotificationReq {
    return {};
  },

  toJSON(_: NotificationReq): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<NotificationReq>, I>>(base?: I): NotificationReq {
    return NotificationReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<NotificationReq>, I>>(_: I): NotificationReq {
    const message = createBaseNotificationReq();
    return message;
  },
};

function createBaseNotificationResp(): NotificationResp {
  return { notification: [] };
}

export const NotificationResp = {
  encode(message: NotificationResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.notification) {
      Notification.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NotificationResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotificationResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.notification.push(Notification.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NotificationResp {
    return {
      notification: Array.isArray(object?.notification)
        ? object.notification.map((e: any) => Notification.fromJSON(e))
        : [],
    };
  },

  toJSON(message: NotificationResp): unknown {
    const obj: any = {};
    if (message.notification) {
      obj.notification = message.notification.map((e) => e ? Notification.toJSON(e) : undefined);
    } else {
      obj.notification = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NotificationResp>, I>>(base?: I): NotificationResp {
    return NotificationResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<NotificationResp>, I>>(object: I): NotificationResp {
    const message = createBaseNotificationResp();
    message.notification = object.notification?.map((e) => Notification.fromPartial(e)) || [];
    return message;
  },
};

function createBaseNotificationUpd(): NotificationUpd {
  return { notification: undefined };
}

export const NotificationUpd = {
  encode(message: NotificationUpd, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.notification !== undefined) {
      Notification.encode(message.notification, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NotificationUpd {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotificationUpd();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.notification = Notification.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NotificationUpd {
    return { notification: isSet(object.notification) ? Notification.fromJSON(object.notification) : undefined };
  },

  toJSON(message: NotificationUpd): unknown {
    const obj: any = {};
    message.notification !== undefined &&
      (obj.notification = message.notification ? Notification.toJSON(message.notification) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<NotificationUpd>, I>>(base?: I): NotificationUpd {
    return NotificationUpd.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<NotificationUpd>, I>>(object: I): NotificationUpd {
    const message = createBaseNotificationUpd();
    message.notification = (object.notification !== undefined && object.notification !== null)
      ? Notification.fromPartial(object.notification)
      : undefined;
    return message;
  },
};

function createBaseNotificationDismissReq(): NotificationDismissReq {
  return { id: "" };
}

export const NotificationDismissReq = {
  encode(message: NotificationDismissReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NotificationDismissReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotificationDismissReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NotificationDismissReq {
    return { id: isSet(object.id) ? String(object.id) : "" };
  },

  toJSON(message: NotificationDismissReq): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  create<I extends Exact<DeepPartial<NotificationDismissReq>, I>>(base?: I): NotificationDismissReq {
    return NotificationDismissReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<NotificationDismissReq>, I>>(object: I): NotificationDismissReq {
    const message = createBaseNotificationDismissReq();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseNotificationDismissResp(): NotificationDismissResp {
  return {};
}

export const NotificationDismissResp = {
  encode(_: NotificationDismissResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NotificationDismissResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotificationDismissResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): NotificationDismissResp {
    return {};
  },

  toJSON(_: NotificationDismissResp): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<NotificationDismissResp>, I>>(base?: I): NotificationDismissResp {
    return NotificationDismissResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<NotificationDismissResp>, I>>(_: I): NotificationDismissResp {
    const message = createBaseNotificationDismissResp();
    return message;
  },
};

function createBaseSendUserNotificationReq(): SendUserNotificationReq {
  return { userIds: [], groupIds: [], notification: undefined };
}

export const SendUserNotificationReq = {
  encode(message: SendUserNotificationReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.userIds) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.groupIds) {
      writer.uint32(18).string(v!);
    }
    if (message.notification !== undefined) {
      Notification.encode(message.notification, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SendUserNotificationReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSendUserNotificationReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userIds.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.groupIds.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.notification = Notification.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SendUserNotificationReq {
    return {
      userIds: Array.isArray(object?.userIds) ? object.userIds.map((e: any) => String(e)) : [],
      groupIds: Array.isArray(object?.groupIds) ? object.groupIds.map((e: any) => String(e)) : [],
      notification: isSet(object.notification) ? Notification.fromJSON(object.notification) : undefined,
    };
  },

  toJSON(message: SendUserNotificationReq): unknown {
    const obj: any = {};
    if (message.userIds) {
      obj.userIds = message.userIds.map((e) => e);
    } else {
      obj.userIds = [];
    }
    if (message.groupIds) {
      obj.groupIds = message.groupIds.map((e) => e);
    } else {
      obj.groupIds = [];
    }
    message.notification !== undefined &&
      (obj.notification = message.notification ? Notification.toJSON(message.notification) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<SendUserNotificationReq>, I>>(base?: I): SendUserNotificationReq {
    return SendUserNotificationReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SendUserNotificationReq>, I>>(object: I): SendUserNotificationReq {
    const message = createBaseSendUserNotificationReq();
    message.userIds = object.userIds?.map((e) => e) || [];
    message.groupIds = object.groupIds?.map((e) => e) || [];
    message.notification = (object.notification !== undefined && object.notification !== null)
      ? Notification.fromPartial(object.notification)
      : undefined;
    return message;
  },
};

function createBaseSendUserNotificationResp(): SendUserNotificationResp {
  return {};
}

export const SendUserNotificationResp = {
  encode(_: SendUserNotificationResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SendUserNotificationResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSendUserNotificationResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): SendUserNotificationResp {
    return {};
  },

  toJSON(_: SendUserNotificationResp): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<SendUserNotificationResp>, I>>(base?: I): SendUserNotificationResp {
    return SendUserNotificationResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SendUserNotificationResp>, I>>(_: I): SendUserNotificationResp {
    const message = createBaseSendUserNotificationResp();
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
