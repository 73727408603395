/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import {
  QuantCombineItem,
  QuantCombineItemList,
  QuantCombineSummary,
  QuantComparisonTable,
} from "./quantification-multi";

export const protobufPackage = "";

/** requires(QUANTIFY) */
export interface QuantCombineReq {
  scanId: string;
  roiZStack: QuantCombineItem[];
  name: string;
  description: string;
  summaryOnly: boolean;
}

export interface QuantCombineResp {
  jobId?: string | undefined;
  summary?: QuantCombineSummary | undefined;
}

/** requires(QUANTIFY) */
export interface QuantCombineListGetReq {
  scanId: string;
}

export interface QuantCombineListGetResp {
  list: QuantCombineItemList | undefined;
}

/** requires(QUANTIFY) */
export interface QuantCombineListWriteReq {
  scanId: string;
  list: QuantCombineItemList | undefined;
}

export interface QuantCombineListWriteResp {
}

/** requires(QUANTIFY) */
export interface MultiQuantCompareReq {
  scanId: string;
  reqRoiId: string;
  quantIds: string[];
  remainingPointsPMCs: number[];
}

export interface MultiQuantCompareResp {
  roiId: string;
  quantTables: QuantComparisonTable[];
}

function createBaseQuantCombineReq(): QuantCombineReq {
  return { scanId: "", roiZStack: [], name: "", description: "", summaryOnly: false };
}

export const QuantCombineReq = {
  encode(message: QuantCombineReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.scanId !== "") {
      writer.uint32(10).string(message.scanId);
    }
    for (const v of message.roiZStack) {
      QuantCombineItem.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(34).string(message.description);
    }
    if (message.summaryOnly === true) {
      writer.uint32(40).bool(message.summaryOnly);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuantCombineReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantCombineReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.scanId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.roiZStack.push(QuantCombineItem.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.description = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.summaryOnly = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuantCombineReq {
    return {
      scanId: isSet(object.scanId) ? String(object.scanId) : "",
      roiZStack: Array.isArray(object?.roiZStack) ? object.roiZStack.map((e: any) => QuantCombineItem.fromJSON(e)) : [],
      name: isSet(object.name) ? String(object.name) : "",
      description: isSet(object.description) ? String(object.description) : "",
      summaryOnly: isSet(object.summaryOnly) ? Boolean(object.summaryOnly) : false,
    };
  },

  toJSON(message: QuantCombineReq): unknown {
    const obj: any = {};
    message.scanId !== undefined && (obj.scanId = message.scanId);
    if (message.roiZStack) {
      obj.roiZStack = message.roiZStack.map((e) => e ? QuantCombineItem.toJSON(e) : undefined);
    } else {
      obj.roiZStack = [];
    }
    message.name !== undefined && (obj.name = message.name);
    message.description !== undefined && (obj.description = message.description);
    message.summaryOnly !== undefined && (obj.summaryOnly = message.summaryOnly);
    return obj;
  },

  create<I extends Exact<DeepPartial<QuantCombineReq>, I>>(base?: I): QuantCombineReq {
    return QuantCombineReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuantCombineReq>, I>>(object: I): QuantCombineReq {
    const message = createBaseQuantCombineReq();
    message.scanId = object.scanId ?? "";
    message.roiZStack = object.roiZStack?.map((e) => QuantCombineItem.fromPartial(e)) || [];
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.summaryOnly = object.summaryOnly ?? false;
    return message;
  },
};

function createBaseQuantCombineResp(): QuantCombineResp {
  return { jobId: undefined, summary: undefined };
}

export const QuantCombineResp = {
  encode(message: QuantCombineResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.jobId !== undefined) {
      writer.uint32(10).string(message.jobId);
    }
    if (message.summary !== undefined) {
      QuantCombineSummary.encode(message.summary, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuantCombineResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantCombineResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.jobId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.summary = QuantCombineSummary.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuantCombineResp {
    return {
      jobId: isSet(object.jobId) ? String(object.jobId) : undefined,
      summary: isSet(object.summary) ? QuantCombineSummary.fromJSON(object.summary) : undefined,
    };
  },

  toJSON(message: QuantCombineResp): unknown {
    const obj: any = {};
    message.jobId !== undefined && (obj.jobId = message.jobId);
    message.summary !== undefined &&
      (obj.summary = message.summary ? QuantCombineSummary.toJSON(message.summary) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<QuantCombineResp>, I>>(base?: I): QuantCombineResp {
    return QuantCombineResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuantCombineResp>, I>>(object: I): QuantCombineResp {
    const message = createBaseQuantCombineResp();
    message.jobId = object.jobId ?? undefined;
    message.summary = (object.summary !== undefined && object.summary !== null)
      ? QuantCombineSummary.fromPartial(object.summary)
      : undefined;
    return message;
  },
};

function createBaseQuantCombineListGetReq(): QuantCombineListGetReq {
  return { scanId: "" };
}

export const QuantCombineListGetReq = {
  encode(message: QuantCombineListGetReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.scanId !== "") {
      writer.uint32(10).string(message.scanId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuantCombineListGetReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantCombineListGetReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.scanId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuantCombineListGetReq {
    return { scanId: isSet(object.scanId) ? String(object.scanId) : "" };
  },

  toJSON(message: QuantCombineListGetReq): unknown {
    const obj: any = {};
    message.scanId !== undefined && (obj.scanId = message.scanId);
    return obj;
  },

  create<I extends Exact<DeepPartial<QuantCombineListGetReq>, I>>(base?: I): QuantCombineListGetReq {
    return QuantCombineListGetReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuantCombineListGetReq>, I>>(object: I): QuantCombineListGetReq {
    const message = createBaseQuantCombineListGetReq();
    message.scanId = object.scanId ?? "";
    return message;
  },
};

function createBaseQuantCombineListGetResp(): QuantCombineListGetResp {
  return { list: undefined };
}

export const QuantCombineListGetResp = {
  encode(message: QuantCombineListGetResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.list !== undefined) {
      QuantCombineItemList.encode(message.list, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuantCombineListGetResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantCombineListGetResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.list = QuantCombineItemList.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuantCombineListGetResp {
    return { list: isSet(object.list) ? QuantCombineItemList.fromJSON(object.list) : undefined };
  },

  toJSON(message: QuantCombineListGetResp): unknown {
    const obj: any = {};
    message.list !== undefined && (obj.list = message.list ? QuantCombineItemList.toJSON(message.list) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<QuantCombineListGetResp>, I>>(base?: I): QuantCombineListGetResp {
    return QuantCombineListGetResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuantCombineListGetResp>, I>>(object: I): QuantCombineListGetResp {
    const message = createBaseQuantCombineListGetResp();
    message.list = (object.list !== undefined && object.list !== null)
      ? QuantCombineItemList.fromPartial(object.list)
      : undefined;
    return message;
  },
};

function createBaseQuantCombineListWriteReq(): QuantCombineListWriteReq {
  return { scanId: "", list: undefined };
}

export const QuantCombineListWriteReq = {
  encode(message: QuantCombineListWriteReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.scanId !== "") {
      writer.uint32(10).string(message.scanId);
    }
    if (message.list !== undefined) {
      QuantCombineItemList.encode(message.list, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuantCombineListWriteReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantCombineListWriteReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.scanId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.list = QuantCombineItemList.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuantCombineListWriteReq {
    return {
      scanId: isSet(object.scanId) ? String(object.scanId) : "",
      list: isSet(object.list) ? QuantCombineItemList.fromJSON(object.list) : undefined,
    };
  },

  toJSON(message: QuantCombineListWriteReq): unknown {
    const obj: any = {};
    message.scanId !== undefined && (obj.scanId = message.scanId);
    message.list !== undefined && (obj.list = message.list ? QuantCombineItemList.toJSON(message.list) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<QuantCombineListWriteReq>, I>>(base?: I): QuantCombineListWriteReq {
    return QuantCombineListWriteReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuantCombineListWriteReq>, I>>(object: I): QuantCombineListWriteReq {
    const message = createBaseQuantCombineListWriteReq();
    message.scanId = object.scanId ?? "";
    message.list = (object.list !== undefined && object.list !== null)
      ? QuantCombineItemList.fromPartial(object.list)
      : undefined;
    return message;
  },
};

function createBaseQuantCombineListWriteResp(): QuantCombineListWriteResp {
  return {};
}

export const QuantCombineListWriteResp = {
  encode(_: QuantCombineListWriteResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuantCombineListWriteResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantCombineListWriteResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): QuantCombineListWriteResp {
    return {};
  },

  toJSON(_: QuantCombineListWriteResp): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<QuantCombineListWriteResp>, I>>(base?: I): QuantCombineListWriteResp {
    return QuantCombineListWriteResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuantCombineListWriteResp>, I>>(_: I): QuantCombineListWriteResp {
    const message = createBaseQuantCombineListWriteResp();
    return message;
  },
};

function createBaseMultiQuantCompareReq(): MultiQuantCompareReq {
  return { scanId: "", reqRoiId: "", quantIds: [], remainingPointsPMCs: [] };
}

export const MultiQuantCompareReq = {
  encode(message: MultiQuantCompareReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.scanId !== "") {
      writer.uint32(10).string(message.scanId);
    }
    if (message.reqRoiId !== "") {
      writer.uint32(18).string(message.reqRoiId);
    }
    for (const v of message.quantIds) {
      writer.uint32(26).string(v!);
    }
    writer.uint32(34).fork();
    for (const v of message.remainingPointsPMCs) {
      writer.int32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MultiQuantCompareReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMultiQuantCompareReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.scanId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.reqRoiId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.quantIds.push(reader.string());
          continue;
        case 4:
          if (tag === 32) {
            message.remainingPointsPMCs.push(reader.int32());

            continue;
          }

          if (tag === 34) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.remainingPointsPMCs.push(reader.int32());
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MultiQuantCompareReq {
    return {
      scanId: isSet(object.scanId) ? String(object.scanId) : "",
      reqRoiId: isSet(object.reqRoiId) ? String(object.reqRoiId) : "",
      quantIds: Array.isArray(object?.quantIds) ? object.quantIds.map((e: any) => String(e)) : [],
      remainingPointsPMCs: Array.isArray(object?.remainingPointsPMCs)
        ? object.remainingPointsPMCs.map((e: any) => Number(e))
        : [],
    };
  },

  toJSON(message: MultiQuantCompareReq): unknown {
    const obj: any = {};
    message.scanId !== undefined && (obj.scanId = message.scanId);
    message.reqRoiId !== undefined && (obj.reqRoiId = message.reqRoiId);
    if (message.quantIds) {
      obj.quantIds = message.quantIds.map((e) => e);
    } else {
      obj.quantIds = [];
    }
    if (message.remainingPointsPMCs) {
      obj.remainingPointsPMCs = message.remainingPointsPMCs.map((e) => Math.round(e));
    } else {
      obj.remainingPointsPMCs = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MultiQuantCompareReq>, I>>(base?: I): MultiQuantCompareReq {
    return MultiQuantCompareReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MultiQuantCompareReq>, I>>(object: I): MultiQuantCompareReq {
    const message = createBaseMultiQuantCompareReq();
    message.scanId = object.scanId ?? "";
    message.reqRoiId = object.reqRoiId ?? "";
    message.quantIds = object.quantIds?.map((e) => e) || [];
    message.remainingPointsPMCs = object.remainingPointsPMCs?.map((e) => e) || [];
    return message;
  },
};

function createBaseMultiQuantCompareResp(): MultiQuantCompareResp {
  return { roiId: "", quantTables: [] };
}

export const MultiQuantCompareResp = {
  encode(message: MultiQuantCompareResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.roiId !== "") {
      writer.uint32(10).string(message.roiId);
    }
    for (const v of message.quantTables) {
      QuantComparisonTable.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MultiQuantCompareResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMultiQuantCompareResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.roiId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.quantTables.push(QuantComparisonTable.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MultiQuantCompareResp {
    return {
      roiId: isSet(object.roiId) ? String(object.roiId) : "",
      quantTables: Array.isArray(object?.quantTables)
        ? object.quantTables.map((e: any) => QuantComparisonTable.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MultiQuantCompareResp): unknown {
    const obj: any = {};
    message.roiId !== undefined && (obj.roiId = message.roiId);
    if (message.quantTables) {
      obj.quantTables = message.quantTables.map((e) => e ? QuantComparisonTable.toJSON(e) : undefined);
    } else {
      obj.quantTables = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MultiQuantCompareResp>, I>>(base?: I): MultiQuantCompareResp {
    return MultiQuantCompareResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MultiQuantCompareResp>, I>>(object: I): MultiQuantCompareResp {
    const message = createBaseMultiQuantCompareResp();
    message.roiId = object.roiId ?? "";
    message.quantTables = object.quantTables?.map((e) => QuantComparisonTable.fromPartial(e)) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
