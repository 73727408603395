/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { ScanEntryRange } from "./scan";

export const protobufPackage = "";

/** requires(NONE) */
export interface SelectedScanEntriesReq {
  scanIds: string[];
}

export interface SelectedScanEntriesResp {
  scanIdEntryIndexes: { [key: string]: ScanEntryRange };
}

export interface SelectedScanEntriesResp_ScanIdEntryIndexesEntry {
  key: string;
  value: ScanEntryRange | undefined;
}

/** requires(NONE) */
export interface SelectedScanEntriesWriteReq {
  scanIdEntryIndexes: { [key: string]: ScanEntryRange };
}

export interface SelectedScanEntriesWriteReq_ScanIdEntryIndexesEntry {
  key: string;
  value: ScanEntryRange | undefined;
}

export interface SelectedScanEntriesWriteResp {
}

function createBaseSelectedScanEntriesReq(): SelectedScanEntriesReq {
  return { scanIds: [] };
}

export const SelectedScanEntriesReq = {
  encode(message: SelectedScanEntriesReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.scanIds) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SelectedScanEntriesReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSelectedScanEntriesReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.scanIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SelectedScanEntriesReq {
    return { scanIds: Array.isArray(object?.scanIds) ? object.scanIds.map((e: any) => String(e)) : [] };
  },

  toJSON(message: SelectedScanEntriesReq): unknown {
    const obj: any = {};
    if (message.scanIds) {
      obj.scanIds = message.scanIds.map((e) => e);
    } else {
      obj.scanIds = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SelectedScanEntriesReq>, I>>(base?: I): SelectedScanEntriesReq {
    return SelectedScanEntriesReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SelectedScanEntriesReq>, I>>(object: I): SelectedScanEntriesReq {
    const message = createBaseSelectedScanEntriesReq();
    message.scanIds = object.scanIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseSelectedScanEntriesResp(): SelectedScanEntriesResp {
  return { scanIdEntryIndexes: {} };
}

export const SelectedScanEntriesResp = {
  encode(message: SelectedScanEntriesResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.scanIdEntryIndexes).forEach(([key, value]) => {
      SelectedScanEntriesResp_ScanIdEntryIndexesEntry.encode({ key: key as any, value }, writer.uint32(10).fork())
        .ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SelectedScanEntriesResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSelectedScanEntriesResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = SelectedScanEntriesResp_ScanIdEntryIndexesEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.scanIdEntryIndexes[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SelectedScanEntriesResp {
    return {
      scanIdEntryIndexes: isObject(object.scanIdEntryIndexes)
        ? Object.entries(object.scanIdEntryIndexes).reduce<{ [key: string]: ScanEntryRange }>((acc, [key, value]) => {
          acc[key] = ScanEntryRange.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: SelectedScanEntriesResp): unknown {
    const obj: any = {};
    obj.scanIdEntryIndexes = {};
    if (message.scanIdEntryIndexes) {
      Object.entries(message.scanIdEntryIndexes).forEach(([k, v]) => {
        obj.scanIdEntryIndexes[k] = ScanEntryRange.toJSON(v);
      });
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SelectedScanEntriesResp>, I>>(base?: I): SelectedScanEntriesResp {
    return SelectedScanEntriesResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SelectedScanEntriesResp>, I>>(object: I): SelectedScanEntriesResp {
    const message = createBaseSelectedScanEntriesResp();
    message.scanIdEntryIndexes = Object.entries(object.scanIdEntryIndexes ?? {}).reduce<
      { [key: string]: ScanEntryRange }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = ScanEntryRange.fromPartial(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseSelectedScanEntriesResp_ScanIdEntryIndexesEntry(): SelectedScanEntriesResp_ScanIdEntryIndexesEntry {
  return { key: "", value: undefined };
}

export const SelectedScanEntriesResp_ScanIdEntryIndexesEntry = {
  encode(
    message: SelectedScanEntriesResp_ScanIdEntryIndexesEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      ScanEntryRange.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SelectedScanEntriesResp_ScanIdEntryIndexesEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSelectedScanEntriesResp_ScanIdEntryIndexesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = ScanEntryRange.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SelectedScanEntriesResp_ScanIdEntryIndexesEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? ScanEntryRange.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: SelectedScanEntriesResp_ScanIdEntryIndexesEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value ? ScanEntryRange.toJSON(message.value) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<SelectedScanEntriesResp_ScanIdEntryIndexesEntry>, I>>(
    base?: I,
  ): SelectedScanEntriesResp_ScanIdEntryIndexesEntry {
    return SelectedScanEntriesResp_ScanIdEntryIndexesEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SelectedScanEntriesResp_ScanIdEntryIndexesEntry>, I>>(
    object: I,
  ): SelectedScanEntriesResp_ScanIdEntryIndexesEntry {
    const message = createBaseSelectedScanEntriesResp_ScanIdEntryIndexesEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? ScanEntryRange.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseSelectedScanEntriesWriteReq(): SelectedScanEntriesWriteReq {
  return { scanIdEntryIndexes: {} };
}

export const SelectedScanEntriesWriteReq = {
  encode(message: SelectedScanEntriesWriteReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.scanIdEntryIndexes).forEach(([key, value]) => {
      SelectedScanEntriesWriteReq_ScanIdEntryIndexesEntry.encode({ key: key as any, value }, writer.uint32(10).fork())
        .ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SelectedScanEntriesWriteReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSelectedScanEntriesWriteReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = SelectedScanEntriesWriteReq_ScanIdEntryIndexesEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.scanIdEntryIndexes[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SelectedScanEntriesWriteReq {
    return {
      scanIdEntryIndexes: isObject(object.scanIdEntryIndexes)
        ? Object.entries(object.scanIdEntryIndexes).reduce<{ [key: string]: ScanEntryRange }>((acc, [key, value]) => {
          acc[key] = ScanEntryRange.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: SelectedScanEntriesWriteReq): unknown {
    const obj: any = {};
    obj.scanIdEntryIndexes = {};
    if (message.scanIdEntryIndexes) {
      Object.entries(message.scanIdEntryIndexes).forEach(([k, v]) => {
        obj.scanIdEntryIndexes[k] = ScanEntryRange.toJSON(v);
      });
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SelectedScanEntriesWriteReq>, I>>(base?: I): SelectedScanEntriesWriteReq {
    return SelectedScanEntriesWriteReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SelectedScanEntriesWriteReq>, I>>(object: I): SelectedScanEntriesWriteReq {
    const message = createBaseSelectedScanEntriesWriteReq();
    message.scanIdEntryIndexes = Object.entries(object.scanIdEntryIndexes ?? {}).reduce<
      { [key: string]: ScanEntryRange }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = ScanEntryRange.fromPartial(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseSelectedScanEntriesWriteReq_ScanIdEntryIndexesEntry(): SelectedScanEntriesWriteReq_ScanIdEntryIndexesEntry {
  return { key: "", value: undefined };
}

export const SelectedScanEntriesWriteReq_ScanIdEntryIndexesEntry = {
  encode(
    message: SelectedScanEntriesWriteReq_ScanIdEntryIndexesEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      ScanEntryRange.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SelectedScanEntriesWriteReq_ScanIdEntryIndexesEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSelectedScanEntriesWriteReq_ScanIdEntryIndexesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = ScanEntryRange.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SelectedScanEntriesWriteReq_ScanIdEntryIndexesEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? ScanEntryRange.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: SelectedScanEntriesWriteReq_ScanIdEntryIndexesEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value ? ScanEntryRange.toJSON(message.value) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<SelectedScanEntriesWriteReq_ScanIdEntryIndexesEntry>, I>>(
    base?: I,
  ): SelectedScanEntriesWriteReq_ScanIdEntryIndexesEntry {
    return SelectedScanEntriesWriteReq_ScanIdEntryIndexesEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SelectedScanEntriesWriteReq_ScanIdEntryIndexesEntry>, I>>(
    object: I,
  ): SelectedScanEntriesWriteReq_ScanIdEntryIndexesEntry {
    const message = createBaseSelectedScanEntriesWriteReq_ScanIdEntryIndexesEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? ScanEntryRange.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseSelectedScanEntriesWriteResp(): SelectedScanEntriesWriteResp {
  return {};
}

export const SelectedScanEntriesWriteResp = {
  encode(_: SelectedScanEntriesWriteResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SelectedScanEntriesWriteResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSelectedScanEntriesWriteResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): SelectedScanEntriesWriteResp {
    return {};
  },

  toJSON(_: SelectedScanEntriesWriteResp): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<SelectedScanEntriesWriteResp>, I>>(base?: I): SelectedScanEntriesWriteResp {
    return SelectedScanEntriesWriteResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SelectedScanEntriesWriteResp>, I>>(_: I): SelectedScanEntriesWriteResp {
    const message = createBaseSelectedScanEntriesWriteResp();
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
