/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { UserGroupJoinRequestDB } from "./user-group";

export const protobufPackage = "";

/** requires(NONE) */
export interface UserGroupJoinReq {
  groupId: string;
  asMember: boolean;
}

export interface UserGroupJoinResp {
}

/** requires(NONE) */
export interface UserGroupIgnoreJoinReq {
  groupId: string;
  requestId: string;
}

export interface UserGroupIgnoreJoinResp {
}

/** requires(NONE) */
export interface UserGroupJoinListReq {
  groupId: string;
}

export interface UserGroupJoinListResp {
  requests: UserGroupJoinRequestDB[];
}

function createBaseUserGroupJoinReq(): UserGroupJoinReq {
  return { groupId: "", asMember: false };
}

export const UserGroupJoinReq = {
  encode(message: UserGroupJoinReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.groupId !== "") {
      writer.uint32(10).string(message.groupId);
    }
    if (message.asMember === true) {
      writer.uint32(16).bool(message.asMember);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserGroupJoinReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserGroupJoinReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.groupId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.asMember = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserGroupJoinReq {
    return {
      groupId: isSet(object.groupId) ? String(object.groupId) : "",
      asMember: isSet(object.asMember) ? Boolean(object.asMember) : false,
    };
  },

  toJSON(message: UserGroupJoinReq): unknown {
    const obj: any = {};
    message.groupId !== undefined && (obj.groupId = message.groupId);
    message.asMember !== undefined && (obj.asMember = message.asMember);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserGroupJoinReq>, I>>(base?: I): UserGroupJoinReq {
    return UserGroupJoinReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserGroupJoinReq>, I>>(object: I): UserGroupJoinReq {
    const message = createBaseUserGroupJoinReq();
    message.groupId = object.groupId ?? "";
    message.asMember = object.asMember ?? false;
    return message;
  },
};

function createBaseUserGroupJoinResp(): UserGroupJoinResp {
  return {};
}

export const UserGroupJoinResp = {
  encode(_: UserGroupJoinResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserGroupJoinResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserGroupJoinResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UserGroupJoinResp {
    return {};
  },

  toJSON(_: UserGroupJoinResp): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<UserGroupJoinResp>, I>>(base?: I): UserGroupJoinResp {
    return UserGroupJoinResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserGroupJoinResp>, I>>(_: I): UserGroupJoinResp {
    const message = createBaseUserGroupJoinResp();
    return message;
  },
};

function createBaseUserGroupIgnoreJoinReq(): UserGroupIgnoreJoinReq {
  return { groupId: "", requestId: "" };
}

export const UserGroupIgnoreJoinReq = {
  encode(message: UserGroupIgnoreJoinReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.groupId !== "") {
      writer.uint32(10).string(message.groupId);
    }
    if (message.requestId !== "") {
      writer.uint32(18).string(message.requestId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserGroupIgnoreJoinReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserGroupIgnoreJoinReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.groupId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.requestId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserGroupIgnoreJoinReq {
    return {
      groupId: isSet(object.groupId) ? String(object.groupId) : "",
      requestId: isSet(object.requestId) ? String(object.requestId) : "",
    };
  },

  toJSON(message: UserGroupIgnoreJoinReq): unknown {
    const obj: any = {};
    message.groupId !== undefined && (obj.groupId = message.groupId);
    message.requestId !== undefined && (obj.requestId = message.requestId);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserGroupIgnoreJoinReq>, I>>(base?: I): UserGroupIgnoreJoinReq {
    return UserGroupIgnoreJoinReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserGroupIgnoreJoinReq>, I>>(object: I): UserGroupIgnoreJoinReq {
    const message = createBaseUserGroupIgnoreJoinReq();
    message.groupId = object.groupId ?? "";
    message.requestId = object.requestId ?? "";
    return message;
  },
};

function createBaseUserGroupIgnoreJoinResp(): UserGroupIgnoreJoinResp {
  return {};
}

export const UserGroupIgnoreJoinResp = {
  encode(_: UserGroupIgnoreJoinResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserGroupIgnoreJoinResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserGroupIgnoreJoinResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UserGroupIgnoreJoinResp {
    return {};
  },

  toJSON(_: UserGroupIgnoreJoinResp): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<UserGroupIgnoreJoinResp>, I>>(base?: I): UserGroupIgnoreJoinResp {
    return UserGroupIgnoreJoinResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserGroupIgnoreJoinResp>, I>>(_: I): UserGroupIgnoreJoinResp {
    const message = createBaseUserGroupIgnoreJoinResp();
    return message;
  },
};

function createBaseUserGroupJoinListReq(): UserGroupJoinListReq {
  return { groupId: "" };
}

export const UserGroupJoinListReq = {
  encode(message: UserGroupJoinListReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.groupId !== "") {
      writer.uint32(10).string(message.groupId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserGroupJoinListReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserGroupJoinListReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.groupId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserGroupJoinListReq {
    return { groupId: isSet(object.groupId) ? String(object.groupId) : "" };
  },

  toJSON(message: UserGroupJoinListReq): unknown {
    const obj: any = {};
    message.groupId !== undefined && (obj.groupId = message.groupId);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserGroupJoinListReq>, I>>(base?: I): UserGroupJoinListReq {
    return UserGroupJoinListReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserGroupJoinListReq>, I>>(object: I): UserGroupJoinListReq {
    const message = createBaseUserGroupJoinListReq();
    message.groupId = object.groupId ?? "";
    return message;
  },
};

function createBaseUserGroupJoinListResp(): UserGroupJoinListResp {
  return { requests: [] };
}

export const UserGroupJoinListResp = {
  encode(message: UserGroupJoinListResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.requests) {
      UserGroupJoinRequestDB.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserGroupJoinListResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserGroupJoinListResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.requests.push(UserGroupJoinRequestDB.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserGroupJoinListResp {
    return {
      requests: Array.isArray(object?.requests)
        ? object.requests.map((e: any) => UserGroupJoinRequestDB.fromJSON(e))
        : [],
    };
  },

  toJSON(message: UserGroupJoinListResp): unknown {
    const obj: any = {};
    if (message.requests) {
      obj.requests = message.requests.map((e) => e ? UserGroupJoinRequestDB.toJSON(e) : undefined);
    } else {
      obj.requests = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserGroupJoinListResp>, I>>(base?: I): UserGroupJoinListResp {
    return UserGroupJoinListResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserGroupJoinListResp>, I>>(object: I): UserGroupJoinListResp {
    const message = createBaseUserGroupJoinListResp();
    message.requests = object.requests?.map((e) => UserGroupJoinRequestDB.fromPartial(e)) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
