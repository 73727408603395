/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { DataExpression } from "./expressions";
import { ROIItem, ROIItemDisplaySettings } from "./roi";

export const protobufPackage = "";

export interface MemoisedItem {
  /** @gotags: bson:"_id,omitempty" */
  key: string;
  memoTimeUnixSec: number;
  data: Uint8Array;
}

export interface MemPMCDataValue {
  pmc: number;
  value: number;
  isUndefined: boolean;
  label: string;
}

export interface MemPMCDataValues {
  minValue: number;
  maxValue: number;
  values: MemPMCDataValue[];
  isBinary: boolean;
  warning: string;
}

export interface MemRegionSettings {
  region: ROIItem | undefined;
  displaySettings: ROIItemDisplaySettings | undefined;
  pixelIndexSet: number[];
}

export interface MemDataQueryResult {
  resultValues: MemPMCDataValues | undefined;
  isPMCTable: boolean;
  expression: DataExpression | undefined;
  region: MemRegionSettings | undefined;
}

function createBaseMemoisedItem(): MemoisedItem {
  return { key: "", memoTimeUnixSec: 0, data: new Uint8Array(0) };
}

export const MemoisedItem = {
  encode(message: MemoisedItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.memoTimeUnixSec !== 0) {
      writer.uint32(16).uint32(message.memoTimeUnixSec);
    }
    if (message.data.length !== 0) {
      writer.uint32(26).bytes(message.data);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MemoisedItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMemoisedItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.memoTimeUnixSec = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.data = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MemoisedItem {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      memoTimeUnixSec: isSet(object.memoTimeUnixSec) ? Number(object.memoTimeUnixSec) : 0,
      data: isSet(object.data) ? bytesFromBase64(object.data) : new Uint8Array(0),
    };
  },

  toJSON(message: MemoisedItem): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.memoTimeUnixSec !== undefined && (obj.memoTimeUnixSec = Math.round(message.memoTimeUnixSec));
    message.data !== undefined &&
      (obj.data = base64FromBytes(message.data !== undefined ? message.data : new Uint8Array(0)));
    return obj;
  },

  create<I extends Exact<DeepPartial<MemoisedItem>, I>>(base?: I): MemoisedItem {
    return MemoisedItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MemoisedItem>, I>>(object: I): MemoisedItem {
    const message = createBaseMemoisedItem();
    message.key = object.key ?? "";
    message.memoTimeUnixSec = object.memoTimeUnixSec ?? 0;
    message.data = object.data ?? new Uint8Array(0);
    return message;
  },
};

function createBaseMemPMCDataValue(): MemPMCDataValue {
  return { pmc: 0, value: 0, isUndefined: false, label: "" };
}

export const MemPMCDataValue = {
  encode(message: MemPMCDataValue, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pmc !== 0) {
      writer.uint32(8).uint32(message.pmc);
    }
    if (message.value !== 0) {
      writer.uint32(21).float(message.value);
    }
    if (message.isUndefined === true) {
      writer.uint32(24).bool(message.isUndefined);
    }
    if (message.label !== "") {
      writer.uint32(34).string(message.label);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MemPMCDataValue {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMemPMCDataValue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.pmc = reader.uint32();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.value = reader.float();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.isUndefined = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.label = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MemPMCDataValue {
    return {
      pmc: isSet(object.pmc) ? Number(object.pmc) : 0,
      value: isSet(object.value) ? Number(object.value) : 0,
      isUndefined: isSet(object.isUndefined) ? Boolean(object.isUndefined) : false,
      label: isSet(object.label) ? String(object.label) : "",
    };
  },

  toJSON(message: MemPMCDataValue): unknown {
    const obj: any = {};
    message.pmc !== undefined && (obj.pmc = Math.round(message.pmc));
    message.value !== undefined && (obj.value = message.value);
    message.isUndefined !== undefined && (obj.isUndefined = message.isUndefined);
    message.label !== undefined && (obj.label = message.label);
    return obj;
  },

  create<I extends Exact<DeepPartial<MemPMCDataValue>, I>>(base?: I): MemPMCDataValue {
    return MemPMCDataValue.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MemPMCDataValue>, I>>(object: I): MemPMCDataValue {
    const message = createBaseMemPMCDataValue();
    message.pmc = object.pmc ?? 0;
    message.value = object.value ?? 0;
    message.isUndefined = object.isUndefined ?? false;
    message.label = object.label ?? "";
    return message;
  },
};

function createBaseMemPMCDataValues(): MemPMCDataValues {
  return { minValue: 0, maxValue: 0, values: [], isBinary: false, warning: "" };
}

export const MemPMCDataValues = {
  encode(message: MemPMCDataValues, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.minValue !== 0) {
      writer.uint32(13).float(message.minValue);
    }
    if (message.maxValue !== 0) {
      writer.uint32(21).float(message.maxValue);
    }
    for (const v of message.values) {
      MemPMCDataValue.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.isBinary === true) {
      writer.uint32(32).bool(message.isBinary);
    }
    if (message.warning !== "") {
      writer.uint32(42).string(message.warning);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MemPMCDataValues {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMemPMCDataValues();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break;
          }

          message.minValue = reader.float();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.maxValue = reader.float();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.values.push(MemPMCDataValue.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.isBinary = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.warning = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MemPMCDataValues {
    return {
      minValue: isSet(object.minValue) ? Number(object.minValue) : 0,
      maxValue: isSet(object.maxValue) ? Number(object.maxValue) : 0,
      values: Array.isArray(object?.values) ? object.values.map((e: any) => MemPMCDataValue.fromJSON(e)) : [],
      isBinary: isSet(object.isBinary) ? Boolean(object.isBinary) : false,
      warning: isSet(object.warning) ? String(object.warning) : "",
    };
  },

  toJSON(message: MemPMCDataValues): unknown {
    const obj: any = {};
    message.minValue !== undefined && (obj.minValue = message.minValue);
    message.maxValue !== undefined && (obj.maxValue = message.maxValue);
    if (message.values) {
      obj.values = message.values.map((e) => e ? MemPMCDataValue.toJSON(e) : undefined);
    } else {
      obj.values = [];
    }
    message.isBinary !== undefined && (obj.isBinary = message.isBinary);
    message.warning !== undefined && (obj.warning = message.warning);
    return obj;
  },

  create<I extends Exact<DeepPartial<MemPMCDataValues>, I>>(base?: I): MemPMCDataValues {
    return MemPMCDataValues.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MemPMCDataValues>, I>>(object: I): MemPMCDataValues {
    const message = createBaseMemPMCDataValues();
    message.minValue = object.minValue ?? 0;
    message.maxValue = object.maxValue ?? 0;
    message.values = object.values?.map((e) => MemPMCDataValue.fromPartial(e)) || [];
    message.isBinary = object.isBinary ?? false;
    message.warning = object.warning ?? "";
    return message;
  },
};

function createBaseMemRegionSettings(): MemRegionSettings {
  return { region: undefined, displaySettings: undefined, pixelIndexSet: [] };
}

export const MemRegionSettings = {
  encode(message: MemRegionSettings, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.region !== undefined) {
      ROIItem.encode(message.region, writer.uint32(10).fork()).ldelim();
    }
    if (message.displaySettings !== undefined) {
      ROIItemDisplaySettings.encode(message.displaySettings, writer.uint32(18).fork()).ldelim();
    }
    writer.uint32(26).fork();
    for (const v of message.pixelIndexSet) {
      writer.uint32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MemRegionSettings {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMemRegionSettings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.region = ROIItem.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.displaySettings = ROIItemDisplaySettings.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag === 24) {
            message.pixelIndexSet.push(reader.uint32());

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.pixelIndexSet.push(reader.uint32());
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MemRegionSettings {
    return {
      region: isSet(object.region) ? ROIItem.fromJSON(object.region) : undefined,
      displaySettings: isSet(object.displaySettings)
        ? ROIItemDisplaySettings.fromJSON(object.displaySettings)
        : undefined,
      pixelIndexSet: Array.isArray(object?.pixelIndexSet) ? object.pixelIndexSet.map((e: any) => Number(e)) : [],
    };
  },

  toJSON(message: MemRegionSettings): unknown {
    const obj: any = {};
    message.region !== undefined && (obj.region = message.region ? ROIItem.toJSON(message.region) : undefined);
    message.displaySettings !== undefined && (obj.displaySettings = message.displaySettings
      ? ROIItemDisplaySettings.toJSON(message.displaySettings)
      : undefined);
    if (message.pixelIndexSet) {
      obj.pixelIndexSet = message.pixelIndexSet.map((e) => Math.round(e));
    } else {
      obj.pixelIndexSet = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MemRegionSettings>, I>>(base?: I): MemRegionSettings {
    return MemRegionSettings.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MemRegionSettings>, I>>(object: I): MemRegionSettings {
    const message = createBaseMemRegionSettings();
    message.region = (object.region !== undefined && object.region !== null)
      ? ROIItem.fromPartial(object.region)
      : undefined;
    message.displaySettings = (object.displaySettings !== undefined && object.displaySettings !== null)
      ? ROIItemDisplaySettings.fromPartial(object.displaySettings)
      : undefined;
    message.pixelIndexSet = object.pixelIndexSet?.map((e) => e) || [];
    return message;
  },
};

function createBaseMemDataQueryResult(): MemDataQueryResult {
  return { resultValues: undefined, isPMCTable: false, expression: undefined, region: undefined };
}

export const MemDataQueryResult = {
  encode(message: MemDataQueryResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.resultValues !== undefined) {
      MemPMCDataValues.encode(message.resultValues, writer.uint32(10).fork()).ldelim();
    }
    if (message.isPMCTable === true) {
      writer.uint32(16).bool(message.isPMCTable);
    }
    if (message.expression !== undefined) {
      DataExpression.encode(message.expression, writer.uint32(26).fork()).ldelim();
    }
    if (message.region !== undefined) {
      MemRegionSettings.encode(message.region, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MemDataQueryResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMemDataQueryResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.resultValues = MemPMCDataValues.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isPMCTable = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.expression = DataExpression.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.region = MemRegionSettings.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MemDataQueryResult {
    return {
      resultValues: isSet(object.resultValues) ? MemPMCDataValues.fromJSON(object.resultValues) : undefined,
      isPMCTable: isSet(object.isPMCTable) ? Boolean(object.isPMCTable) : false,
      expression: isSet(object.expression) ? DataExpression.fromJSON(object.expression) : undefined,
      region: isSet(object.region) ? MemRegionSettings.fromJSON(object.region) : undefined,
    };
  },

  toJSON(message: MemDataQueryResult): unknown {
    const obj: any = {};
    message.resultValues !== undefined &&
      (obj.resultValues = message.resultValues ? MemPMCDataValues.toJSON(message.resultValues) : undefined);
    message.isPMCTable !== undefined && (obj.isPMCTable = message.isPMCTable);
    message.expression !== undefined &&
      (obj.expression = message.expression ? DataExpression.toJSON(message.expression) : undefined);
    message.region !== undefined &&
      (obj.region = message.region ? MemRegionSettings.toJSON(message.region) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<MemDataQueryResult>, I>>(base?: I): MemDataQueryResult {
    return MemDataQueryResult.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MemDataQueryResult>, I>>(object: I): MemDataQueryResult {
    const message = createBaseMemDataQueryResult();
    message.resultValues = (object.resultValues !== undefined && object.resultValues !== null)
      ? MemPMCDataValues.fromPartial(object.resultValues)
      : undefined;
    message.isPMCTable = object.isPMCTable ?? false;
    message.expression = (object.expression !== undefined && object.expression !== null)
      ? DataExpression.fromPartial(object.expression)
      : undefined;
    message.region = (object.region !== undefined && object.region !== null)
      ? MemRegionSettings.fromPartial(object.region)
      : undefined;
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

function bytesFromBase64(b64: string): Uint8Array {
  if (tsProtoGlobalThis.Buffer) {
    return Uint8Array.from(tsProtoGlobalThis.Buffer.from(b64, "base64"));
  } else {
    const bin = tsProtoGlobalThis.atob(b64);
    const arr = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; ++i) {
      arr[i] = bin.charCodeAt(i);
    }
    return arr;
  }
}

function base64FromBytes(arr: Uint8Array): string {
  if (tsProtoGlobalThis.Buffer) {
    return tsProtoGlobalThis.Buffer.from(arr).toString("base64");
  } else {
    const bin: string[] = [];
    arr.forEach((byte) => {
      bin.push(String.fromCharCode(byte));
    });
    return tsProtoGlobalThis.btoa(bin.join(""));
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
