/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { OwnershipSummary } from "./ownership-access";

export const protobufPackage = "";

export interface ElementLine {
  Z: number;
  K: boolean;
  L: boolean;
  M: boolean;
  Esc: boolean;
}

export interface ElementSet {
  /** @gotags: bson:"_id,omitempty" */
  id: string;
  name: string;
  lines: ElementLine[];
  modifiedUnixSec: number;
  /** Only sent out by API, not stored in DB this way */
  owner: OwnershipSummary | undefined;
}

/**
 * This is a view of ElementSet, only to be used when viewing
 * a list of element sets, not intended to be in DB
 */
export interface ElementSetSummary {
  id: string;
  name: string;
  atomicNumbers: number[];
  modifiedUnixSec: number;
  owner: OwnershipSummary | undefined;
}

function createBaseElementLine(): ElementLine {
  return { Z: 0, K: false, L: false, M: false, Esc: false };
}

export const ElementLine = {
  encode(message: ElementLine, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.Z !== 0) {
      writer.uint32(8).int32(message.Z);
    }
    if (message.K === true) {
      writer.uint32(16).bool(message.K);
    }
    if (message.L === true) {
      writer.uint32(24).bool(message.L);
    }
    if (message.M === true) {
      writer.uint32(32).bool(message.M);
    }
    if (message.Esc === true) {
      writer.uint32(40).bool(message.Esc);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ElementLine {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseElementLine();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.Z = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.K = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.L = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.M = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.Esc = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ElementLine {
    return {
      Z: isSet(object.Z) ? Number(object.Z) : 0,
      K: isSet(object.K) ? Boolean(object.K) : false,
      L: isSet(object.L) ? Boolean(object.L) : false,
      M: isSet(object.M) ? Boolean(object.M) : false,
      Esc: isSet(object.Esc) ? Boolean(object.Esc) : false,
    };
  },

  toJSON(message: ElementLine): unknown {
    const obj: any = {};
    message.Z !== undefined && (obj.Z = Math.round(message.Z));
    message.K !== undefined && (obj.K = message.K);
    message.L !== undefined && (obj.L = message.L);
    message.M !== undefined && (obj.M = message.M);
    message.Esc !== undefined && (obj.Esc = message.Esc);
    return obj;
  },

  create<I extends Exact<DeepPartial<ElementLine>, I>>(base?: I): ElementLine {
    return ElementLine.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ElementLine>, I>>(object: I): ElementLine {
    const message = createBaseElementLine();
    message.Z = object.Z ?? 0;
    message.K = object.K ?? false;
    message.L = object.L ?? false;
    message.M = object.M ?? false;
    message.Esc = object.Esc ?? false;
    return message;
  },
};

function createBaseElementSet(): ElementSet {
  return { id: "", name: "", lines: [], modifiedUnixSec: 0, owner: undefined };
}

export const ElementSet = {
  encode(message: ElementSet, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    for (const v of message.lines) {
      ElementLine.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.modifiedUnixSec !== 0) {
      writer.uint32(32).uint32(message.modifiedUnixSec);
    }
    if (message.owner !== undefined) {
      OwnershipSummary.encode(message.owner, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ElementSet {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseElementSet();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.lines.push(ElementLine.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.modifiedUnixSec = reader.uint32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.owner = OwnershipSummary.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ElementSet {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      name: isSet(object.name) ? String(object.name) : "",
      lines: Array.isArray(object?.lines) ? object.lines.map((e: any) => ElementLine.fromJSON(e)) : [],
      modifiedUnixSec: isSet(object.modifiedUnixSec) ? Number(object.modifiedUnixSec) : 0,
      owner: isSet(object.owner) ? OwnershipSummary.fromJSON(object.owner) : undefined,
    };
  },

  toJSON(message: ElementSet): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    if (message.lines) {
      obj.lines = message.lines.map((e) => e ? ElementLine.toJSON(e) : undefined);
    } else {
      obj.lines = [];
    }
    message.modifiedUnixSec !== undefined && (obj.modifiedUnixSec = Math.round(message.modifiedUnixSec));
    message.owner !== undefined && (obj.owner = message.owner ? OwnershipSummary.toJSON(message.owner) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ElementSet>, I>>(base?: I): ElementSet {
    return ElementSet.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ElementSet>, I>>(object: I): ElementSet {
    const message = createBaseElementSet();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.lines = object.lines?.map((e) => ElementLine.fromPartial(e)) || [];
    message.modifiedUnixSec = object.modifiedUnixSec ?? 0;
    message.owner = (object.owner !== undefined && object.owner !== null)
      ? OwnershipSummary.fromPartial(object.owner)
      : undefined;
    return message;
  },
};

function createBaseElementSetSummary(): ElementSetSummary {
  return { id: "", name: "", atomicNumbers: [], modifiedUnixSec: 0, owner: undefined };
}

export const ElementSetSummary = {
  encode(message: ElementSetSummary, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    writer.uint32(26).fork();
    for (const v of message.atomicNumbers) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.modifiedUnixSec !== 0) {
      writer.uint32(32).uint32(message.modifiedUnixSec);
    }
    if (message.owner !== undefined) {
      OwnershipSummary.encode(message.owner, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ElementSetSummary {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseElementSetSummary();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag === 24) {
            message.atomicNumbers.push(reader.int32());

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.atomicNumbers.push(reader.int32());
            }

            continue;
          }

          break;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.modifiedUnixSec = reader.uint32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.owner = OwnershipSummary.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ElementSetSummary {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      name: isSet(object.name) ? String(object.name) : "",
      atomicNumbers: Array.isArray(object?.atomicNumbers) ? object.atomicNumbers.map((e: any) => Number(e)) : [],
      modifiedUnixSec: isSet(object.modifiedUnixSec) ? Number(object.modifiedUnixSec) : 0,
      owner: isSet(object.owner) ? OwnershipSummary.fromJSON(object.owner) : undefined,
    };
  },

  toJSON(message: ElementSetSummary): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    if (message.atomicNumbers) {
      obj.atomicNumbers = message.atomicNumbers.map((e) => Math.round(e));
    } else {
      obj.atomicNumbers = [];
    }
    message.modifiedUnixSec !== undefined && (obj.modifiedUnixSec = Math.round(message.modifiedUnixSec));
    message.owner !== undefined && (obj.owner = message.owner ? OwnershipSummary.toJSON(message.owner) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ElementSetSummary>, I>>(base?: I): ElementSetSummary {
    return ElementSetSummary.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ElementSetSummary>, I>>(object: I): ElementSetSummary {
    const message = createBaseElementSetSummary();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.atomicNumbers = object.atomicNumbers?.map((e) => e) || [];
    message.modifiedUnixSec = object.modifiedUnixSec ?? 0;
    message.owner = (object.owner !== undefined && object.owner !== null)
      ? OwnershipSummary.fromPartial(object.owner)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
