/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { ScanEntryRange } from "./scan";
import { ScanEntryMetadata } from "./scan-entry-metadata";

export const protobufPackage = "";

/** requires(NONE) */
export interface ScanEntryMetadataReq {
  scanId: string;
  entries: ScanEntryRange | undefined;
}

export interface ScanEntryMetadataResp {
  entries: ScanEntryMetadata[];
}

function createBaseScanEntryMetadataReq(): ScanEntryMetadataReq {
  return { scanId: "", entries: undefined };
}

export const ScanEntryMetadataReq = {
  encode(message: ScanEntryMetadataReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.scanId !== "") {
      writer.uint32(10).string(message.scanId);
    }
    if (message.entries !== undefined) {
      ScanEntryRange.encode(message.entries, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScanEntryMetadataReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScanEntryMetadataReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.scanId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.entries = ScanEntryRange.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScanEntryMetadataReq {
    return {
      scanId: isSet(object.scanId) ? String(object.scanId) : "",
      entries: isSet(object.entries) ? ScanEntryRange.fromJSON(object.entries) : undefined,
    };
  },

  toJSON(message: ScanEntryMetadataReq): unknown {
    const obj: any = {};
    message.scanId !== undefined && (obj.scanId = message.scanId);
    message.entries !== undefined &&
      (obj.entries = message.entries ? ScanEntryRange.toJSON(message.entries) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ScanEntryMetadataReq>, I>>(base?: I): ScanEntryMetadataReq {
    return ScanEntryMetadataReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScanEntryMetadataReq>, I>>(object: I): ScanEntryMetadataReq {
    const message = createBaseScanEntryMetadataReq();
    message.scanId = object.scanId ?? "";
    message.entries = (object.entries !== undefined && object.entries !== null)
      ? ScanEntryRange.fromPartial(object.entries)
      : undefined;
    return message;
  },
};

function createBaseScanEntryMetadataResp(): ScanEntryMetadataResp {
  return { entries: [] };
}

export const ScanEntryMetadataResp = {
  encode(message: ScanEntryMetadataResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.entries) {
      ScanEntryMetadata.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScanEntryMetadataResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScanEntryMetadataResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.entries.push(ScanEntryMetadata.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScanEntryMetadataResp {
    return {
      entries: Array.isArray(object?.entries) ? object.entries.map((e: any) => ScanEntryMetadata.fromJSON(e)) : [],
    };
  },

  toJSON(message: ScanEntryMetadataResp): unknown {
    const obj: any = {};
    if (message.entries) {
      obj.entries = message.entries.map((e) => e ? ScanEntryMetadata.toJSON(e) : undefined);
    } else {
      obj.entries = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ScanEntryMetadataResp>, I>>(base?: I): ScanEntryMetadataResp {
    return ScanEntryMetadataResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScanEntryMetadataResp>, I>>(object: I): ScanEntryMetadataResp {
    const message = createBaseScanEntryMetadataResp();
    message.entries = object.entries?.map((e) => ScanEntryMetadata.fromPartial(e)) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
