/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { DetectedDiffractionPerLocation } from "./diffraction-data";
import { ScanEntryRange } from "./scan";

export const protobufPackage = "";

/** requires(NONE) */
export interface DetectedDiffractionPeaksReq {
  scanId: string;
  entries: ScanEntryRange | undefined;
}

export interface DetectedDiffractionPeaksResp {
  peaksPerLocation: DetectedDiffractionPerLocation[];
}

function createBaseDetectedDiffractionPeaksReq(): DetectedDiffractionPeaksReq {
  return { scanId: "", entries: undefined };
}

export const DetectedDiffractionPeaksReq = {
  encode(message: DetectedDiffractionPeaksReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.scanId !== "") {
      writer.uint32(10).string(message.scanId);
    }
    if (message.entries !== undefined) {
      ScanEntryRange.encode(message.entries, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DetectedDiffractionPeaksReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDetectedDiffractionPeaksReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.scanId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.entries = ScanEntryRange.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DetectedDiffractionPeaksReq {
    return {
      scanId: isSet(object.scanId) ? String(object.scanId) : "",
      entries: isSet(object.entries) ? ScanEntryRange.fromJSON(object.entries) : undefined,
    };
  },

  toJSON(message: DetectedDiffractionPeaksReq): unknown {
    const obj: any = {};
    message.scanId !== undefined && (obj.scanId = message.scanId);
    message.entries !== undefined &&
      (obj.entries = message.entries ? ScanEntryRange.toJSON(message.entries) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DetectedDiffractionPeaksReq>, I>>(base?: I): DetectedDiffractionPeaksReq {
    return DetectedDiffractionPeaksReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DetectedDiffractionPeaksReq>, I>>(object: I): DetectedDiffractionPeaksReq {
    const message = createBaseDetectedDiffractionPeaksReq();
    message.scanId = object.scanId ?? "";
    message.entries = (object.entries !== undefined && object.entries !== null)
      ? ScanEntryRange.fromPartial(object.entries)
      : undefined;
    return message;
  },
};

function createBaseDetectedDiffractionPeaksResp(): DetectedDiffractionPeaksResp {
  return { peaksPerLocation: [] };
}

export const DetectedDiffractionPeaksResp = {
  encode(message: DetectedDiffractionPeaksResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.peaksPerLocation) {
      DetectedDiffractionPerLocation.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DetectedDiffractionPeaksResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDetectedDiffractionPeaksResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.peaksPerLocation.push(DetectedDiffractionPerLocation.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DetectedDiffractionPeaksResp {
    return {
      peaksPerLocation: Array.isArray(object?.peaksPerLocation)
        ? object.peaksPerLocation.map((e: any) => DetectedDiffractionPerLocation.fromJSON(e))
        : [],
    };
  },

  toJSON(message: DetectedDiffractionPeaksResp): unknown {
    const obj: any = {};
    if (message.peaksPerLocation) {
      obj.peaksPerLocation = message.peaksPerLocation.map((e) =>
        e ? DetectedDiffractionPerLocation.toJSON(e) : undefined
      );
    } else {
      obj.peaksPerLocation = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DetectedDiffractionPeaksResp>, I>>(base?: I): DetectedDiffractionPeaksResp {
    return DetectedDiffractionPeaksResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DetectedDiffractionPeaksResp>, I>>(object: I): DetectedDiffractionPeaksResp {
    const message = createBaseDetectedDiffractionPeaksResp();
    message.peaksPerLocation = object.peaksPerLocation?.map((e) => DetectedDiffractionPerLocation.fromPartial(e)) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
