/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { DetectorConfig } from "./detector-config";

export const protobufPackage = "";

/** requires(NONE) */
export interface DetectorConfigReq {
  id: string;
}

export interface DetectorConfigResp {
  config: DetectorConfig | undefined;
  piquantConfigVersions: string[];
}

/** requires(NONE) */
export interface DetectorConfigListReq {
  id: string;
}

export interface DetectorConfigListResp {
  configs: string[];
}

function createBaseDetectorConfigReq(): DetectorConfigReq {
  return { id: "" };
}

export const DetectorConfigReq = {
  encode(message: DetectorConfigReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DetectorConfigReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDetectorConfigReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DetectorConfigReq {
    return { id: isSet(object.id) ? String(object.id) : "" };
  },

  toJSON(message: DetectorConfigReq): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  create<I extends Exact<DeepPartial<DetectorConfigReq>, I>>(base?: I): DetectorConfigReq {
    return DetectorConfigReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DetectorConfigReq>, I>>(object: I): DetectorConfigReq {
    const message = createBaseDetectorConfigReq();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseDetectorConfigResp(): DetectorConfigResp {
  return { config: undefined, piquantConfigVersions: [] };
}

export const DetectorConfigResp = {
  encode(message: DetectorConfigResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.config !== undefined) {
      DetectorConfig.encode(message.config, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.piquantConfigVersions) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DetectorConfigResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDetectorConfigResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.config = DetectorConfig.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.piquantConfigVersions.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DetectorConfigResp {
    return {
      config: isSet(object.config) ? DetectorConfig.fromJSON(object.config) : undefined,
      piquantConfigVersions: Array.isArray(object?.piquantConfigVersions)
        ? object.piquantConfigVersions.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: DetectorConfigResp): unknown {
    const obj: any = {};
    message.config !== undefined && (obj.config = message.config ? DetectorConfig.toJSON(message.config) : undefined);
    if (message.piquantConfigVersions) {
      obj.piquantConfigVersions = message.piquantConfigVersions.map((e) => e);
    } else {
      obj.piquantConfigVersions = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DetectorConfigResp>, I>>(base?: I): DetectorConfigResp {
    return DetectorConfigResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DetectorConfigResp>, I>>(object: I): DetectorConfigResp {
    const message = createBaseDetectorConfigResp();
    message.config = (object.config !== undefined && object.config !== null)
      ? DetectorConfig.fromPartial(object.config)
      : undefined;
    message.piquantConfigVersions = object.piquantConfigVersions?.map((e) => e) || [];
    return message;
  },
};

function createBaseDetectorConfigListReq(): DetectorConfigListReq {
  return { id: "" };
}

export const DetectorConfigListReq = {
  encode(message: DetectorConfigListReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DetectorConfigListReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDetectorConfigListReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DetectorConfigListReq {
    return { id: isSet(object.id) ? String(object.id) : "" };
  },

  toJSON(message: DetectorConfigListReq): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  create<I extends Exact<DeepPartial<DetectorConfigListReq>, I>>(base?: I): DetectorConfigListReq {
    return DetectorConfigListReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DetectorConfigListReq>, I>>(object: I): DetectorConfigListReq {
    const message = createBaseDetectorConfigListReq();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseDetectorConfigListResp(): DetectorConfigListResp {
  return { configs: [] };
}

export const DetectorConfigListResp = {
  encode(message: DetectorConfigListResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.configs) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DetectorConfigListResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDetectorConfigListResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.configs.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DetectorConfigListResp {
    return { configs: Array.isArray(object?.configs) ? object.configs.map((e: any) => String(e)) : [] };
  },

  toJSON(message: DetectorConfigListResp): unknown {
    const obj: any = {};
    if (message.configs) {
      obj.configs = message.configs.map((e) => e);
    } else {
      obj.configs = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DetectorConfigListResp>, I>>(base?: I): DetectorConfigListResp {
    return DetectorConfigListResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DetectorConfigListResp>, I>>(object: I): DetectorConfigListResp {
    const message = createBaseDetectorConfigListResp();
    message.configs = object.configs?.map((e) => e) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
