<ng-template #settingsMenu>
  <div class="settings">
    <two-state-button
      leftLabel="Bulk"
      rightLabel="Selection"
      title="Toggle to show data only for selected poitns vs bulk sum of all points"
      [active]="showSelectionOnly"
      (onToggle)="onToggleShowSelectionOnly()">
    </two-state-button>
    <h4>Correlation Display</h4>
    <multi-state-button
      class="gap-top"
      [stateNames]="stateNames"
      [items]="['assets/button-icons/minus-yellow.svg', 'Both', 'assets/button-icons/plus-blue.svg']"
      [activeState]="correlationDisplayMode"
      (onChange)="onToggleCorrelationDisplayMode($event)"></multi-state-button>
    <h4>Threshold</h4>
    <div class="row">
      <span class="slider-end">0</span>
      <slider
        [minValue]="0"
        [maxValue]="1"
        [pxLength]="100"
        [trackColourBelowValue]="sliderTrackColourYellow"
        [trackColourAboveValue]="sliderTrackColourGray"
        [value]="threshold"
        (onChange)="onChangeThreshold($event)">
      </slider>
      <span class="slider-end">1</span>
    </div>
  </div>
</ng-template>

<interactive-canvas [ngClass]="mdl.cursorShown" [drawer]="drawer" [drawNotifier]="mdl" [interactionHandler]="interactionHandler" [transform]="transform">
</interactive-canvas>
