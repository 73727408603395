/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { DataModule } from "./modules";
import { SemanticVersion, VersionField, versionFieldFromJSON, versionFieldToJSON } from "./version";

export const protobufPackage = "";

/** requires(NONE) */
export interface DataModuleListReq {
}

export interface DataModuleListResp {
  modules: { [key: string]: DataModule };
}

export interface DataModuleListResp_ModulesEntry {
  key: string;
  value: DataModule | undefined;
}

/** requires(NONE) */
export interface DataModuleGetReq {
  id: string;
  version: SemanticVersion | undefined;
}

export interface DataModuleGetResp {
  module: DataModule | undefined;
}

/**
 * If id is blank, assume its new and generate an ID to return, otherwise update & return same one
 * requires(EDIT_EXPRESSION)
 */
export interface DataModuleWriteReq {
  id: string;
  name: string;
  comments: string;
  /**
   * Must be empty if id is empty, this is only the
   * initial source when creating with a blank id
   */
  initialSourceCode: string;
  /** Same as above... */
  initialTags: string[];
}

export interface DataModuleWriteResp {
  /** We return the created module with an initial version set */
  module: DataModule | undefined;
}

/** requires(EDIT_EXPRESSION) */
export interface DataModuleAddVersionReq {
  moduleId: string;
  versionUpdate: VersionField;
  sourceCode: string;
  comments: string;
  tags: string[];
}

export interface DataModuleAddVersionResp {
  /** We return the module with the created version set in it */
  module: DataModule | undefined;
}

function createBaseDataModuleListReq(): DataModuleListReq {
  return {};
}

export const DataModuleListReq = {
  encode(_: DataModuleListReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DataModuleListReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDataModuleListReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): DataModuleListReq {
    return {};
  },

  toJSON(_: DataModuleListReq): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<DataModuleListReq>, I>>(base?: I): DataModuleListReq {
    return DataModuleListReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DataModuleListReq>, I>>(_: I): DataModuleListReq {
    const message = createBaseDataModuleListReq();
    return message;
  },
};

function createBaseDataModuleListResp(): DataModuleListResp {
  return { modules: {} };
}

export const DataModuleListResp = {
  encode(message: DataModuleListResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.modules).forEach(([key, value]) => {
      DataModuleListResp_ModulesEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DataModuleListResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDataModuleListResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = DataModuleListResp_ModulesEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.modules[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DataModuleListResp {
    return {
      modules: isObject(object.modules)
        ? Object.entries(object.modules).reduce<{ [key: string]: DataModule }>((acc, [key, value]) => {
          acc[key] = DataModule.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: DataModuleListResp): unknown {
    const obj: any = {};
    obj.modules = {};
    if (message.modules) {
      Object.entries(message.modules).forEach(([k, v]) => {
        obj.modules[k] = DataModule.toJSON(v);
      });
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DataModuleListResp>, I>>(base?: I): DataModuleListResp {
    return DataModuleListResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DataModuleListResp>, I>>(object: I): DataModuleListResp {
    const message = createBaseDataModuleListResp();
    message.modules = Object.entries(object.modules ?? {}).reduce<{ [key: string]: DataModule }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = DataModule.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseDataModuleListResp_ModulesEntry(): DataModuleListResp_ModulesEntry {
  return { key: "", value: undefined };
}

export const DataModuleListResp_ModulesEntry = {
  encode(message: DataModuleListResp_ModulesEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      DataModule.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DataModuleListResp_ModulesEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDataModuleListResp_ModulesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = DataModule.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DataModuleListResp_ModulesEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? DataModule.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: DataModuleListResp_ModulesEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value ? DataModule.toJSON(message.value) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DataModuleListResp_ModulesEntry>, I>>(base?: I): DataModuleListResp_ModulesEntry {
    return DataModuleListResp_ModulesEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DataModuleListResp_ModulesEntry>, I>>(
    object: I,
  ): DataModuleListResp_ModulesEntry {
    const message = createBaseDataModuleListResp_ModulesEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? DataModule.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseDataModuleGetReq(): DataModuleGetReq {
  return { id: "", version: undefined };
}

export const DataModuleGetReq = {
  encode(message: DataModuleGetReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.version !== undefined) {
      SemanticVersion.encode(message.version, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DataModuleGetReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDataModuleGetReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.version = SemanticVersion.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DataModuleGetReq {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      version: isSet(object.version) ? SemanticVersion.fromJSON(object.version) : undefined,
    };
  },

  toJSON(message: DataModuleGetReq): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.version !== undefined &&
      (obj.version = message.version ? SemanticVersion.toJSON(message.version) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DataModuleGetReq>, I>>(base?: I): DataModuleGetReq {
    return DataModuleGetReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DataModuleGetReq>, I>>(object: I): DataModuleGetReq {
    const message = createBaseDataModuleGetReq();
    message.id = object.id ?? "";
    message.version = (object.version !== undefined && object.version !== null)
      ? SemanticVersion.fromPartial(object.version)
      : undefined;
    return message;
  },
};

function createBaseDataModuleGetResp(): DataModuleGetResp {
  return { module: undefined };
}

export const DataModuleGetResp = {
  encode(message: DataModuleGetResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.module !== undefined) {
      DataModule.encode(message.module, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DataModuleGetResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDataModuleGetResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.module = DataModule.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DataModuleGetResp {
    return { module: isSet(object.module) ? DataModule.fromJSON(object.module) : undefined };
  },

  toJSON(message: DataModuleGetResp): unknown {
    const obj: any = {};
    message.module !== undefined && (obj.module = message.module ? DataModule.toJSON(message.module) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DataModuleGetResp>, I>>(base?: I): DataModuleGetResp {
    return DataModuleGetResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DataModuleGetResp>, I>>(object: I): DataModuleGetResp {
    const message = createBaseDataModuleGetResp();
    message.module = (object.module !== undefined && object.module !== null)
      ? DataModule.fromPartial(object.module)
      : undefined;
    return message;
  },
};

function createBaseDataModuleWriteReq(): DataModuleWriteReq {
  return { id: "", name: "", comments: "", initialSourceCode: "", initialTags: [] };
}

export const DataModuleWriteReq = {
  encode(message: DataModuleWriteReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.comments !== "") {
      writer.uint32(26).string(message.comments);
    }
    if (message.initialSourceCode !== "") {
      writer.uint32(34).string(message.initialSourceCode);
    }
    for (const v of message.initialTags) {
      writer.uint32(42).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DataModuleWriteReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDataModuleWriteReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.comments = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.initialSourceCode = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.initialTags.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DataModuleWriteReq {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      name: isSet(object.name) ? String(object.name) : "",
      comments: isSet(object.comments) ? String(object.comments) : "",
      initialSourceCode: isSet(object.initialSourceCode) ? String(object.initialSourceCode) : "",
      initialTags: Array.isArray(object?.initialTags) ? object.initialTags.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: DataModuleWriteReq): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.comments !== undefined && (obj.comments = message.comments);
    message.initialSourceCode !== undefined && (obj.initialSourceCode = message.initialSourceCode);
    if (message.initialTags) {
      obj.initialTags = message.initialTags.map((e) => e);
    } else {
      obj.initialTags = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DataModuleWriteReq>, I>>(base?: I): DataModuleWriteReq {
    return DataModuleWriteReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DataModuleWriteReq>, I>>(object: I): DataModuleWriteReq {
    const message = createBaseDataModuleWriteReq();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.comments = object.comments ?? "";
    message.initialSourceCode = object.initialSourceCode ?? "";
    message.initialTags = object.initialTags?.map((e) => e) || [];
    return message;
  },
};

function createBaseDataModuleWriteResp(): DataModuleWriteResp {
  return { module: undefined };
}

export const DataModuleWriteResp = {
  encode(message: DataModuleWriteResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.module !== undefined) {
      DataModule.encode(message.module, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DataModuleWriteResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDataModuleWriteResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.module = DataModule.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DataModuleWriteResp {
    return { module: isSet(object.module) ? DataModule.fromJSON(object.module) : undefined };
  },

  toJSON(message: DataModuleWriteResp): unknown {
    const obj: any = {};
    message.module !== undefined && (obj.module = message.module ? DataModule.toJSON(message.module) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DataModuleWriteResp>, I>>(base?: I): DataModuleWriteResp {
    return DataModuleWriteResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DataModuleWriteResp>, I>>(object: I): DataModuleWriteResp {
    const message = createBaseDataModuleWriteResp();
    message.module = (object.module !== undefined && object.module !== null)
      ? DataModule.fromPartial(object.module)
      : undefined;
    return message;
  },
};

function createBaseDataModuleAddVersionReq(): DataModuleAddVersionReq {
  return { moduleId: "", versionUpdate: 0, sourceCode: "", comments: "", tags: [] };
}

export const DataModuleAddVersionReq = {
  encode(message: DataModuleAddVersionReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.moduleId !== "") {
      writer.uint32(10).string(message.moduleId);
    }
    if (message.versionUpdate !== 0) {
      writer.uint32(16).int32(message.versionUpdate);
    }
    if (message.sourceCode !== "") {
      writer.uint32(26).string(message.sourceCode);
    }
    if (message.comments !== "") {
      writer.uint32(34).string(message.comments);
    }
    for (const v of message.tags) {
      writer.uint32(42).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DataModuleAddVersionReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDataModuleAddVersionReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.moduleId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.versionUpdate = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.sourceCode = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.comments = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.tags.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DataModuleAddVersionReq {
    return {
      moduleId: isSet(object.moduleId) ? String(object.moduleId) : "",
      versionUpdate: isSet(object.versionUpdate) ? versionFieldFromJSON(object.versionUpdate) : 0,
      sourceCode: isSet(object.sourceCode) ? String(object.sourceCode) : "",
      comments: isSet(object.comments) ? String(object.comments) : "",
      tags: Array.isArray(object?.tags) ? object.tags.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: DataModuleAddVersionReq): unknown {
    const obj: any = {};
    message.moduleId !== undefined && (obj.moduleId = message.moduleId);
    message.versionUpdate !== undefined && (obj.versionUpdate = versionFieldToJSON(message.versionUpdate));
    message.sourceCode !== undefined && (obj.sourceCode = message.sourceCode);
    message.comments !== undefined && (obj.comments = message.comments);
    if (message.tags) {
      obj.tags = message.tags.map((e) => e);
    } else {
      obj.tags = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DataModuleAddVersionReq>, I>>(base?: I): DataModuleAddVersionReq {
    return DataModuleAddVersionReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DataModuleAddVersionReq>, I>>(object: I): DataModuleAddVersionReq {
    const message = createBaseDataModuleAddVersionReq();
    message.moduleId = object.moduleId ?? "";
    message.versionUpdate = object.versionUpdate ?? 0;
    message.sourceCode = object.sourceCode ?? "";
    message.comments = object.comments ?? "";
    message.tags = object.tags?.map((e) => e) || [];
    return message;
  },
};

function createBaseDataModuleAddVersionResp(): DataModuleAddVersionResp {
  return { module: undefined };
}

export const DataModuleAddVersionResp = {
  encode(message: DataModuleAddVersionResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.module !== undefined) {
      DataModule.encode(message.module, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DataModuleAddVersionResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDataModuleAddVersionResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.module = DataModule.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DataModuleAddVersionResp {
    return { module: isSet(object.module) ? DataModule.fromJSON(object.module) : undefined };
  },

  toJSON(message: DataModuleAddVersionResp): unknown {
    const obj: any = {};
    message.module !== undefined && (obj.module = message.module ? DataModule.toJSON(message.module) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DataModuleAddVersionResp>, I>>(base?: I): DataModuleAddVersionResp {
    return DataModuleAddVersionResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DataModuleAddVersionResp>, I>>(object: I): DataModuleAddVersionResp {
    const message = createBaseDataModuleAddVersionResp();
    message.module = (object.module !== undefined && object.module !== null)
      ? DataModule.fromPartial(object.module)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
