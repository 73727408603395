<div class="filters-row">
  <div class="broad-filters">
    <filter-box placeholder="Search" (onFilterChanged)="onFilterText($event)"> </filter-box>
  </div>
  <div class="option-filters">
    <two-state-push-button
      class="editable"
      [buttonStyle]="'control-button'"
      [active]="onlyShowEditable"
      (onToggle)="onToggleOnlyShowEditable()"
      title="Only show expressions that can be edited"
      >Can Edit?</two-state-push-button
    >
    <mat-select #tooltip="matTooltip" [matTooltip]="authorsTooltip" multiple [(ngModel)]="filteredAuthors" class="authors-filter" placeholder="Author">
      <mat-option *ngFor="let author of authors" [value]="author.id">{{ author.name }} </mat-option>
    </mat-select>
    <tag-picker
      type="expression"
      buttonStyle="button"
      [selectedTagIDs]="filteredTagIDs"
      [showCurrentTagsSection]="false"
      [allowAdminBuiltin]="true"
      (onTagSelectionChanged)="onTagFilterChanged($event)">
    </tag-picker>
  </div>
</div>
