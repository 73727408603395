/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { Auth0UserDetails, Auth0UserRole, UserInfo } from "./user";

export const protobufPackage = "";

/** requires(USER_ADMIN) */
export interface UserListReq {
  /** Get all users who have a role */
  roleId?:
    | string
    | undefined;
  /** Get a specific user by ID */
  userId?:
    | string
    | undefined;
  /** Get all users who have searchText in their name/surname */
  searchText?: string | undefined;
}

export interface UserListResp {
  details: Auth0UserDetails[];
}

/**
 * //////////////////////////////////
 * Get all user roles
 * requires(USER_ADMIN)
 */
export interface UserRoleListReq {
}

export interface UserRoleListResp {
  roles: Auth0UserRole[];
}

/**
 * //////////////////////////////////
 * Get roles for a given user
 * requires(USER_ADMIN)
 */
export interface UserRolesListReq {
  userId: string;
}

export interface UserRolesListResp {
  roles: Auth0UserRole[];
}

/**
 * //////////////////////////////////
 * Add a user role to a user
 * requires(USER_ADMIN)
 */
export interface UserAddRoleReq {
  userId: string;
  roleId: string;
}

/** Changing user roles, this should publish a UserDetailsUpd */
export interface UserAddRoleResp {
}

/**
 * //////////////////////////////////
 * Delete a user role for a user
 * requires(USER_ADMIN)
 */
export interface UserDeleteRoleReq {
  userId: string;
  roleId: string;
}

/** Changing user roles, this should publish a UserDetailsUpd */
export interface UserDeleteRoleResp {
}

/** requires(PIXLISE_ADMIN) */
export interface UserImpersonateReq {
  /** "Become" the user with specified id. If this is blank, this session stops impersonating */
  userId: string;
}

export interface UserImpersonateResp {
  /** Returns the currently set user in this user session */
  sessionUser: UserInfo | undefined;
}

/**
 * Allows querying if we're impersonating anyone
 * requires(NONE)
 */
export interface UserImpersonateGetReq {
}

export interface UserImpersonateGetResp {
  /** Returns the currently set user in this user session */
  sessionUser: UserInfo | undefined;
}

function createBaseUserListReq(): UserListReq {
  return { roleId: undefined, userId: undefined, searchText: undefined };
}

export const UserListReq = {
  encode(message: UserListReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.roleId !== undefined) {
      writer.uint32(10).string(message.roleId);
    }
    if (message.userId !== undefined) {
      writer.uint32(18).string(message.userId);
    }
    if (message.searchText !== undefined) {
      writer.uint32(26).string(message.searchText);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserListReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserListReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.roleId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.searchText = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserListReq {
    return {
      roleId: isSet(object.roleId) ? String(object.roleId) : undefined,
      userId: isSet(object.userId) ? String(object.userId) : undefined,
      searchText: isSet(object.searchText) ? String(object.searchText) : undefined,
    };
  },

  toJSON(message: UserListReq): unknown {
    const obj: any = {};
    message.roleId !== undefined && (obj.roleId = message.roleId);
    message.userId !== undefined && (obj.userId = message.userId);
    message.searchText !== undefined && (obj.searchText = message.searchText);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserListReq>, I>>(base?: I): UserListReq {
    return UserListReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserListReq>, I>>(object: I): UserListReq {
    const message = createBaseUserListReq();
    message.roleId = object.roleId ?? undefined;
    message.userId = object.userId ?? undefined;
    message.searchText = object.searchText ?? undefined;
    return message;
  },
};

function createBaseUserListResp(): UserListResp {
  return { details: [] };
}

export const UserListResp = {
  encode(message: UserListResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.details) {
      Auth0UserDetails.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserListResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserListResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.details.push(Auth0UserDetails.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserListResp {
    return {
      details: Array.isArray(object?.details) ? object.details.map((e: any) => Auth0UserDetails.fromJSON(e)) : [],
    };
  },

  toJSON(message: UserListResp): unknown {
    const obj: any = {};
    if (message.details) {
      obj.details = message.details.map((e) => e ? Auth0UserDetails.toJSON(e) : undefined);
    } else {
      obj.details = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserListResp>, I>>(base?: I): UserListResp {
    return UserListResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserListResp>, I>>(object: I): UserListResp {
    const message = createBaseUserListResp();
    message.details = object.details?.map((e) => Auth0UserDetails.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUserRoleListReq(): UserRoleListReq {
  return {};
}

export const UserRoleListReq = {
  encode(_: UserRoleListReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserRoleListReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserRoleListReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UserRoleListReq {
    return {};
  },

  toJSON(_: UserRoleListReq): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<UserRoleListReq>, I>>(base?: I): UserRoleListReq {
    return UserRoleListReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserRoleListReq>, I>>(_: I): UserRoleListReq {
    const message = createBaseUserRoleListReq();
    return message;
  },
};

function createBaseUserRoleListResp(): UserRoleListResp {
  return { roles: [] };
}

export const UserRoleListResp = {
  encode(message: UserRoleListResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.roles) {
      Auth0UserRole.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserRoleListResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserRoleListResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.roles.push(Auth0UserRole.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserRoleListResp {
    return { roles: Array.isArray(object?.roles) ? object.roles.map((e: any) => Auth0UserRole.fromJSON(e)) : [] };
  },

  toJSON(message: UserRoleListResp): unknown {
    const obj: any = {};
    if (message.roles) {
      obj.roles = message.roles.map((e) => e ? Auth0UserRole.toJSON(e) : undefined);
    } else {
      obj.roles = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserRoleListResp>, I>>(base?: I): UserRoleListResp {
    return UserRoleListResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserRoleListResp>, I>>(object: I): UserRoleListResp {
    const message = createBaseUserRoleListResp();
    message.roles = object.roles?.map((e) => Auth0UserRole.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUserRolesListReq(): UserRolesListReq {
  return { userId: "" };
}

export const UserRolesListReq = {
  encode(message: UserRolesListReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== "") {
      writer.uint32(10).string(message.userId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserRolesListReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserRolesListReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserRolesListReq {
    return { userId: isSet(object.userId) ? String(object.userId) : "" };
  },

  toJSON(message: UserRolesListReq): unknown {
    const obj: any = {};
    message.userId !== undefined && (obj.userId = message.userId);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserRolesListReq>, I>>(base?: I): UserRolesListReq {
    return UserRolesListReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserRolesListReq>, I>>(object: I): UserRolesListReq {
    const message = createBaseUserRolesListReq();
    message.userId = object.userId ?? "";
    return message;
  },
};

function createBaseUserRolesListResp(): UserRolesListResp {
  return { roles: [] };
}

export const UserRolesListResp = {
  encode(message: UserRolesListResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.roles) {
      Auth0UserRole.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserRolesListResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserRolesListResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.roles.push(Auth0UserRole.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserRolesListResp {
    return { roles: Array.isArray(object?.roles) ? object.roles.map((e: any) => Auth0UserRole.fromJSON(e)) : [] };
  },

  toJSON(message: UserRolesListResp): unknown {
    const obj: any = {};
    if (message.roles) {
      obj.roles = message.roles.map((e) => e ? Auth0UserRole.toJSON(e) : undefined);
    } else {
      obj.roles = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserRolesListResp>, I>>(base?: I): UserRolesListResp {
    return UserRolesListResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserRolesListResp>, I>>(object: I): UserRolesListResp {
    const message = createBaseUserRolesListResp();
    message.roles = object.roles?.map((e) => Auth0UserRole.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUserAddRoleReq(): UserAddRoleReq {
  return { userId: "", roleId: "" };
}

export const UserAddRoleReq = {
  encode(message: UserAddRoleReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== "") {
      writer.uint32(10).string(message.userId);
    }
    if (message.roleId !== "") {
      writer.uint32(18).string(message.roleId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserAddRoleReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserAddRoleReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.roleId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserAddRoleReq {
    return {
      userId: isSet(object.userId) ? String(object.userId) : "",
      roleId: isSet(object.roleId) ? String(object.roleId) : "",
    };
  },

  toJSON(message: UserAddRoleReq): unknown {
    const obj: any = {};
    message.userId !== undefined && (obj.userId = message.userId);
    message.roleId !== undefined && (obj.roleId = message.roleId);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserAddRoleReq>, I>>(base?: I): UserAddRoleReq {
    return UserAddRoleReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserAddRoleReq>, I>>(object: I): UserAddRoleReq {
    const message = createBaseUserAddRoleReq();
    message.userId = object.userId ?? "";
    message.roleId = object.roleId ?? "";
    return message;
  },
};

function createBaseUserAddRoleResp(): UserAddRoleResp {
  return {};
}

export const UserAddRoleResp = {
  encode(_: UserAddRoleResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserAddRoleResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserAddRoleResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UserAddRoleResp {
    return {};
  },

  toJSON(_: UserAddRoleResp): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<UserAddRoleResp>, I>>(base?: I): UserAddRoleResp {
    return UserAddRoleResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserAddRoleResp>, I>>(_: I): UserAddRoleResp {
    const message = createBaseUserAddRoleResp();
    return message;
  },
};

function createBaseUserDeleteRoleReq(): UserDeleteRoleReq {
  return { userId: "", roleId: "" };
}

export const UserDeleteRoleReq = {
  encode(message: UserDeleteRoleReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== "") {
      writer.uint32(10).string(message.userId);
    }
    if (message.roleId !== "") {
      writer.uint32(18).string(message.roleId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserDeleteRoleReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserDeleteRoleReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.roleId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserDeleteRoleReq {
    return {
      userId: isSet(object.userId) ? String(object.userId) : "",
      roleId: isSet(object.roleId) ? String(object.roleId) : "",
    };
  },

  toJSON(message: UserDeleteRoleReq): unknown {
    const obj: any = {};
    message.userId !== undefined && (obj.userId = message.userId);
    message.roleId !== undefined && (obj.roleId = message.roleId);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserDeleteRoleReq>, I>>(base?: I): UserDeleteRoleReq {
    return UserDeleteRoleReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserDeleteRoleReq>, I>>(object: I): UserDeleteRoleReq {
    const message = createBaseUserDeleteRoleReq();
    message.userId = object.userId ?? "";
    message.roleId = object.roleId ?? "";
    return message;
  },
};

function createBaseUserDeleteRoleResp(): UserDeleteRoleResp {
  return {};
}

export const UserDeleteRoleResp = {
  encode(_: UserDeleteRoleResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserDeleteRoleResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserDeleteRoleResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UserDeleteRoleResp {
    return {};
  },

  toJSON(_: UserDeleteRoleResp): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<UserDeleteRoleResp>, I>>(base?: I): UserDeleteRoleResp {
    return UserDeleteRoleResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserDeleteRoleResp>, I>>(_: I): UserDeleteRoleResp {
    const message = createBaseUserDeleteRoleResp();
    return message;
  },
};

function createBaseUserImpersonateReq(): UserImpersonateReq {
  return { userId: "" };
}

export const UserImpersonateReq = {
  encode(message: UserImpersonateReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== "") {
      writer.uint32(10).string(message.userId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserImpersonateReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserImpersonateReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserImpersonateReq {
    return { userId: isSet(object.userId) ? String(object.userId) : "" };
  },

  toJSON(message: UserImpersonateReq): unknown {
    const obj: any = {};
    message.userId !== undefined && (obj.userId = message.userId);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserImpersonateReq>, I>>(base?: I): UserImpersonateReq {
    return UserImpersonateReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserImpersonateReq>, I>>(object: I): UserImpersonateReq {
    const message = createBaseUserImpersonateReq();
    message.userId = object.userId ?? "";
    return message;
  },
};

function createBaseUserImpersonateResp(): UserImpersonateResp {
  return { sessionUser: undefined };
}

export const UserImpersonateResp = {
  encode(message: UserImpersonateResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sessionUser !== undefined) {
      UserInfo.encode(message.sessionUser, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserImpersonateResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserImpersonateResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.sessionUser = UserInfo.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserImpersonateResp {
    return { sessionUser: isSet(object.sessionUser) ? UserInfo.fromJSON(object.sessionUser) : undefined };
  },

  toJSON(message: UserImpersonateResp): unknown {
    const obj: any = {};
    message.sessionUser !== undefined &&
      (obj.sessionUser = message.sessionUser ? UserInfo.toJSON(message.sessionUser) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserImpersonateResp>, I>>(base?: I): UserImpersonateResp {
    return UserImpersonateResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserImpersonateResp>, I>>(object: I): UserImpersonateResp {
    const message = createBaseUserImpersonateResp();
    message.sessionUser = (object.sessionUser !== undefined && object.sessionUser !== null)
      ? UserInfo.fromPartial(object.sessionUser)
      : undefined;
    return message;
  },
};

function createBaseUserImpersonateGetReq(): UserImpersonateGetReq {
  return {};
}

export const UserImpersonateGetReq = {
  encode(_: UserImpersonateGetReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserImpersonateGetReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserImpersonateGetReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UserImpersonateGetReq {
    return {};
  },

  toJSON(_: UserImpersonateGetReq): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<UserImpersonateGetReq>, I>>(base?: I): UserImpersonateGetReq {
    return UserImpersonateGetReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserImpersonateGetReq>, I>>(_: I): UserImpersonateGetReq {
    const message = createBaseUserImpersonateGetReq();
    return message;
  },
};

function createBaseUserImpersonateGetResp(): UserImpersonateGetResp {
  return { sessionUser: undefined };
}

export const UserImpersonateGetResp = {
  encode(message: UserImpersonateGetResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sessionUser !== undefined) {
      UserInfo.encode(message.sessionUser, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserImpersonateGetResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserImpersonateGetResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.sessionUser = UserInfo.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserImpersonateGetResp {
    return { sessionUser: isSet(object.sessionUser) ? UserInfo.fromJSON(object.sessionUser) : undefined };
  },

  toJSON(message: UserImpersonateGetResp): unknown {
    const obj: any = {};
    message.sessionUser !== undefined &&
      (obj.sessionUser = message.sessionUser ? UserInfo.toJSON(message.sessionUser) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserImpersonateGetResp>, I>>(base?: I): UserImpersonateGetResp {
    return UserImpersonateGetResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserImpersonateGetResp>, I>>(object: I): UserImpersonateGetResp {
    const message = createBaseUserImpersonateGetResp();
    message.sessionUser = (object.sessionUser !== undefined && object.sessionUser !== null)
      ? UserInfo.fromPartial(object.sessionUser)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
