/* eslint-disable */
import * as _m0 from "protobufjs/minimal";

export const protobufPackage = "";

export interface SearchParams {
  nameSearch: string;
  tagId: string;
  creatorUserId: string;
  scanId: string;
  firstResultIdx: string;
  resultCount: string;
}

function createBaseSearchParams(): SearchParams {
  return { nameSearch: "", tagId: "", creatorUserId: "", scanId: "", firstResultIdx: "", resultCount: "" };
}

export const SearchParams = {
  encode(message: SearchParams, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.nameSearch !== "") {
      writer.uint32(10).string(message.nameSearch);
    }
    if (message.tagId !== "") {
      writer.uint32(18).string(message.tagId);
    }
    if (message.creatorUserId !== "") {
      writer.uint32(26).string(message.creatorUserId);
    }
    if (message.scanId !== "") {
      writer.uint32(34).string(message.scanId);
    }
    if (message.firstResultIdx !== "") {
      writer.uint32(42).string(message.firstResultIdx);
    }
    if (message.resultCount !== "") {
      writer.uint32(50).string(message.resultCount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchParams {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchParams();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.nameSearch = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.tagId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.creatorUserId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.scanId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.firstResultIdx = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.resultCount = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SearchParams {
    return {
      nameSearch: isSet(object.nameSearch) ? String(object.nameSearch) : "",
      tagId: isSet(object.tagId) ? String(object.tagId) : "",
      creatorUserId: isSet(object.creatorUserId) ? String(object.creatorUserId) : "",
      scanId: isSet(object.scanId) ? String(object.scanId) : "",
      firstResultIdx: isSet(object.firstResultIdx) ? String(object.firstResultIdx) : "",
      resultCount: isSet(object.resultCount) ? String(object.resultCount) : "",
    };
  },

  toJSON(message: SearchParams): unknown {
    const obj: any = {};
    message.nameSearch !== undefined && (obj.nameSearch = message.nameSearch);
    message.tagId !== undefined && (obj.tagId = message.tagId);
    message.creatorUserId !== undefined && (obj.creatorUserId = message.creatorUserId);
    message.scanId !== undefined && (obj.scanId = message.scanId);
    message.firstResultIdx !== undefined && (obj.firstResultIdx = message.firstResultIdx);
    message.resultCount !== undefined && (obj.resultCount = message.resultCount);
    return obj;
  },

  create<I extends Exact<DeepPartial<SearchParams>, I>>(base?: I): SearchParams {
    return SearchParams.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SearchParams>, I>>(object: I): SearchParams {
    const message = createBaseSearchParams();
    message.nameSearch = object.nameSearch ?? "";
    message.tagId = object.tagId ?? "";
    message.creatorUserId = object.creatorUserId ?? "";
    message.scanId = object.scanId ?? "";
    message.firstResultIdx = object.firstResultIdx ?? "";
    message.resultCount = object.resultCount ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
