/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { UserGroup } from "./user-group";

export const protobufPackage = "";

/**
 * //////////////////////////////////////////////////////////////
 * Adding and deleting members from the group
 * Should only be accessible to group admins and sys admins
 * requires(NONE)
 */
export interface UserGroupAddMemberReq {
  groupId: string;
  groupMemberId?: string | undefined;
  userMemberId?: string | undefined;
}

export interface UserGroupAddMemberResp {
  group: UserGroup | undefined;
}

/** requires(NONE) */
export interface UserGroupDeleteMemberReq {
  groupId: string;
  groupMemberId?: string | undefined;
  userMemberId?: string | undefined;
}

export interface UserGroupDeleteMemberResp {
  group: UserGroup | undefined;
}

/**
 * //////////////////////////////////////////////////////////////
 * Adding and deleting viewers from the group
 * Should only be accessible to group admins and sys admins
 * requires(NONE)
 */
export interface UserGroupAddViewerReq {
  groupId: string;
  groupViewerId?: string | undefined;
  userViewerId?: string | undefined;
}

export interface UserGroupAddViewerResp {
  group: UserGroup | undefined;
}

/** requires(NONE) */
export interface UserGroupDeleteViewerReq {
  groupId: string;
  groupViewerId?: string | undefined;
  userViewerId?: string | undefined;
}

export interface UserGroupDeleteViewerResp {
  group: UserGroup | undefined;
}

function createBaseUserGroupAddMemberReq(): UserGroupAddMemberReq {
  return { groupId: "", groupMemberId: undefined, userMemberId: undefined };
}

export const UserGroupAddMemberReq = {
  encode(message: UserGroupAddMemberReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.groupId !== "") {
      writer.uint32(10).string(message.groupId);
    }
    if (message.groupMemberId !== undefined) {
      writer.uint32(18).string(message.groupMemberId);
    }
    if (message.userMemberId !== undefined) {
      writer.uint32(26).string(message.userMemberId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserGroupAddMemberReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserGroupAddMemberReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.groupId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.groupMemberId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.userMemberId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserGroupAddMemberReq {
    return {
      groupId: isSet(object.groupId) ? String(object.groupId) : "",
      groupMemberId: isSet(object.groupMemberId) ? String(object.groupMemberId) : undefined,
      userMemberId: isSet(object.userMemberId) ? String(object.userMemberId) : undefined,
    };
  },

  toJSON(message: UserGroupAddMemberReq): unknown {
    const obj: any = {};
    message.groupId !== undefined && (obj.groupId = message.groupId);
    message.groupMemberId !== undefined && (obj.groupMemberId = message.groupMemberId);
    message.userMemberId !== undefined && (obj.userMemberId = message.userMemberId);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserGroupAddMemberReq>, I>>(base?: I): UserGroupAddMemberReq {
    return UserGroupAddMemberReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserGroupAddMemberReq>, I>>(object: I): UserGroupAddMemberReq {
    const message = createBaseUserGroupAddMemberReq();
    message.groupId = object.groupId ?? "";
    message.groupMemberId = object.groupMemberId ?? undefined;
    message.userMemberId = object.userMemberId ?? undefined;
    return message;
  },
};

function createBaseUserGroupAddMemberResp(): UserGroupAddMemberResp {
  return { group: undefined };
}

export const UserGroupAddMemberResp = {
  encode(message: UserGroupAddMemberResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.group !== undefined) {
      UserGroup.encode(message.group, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserGroupAddMemberResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserGroupAddMemberResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.group = UserGroup.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserGroupAddMemberResp {
    return { group: isSet(object.group) ? UserGroup.fromJSON(object.group) : undefined };
  },

  toJSON(message: UserGroupAddMemberResp): unknown {
    const obj: any = {};
    message.group !== undefined && (obj.group = message.group ? UserGroup.toJSON(message.group) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserGroupAddMemberResp>, I>>(base?: I): UserGroupAddMemberResp {
    return UserGroupAddMemberResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserGroupAddMemberResp>, I>>(object: I): UserGroupAddMemberResp {
    const message = createBaseUserGroupAddMemberResp();
    message.group = (object.group !== undefined && object.group !== null)
      ? UserGroup.fromPartial(object.group)
      : undefined;
    return message;
  },
};

function createBaseUserGroupDeleteMemberReq(): UserGroupDeleteMemberReq {
  return { groupId: "", groupMemberId: undefined, userMemberId: undefined };
}

export const UserGroupDeleteMemberReq = {
  encode(message: UserGroupDeleteMemberReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.groupId !== "") {
      writer.uint32(10).string(message.groupId);
    }
    if (message.groupMemberId !== undefined) {
      writer.uint32(18).string(message.groupMemberId);
    }
    if (message.userMemberId !== undefined) {
      writer.uint32(26).string(message.userMemberId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserGroupDeleteMemberReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserGroupDeleteMemberReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.groupId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.groupMemberId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.userMemberId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserGroupDeleteMemberReq {
    return {
      groupId: isSet(object.groupId) ? String(object.groupId) : "",
      groupMemberId: isSet(object.groupMemberId) ? String(object.groupMemberId) : undefined,
      userMemberId: isSet(object.userMemberId) ? String(object.userMemberId) : undefined,
    };
  },

  toJSON(message: UserGroupDeleteMemberReq): unknown {
    const obj: any = {};
    message.groupId !== undefined && (obj.groupId = message.groupId);
    message.groupMemberId !== undefined && (obj.groupMemberId = message.groupMemberId);
    message.userMemberId !== undefined && (obj.userMemberId = message.userMemberId);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserGroupDeleteMemberReq>, I>>(base?: I): UserGroupDeleteMemberReq {
    return UserGroupDeleteMemberReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserGroupDeleteMemberReq>, I>>(object: I): UserGroupDeleteMemberReq {
    const message = createBaseUserGroupDeleteMemberReq();
    message.groupId = object.groupId ?? "";
    message.groupMemberId = object.groupMemberId ?? undefined;
    message.userMemberId = object.userMemberId ?? undefined;
    return message;
  },
};

function createBaseUserGroupDeleteMemberResp(): UserGroupDeleteMemberResp {
  return { group: undefined };
}

export const UserGroupDeleteMemberResp = {
  encode(message: UserGroupDeleteMemberResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.group !== undefined) {
      UserGroup.encode(message.group, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserGroupDeleteMemberResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserGroupDeleteMemberResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.group = UserGroup.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserGroupDeleteMemberResp {
    return { group: isSet(object.group) ? UserGroup.fromJSON(object.group) : undefined };
  },

  toJSON(message: UserGroupDeleteMemberResp): unknown {
    const obj: any = {};
    message.group !== undefined && (obj.group = message.group ? UserGroup.toJSON(message.group) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserGroupDeleteMemberResp>, I>>(base?: I): UserGroupDeleteMemberResp {
    return UserGroupDeleteMemberResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserGroupDeleteMemberResp>, I>>(object: I): UserGroupDeleteMemberResp {
    const message = createBaseUserGroupDeleteMemberResp();
    message.group = (object.group !== undefined && object.group !== null)
      ? UserGroup.fromPartial(object.group)
      : undefined;
    return message;
  },
};

function createBaseUserGroupAddViewerReq(): UserGroupAddViewerReq {
  return { groupId: "", groupViewerId: undefined, userViewerId: undefined };
}

export const UserGroupAddViewerReq = {
  encode(message: UserGroupAddViewerReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.groupId !== "") {
      writer.uint32(10).string(message.groupId);
    }
    if (message.groupViewerId !== undefined) {
      writer.uint32(18).string(message.groupViewerId);
    }
    if (message.userViewerId !== undefined) {
      writer.uint32(26).string(message.userViewerId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserGroupAddViewerReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserGroupAddViewerReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.groupId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.groupViewerId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.userViewerId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserGroupAddViewerReq {
    return {
      groupId: isSet(object.groupId) ? String(object.groupId) : "",
      groupViewerId: isSet(object.groupViewerId) ? String(object.groupViewerId) : undefined,
      userViewerId: isSet(object.userViewerId) ? String(object.userViewerId) : undefined,
    };
  },

  toJSON(message: UserGroupAddViewerReq): unknown {
    const obj: any = {};
    message.groupId !== undefined && (obj.groupId = message.groupId);
    message.groupViewerId !== undefined && (obj.groupViewerId = message.groupViewerId);
    message.userViewerId !== undefined && (obj.userViewerId = message.userViewerId);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserGroupAddViewerReq>, I>>(base?: I): UserGroupAddViewerReq {
    return UserGroupAddViewerReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserGroupAddViewerReq>, I>>(object: I): UserGroupAddViewerReq {
    const message = createBaseUserGroupAddViewerReq();
    message.groupId = object.groupId ?? "";
    message.groupViewerId = object.groupViewerId ?? undefined;
    message.userViewerId = object.userViewerId ?? undefined;
    return message;
  },
};

function createBaseUserGroupAddViewerResp(): UserGroupAddViewerResp {
  return { group: undefined };
}

export const UserGroupAddViewerResp = {
  encode(message: UserGroupAddViewerResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.group !== undefined) {
      UserGroup.encode(message.group, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserGroupAddViewerResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserGroupAddViewerResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.group = UserGroup.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserGroupAddViewerResp {
    return { group: isSet(object.group) ? UserGroup.fromJSON(object.group) : undefined };
  },

  toJSON(message: UserGroupAddViewerResp): unknown {
    const obj: any = {};
    message.group !== undefined && (obj.group = message.group ? UserGroup.toJSON(message.group) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserGroupAddViewerResp>, I>>(base?: I): UserGroupAddViewerResp {
    return UserGroupAddViewerResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserGroupAddViewerResp>, I>>(object: I): UserGroupAddViewerResp {
    const message = createBaseUserGroupAddViewerResp();
    message.group = (object.group !== undefined && object.group !== null)
      ? UserGroup.fromPartial(object.group)
      : undefined;
    return message;
  },
};

function createBaseUserGroupDeleteViewerReq(): UserGroupDeleteViewerReq {
  return { groupId: "", groupViewerId: undefined, userViewerId: undefined };
}

export const UserGroupDeleteViewerReq = {
  encode(message: UserGroupDeleteViewerReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.groupId !== "") {
      writer.uint32(10).string(message.groupId);
    }
    if (message.groupViewerId !== undefined) {
      writer.uint32(18).string(message.groupViewerId);
    }
    if (message.userViewerId !== undefined) {
      writer.uint32(26).string(message.userViewerId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserGroupDeleteViewerReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserGroupDeleteViewerReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.groupId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.groupViewerId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.userViewerId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserGroupDeleteViewerReq {
    return {
      groupId: isSet(object.groupId) ? String(object.groupId) : "",
      groupViewerId: isSet(object.groupViewerId) ? String(object.groupViewerId) : undefined,
      userViewerId: isSet(object.userViewerId) ? String(object.userViewerId) : undefined,
    };
  },

  toJSON(message: UserGroupDeleteViewerReq): unknown {
    const obj: any = {};
    message.groupId !== undefined && (obj.groupId = message.groupId);
    message.groupViewerId !== undefined && (obj.groupViewerId = message.groupViewerId);
    message.userViewerId !== undefined && (obj.userViewerId = message.userViewerId);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserGroupDeleteViewerReq>, I>>(base?: I): UserGroupDeleteViewerReq {
    return UserGroupDeleteViewerReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserGroupDeleteViewerReq>, I>>(object: I): UserGroupDeleteViewerReq {
    const message = createBaseUserGroupDeleteViewerReq();
    message.groupId = object.groupId ?? "";
    message.groupViewerId = object.groupViewerId ?? undefined;
    message.userViewerId = object.userViewerId ?? undefined;
    return message;
  },
};

function createBaseUserGroupDeleteViewerResp(): UserGroupDeleteViewerResp {
  return { group: undefined };
}

export const UserGroupDeleteViewerResp = {
  encode(message: UserGroupDeleteViewerResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.group !== undefined) {
      UserGroup.encode(message.group, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserGroupDeleteViewerResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserGroupDeleteViewerResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.group = UserGroup.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserGroupDeleteViewerResp {
    return { group: isSet(object.group) ? UserGroup.fromJSON(object.group) : undefined };
  },

  toJSON(message: UserGroupDeleteViewerResp): unknown {
    const obj: any = {};
    message.group !== undefined && (obj.group = message.group ? UserGroup.toJSON(message.group) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserGroupDeleteViewerResp>, I>>(base?: I): UserGroupDeleteViewerResp {
    return UserGroupDeleteViewerResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserGroupDeleteViewerResp>, I>>(object: I): UserGroupDeleteViewerResp {
    const message = createBaseUserGroupDeleteViewerResp();
    message.group = (object.group !== undefined && object.group !== null)
      ? UserGroup.fromPartial(object.group)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
