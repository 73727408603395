/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { OwnershipSummary } from "./ownership-access";
import { SemanticVersion } from "./version";

export const protobufPackage = "";

/** Data Modules are "shared" by default */
export interface DataModuleDB {
  /** @gotags: bson:"_id,omitempty" */
  id: string;
  name: string;
  comments: string;
  modifiedUnixSec: number;
  /** Only sent out by API, not stored in DB this way */
  owner: OwnershipSummary | undefined;
}

export interface DataModuleVersionDB {
  /** @gotags: bson:"_id,omitempty" */
  id: string;
  moduleId: string;
  version: SemanticVersion | undefined;
  tags: string[];
  comments: string;
  timeStampUnixSec: number;
  /** DOIMetadata doiMetadata = 8; */
  sourceCode: string;
}

export interface DataModuleVersion {
  version: SemanticVersion | undefined;
  tags: string[];
  comments: string;
  timeStampUnixSec: number;
  /** Optional - Get Module doesn't return this but querying a specific version does */
  sourceCode: string;
}

/** NOT stored in DB! */
export interface DataModule {
  id: string;
  name: string;
  comments: string;
  modifiedUnixSec: number;
  creator: OwnershipSummary | undefined;
  versions: DataModuleVersion[];
}

function createBaseDataModuleDB(): DataModuleDB {
  return { id: "", name: "", comments: "", modifiedUnixSec: 0, owner: undefined };
}

export const DataModuleDB = {
  encode(message: DataModuleDB, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.comments !== "") {
      writer.uint32(26).string(message.comments);
    }
    if (message.modifiedUnixSec !== 0) {
      writer.uint32(32).uint32(message.modifiedUnixSec);
    }
    if (message.owner !== undefined) {
      OwnershipSummary.encode(message.owner, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DataModuleDB {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDataModuleDB();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.comments = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.modifiedUnixSec = reader.uint32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.owner = OwnershipSummary.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DataModuleDB {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      name: isSet(object.name) ? String(object.name) : "",
      comments: isSet(object.comments) ? String(object.comments) : "",
      modifiedUnixSec: isSet(object.modifiedUnixSec) ? Number(object.modifiedUnixSec) : 0,
      owner: isSet(object.owner) ? OwnershipSummary.fromJSON(object.owner) : undefined,
    };
  },

  toJSON(message: DataModuleDB): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.comments !== undefined && (obj.comments = message.comments);
    message.modifiedUnixSec !== undefined && (obj.modifiedUnixSec = Math.round(message.modifiedUnixSec));
    message.owner !== undefined && (obj.owner = message.owner ? OwnershipSummary.toJSON(message.owner) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DataModuleDB>, I>>(base?: I): DataModuleDB {
    return DataModuleDB.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DataModuleDB>, I>>(object: I): DataModuleDB {
    const message = createBaseDataModuleDB();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.comments = object.comments ?? "";
    message.modifiedUnixSec = object.modifiedUnixSec ?? 0;
    message.owner = (object.owner !== undefined && object.owner !== null)
      ? OwnershipSummary.fromPartial(object.owner)
      : undefined;
    return message;
  },
};

function createBaseDataModuleVersionDB(): DataModuleVersionDB {
  return { id: "", moduleId: "", version: undefined, tags: [], comments: "", timeStampUnixSec: 0, sourceCode: "" };
}

export const DataModuleVersionDB = {
  encode(message: DataModuleVersionDB, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.moduleId !== "") {
      writer.uint32(18).string(message.moduleId);
    }
    if (message.version !== undefined) {
      SemanticVersion.encode(message.version, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.tags) {
      writer.uint32(34).string(v!);
    }
    if (message.comments !== "") {
      writer.uint32(42).string(message.comments);
    }
    if (message.timeStampUnixSec !== 0) {
      writer.uint32(48).uint32(message.timeStampUnixSec);
    }
    if (message.sourceCode !== "") {
      writer.uint32(58).string(message.sourceCode);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DataModuleVersionDB {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDataModuleVersionDB();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.moduleId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.version = SemanticVersion.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.tags.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.comments = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.timeStampUnixSec = reader.uint32();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.sourceCode = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DataModuleVersionDB {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      moduleId: isSet(object.moduleId) ? String(object.moduleId) : "",
      version: isSet(object.version) ? SemanticVersion.fromJSON(object.version) : undefined,
      tags: Array.isArray(object?.tags) ? object.tags.map((e: any) => String(e)) : [],
      comments: isSet(object.comments) ? String(object.comments) : "",
      timeStampUnixSec: isSet(object.timeStampUnixSec) ? Number(object.timeStampUnixSec) : 0,
      sourceCode: isSet(object.sourceCode) ? String(object.sourceCode) : "",
    };
  },

  toJSON(message: DataModuleVersionDB): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.moduleId !== undefined && (obj.moduleId = message.moduleId);
    message.version !== undefined &&
      (obj.version = message.version ? SemanticVersion.toJSON(message.version) : undefined);
    if (message.tags) {
      obj.tags = message.tags.map((e) => e);
    } else {
      obj.tags = [];
    }
    message.comments !== undefined && (obj.comments = message.comments);
    message.timeStampUnixSec !== undefined && (obj.timeStampUnixSec = Math.round(message.timeStampUnixSec));
    message.sourceCode !== undefined && (obj.sourceCode = message.sourceCode);
    return obj;
  },

  create<I extends Exact<DeepPartial<DataModuleVersionDB>, I>>(base?: I): DataModuleVersionDB {
    return DataModuleVersionDB.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DataModuleVersionDB>, I>>(object: I): DataModuleVersionDB {
    const message = createBaseDataModuleVersionDB();
    message.id = object.id ?? "";
    message.moduleId = object.moduleId ?? "";
    message.version = (object.version !== undefined && object.version !== null)
      ? SemanticVersion.fromPartial(object.version)
      : undefined;
    message.tags = object.tags?.map((e) => e) || [];
    message.comments = object.comments ?? "";
    message.timeStampUnixSec = object.timeStampUnixSec ?? 0;
    message.sourceCode = object.sourceCode ?? "";
    return message;
  },
};

function createBaseDataModuleVersion(): DataModuleVersion {
  return { version: undefined, tags: [], comments: "", timeStampUnixSec: 0, sourceCode: "" };
}

export const DataModuleVersion = {
  encode(message: DataModuleVersion, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.version !== undefined) {
      SemanticVersion.encode(message.version, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.tags) {
      writer.uint32(18).string(v!);
    }
    if (message.comments !== "") {
      writer.uint32(26).string(message.comments);
    }
    if (message.timeStampUnixSec !== 0) {
      writer.uint32(32).uint32(message.timeStampUnixSec);
    }
    if (message.sourceCode !== "") {
      writer.uint32(42).string(message.sourceCode);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DataModuleVersion {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDataModuleVersion();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.version = SemanticVersion.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.tags.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.comments = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.timeStampUnixSec = reader.uint32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.sourceCode = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DataModuleVersion {
    return {
      version: isSet(object.version) ? SemanticVersion.fromJSON(object.version) : undefined,
      tags: Array.isArray(object?.tags) ? object.tags.map((e: any) => String(e)) : [],
      comments: isSet(object.comments) ? String(object.comments) : "",
      timeStampUnixSec: isSet(object.timeStampUnixSec) ? Number(object.timeStampUnixSec) : 0,
      sourceCode: isSet(object.sourceCode) ? String(object.sourceCode) : "",
    };
  },

  toJSON(message: DataModuleVersion): unknown {
    const obj: any = {};
    message.version !== undefined &&
      (obj.version = message.version ? SemanticVersion.toJSON(message.version) : undefined);
    if (message.tags) {
      obj.tags = message.tags.map((e) => e);
    } else {
      obj.tags = [];
    }
    message.comments !== undefined && (obj.comments = message.comments);
    message.timeStampUnixSec !== undefined && (obj.timeStampUnixSec = Math.round(message.timeStampUnixSec));
    message.sourceCode !== undefined && (obj.sourceCode = message.sourceCode);
    return obj;
  },

  create<I extends Exact<DeepPartial<DataModuleVersion>, I>>(base?: I): DataModuleVersion {
    return DataModuleVersion.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DataModuleVersion>, I>>(object: I): DataModuleVersion {
    const message = createBaseDataModuleVersion();
    message.version = (object.version !== undefined && object.version !== null)
      ? SemanticVersion.fromPartial(object.version)
      : undefined;
    message.tags = object.tags?.map((e) => e) || [];
    message.comments = object.comments ?? "";
    message.timeStampUnixSec = object.timeStampUnixSec ?? 0;
    message.sourceCode = object.sourceCode ?? "";
    return message;
  },
};

function createBaseDataModule(): DataModule {
  return { id: "", name: "", comments: "", modifiedUnixSec: 0, creator: undefined, versions: [] };
}

export const DataModule = {
  encode(message: DataModule, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.comments !== "") {
      writer.uint32(26).string(message.comments);
    }
    if (message.modifiedUnixSec !== 0) {
      writer.uint32(32).uint32(message.modifiedUnixSec);
    }
    if (message.creator !== undefined) {
      OwnershipSummary.encode(message.creator, writer.uint32(42).fork()).ldelim();
    }
    for (const v of message.versions) {
      DataModuleVersion.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DataModule {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDataModule();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.comments = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.modifiedUnixSec = reader.uint32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.creator = OwnershipSummary.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.versions.push(DataModuleVersion.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DataModule {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      name: isSet(object.name) ? String(object.name) : "",
      comments: isSet(object.comments) ? String(object.comments) : "",
      modifiedUnixSec: isSet(object.modifiedUnixSec) ? Number(object.modifiedUnixSec) : 0,
      creator: isSet(object.creator) ? OwnershipSummary.fromJSON(object.creator) : undefined,
      versions: Array.isArray(object?.versions) ? object.versions.map((e: any) => DataModuleVersion.fromJSON(e)) : [],
    };
  },

  toJSON(message: DataModule): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.comments !== undefined && (obj.comments = message.comments);
    message.modifiedUnixSec !== undefined && (obj.modifiedUnixSec = Math.round(message.modifiedUnixSec));
    message.creator !== undefined &&
      (obj.creator = message.creator ? OwnershipSummary.toJSON(message.creator) : undefined);
    if (message.versions) {
      obj.versions = message.versions.map((e) => e ? DataModuleVersion.toJSON(e) : undefined);
    } else {
      obj.versions = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DataModule>, I>>(base?: I): DataModule {
    return DataModule.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DataModule>, I>>(object: I): DataModule {
    const message = createBaseDataModule();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.comments = object.comments ?? "";
    message.modifiedUnixSec = object.modifiedUnixSec ?? 0;
    message.creator = (object.creator !== undefined && object.creator !== null)
      ? OwnershipSummary.fromPartial(object.creator)
      : undefined;
    message.versions = object.versions?.map((e) => DataModuleVersion.fromPartial(e)) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
