/* eslint-disable */
import * as _m0 from "protobufjs/minimal";

export const protobufPackage = "";

/** requires(PIXLISE_ADMIN) */
export interface BackupDBReq {
}

export interface BackupDBResp {
}

export interface BackupDBUpd {
}

/** requires(PIXLISE_ADMIN) */
export interface RestoreDBReq {
}

export interface RestoreDBResp {
}

/** requires(PIXLISE_ADMIN) */
export interface DBAdminConfigGetReq {
}

export interface DBAdminConfigGetResp {
  canBackup: boolean;
  backupDestination: string;
  canRestore: boolean;
  restoreFrom: string;
  impersonateEnabled: boolean;
}

function createBaseBackupDBReq(): BackupDBReq {
  return {};
}

export const BackupDBReq = {
  encode(_: BackupDBReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BackupDBReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBackupDBReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): BackupDBReq {
    return {};
  },

  toJSON(_: BackupDBReq): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<BackupDBReq>, I>>(base?: I): BackupDBReq {
    return BackupDBReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<BackupDBReq>, I>>(_: I): BackupDBReq {
    const message = createBaseBackupDBReq();
    return message;
  },
};

function createBaseBackupDBResp(): BackupDBResp {
  return {};
}

export const BackupDBResp = {
  encode(_: BackupDBResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BackupDBResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBackupDBResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): BackupDBResp {
    return {};
  },

  toJSON(_: BackupDBResp): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<BackupDBResp>, I>>(base?: I): BackupDBResp {
    return BackupDBResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<BackupDBResp>, I>>(_: I): BackupDBResp {
    const message = createBaseBackupDBResp();
    return message;
  },
};

function createBaseBackupDBUpd(): BackupDBUpd {
  return {};
}

export const BackupDBUpd = {
  encode(_: BackupDBUpd, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BackupDBUpd {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBackupDBUpd();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): BackupDBUpd {
    return {};
  },

  toJSON(_: BackupDBUpd): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<BackupDBUpd>, I>>(base?: I): BackupDBUpd {
    return BackupDBUpd.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<BackupDBUpd>, I>>(_: I): BackupDBUpd {
    const message = createBaseBackupDBUpd();
    return message;
  },
};

function createBaseRestoreDBReq(): RestoreDBReq {
  return {};
}

export const RestoreDBReq = {
  encode(_: RestoreDBReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RestoreDBReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRestoreDBReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): RestoreDBReq {
    return {};
  },

  toJSON(_: RestoreDBReq): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<RestoreDBReq>, I>>(base?: I): RestoreDBReq {
    return RestoreDBReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RestoreDBReq>, I>>(_: I): RestoreDBReq {
    const message = createBaseRestoreDBReq();
    return message;
  },
};

function createBaseRestoreDBResp(): RestoreDBResp {
  return {};
}

export const RestoreDBResp = {
  encode(_: RestoreDBResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RestoreDBResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRestoreDBResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): RestoreDBResp {
    return {};
  },

  toJSON(_: RestoreDBResp): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<RestoreDBResp>, I>>(base?: I): RestoreDBResp {
    return RestoreDBResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RestoreDBResp>, I>>(_: I): RestoreDBResp {
    const message = createBaseRestoreDBResp();
    return message;
  },
};

function createBaseDBAdminConfigGetReq(): DBAdminConfigGetReq {
  return {};
}

export const DBAdminConfigGetReq = {
  encode(_: DBAdminConfigGetReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DBAdminConfigGetReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDBAdminConfigGetReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): DBAdminConfigGetReq {
    return {};
  },

  toJSON(_: DBAdminConfigGetReq): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<DBAdminConfigGetReq>, I>>(base?: I): DBAdminConfigGetReq {
    return DBAdminConfigGetReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DBAdminConfigGetReq>, I>>(_: I): DBAdminConfigGetReq {
    const message = createBaseDBAdminConfigGetReq();
    return message;
  },
};

function createBaseDBAdminConfigGetResp(): DBAdminConfigGetResp {
  return { canBackup: false, backupDestination: "", canRestore: false, restoreFrom: "", impersonateEnabled: false };
}

export const DBAdminConfigGetResp = {
  encode(message: DBAdminConfigGetResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.canBackup === true) {
      writer.uint32(8).bool(message.canBackup);
    }
    if (message.backupDestination !== "") {
      writer.uint32(18).string(message.backupDestination);
    }
    if (message.canRestore === true) {
      writer.uint32(24).bool(message.canRestore);
    }
    if (message.restoreFrom !== "") {
      writer.uint32(34).string(message.restoreFrom);
    }
    if (message.impersonateEnabled === true) {
      writer.uint32(40).bool(message.impersonateEnabled);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DBAdminConfigGetResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDBAdminConfigGetResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.canBackup = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.backupDestination = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.canRestore = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.restoreFrom = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.impersonateEnabled = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DBAdminConfigGetResp {
    return {
      canBackup: isSet(object.canBackup) ? Boolean(object.canBackup) : false,
      backupDestination: isSet(object.backupDestination) ? String(object.backupDestination) : "",
      canRestore: isSet(object.canRestore) ? Boolean(object.canRestore) : false,
      restoreFrom: isSet(object.restoreFrom) ? String(object.restoreFrom) : "",
      impersonateEnabled: isSet(object.impersonateEnabled) ? Boolean(object.impersonateEnabled) : false,
    };
  },

  toJSON(message: DBAdminConfigGetResp): unknown {
    const obj: any = {};
    message.canBackup !== undefined && (obj.canBackup = message.canBackup);
    message.backupDestination !== undefined && (obj.backupDestination = message.backupDestination);
    message.canRestore !== undefined && (obj.canRestore = message.canRestore);
    message.restoreFrom !== undefined && (obj.restoreFrom = message.restoreFrom);
    message.impersonateEnabled !== undefined && (obj.impersonateEnabled = message.impersonateEnabled);
    return obj;
  },

  create<I extends Exact<DeepPartial<DBAdminConfigGetResp>, I>>(base?: I): DBAdminConfigGetResp {
    return DBAdminConfigGetResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DBAdminConfigGetResp>, I>>(object: I): DBAdminConfigGetResp {
    const message = createBaseDBAdminConfigGetResp();
    message.canBackup = object.canBackup ?? false;
    message.backupDestination = object.backupDestination ?? "";
    message.canRestore = object.canRestore ?? false;
    message.restoreFrom = object.restoreFrom ?? "";
    message.impersonateEnabled = object.impersonateEnabled ?? false;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
