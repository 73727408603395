/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { ExpressionGroup } from "./expression-group";
import { SearchParams } from "./search-params";

export const protobufPackage = "";

/**
 * TODO: Paginate?
 * requires(NONE)
 */
export interface ExpressionGroupListReq {
  searchParams: SearchParams | undefined;
}

export interface ExpressionGroupListResp {
  groups: { [key: string]: ExpressionGroup };
}

export interface ExpressionGroupListResp_GroupsEntry {
  key: string;
  value: ExpressionGroup | undefined;
}

/** requires(NONE) */
export interface ExpressionGroupGetReq {
  id: string;
}

export interface ExpressionGroupGetResp {
  group: ExpressionGroup | undefined;
}

/**
 * If id is blank, assume its new and generate an ID to return, otherwise update & return same one
 * requires(EDIT_EXPRESSION_GROUP)
 */
export interface ExpressionGroupWriteReq {
  group: ExpressionGroup | undefined;
}

export interface ExpressionGroupWriteResp {
  group: ExpressionGroup | undefined;
}

/** requires(EDIT_EXPRESSION_GROUP) */
export interface ExpressionGroupDeleteReq {
  id: string;
}

export interface ExpressionGroupDeleteResp {
}

function createBaseExpressionGroupListReq(): ExpressionGroupListReq {
  return { searchParams: undefined };
}

export const ExpressionGroupListReq = {
  encode(message: ExpressionGroupListReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.searchParams !== undefined) {
      SearchParams.encode(message.searchParams, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExpressionGroupListReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExpressionGroupListReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.searchParams = SearchParams.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExpressionGroupListReq {
    return { searchParams: isSet(object.searchParams) ? SearchParams.fromJSON(object.searchParams) : undefined };
  },

  toJSON(message: ExpressionGroupListReq): unknown {
    const obj: any = {};
    message.searchParams !== undefined &&
      (obj.searchParams = message.searchParams ? SearchParams.toJSON(message.searchParams) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ExpressionGroupListReq>, I>>(base?: I): ExpressionGroupListReq {
    return ExpressionGroupListReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ExpressionGroupListReq>, I>>(object: I): ExpressionGroupListReq {
    const message = createBaseExpressionGroupListReq();
    message.searchParams = (object.searchParams !== undefined && object.searchParams !== null)
      ? SearchParams.fromPartial(object.searchParams)
      : undefined;
    return message;
  },
};

function createBaseExpressionGroupListResp(): ExpressionGroupListResp {
  return { groups: {} };
}

export const ExpressionGroupListResp = {
  encode(message: ExpressionGroupListResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.groups).forEach(([key, value]) => {
      ExpressionGroupListResp_GroupsEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExpressionGroupListResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExpressionGroupListResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = ExpressionGroupListResp_GroupsEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.groups[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExpressionGroupListResp {
    return {
      groups: isObject(object.groups)
        ? Object.entries(object.groups).reduce<{ [key: string]: ExpressionGroup }>((acc, [key, value]) => {
          acc[key] = ExpressionGroup.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: ExpressionGroupListResp): unknown {
    const obj: any = {};
    obj.groups = {};
    if (message.groups) {
      Object.entries(message.groups).forEach(([k, v]) => {
        obj.groups[k] = ExpressionGroup.toJSON(v);
      });
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ExpressionGroupListResp>, I>>(base?: I): ExpressionGroupListResp {
    return ExpressionGroupListResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ExpressionGroupListResp>, I>>(object: I): ExpressionGroupListResp {
    const message = createBaseExpressionGroupListResp();
    message.groups = Object.entries(object.groups ?? {}).reduce<{ [key: string]: ExpressionGroup }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = ExpressionGroup.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseExpressionGroupListResp_GroupsEntry(): ExpressionGroupListResp_GroupsEntry {
  return { key: "", value: undefined };
}

export const ExpressionGroupListResp_GroupsEntry = {
  encode(message: ExpressionGroupListResp_GroupsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      ExpressionGroup.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExpressionGroupListResp_GroupsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExpressionGroupListResp_GroupsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = ExpressionGroup.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExpressionGroupListResp_GroupsEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? ExpressionGroup.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: ExpressionGroupListResp_GroupsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value ? ExpressionGroup.toJSON(message.value) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ExpressionGroupListResp_GroupsEntry>, I>>(
    base?: I,
  ): ExpressionGroupListResp_GroupsEntry {
    return ExpressionGroupListResp_GroupsEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ExpressionGroupListResp_GroupsEntry>, I>>(
    object: I,
  ): ExpressionGroupListResp_GroupsEntry {
    const message = createBaseExpressionGroupListResp_GroupsEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? ExpressionGroup.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseExpressionGroupGetReq(): ExpressionGroupGetReq {
  return { id: "" };
}

export const ExpressionGroupGetReq = {
  encode(message: ExpressionGroupGetReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExpressionGroupGetReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExpressionGroupGetReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExpressionGroupGetReq {
    return { id: isSet(object.id) ? String(object.id) : "" };
  },

  toJSON(message: ExpressionGroupGetReq): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  create<I extends Exact<DeepPartial<ExpressionGroupGetReq>, I>>(base?: I): ExpressionGroupGetReq {
    return ExpressionGroupGetReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ExpressionGroupGetReq>, I>>(object: I): ExpressionGroupGetReq {
    const message = createBaseExpressionGroupGetReq();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseExpressionGroupGetResp(): ExpressionGroupGetResp {
  return { group: undefined };
}

export const ExpressionGroupGetResp = {
  encode(message: ExpressionGroupGetResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.group !== undefined) {
      ExpressionGroup.encode(message.group, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExpressionGroupGetResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExpressionGroupGetResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.group = ExpressionGroup.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExpressionGroupGetResp {
    return { group: isSet(object.group) ? ExpressionGroup.fromJSON(object.group) : undefined };
  },

  toJSON(message: ExpressionGroupGetResp): unknown {
    const obj: any = {};
    message.group !== undefined && (obj.group = message.group ? ExpressionGroup.toJSON(message.group) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ExpressionGroupGetResp>, I>>(base?: I): ExpressionGroupGetResp {
    return ExpressionGroupGetResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ExpressionGroupGetResp>, I>>(object: I): ExpressionGroupGetResp {
    const message = createBaseExpressionGroupGetResp();
    message.group = (object.group !== undefined && object.group !== null)
      ? ExpressionGroup.fromPartial(object.group)
      : undefined;
    return message;
  },
};

function createBaseExpressionGroupWriteReq(): ExpressionGroupWriteReq {
  return { group: undefined };
}

export const ExpressionGroupWriteReq = {
  encode(message: ExpressionGroupWriteReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.group !== undefined) {
      ExpressionGroup.encode(message.group, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExpressionGroupWriteReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExpressionGroupWriteReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.group = ExpressionGroup.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExpressionGroupWriteReq {
    return { group: isSet(object.group) ? ExpressionGroup.fromJSON(object.group) : undefined };
  },

  toJSON(message: ExpressionGroupWriteReq): unknown {
    const obj: any = {};
    message.group !== undefined && (obj.group = message.group ? ExpressionGroup.toJSON(message.group) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ExpressionGroupWriteReq>, I>>(base?: I): ExpressionGroupWriteReq {
    return ExpressionGroupWriteReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ExpressionGroupWriteReq>, I>>(object: I): ExpressionGroupWriteReq {
    const message = createBaseExpressionGroupWriteReq();
    message.group = (object.group !== undefined && object.group !== null)
      ? ExpressionGroup.fromPartial(object.group)
      : undefined;
    return message;
  },
};

function createBaseExpressionGroupWriteResp(): ExpressionGroupWriteResp {
  return { group: undefined };
}

export const ExpressionGroupWriteResp = {
  encode(message: ExpressionGroupWriteResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.group !== undefined) {
      ExpressionGroup.encode(message.group, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExpressionGroupWriteResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExpressionGroupWriteResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.group = ExpressionGroup.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExpressionGroupWriteResp {
    return { group: isSet(object.group) ? ExpressionGroup.fromJSON(object.group) : undefined };
  },

  toJSON(message: ExpressionGroupWriteResp): unknown {
    const obj: any = {};
    message.group !== undefined && (obj.group = message.group ? ExpressionGroup.toJSON(message.group) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ExpressionGroupWriteResp>, I>>(base?: I): ExpressionGroupWriteResp {
    return ExpressionGroupWriteResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ExpressionGroupWriteResp>, I>>(object: I): ExpressionGroupWriteResp {
    const message = createBaseExpressionGroupWriteResp();
    message.group = (object.group !== undefined && object.group !== null)
      ? ExpressionGroup.fromPartial(object.group)
      : undefined;
    return message;
  },
};

function createBaseExpressionGroupDeleteReq(): ExpressionGroupDeleteReq {
  return { id: "" };
}

export const ExpressionGroupDeleteReq = {
  encode(message: ExpressionGroupDeleteReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExpressionGroupDeleteReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExpressionGroupDeleteReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExpressionGroupDeleteReq {
    return { id: isSet(object.id) ? String(object.id) : "" };
  },

  toJSON(message: ExpressionGroupDeleteReq): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  create<I extends Exact<DeepPartial<ExpressionGroupDeleteReq>, I>>(base?: I): ExpressionGroupDeleteReq {
    return ExpressionGroupDeleteReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ExpressionGroupDeleteReq>, I>>(object: I): ExpressionGroupDeleteReq {
    const message = createBaseExpressionGroupDeleteReq();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseExpressionGroupDeleteResp(): ExpressionGroupDeleteResp {
  return {};
}

export const ExpressionGroupDeleteResp = {
  encode(_: ExpressionGroupDeleteResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExpressionGroupDeleteResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExpressionGroupDeleteResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ExpressionGroupDeleteResp {
    return {};
  },

  toJSON(_: ExpressionGroupDeleteResp): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ExpressionGroupDeleteResp>, I>>(base?: I): ExpressionGroupDeleteResp {
    return ExpressionGroupDeleteResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ExpressionGroupDeleteResp>, I>>(_: I): ExpressionGroupDeleteResp {
    const message = createBaseExpressionGroupDeleteResp();
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
