/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { JobStatus } from "./job";
import { OwnershipSummary } from "./ownership-access";

export const protobufPackage = "";

/**
 * TODO: Quants likely need to change to accommodate quantifying spectra from multiple
 * datasets together??
 */
export interface QuantStartingParameters {
  userParams:
    | QuantCreateParams
    | undefined;
  /** This is for when we store/send just the summary, sometimes we only need to supply count for brevity */
  pmcCount: number;
  /** Paths, Buckets, to help runner initialise */
  scanFilePath: string;
  dataBucket: string;
  piquantJobsBucket: string;
  /** How many cores we expect to have on the same node as us! */
  coresPerNode: number;
  startUnixTimeSec: number;
  requestorUserId: string;
  PIQUANTVersion: string;
  comments: string;
}

export interface QuantCreateParams {
  /**
   * Depending on this, we may return the result straight away or return a job status
   * Valid values at time of writing are:
   * "map" - Runs a quant job, to output a map
   * "quant" - Runs a quant "fit", a quick job, so we wait and return the result in resp
   */
  command: string;
  /** Name of the quantification - note for "quant" (aka Fit) mode, this is not needed */
  name: string;
  /** Required, id of scan file to pass to PIQUANT */
  scanId: string;
  /** List of all PMCs (over wire it should be sent encoded with our simple compression method) */
  pmcs: number[];
  /**
   * What elements to quantify. NOTE: PIQUANT output will not necessarily match this as
   * it may choose to output oxides or carbonates
   */
  elements: string[];
  /** The config of the detector used to capture the scan */
  detectorConfig: string;
  /** Any extra command line parameters, these are just tacked onto the cmd line */
  parameters: string;
  /**
   * A "hint" to the PIQUANT runner saying how fast we want it to complete. It should
   * then create more or less nodes to run PIQUANT on to try to achieve that run time
   */
  runTimeSec: number;
  /** What kind of quantification we're creating */
  quantMode: string;
  /**
   * List of all ROI IDs within the scan that we want to quantify. This is required
   * so we can output bulk quants for all PMCs in an ROI
   * NOTE: If QuantMode = *Bulk, this is used, pmcsEncoded is ignored.
   */
  roiIDs: string[];
  /** Should dwell spectra be included in the quantification calculation */
  includeDwells: boolean;
}

export interface QuantificationSummary {
  /** @gotags: bson:"_id,omitempty" */
  id: string;
  scanId: string;
  params: QuantStartingParameters | undefined;
  elements: string[];
  /** TODO: Make this also contain *APIObjectItem and remove its own Shared field... */
  status:
    | JobStatus
    | undefined;
  /** Only sent out by API, not stored in DB this way */
  owner: OwnershipSummary | undefined;
}

function createBaseQuantStartingParameters(): QuantStartingParameters {
  return {
    userParams: undefined,
    pmcCount: 0,
    scanFilePath: "",
    dataBucket: "",
    piquantJobsBucket: "",
    coresPerNode: 0,
    startUnixTimeSec: 0,
    requestorUserId: "",
    PIQUANTVersion: "",
    comments: "",
  };
}

export const QuantStartingParameters = {
  encode(message: QuantStartingParameters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userParams !== undefined) {
      QuantCreateParams.encode(message.userParams, writer.uint32(10).fork()).ldelim();
    }
    if (message.pmcCount !== 0) {
      writer.uint32(16).uint32(message.pmcCount);
    }
    if (message.scanFilePath !== "") {
      writer.uint32(26).string(message.scanFilePath);
    }
    if (message.dataBucket !== "") {
      writer.uint32(34).string(message.dataBucket);
    }
    if (message.piquantJobsBucket !== "") {
      writer.uint32(42).string(message.piquantJobsBucket);
    }
    if (message.coresPerNode !== 0) {
      writer.uint32(48).uint32(message.coresPerNode);
    }
    if (message.startUnixTimeSec !== 0) {
      writer.uint32(56).uint32(message.startUnixTimeSec);
    }
    if (message.requestorUserId !== "") {
      writer.uint32(66).string(message.requestorUserId);
    }
    if (message.PIQUANTVersion !== "") {
      writer.uint32(74).string(message.PIQUANTVersion);
    }
    if (message.comments !== "") {
      writer.uint32(82).string(message.comments);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuantStartingParameters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantStartingParameters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userParams = QuantCreateParams.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.pmcCount = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.scanFilePath = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.dataBucket = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.piquantJobsBucket = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.coresPerNode = reader.uint32();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.startUnixTimeSec = reader.uint32();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.requestorUserId = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.PIQUANTVersion = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.comments = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuantStartingParameters {
    return {
      userParams: isSet(object.userParams) ? QuantCreateParams.fromJSON(object.userParams) : undefined,
      pmcCount: isSet(object.pmcCount) ? Number(object.pmcCount) : 0,
      scanFilePath: isSet(object.scanFilePath) ? String(object.scanFilePath) : "",
      dataBucket: isSet(object.dataBucket) ? String(object.dataBucket) : "",
      piquantJobsBucket: isSet(object.piquantJobsBucket) ? String(object.piquantJobsBucket) : "",
      coresPerNode: isSet(object.coresPerNode) ? Number(object.coresPerNode) : 0,
      startUnixTimeSec: isSet(object.startUnixTimeSec) ? Number(object.startUnixTimeSec) : 0,
      requestorUserId: isSet(object.requestorUserId) ? String(object.requestorUserId) : "",
      PIQUANTVersion: isSet(object.PIQUANTVersion) ? String(object.PIQUANTVersion) : "",
      comments: isSet(object.comments) ? String(object.comments) : "",
    };
  },

  toJSON(message: QuantStartingParameters): unknown {
    const obj: any = {};
    message.userParams !== undefined &&
      (obj.userParams = message.userParams ? QuantCreateParams.toJSON(message.userParams) : undefined);
    message.pmcCount !== undefined && (obj.pmcCount = Math.round(message.pmcCount));
    message.scanFilePath !== undefined && (obj.scanFilePath = message.scanFilePath);
    message.dataBucket !== undefined && (obj.dataBucket = message.dataBucket);
    message.piquantJobsBucket !== undefined && (obj.piquantJobsBucket = message.piquantJobsBucket);
    message.coresPerNode !== undefined && (obj.coresPerNode = Math.round(message.coresPerNode));
    message.startUnixTimeSec !== undefined && (obj.startUnixTimeSec = Math.round(message.startUnixTimeSec));
    message.requestorUserId !== undefined && (obj.requestorUserId = message.requestorUserId);
    message.PIQUANTVersion !== undefined && (obj.PIQUANTVersion = message.PIQUANTVersion);
    message.comments !== undefined && (obj.comments = message.comments);
    return obj;
  },

  create<I extends Exact<DeepPartial<QuantStartingParameters>, I>>(base?: I): QuantStartingParameters {
    return QuantStartingParameters.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuantStartingParameters>, I>>(object: I): QuantStartingParameters {
    const message = createBaseQuantStartingParameters();
    message.userParams = (object.userParams !== undefined && object.userParams !== null)
      ? QuantCreateParams.fromPartial(object.userParams)
      : undefined;
    message.pmcCount = object.pmcCount ?? 0;
    message.scanFilePath = object.scanFilePath ?? "";
    message.dataBucket = object.dataBucket ?? "";
    message.piquantJobsBucket = object.piquantJobsBucket ?? "";
    message.coresPerNode = object.coresPerNode ?? 0;
    message.startUnixTimeSec = object.startUnixTimeSec ?? 0;
    message.requestorUserId = object.requestorUserId ?? "";
    message.PIQUANTVersion = object.PIQUANTVersion ?? "";
    message.comments = object.comments ?? "";
    return message;
  },
};

function createBaseQuantCreateParams(): QuantCreateParams {
  return {
    command: "",
    name: "",
    scanId: "",
    pmcs: [],
    elements: [],
    detectorConfig: "",
    parameters: "",
    runTimeSec: 0,
    quantMode: "",
    roiIDs: [],
    includeDwells: false,
  };
}

export const QuantCreateParams = {
  encode(message: QuantCreateParams, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.command !== "") {
      writer.uint32(10).string(message.command);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.scanId !== "") {
      writer.uint32(26).string(message.scanId);
    }
    writer.uint32(34).fork();
    for (const v of message.pmcs) {
      writer.int32(v);
    }
    writer.ldelim();
    for (const v of message.elements) {
      writer.uint32(42).string(v!);
    }
    if (message.detectorConfig !== "") {
      writer.uint32(50).string(message.detectorConfig);
    }
    if (message.parameters !== "") {
      writer.uint32(58).string(message.parameters);
    }
    if (message.runTimeSec !== 0) {
      writer.uint32(64).uint32(message.runTimeSec);
    }
    if (message.quantMode !== "") {
      writer.uint32(74).string(message.quantMode);
    }
    for (const v of message.roiIDs) {
      writer.uint32(82).string(v!);
    }
    if (message.includeDwells === true) {
      writer.uint32(88).bool(message.includeDwells);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuantCreateParams {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantCreateParams();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.command = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.scanId = reader.string();
          continue;
        case 4:
          if (tag === 32) {
            message.pmcs.push(reader.int32());

            continue;
          }

          if (tag === 34) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.pmcs.push(reader.int32());
            }

            continue;
          }

          break;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.elements.push(reader.string());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.detectorConfig = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.parameters = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.runTimeSec = reader.uint32();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.quantMode = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.roiIDs.push(reader.string());
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.includeDwells = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuantCreateParams {
    return {
      command: isSet(object.command) ? String(object.command) : "",
      name: isSet(object.name) ? String(object.name) : "",
      scanId: isSet(object.scanId) ? String(object.scanId) : "",
      pmcs: Array.isArray(object?.pmcs) ? object.pmcs.map((e: any) => Number(e)) : [],
      elements: Array.isArray(object?.elements) ? object.elements.map((e: any) => String(e)) : [],
      detectorConfig: isSet(object.detectorConfig) ? String(object.detectorConfig) : "",
      parameters: isSet(object.parameters) ? String(object.parameters) : "",
      runTimeSec: isSet(object.runTimeSec) ? Number(object.runTimeSec) : 0,
      quantMode: isSet(object.quantMode) ? String(object.quantMode) : "",
      roiIDs: Array.isArray(object?.roiIDs) ? object.roiIDs.map((e: any) => String(e)) : [],
      includeDwells: isSet(object.includeDwells) ? Boolean(object.includeDwells) : false,
    };
  },

  toJSON(message: QuantCreateParams): unknown {
    const obj: any = {};
    message.command !== undefined && (obj.command = message.command);
    message.name !== undefined && (obj.name = message.name);
    message.scanId !== undefined && (obj.scanId = message.scanId);
    if (message.pmcs) {
      obj.pmcs = message.pmcs.map((e) => Math.round(e));
    } else {
      obj.pmcs = [];
    }
    if (message.elements) {
      obj.elements = message.elements.map((e) => e);
    } else {
      obj.elements = [];
    }
    message.detectorConfig !== undefined && (obj.detectorConfig = message.detectorConfig);
    message.parameters !== undefined && (obj.parameters = message.parameters);
    message.runTimeSec !== undefined && (obj.runTimeSec = Math.round(message.runTimeSec));
    message.quantMode !== undefined && (obj.quantMode = message.quantMode);
    if (message.roiIDs) {
      obj.roiIDs = message.roiIDs.map((e) => e);
    } else {
      obj.roiIDs = [];
    }
    message.includeDwells !== undefined && (obj.includeDwells = message.includeDwells);
    return obj;
  },

  create<I extends Exact<DeepPartial<QuantCreateParams>, I>>(base?: I): QuantCreateParams {
    return QuantCreateParams.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuantCreateParams>, I>>(object: I): QuantCreateParams {
    const message = createBaseQuantCreateParams();
    message.command = object.command ?? "";
    message.name = object.name ?? "";
    message.scanId = object.scanId ?? "";
    message.pmcs = object.pmcs?.map((e) => e) || [];
    message.elements = object.elements?.map((e) => e) || [];
    message.detectorConfig = object.detectorConfig ?? "";
    message.parameters = object.parameters ?? "";
    message.runTimeSec = object.runTimeSec ?? 0;
    message.quantMode = object.quantMode ?? "";
    message.roiIDs = object.roiIDs?.map((e) => e) || [];
    message.includeDwells = object.includeDwells ?? false;
    return message;
  },
};

function createBaseQuantificationSummary(): QuantificationSummary {
  return { id: "", scanId: "", params: undefined, elements: [], status: undefined, owner: undefined };
}

export const QuantificationSummary = {
  encode(message: QuantificationSummary, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.scanId !== "") {
      writer.uint32(42).string(message.scanId);
    }
    if (message.params !== undefined) {
      QuantStartingParameters.encode(message.params, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.elements) {
      writer.uint32(26).string(v!);
    }
    if (message.status !== undefined) {
      JobStatus.encode(message.status, writer.uint32(34).fork()).ldelim();
    }
    if (message.owner !== undefined) {
      OwnershipSummary.encode(message.owner, writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuantificationSummary {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantificationSummary();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.scanId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.params = QuantStartingParameters.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.elements.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.status = JobStatus.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.owner = OwnershipSummary.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuantificationSummary {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      scanId: isSet(object.scanId) ? String(object.scanId) : "",
      params: isSet(object.params) ? QuantStartingParameters.fromJSON(object.params) : undefined,
      elements: Array.isArray(object?.elements) ? object.elements.map((e: any) => String(e)) : [],
      status: isSet(object.status) ? JobStatus.fromJSON(object.status) : undefined,
      owner: isSet(object.owner) ? OwnershipSummary.fromJSON(object.owner) : undefined,
    };
  },

  toJSON(message: QuantificationSummary): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.scanId !== undefined && (obj.scanId = message.scanId);
    message.params !== undefined &&
      (obj.params = message.params ? QuantStartingParameters.toJSON(message.params) : undefined);
    if (message.elements) {
      obj.elements = message.elements.map((e) => e);
    } else {
      obj.elements = [];
    }
    message.status !== undefined && (obj.status = message.status ? JobStatus.toJSON(message.status) : undefined);
    message.owner !== undefined && (obj.owner = message.owner ? OwnershipSummary.toJSON(message.owner) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<QuantificationSummary>, I>>(base?: I): QuantificationSummary {
    return QuantificationSummary.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuantificationSummary>, I>>(object: I): QuantificationSummary {
    const message = createBaseQuantificationSummary();
    message.id = object.id ?? "";
    message.scanId = object.scanId ?? "";
    message.params = (object.params !== undefined && object.params !== null)
      ? QuantStartingParameters.fromPartial(object.params)
      : undefined;
    message.elements = object.elements?.map((e) => e) || [];
    message.status = (object.status !== undefined && object.status !== null)
      ? JobStatus.fromPartial(object.status)
      : undefined;
    message.owner = (object.owner !== undefined && object.owner !== null)
      ? OwnershipSummary.fromPartial(object.owner)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
