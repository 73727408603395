<div class="new-roi">
  <header>
    <h1 class="title">New ROI</h1>
    <tag-picker
      type="roi"
      placeholderText="Search ROI Tags ..."
      [selectedTagIDs]="newROITags"
      [showCurrentTagsSection]="true"
      [editable]="true"
      (onTagSelectionChanged)="onNewTagSelectionChanged($event)">
    </tag-picker>
  </header>
  <div class="meta-line">Pixels: {{ pixelCount | number }}, PMCs: {{ entryCount | number }}</div>

  <input type="text" class="name" [(ngModel)]="newROIName" placeholder="ROI Name" />
  <textarea class="description" [(ngModel)]="newROIDescription" placeholder="ROI Description" rows="4"></textarea>

  <div class="multiple-scan-warning" *ngIf="selectedScanIds.length > 1">
    <span>*ROI spans multiple scans, so multiple ROIs for the following scans will be created: {{ selectedScanIds.join(", ") }}</span>
  </div>

  <div class="buttons">
    <push-button buttonStyle="outline" (onClick)="onCancelCreateROI()" title="Cancel creating this ROI">Cancel</push-button>
    <push-button buttonStyle="yellow" (onClick)="onSaveNewROI()" title="Save new ROI">Save</push-button>
  </div>
</div>
