/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { ManualDiffractionPeak } from "./diffraction-data";

export const protobufPackage = "";

/** requires(NONE) */
export interface DiffractionPeakManualListReq {
  scanId: string;
}

export interface DiffractionPeakManualListResp {
  /** id->ManualDiffractionPeak */
  peaks: { [key: string]: ManualDiffractionPeak };
}

export interface DiffractionPeakManualListResp_PeaksEntry {
  key: string;
  value: ManualDiffractionPeak | undefined;
}

/** requires(EDIT_DIFFRACTION) */
export interface DiffractionPeakManualInsertReq {
  scanId: string;
  pmc: number;
  energykeV: number;
}

export interface DiffractionPeakManualInsertResp {
  createdId: string;
}

/** requires(EDIT_DIFFRACTION) */
export interface DiffractionPeakManualDeleteReq {
  id: string;
}

export interface DiffractionPeakManualDeleteResp {
}

function createBaseDiffractionPeakManualListReq(): DiffractionPeakManualListReq {
  return { scanId: "" };
}

export const DiffractionPeakManualListReq = {
  encode(message: DiffractionPeakManualListReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.scanId !== "") {
      writer.uint32(10).string(message.scanId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DiffractionPeakManualListReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDiffractionPeakManualListReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.scanId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DiffractionPeakManualListReq {
    return { scanId: isSet(object.scanId) ? String(object.scanId) : "" };
  },

  toJSON(message: DiffractionPeakManualListReq): unknown {
    const obj: any = {};
    message.scanId !== undefined && (obj.scanId = message.scanId);
    return obj;
  },

  create<I extends Exact<DeepPartial<DiffractionPeakManualListReq>, I>>(base?: I): DiffractionPeakManualListReq {
    return DiffractionPeakManualListReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DiffractionPeakManualListReq>, I>>(object: I): DiffractionPeakManualListReq {
    const message = createBaseDiffractionPeakManualListReq();
    message.scanId = object.scanId ?? "";
    return message;
  },
};

function createBaseDiffractionPeakManualListResp(): DiffractionPeakManualListResp {
  return { peaks: {} };
}

export const DiffractionPeakManualListResp = {
  encode(message: DiffractionPeakManualListResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.peaks).forEach(([key, value]) => {
      DiffractionPeakManualListResp_PeaksEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DiffractionPeakManualListResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDiffractionPeakManualListResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = DiffractionPeakManualListResp_PeaksEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.peaks[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DiffractionPeakManualListResp {
    return {
      peaks: isObject(object.peaks)
        ? Object.entries(object.peaks).reduce<{ [key: string]: ManualDiffractionPeak }>((acc, [key, value]) => {
          acc[key] = ManualDiffractionPeak.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: DiffractionPeakManualListResp): unknown {
    const obj: any = {};
    obj.peaks = {};
    if (message.peaks) {
      Object.entries(message.peaks).forEach(([k, v]) => {
        obj.peaks[k] = ManualDiffractionPeak.toJSON(v);
      });
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DiffractionPeakManualListResp>, I>>(base?: I): DiffractionPeakManualListResp {
    return DiffractionPeakManualListResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DiffractionPeakManualListResp>, I>>(
    object: I,
  ): DiffractionPeakManualListResp {
    const message = createBaseDiffractionPeakManualListResp();
    message.peaks = Object.entries(object.peaks ?? {}).reduce<{ [key: string]: ManualDiffractionPeak }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = ManualDiffractionPeak.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseDiffractionPeakManualListResp_PeaksEntry(): DiffractionPeakManualListResp_PeaksEntry {
  return { key: "", value: undefined };
}

export const DiffractionPeakManualListResp_PeaksEntry = {
  encode(message: DiffractionPeakManualListResp_PeaksEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      ManualDiffractionPeak.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DiffractionPeakManualListResp_PeaksEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDiffractionPeakManualListResp_PeaksEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = ManualDiffractionPeak.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DiffractionPeakManualListResp_PeaksEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? ManualDiffractionPeak.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: DiffractionPeakManualListResp_PeaksEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined &&
      (obj.value = message.value ? ManualDiffractionPeak.toJSON(message.value) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DiffractionPeakManualListResp_PeaksEntry>, I>>(
    base?: I,
  ): DiffractionPeakManualListResp_PeaksEntry {
    return DiffractionPeakManualListResp_PeaksEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DiffractionPeakManualListResp_PeaksEntry>, I>>(
    object: I,
  ): DiffractionPeakManualListResp_PeaksEntry {
    const message = createBaseDiffractionPeakManualListResp_PeaksEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? ManualDiffractionPeak.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseDiffractionPeakManualInsertReq(): DiffractionPeakManualInsertReq {
  return { scanId: "", pmc: 0, energykeV: 0 };
}

export const DiffractionPeakManualInsertReq = {
  encode(message: DiffractionPeakManualInsertReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.scanId !== "") {
      writer.uint32(10).string(message.scanId);
    }
    if (message.pmc !== 0) {
      writer.uint32(16).int32(message.pmc);
    }
    if (message.energykeV !== 0) {
      writer.uint32(29).float(message.energykeV);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DiffractionPeakManualInsertReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDiffractionPeakManualInsertReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.scanId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.pmc = reader.int32();
          continue;
        case 3:
          if (tag !== 29) {
            break;
          }

          message.energykeV = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DiffractionPeakManualInsertReq {
    return {
      scanId: isSet(object.scanId) ? String(object.scanId) : "",
      pmc: isSet(object.pmc) ? Number(object.pmc) : 0,
      energykeV: isSet(object.energykeV) ? Number(object.energykeV) : 0,
    };
  },

  toJSON(message: DiffractionPeakManualInsertReq): unknown {
    const obj: any = {};
    message.scanId !== undefined && (obj.scanId = message.scanId);
    message.pmc !== undefined && (obj.pmc = Math.round(message.pmc));
    message.energykeV !== undefined && (obj.energykeV = message.energykeV);
    return obj;
  },

  create<I extends Exact<DeepPartial<DiffractionPeakManualInsertReq>, I>>(base?: I): DiffractionPeakManualInsertReq {
    return DiffractionPeakManualInsertReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DiffractionPeakManualInsertReq>, I>>(
    object: I,
  ): DiffractionPeakManualInsertReq {
    const message = createBaseDiffractionPeakManualInsertReq();
    message.scanId = object.scanId ?? "";
    message.pmc = object.pmc ?? 0;
    message.energykeV = object.energykeV ?? 0;
    return message;
  },
};

function createBaseDiffractionPeakManualInsertResp(): DiffractionPeakManualInsertResp {
  return { createdId: "" };
}

export const DiffractionPeakManualInsertResp = {
  encode(message: DiffractionPeakManualInsertResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.createdId !== "") {
      writer.uint32(10).string(message.createdId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DiffractionPeakManualInsertResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDiffractionPeakManualInsertResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.createdId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DiffractionPeakManualInsertResp {
    return { createdId: isSet(object.createdId) ? String(object.createdId) : "" };
  },

  toJSON(message: DiffractionPeakManualInsertResp): unknown {
    const obj: any = {};
    message.createdId !== undefined && (obj.createdId = message.createdId);
    return obj;
  },

  create<I extends Exact<DeepPartial<DiffractionPeakManualInsertResp>, I>>(base?: I): DiffractionPeakManualInsertResp {
    return DiffractionPeakManualInsertResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DiffractionPeakManualInsertResp>, I>>(
    object: I,
  ): DiffractionPeakManualInsertResp {
    const message = createBaseDiffractionPeakManualInsertResp();
    message.createdId = object.createdId ?? "";
    return message;
  },
};

function createBaseDiffractionPeakManualDeleteReq(): DiffractionPeakManualDeleteReq {
  return { id: "" };
}

export const DiffractionPeakManualDeleteReq = {
  encode(message: DiffractionPeakManualDeleteReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DiffractionPeakManualDeleteReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDiffractionPeakManualDeleteReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DiffractionPeakManualDeleteReq {
    return { id: isSet(object.id) ? String(object.id) : "" };
  },

  toJSON(message: DiffractionPeakManualDeleteReq): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  create<I extends Exact<DeepPartial<DiffractionPeakManualDeleteReq>, I>>(base?: I): DiffractionPeakManualDeleteReq {
    return DiffractionPeakManualDeleteReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DiffractionPeakManualDeleteReq>, I>>(
    object: I,
  ): DiffractionPeakManualDeleteReq {
    const message = createBaseDiffractionPeakManualDeleteReq();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseDiffractionPeakManualDeleteResp(): DiffractionPeakManualDeleteResp {
  return {};
}

export const DiffractionPeakManualDeleteResp = {
  encode(_: DiffractionPeakManualDeleteResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DiffractionPeakManualDeleteResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDiffractionPeakManualDeleteResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): DiffractionPeakManualDeleteResp {
    return {};
  },

  toJSON(_: DiffractionPeakManualDeleteResp): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<DiffractionPeakManualDeleteResp>, I>>(base?: I): DiffractionPeakManualDeleteResp {
    return DiffractionPeakManualDeleteResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DiffractionPeakManualDeleteResp>, I>>(_: I): DiffractionPeakManualDeleteResp {
    const message = createBaseDiffractionPeakManualDeleteResp();
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
