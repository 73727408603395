/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { ElementLine } from "./element-set";

export const protobufPackage = "";

export interface VisibleROI {
  id: string;
  scanId: string;
}

export interface SpectrumXRFLineState {
  lineInfo: ElementLine | undefined;
  visible: boolean;
}

export interface EnergyCalibration {
  detector: string;
  eVStart: number;
  eVPerChannel: number;
}

export interface SpectrumLines {
  /** can be "dataset" or "selection" for those special cases */
  roiID: string;
  /** max(A), bulk(A) or things like sum(max(A), max(B)) */
  lineExpressions: string[];
}

export interface SpectrumWidgetState {
  panX: number;
  panY: number;
  zoomX: number;
  zoomY: number;
  spectrumLines: SpectrumLines[];
  logScale: boolean;
  /** repeated SpectrumXRFLineState xrflines = 7; */
  showXAsEnergy: boolean;
  /** repeated EnergyCalibration    energyCalibration = 9; */
  yCountsPerMin: boolean;
  yCountsPerPMC: boolean;
}

export interface HistogramState {
  showStdDeviation: boolean;
  logScale: boolean;
  expressionIDs: string[];
  visibleROIs: VisibleROI[];
  showWhiskers: boolean;
}

export interface ChordState {
  showForSelection: boolean;
  expressionIDs: string[];
  displayROI: string;
  threshold: number;
  drawMode: string;
}

export interface BinaryState {
  showMmol: boolean;
  expressionIDs: string[];
  visibleROIs: VisibleROI[];
}

export interface TernaryState {
  showMmol: boolean;
  expressionIDs: string[];
  visibleROIs: VisibleROI[];
}

export interface VisibleROIAndQuant {
  id: string;
  scanId: string;
  quantId: string;
}

export interface TableState {
  showPureElements: boolean;
  order: string;
  visibleROIs: VisibleROIAndQuant[];
}

export interface ROIQuantTableState {
  roi: string;
  quantIDs: string[];
}

export interface VariogramState {
  expressionIDs: string[];
  visibleROIs: VisibleROI[];
  /** valid: "exponential", "spherical", "gaussian" */
  varioModel: string;
  maxDistance: number;
  binCount: number;
  /** vector or isotropic */
  drawModeVector: boolean;
  /** "Subtract", "XOR-Sum", <Expression ID> */
  comparisonAlgorithms: string[];
  liveUpdate: boolean;
  distanceSliderMin: number;
  distanceSliderMax: number;
  binSliderMin: number;
  binSliderMax: number;
  drawBestFit: boolean;
}

export interface MapLayerVisibility {
  expressionID: string;
  opacity: number;
  visible: boolean;
  displayValueRangeMin: number;
  displayValueRangeMax: number;
  displayValueShading: string;
}

export interface ROILayerVisibility {
  id: string;
  opacity: number;
  visible: boolean;
  scanId: string;
}

export interface ContextImageState {
  panX: number;
  panY: number;
  zoomX: number;
  zoomY: number;
  showPoints: boolean;
  showPointBBox: boolean;
  pointColourScheme: string;
  pointBBoxColourScheme: string;
  contextImage: string;
  contextImageSmoothing: string;
  mapLayers: MapLayerVisibility[];
  roiLayers: ROILayerVisibility[];
  elementRelativeShading: boolean;
  brightness: number;
  rgbuChannels: string;
  unselectedOpacity: number;
  unselectedGrayscale: boolean;
  colourRatioMin: number;
  colourRatioMax: number;
  removeTopSpecularArtifacts: boolean;
  removeBottomSpecularArtifacts: boolean;
  hideFootprintsForScans: string[];
  hidePointsForScans: string[];
  unlinkFromDataset: boolean;
  /** Was drawImage but zero-value being false meant lots of hidden context images! */
  hideImage: boolean;
}

export interface AnnotationPoint {
  x: number;
  y: number;
  screenWidth: number;
  screenHeight: number;
}

export interface FullScreenAnnotationItem {
  type: string;
  points: AnnotationPoint[];
  colour: string;
  complete: boolean;
  text: string;
  fontSize: number;
  id: number;
}

export interface AnnotationDisplayState {
  savedAnnotations: FullScreenAnnotationItem[];
}

export interface ROIDisplayState {
  roiColours: { [key: string]: string };
  roiShapes: { [key: string]: string };
}

export interface ROIDisplayState_RoiColoursEntry {
  key: string;
  value: string;
}

export interface ROIDisplayState_RoiShapesEntry {
  key: string;
  value: string;
}

export interface RGBUPlotWidgetState {
  minerals: string[];
  yChannelA: string;
  yChannelB: string;
  xChannelA: string;
  xChannelB: string;
  drawMonochrome: boolean;
  selectedMinXValue: number;
  selectedMaxXValue: number;
  selectedMinYValue: number;
  selectedMaxYValue: number;
  imageName: string;
  roiIds: string[];
}

export interface SingleAxisRGBUWidgetState {
  minerals: string[];
  channelA: string;
  channelB: string;
  roiStackedOverlap: boolean;
  imageName: string;
  selectedMinValue: number;
  selectedMaxValue: number;
  roiIds: string[];
  showAllMineralLabels: boolean;
}

export interface RGBUImagesWidgetState {
  /**
   * Setting was never implemented
   * bool logColour = 1;
   */
  brightness: number;
  imageName: string;
}

export interface ParallelogramWidgetState {
  regions: string[];
  channels: string[];
  excludeZero: boolean;
  /** "mean", "median" */
  averageMode: string;
  /** None, Sigma +1, Sigma +2 */
  sigmaLevel: string;
}

export interface MarkdownViewState {
  content: string;
}

export interface WidgetData {
  /** @gotags: bson:"_id,omitempty" */
  id: string;
  spectrum: SpectrumWidgetState | undefined;
  binary: BinaryState | undefined;
  ternary: TernaryState | undefined;
  histogram: HistogramState | undefined;
  contextImage: ContextImageState | undefined;
  chord: ChordState | undefined;
  table: TableState | undefined;
  roiQuantTable: ROIQuantTableState | undefined;
  variogram: VariogramState | undefined;
  rgbuPlot: RGBUPlotWidgetState | undefined;
  singleAxisRGBU: SingleAxisRGBUWidgetState | undefined;
  rgbuImage: RGBUImagesWidgetState | undefined;
  parallelogram: ParallelogramWidgetState | undefined;
  markdownView: MarkdownViewState | undefined;
}

function createBaseVisibleROI(): VisibleROI {
  return { id: "", scanId: "" };
}

export const VisibleROI = {
  encode(message: VisibleROI, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.scanId !== "") {
      writer.uint32(18).string(message.scanId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VisibleROI {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVisibleROI();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.scanId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VisibleROI {
    return { id: isSet(object.id) ? String(object.id) : "", scanId: isSet(object.scanId) ? String(object.scanId) : "" };
  },

  toJSON(message: VisibleROI): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.scanId !== undefined && (obj.scanId = message.scanId);
    return obj;
  },

  create<I extends Exact<DeepPartial<VisibleROI>, I>>(base?: I): VisibleROI {
    return VisibleROI.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<VisibleROI>, I>>(object: I): VisibleROI {
    const message = createBaseVisibleROI();
    message.id = object.id ?? "";
    message.scanId = object.scanId ?? "";
    return message;
  },
};

function createBaseSpectrumXRFLineState(): SpectrumXRFLineState {
  return { lineInfo: undefined, visible: false };
}

export const SpectrumXRFLineState = {
  encode(message: SpectrumXRFLineState, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.lineInfo !== undefined) {
      ElementLine.encode(message.lineInfo, writer.uint32(10).fork()).ldelim();
    }
    if (message.visible === true) {
      writer.uint32(16).bool(message.visible);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SpectrumXRFLineState {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSpectrumXRFLineState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.lineInfo = ElementLine.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.visible = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SpectrumXRFLineState {
    return {
      lineInfo: isSet(object.lineInfo) ? ElementLine.fromJSON(object.lineInfo) : undefined,
      visible: isSet(object.visible) ? Boolean(object.visible) : false,
    };
  },

  toJSON(message: SpectrumXRFLineState): unknown {
    const obj: any = {};
    message.lineInfo !== undefined &&
      (obj.lineInfo = message.lineInfo ? ElementLine.toJSON(message.lineInfo) : undefined);
    message.visible !== undefined && (obj.visible = message.visible);
    return obj;
  },

  create<I extends Exact<DeepPartial<SpectrumXRFLineState>, I>>(base?: I): SpectrumXRFLineState {
    return SpectrumXRFLineState.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SpectrumXRFLineState>, I>>(object: I): SpectrumXRFLineState {
    const message = createBaseSpectrumXRFLineState();
    message.lineInfo = (object.lineInfo !== undefined && object.lineInfo !== null)
      ? ElementLine.fromPartial(object.lineInfo)
      : undefined;
    message.visible = object.visible ?? false;
    return message;
  },
};

function createBaseEnergyCalibration(): EnergyCalibration {
  return { detector: "", eVStart: 0, eVPerChannel: 0 };
}

export const EnergyCalibration = {
  encode(message: EnergyCalibration, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.detector !== "") {
      writer.uint32(10).string(message.detector);
    }
    if (message.eVStart !== 0) {
      writer.uint32(21).float(message.eVStart);
    }
    if (message.eVPerChannel !== 0) {
      writer.uint32(29).float(message.eVPerChannel);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EnergyCalibration {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnergyCalibration();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.detector = reader.string();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.eVStart = reader.float();
          continue;
        case 3:
          if (tag !== 29) {
            break;
          }

          message.eVPerChannel = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EnergyCalibration {
    return {
      detector: isSet(object.detector) ? String(object.detector) : "",
      eVStart: isSet(object.eVStart) ? Number(object.eVStart) : 0,
      eVPerChannel: isSet(object.eVPerChannel) ? Number(object.eVPerChannel) : 0,
    };
  },

  toJSON(message: EnergyCalibration): unknown {
    const obj: any = {};
    message.detector !== undefined && (obj.detector = message.detector);
    message.eVStart !== undefined && (obj.eVStart = message.eVStart);
    message.eVPerChannel !== undefined && (obj.eVPerChannel = message.eVPerChannel);
    return obj;
  },

  create<I extends Exact<DeepPartial<EnergyCalibration>, I>>(base?: I): EnergyCalibration {
    return EnergyCalibration.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EnergyCalibration>, I>>(object: I): EnergyCalibration {
    const message = createBaseEnergyCalibration();
    message.detector = object.detector ?? "";
    message.eVStart = object.eVStart ?? 0;
    message.eVPerChannel = object.eVPerChannel ?? 0;
    return message;
  },
};

function createBaseSpectrumLines(): SpectrumLines {
  return { roiID: "", lineExpressions: [] };
}

export const SpectrumLines = {
  encode(message: SpectrumLines, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.roiID !== "") {
      writer.uint32(10).string(message.roiID);
    }
    for (const v of message.lineExpressions) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SpectrumLines {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSpectrumLines();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.roiID = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.lineExpressions.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SpectrumLines {
    return {
      roiID: isSet(object.roiID) ? String(object.roiID) : "",
      lineExpressions: Array.isArray(object?.lineExpressions) ? object.lineExpressions.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: SpectrumLines): unknown {
    const obj: any = {};
    message.roiID !== undefined && (obj.roiID = message.roiID);
    if (message.lineExpressions) {
      obj.lineExpressions = message.lineExpressions.map((e) => e);
    } else {
      obj.lineExpressions = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SpectrumLines>, I>>(base?: I): SpectrumLines {
    return SpectrumLines.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SpectrumLines>, I>>(object: I): SpectrumLines {
    const message = createBaseSpectrumLines();
    message.roiID = object.roiID ?? "";
    message.lineExpressions = object.lineExpressions?.map((e) => e) || [];
    return message;
  },
};

function createBaseSpectrumWidgetState(): SpectrumWidgetState {
  return {
    panX: 0,
    panY: 0,
    zoomX: 0,
    zoomY: 0,
    spectrumLines: [],
    logScale: false,
    showXAsEnergy: false,
    yCountsPerMin: false,
    yCountsPerPMC: false,
  };
}

export const SpectrumWidgetState = {
  encode(message: SpectrumWidgetState, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.panX !== 0) {
      writer.uint32(13).float(message.panX);
    }
    if (message.panY !== 0) {
      writer.uint32(21).float(message.panY);
    }
    if (message.zoomX !== 0) {
      writer.uint32(29).float(message.zoomX);
    }
    if (message.zoomY !== 0) {
      writer.uint32(37).float(message.zoomY);
    }
    for (const v of message.spectrumLines) {
      SpectrumLines.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.logScale === true) {
      writer.uint32(48).bool(message.logScale);
    }
    if (message.showXAsEnergy === true) {
      writer.uint32(64).bool(message.showXAsEnergy);
    }
    if (message.yCountsPerMin === true) {
      writer.uint32(80).bool(message.yCountsPerMin);
    }
    if (message.yCountsPerPMC === true) {
      writer.uint32(88).bool(message.yCountsPerPMC);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SpectrumWidgetState {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSpectrumWidgetState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break;
          }

          message.panX = reader.float();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.panY = reader.float();
          continue;
        case 3:
          if (tag !== 29) {
            break;
          }

          message.zoomX = reader.float();
          continue;
        case 4:
          if (tag !== 37) {
            break;
          }

          message.zoomY = reader.float();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.spectrumLines.push(SpectrumLines.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.logScale = reader.bool();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.showXAsEnergy = reader.bool();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.yCountsPerMin = reader.bool();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.yCountsPerPMC = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SpectrumWidgetState {
    return {
      panX: isSet(object.panX) ? Number(object.panX) : 0,
      panY: isSet(object.panY) ? Number(object.panY) : 0,
      zoomX: isSet(object.zoomX) ? Number(object.zoomX) : 0,
      zoomY: isSet(object.zoomY) ? Number(object.zoomY) : 0,
      spectrumLines: Array.isArray(object?.spectrumLines)
        ? object.spectrumLines.map((e: any) => SpectrumLines.fromJSON(e))
        : [],
      logScale: isSet(object.logScale) ? Boolean(object.logScale) : false,
      showXAsEnergy: isSet(object.showXAsEnergy) ? Boolean(object.showXAsEnergy) : false,
      yCountsPerMin: isSet(object.yCountsPerMin) ? Boolean(object.yCountsPerMin) : false,
      yCountsPerPMC: isSet(object.yCountsPerPMC) ? Boolean(object.yCountsPerPMC) : false,
    };
  },

  toJSON(message: SpectrumWidgetState): unknown {
    const obj: any = {};
    message.panX !== undefined && (obj.panX = message.panX);
    message.panY !== undefined && (obj.panY = message.panY);
    message.zoomX !== undefined && (obj.zoomX = message.zoomX);
    message.zoomY !== undefined && (obj.zoomY = message.zoomY);
    if (message.spectrumLines) {
      obj.spectrumLines = message.spectrumLines.map((e) => e ? SpectrumLines.toJSON(e) : undefined);
    } else {
      obj.spectrumLines = [];
    }
    message.logScale !== undefined && (obj.logScale = message.logScale);
    message.showXAsEnergy !== undefined && (obj.showXAsEnergy = message.showXAsEnergy);
    message.yCountsPerMin !== undefined && (obj.yCountsPerMin = message.yCountsPerMin);
    message.yCountsPerPMC !== undefined && (obj.yCountsPerPMC = message.yCountsPerPMC);
    return obj;
  },

  create<I extends Exact<DeepPartial<SpectrumWidgetState>, I>>(base?: I): SpectrumWidgetState {
    return SpectrumWidgetState.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SpectrumWidgetState>, I>>(object: I): SpectrumWidgetState {
    const message = createBaseSpectrumWidgetState();
    message.panX = object.panX ?? 0;
    message.panY = object.panY ?? 0;
    message.zoomX = object.zoomX ?? 0;
    message.zoomY = object.zoomY ?? 0;
    message.spectrumLines = object.spectrumLines?.map((e) => SpectrumLines.fromPartial(e)) || [];
    message.logScale = object.logScale ?? false;
    message.showXAsEnergy = object.showXAsEnergy ?? false;
    message.yCountsPerMin = object.yCountsPerMin ?? false;
    message.yCountsPerPMC = object.yCountsPerPMC ?? false;
    return message;
  },
};

function createBaseHistogramState(): HistogramState {
  return { showStdDeviation: false, logScale: false, expressionIDs: [], visibleROIs: [], showWhiskers: false };
}

export const HistogramState = {
  encode(message: HistogramState, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.showStdDeviation === true) {
      writer.uint32(8).bool(message.showStdDeviation);
    }
    if (message.logScale === true) {
      writer.uint32(16).bool(message.logScale);
    }
    for (const v of message.expressionIDs) {
      writer.uint32(26).string(v!);
    }
    for (const v of message.visibleROIs) {
      VisibleROI.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    if (message.showWhiskers === true) {
      writer.uint32(40).bool(message.showWhiskers);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HistogramState {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHistogramState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.showStdDeviation = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.logScale = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.expressionIDs.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.visibleROIs.push(VisibleROI.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.showWhiskers = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HistogramState {
    return {
      showStdDeviation: isSet(object.showStdDeviation) ? Boolean(object.showStdDeviation) : false,
      logScale: isSet(object.logScale) ? Boolean(object.logScale) : false,
      expressionIDs: Array.isArray(object?.expressionIDs) ? object.expressionIDs.map((e: any) => String(e)) : [],
      visibleROIs: Array.isArray(object?.visibleROIs) ? object.visibleROIs.map((e: any) => VisibleROI.fromJSON(e)) : [],
      showWhiskers: isSet(object.showWhiskers) ? Boolean(object.showWhiskers) : false,
    };
  },

  toJSON(message: HistogramState): unknown {
    const obj: any = {};
    message.showStdDeviation !== undefined && (obj.showStdDeviation = message.showStdDeviation);
    message.logScale !== undefined && (obj.logScale = message.logScale);
    if (message.expressionIDs) {
      obj.expressionIDs = message.expressionIDs.map((e) => e);
    } else {
      obj.expressionIDs = [];
    }
    if (message.visibleROIs) {
      obj.visibleROIs = message.visibleROIs.map((e) => e ? VisibleROI.toJSON(e) : undefined);
    } else {
      obj.visibleROIs = [];
    }
    message.showWhiskers !== undefined && (obj.showWhiskers = message.showWhiskers);
    return obj;
  },

  create<I extends Exact<DeepPartial<HistogramState>, I>>(base?: I): HistogramState {
    return HistogramState.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<HistogramState>, I>>(object: I): HistogramState {
    const message = createBaseHistogramState();
    message.showStdDeviation = object.showStdDeviation ?? false;
    message.logScale = object.logScale ?? false;
    message.expressionIDs = object.expressionIDs?.map((e) => e) || [];
    message.visibleROIs = object.visibleROIs?.map((e) => VisibleROI.fromPartial(e)) || [];
    message.showWhiskers = object.showWhiskers ?? false;
    return message;
  },
};

function createBaseChordState(): ChordState {
  return { showForSelection: false, expressionIDs: [], displayROI: "", threshold: 0, drawMode: "" };
}

export const ChordState = {
  encode(message: ChordState, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.showForSelection === true) {
      writer.uint32(8).bool(message.showForSelection);
    }
    for (const v of message.expressionIDs) {
      writer.uint32(18).string(v!);
    }
    if (message.displayROI !== "") {
      writer.uint32(26).string(message.displayROI);
    }
    if (message.threshold !== 0) {
      writer.uint32(37).float(message.threshold);
    }
    if (message.drawMode !== "") {
      writer.uint32(42).string(message.drawMode);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChordState {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChordState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.showForSelection = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.expressionIDs.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.displayROI = reader.string();
          continue;
        case 4:
          if (tag !== 37) {
            break;
          }

          message.threshold = reader.float();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.drawMode = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChordState {
    return {
      showForSelection: isSet(object.showForSelection) ? Boolean(object.showForSelection) : false,
      expressionIDs: Array.isArray(object?.expressionIDs) ? object.expressionIDs.map((e: any) => String(e)) : [],
      displayROI: isSet(object.displayROI) ? String(object.displayROI) : "",
      threshold: isSet(object.threshold) ? Number(object.threshold) : 0,
      drawMode: isSet(object.drawMode) ? String(object.drawMode) : "",
    };
  },

  toJSON(message: ChordState): unknown {
    const obj: any = {};
    message.showForSelection !== undefined && (obj.showForSelection = message.showForSelection);
    if (message.expressionIDs) {
      obj.expressionIDs = message.expressionIDs.map((e) => e);
    } else {
      obj.expressionIDs = [];
    }
    message.displayROI !== undefined && (obj.displayROI = message.displayROI);
    message.threshold !== undefined && (obj.threshold = message.threshold);
    message.drawMode !== undefined && (obj.drawMode = message.drawMode);
    return obj;
  },

  create<I extends Exact<DeepPartial<ChordState>, I>>(base?: I): ChordState {
    return ChordState.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ChordState>, I>>(object: I): ChordState {
    const message = createBaseChordState();
    message.showForSelection = object.showForSelection ?? false;
    message.expressionIDs = object.expressionIDs?.map((e) => e) || [];
    message.displayROI = object.displayROI ?? "";
    message.threshold = object.threshold ?? 0;
    message.drawMode = object.drawMode ?? "";
    return message;
  },
};

function createBaseBinaryState(): BinaryState {
  return { showMmol: false, expressionIDs: [], visibleROIs: [] };
}

export const BinaryState = {
  encode(message: BinaryState, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.showMmol === true) {
      writer.uint32(8).bool(message.showMmol);
    }
    for (const v of message.expressionIDs) {
      writer.uint32(18).string(v!);
    }
    for (const v of message.visibleROIs) {
      VisibleROI.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BinaryState {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBinaryState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.showMmol = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.expressionIDs.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.visibleROIs.push(VisibleROI.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BinaryState {
    return {
      showMmol: isSet(object.showMmol) ? Boolean(object.showMmol) : false,
      expressionIDs: Array.isArray(object?.expressionIDs) ? object.expressionIDs.map((e: any) => String(e)) : [],
      visibleROIs: Array.isArray(object?.visibleROIs) ? object.visibleROIs.map((e: any) => VisibleROI.fromJSON(e)) : [],
    };
  },

  toJSON(message: BinaryState): unknown {
    const obj: any = {};
    message.showMmol !== undefined && (obj.showMmol = message.showMmol);
    if (message.expressionIDs) {
      obj.expressionIDs = message.expressionIDs.map((e) => e);
    } else {
      obj.expressionIDs = [];
    }
    if (message.visibleROIs) {
      obj.visibleROIs = message.visibleROIs.map((e) => e ? VisibleROI.toJSON(e) : undefined);
    } else {
      obj.visibleROIs = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BinaryState>, I>>(base?: I): BinaryState {
    return BinaryState.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<BinaryState>, I>>(object: I): BinaryState {
    const message = createBaseBinaryState();
    message.showMmol = object.showMmol ?? false;
    message.expressionIDs = object.expressionIDs?.map((e) => e) || [];
    message.visibleROIs = object.visibleROIs?.map((e) => VisibleROI.fromPartial(e)) || [];
    return message;
  },
};

function createBaseTernaryState(): TernaryState {
  return { showMmol: false, expressionIDs: [], visibleROIs: [] };
}

export const TernaryState = {
  encode(message: TernaryState, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.showMmol === true) {
      writer.uint32(8).bool(message.showMmol);
    }
    for (const v of message.expressionIDs) {
      writer.uint32(18).string(v!);
    }
    for (const v of message.visibleROIs) {
      VisibleROI.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TernaryState {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTernaryState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.showMmol = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.expressionIDs.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.visibleROIs.push(VisibleROI.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TernaryState {
    return {
      showMmol: isSet(object.showMmol) ? Boolean(object.showMmol) : false,
      expressionIDs: Array.isArray(object?.expressionIDs) ? object.expressionIDs.map((e: any) => String(e)) : [],
      visibleROIs: Array.isArray(object?.visibleROIs) ? object.visibleROIs.map((e: any) => VisibleROI.fromJSON(e)) : [],
    };
  },

  toJSON(message: TernaryState): unknown {
    const obj: any = {};
    message.showMmol !== undefined && (obj.showMmol = message.showMmol);
    if (message.expressionIDs) {
      obj.expressionIDs = message.expressionIDs.map((e) => e);
    } else {
      obj.expressionIDs = [];
    }
    if (message.visibleROIs) {
      obj.visibleROIs = message.visibleROIs.map((e) => e ? VisibleROI.toJSON(e) : undefined);
    } else {
      obj.visibleROIs = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TernaryState>, I>>(base?: I): TernaryState {
    return TernaryState.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TernaryState>, I>>(object: I): TernaryState {
    const message = createBaseTernaryState();
    message.showMmol = object.showMmol ?? false;
    message.expressionIDs = object.expressionIDs?.map((e) => e) || [];
    message.visibleROIs = object.visibleROIs?.map((e) => VisibleROI.fromPartial(e)) || [];
    return message;
  },
};

function createBaseVisibleROIAndQuant(): VisibleROIAndQuant {
  return { id: "", scanId: "", quantId: "" };
}

export const VisibleROIAndQuant = {
  encode(message: VisibleROIAndQuant, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.scanId !== "") {
      writer.uint32(18).string(message.scanId);
    }
    if (message.quantId !== "") {
      writer.uint32(26).string(message.quantId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VisibleROIAndQuant {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVisibleROIAndQuant();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.scanId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.quantId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VisibleROIAndQuant {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      scanId: isSet(object.scanId) ? String(object.scanId) : "",
      quantId: isSet(object.quantId) ? String(object.quantId) : "",
    };
  },

  toJSON(message: VisibleROIAndQuant): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.scanId !== undefined && (obj.scanId = message.scanId);
    message.quantId !== undefined && (obj.quantId = message.quantId);
    return obj;
  },

  create<I extends Exact<DeepPartial<VisibleROIAndQuant>, I>>(base?: I): VisibleROIAndQuant {
    return VisibleROIAndQuant.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<VisibleROIAndQuant>, I>>(object: I): VisibleROIAndQuant {
    const message = createBaseVisibleROIAndQuant();
    message.id = object.id ?? "";
    message.scanId = object.scanId ?? "";
    message.quantId = object.quantId ?? "";
    return message;
  },
};

function createBaseTableState(): TableState {
  return { showPureElements: false, order: "", visibleROIs: [] };
}

export const TableState = {
  encode(message: TableState, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.showPureElements === true) {
      writer.uint32(8).bool(message.showPureElements);
    }
    if (message.order !== "") {
      writer.uint32(18).string(message.order);
    }
    for (const v of message.visibleROIs) {
      VisibleROIAndQuant.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TableState {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTableState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.showPureElements = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.order = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.visibleROIs.push(VisibleROIAndQuant.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TableState {
    return {
      showPureElements: isSet(object.showPureElements) ? Boolean(object.showPureElements) : false,
      order: isSet(object.order) ? String(object.order) : "",
      visibleROIs: Array.isArray(object?.visibleROIs)
        ? object.visibleROIs.map((e: any) => VisibleROIAndQuant.fromJSON(e))
        : [],
    };
  },

  toJSON(message: TableState): unknown {
    const obj: any = {};
    message.showPureElements !== undefined && (obj.showPureElements = message.showPureElements);
    message.order !== undefined && (obj.order = message.order);
    if (message.visibleROIs) {
      obj.visibleROIs = message.visibleROIs.map((e) => e ? VisibleROIAndQuant.toJSON(e) : undefined);
    } else {
      obj.visibleROIs = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TableState>, I>>(base?: I): TableState {
    return TableState.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TableState>, I>>(object: I): TableState {
    const message = createBaseTableState();
    message.showPureElements = object.showPureElements ?? false;
    message.order = object.order ?? "";
    message.visibleROIs = object.visibleROIs?.map((e) => VisibleROIAndQuant.fromPartial(e)) || [];
    return message;
  },
};

function createBaseROIQuantTableState(): ROIQuantTableState {
  return { roi: "", quantIDs: [] };
}

export const ROIQuantTableState = {
  encode(message: ROIQuantTableState, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.roi !== "") {
      writer.uint32(10).string(message.roi);
    }
    for (const v of message.quantIDs) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ROIQuantTableState {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseROIQuantTableState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.roi = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.quantIDs.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ROIQuantTableState {
    return {
      roi: isSet(object.roi) ? String(object.roi) : "",
      quantIDs: Array.isArray(object?.quantIDs) ? object.quantIDs.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: ROIQuantTableState): unknown {
    const obj: any = {};
    message.roi !== undefined && (obj.roi = message.roi);
    if (message.quantIDs) {
      obj.quantIDs = message.quantIDs.map((e) => e);
    } else {
      obj.quantIDs = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ROIQuantTableState>, I>>(base?: I): ROIQuantTableState {
    return ROIQuantTableState.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ROIQuantTableState>, I>>(object: I): ROIQuantTableState {
    const message = createBaseROIQuantTableState();
    message.roi = object.roi ?? "";
    message.quantIDs = object.quantIDs?.map((e) => e) || [];
    return message;
  },
};

function createBaseVariogramState(): VariogramState {
  return {
    expressionIDs: [],
    visibleROIs: [],
    varioModel: "",
    maxDistance: 0,
    binCount: 0,
    drawModeVector: false,
    comparisonAlgorithms: [],
    liveUpdate: false,
    distanceSliderMin: 0,
    distanceSliderMax: 0,
    binSliderMin: 0,
    binSliderMax: 0,
    drawBestFit: false,
  };
}

export const VariogramState = {
  encode(message: VariogramState, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.expressionIDs) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.visibleROIs) {
      VisibleROI.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.varioModel !== "") {
      writer.uint32(26).string(message.varioModel);
    }
    if (message.maxDistance !== 0) {
      writer.uint32(37).float(message.maxDistance);
    }
    if (message.binCount !== 0) {
      writer.uint32(40).int32(message.binCount);
    }
    if (message.drawModeVector === true) {
      writer.uint32(48).bool(message.drawModeVector);
    }
    for (const v of message.comparisonAlgorithms) {
      writer.uint32(58).string(v!);
    }
    if (message.liveUpdate === true) {
      writer.uint32(64).bool(message.liveUpdate);
    }
    if (message.distanceSliderMin !== 0) {
      writer.uint32(77).float(message.distanceSliderMin);
    }
    if (message.distanceSliderMax !== 0) {
      writer.uint32(85).float(message.distanceSliderMax);
    }
    if (message.binSliderMin !== 0) {
      writer.uint32(93).float(message.binSliderMin);
    }
    if (message.binSliderMax !== 0) {
      writer.uint32(101).float(message.binSliderMax);
    }
    if (message.drawBestFit === true) {
      writer.uint32(104).bool(message.drawBestFit);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VariogramState {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVariogramState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.expressionIDs.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.visibleROIs.push(VisibleROI.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.varioModel = reader.string();
          continue;
        case 4:
          if (tag !== 37) {
            break;
          }

          message.maxDistance = reader.float();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.binCount = reader.int32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.drawModeVector = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.comparisonAlgorithms.push(reader.string());
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.liveUpdate = reader.bool();
          continue;
        case 9:
          if (tag !== 77) {
            break;
          }

          message.distanceSliderMin = reader.float();
          continue;
        case 10:
          if (tag !== 85) {
            break;
          }

          message.distanceSliderMax = reader.float();
          continue;
        case 11:
          if (tag !== 93) {
            break;
          }

          message.binSliderMin = reader.float();
          continue;
        case 12:
          if (tag !== 101) {
            break;
          }

          message.binSliderMax = reader.float();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.drawBestFit = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VariogramState {
    return {
      expressionIDs: Array.isArray(object?.expressionIDs) ? object.expressionIDs.map((e: any) => String(e)) : [],
      visibleROIs: Array.isArray(object?.visibleROIs) ? object.visibleROIs.map((e: any) => VisibleROI.fromJSON(e)) : [],
      varioModel: isSet(object.varioModel) ? String(object.varioModel) : "",
      maxDistance: isSet(object.maxDistance) ? Number(object.maxDistance) : 0,
      binCount: isSet(object.binCount) ? Number(object.binCount) : 0,
      drawModeVector: isSet(object.drawModeVector) ? Boolean(object.drawModeVector) : false,
      comparisonAlgorithms: Array.isArray(object?.comparisonAlgorithms)
        ? object.comparisonAlgorithms.map((e: any) => String(e))
        : [],
      liveUpdate: isSet(object.liveUpdate) ? Boolean(object.liveUpdate) : false,
      distanceSliderMin: isSet(object.distanceSliderMin) ? Number(object.distanceSliderMin) : 0,
      distanceSliderMax: isSet(object.distanceSliderMax) ? Number(object.distanceSliderMax) : 0,
      binSliderMin: isSet(object.binSliderMin) ? Number(object.binSliderMin) : 0,
      binSliderMax: isSet(object.binSliderMax) ? Number(object.binSliderMax) : 0,
      drawBestFit: isSet(object.drawBestFit) ? Boolean(object.drawBestFit) : false,
    };
  },

  toJSON(message: VariogramState): unknown {
    const obj: any = {};
    if (message.expressionIDs) {
      obj.expressionIDs = message.expressionIDs.map((e) => e);
    } else {
      obj.expressionIDs = [];
    }
    if (message.visibleROIs) {
      obj.visibleROIs = message.visibleROIs.map((e) => e ? VisibleROI.toJSON(e) : undefined);
    } else {
      obj.visibleROIs = [];
    }
    message.varioModel !== undefined && (obj.varioModel = message.varioModel);
    message.maxDistance !== undefined && (obj.maxDistance = message.maxDistance);
    message.binCount !== undefined && (obj.binCount = Math.round(message.binCount));
    message.drawModeVector !== undefined && (obj.drawModeVector = message.drawModeVector);
    if (message.comparisonAlgorithms) {
      obj.comparisonAlgorithms = message.comparisonAlgorithms.map((e) => e);
    } else {
      obj.comparisonAlgorithms = [];
    }
    message.liveUpdate !== undefined && (obj.liveUpdate = message.liveUpdate);
    message.distanceSliderMin !== undefined && (obj.distanceSliderMin = message.distanceSliderMin);
    message.distanceSliderMax !== undefined && (obj.distanceSliderMax = message.distanceSliderMax);
    message.binSliderMin !== undefined && (obj.binSliderMin = message.binSliderMin);
    message.binSliderMax !== undefined && (obj.binSliderMax = message.binSliderMax);
    message.drawBestFit !== undefined && (obj.drawBestFit = message.drawBestFit);
    return obj;
  },

  create<I extends Exact<DeepPartial<VariogramState>, I>>(base?: I): VariogramState {
    return VariogramState.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<VariogramState>, I>>(object: I): VariogramState {
    const message = createBaseVariogramState();
    message.expressionIDs = object.expressionIDs?.map((e) => e) || [];
    message.visibleROIs = object.visibleROIs?.map((e) => VisibleROI.fromPartial(e)) || [];
    message.varioModel = object.varioModel ?? "";
    message.maxDistance = object.maxDistance ?? 0;
    message.binCount = object.binCount ?? 0;
    message.drawModeVector = object.drawModeVector ?? false;
    message.comparisonAlgorithms = object.comparisonAlgorithms?.map((e) => e) || [];
    message.liveUpdate = object.liveUpdate ?? false;
    message.distanceSliderMin = object.distanceSliderMin ?? 0;
    message.distanceSliderMax = object.distanceSliderMax ?? 0;
    message.binSliderMin = object.binSliderMin ?? 0;
    message.binSliderMax = object.binSliderMax ?? 0;
    message.drawBestFit = object.drawBestFit ?? false;
    return message;
  },
};

function createBaseMapLayerVisibility(): MapLayerVisibility {
  return {
    expressionID: "",
    opacity: 0,
    visible: false,
    displayValueRangeMin: 0,
    displayValueRangeMax: 0,
    displayValueShading: "",
  };
}

export const MapLayerVisibility = {
  encode(message: MapLayerVisibility, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.expressionID !== "") {
      writer.uint32(10).string(message.expressionID);
    }
    if (message.opacity !== 0) {
      writer.uint32(21).float(message.opacity);
    }
    if (message.visible === true) {
      writer.uint32(24).bool(message.visible);
    }
    if (message.displayValueRangeMin !== 0) {
      writer.uint32(37).float(message.displayValueRangeMin);
    }
    if (message.displayValueRangeMax !== 0) {
      writer.uint32(45).float(message.displayValueRangeMax);
    }
    if (message.displayValueShading !== "") {
      writer.uint32(50).string(message.displayValueShading);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MapLayerVisibility {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMapLayerVisibility();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.expressionID = reader.string();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.opacity = reader.float();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.visible = reader.bool();
          continue;
        case 4:
          if (tag !== 37) {
            break;
          }

          message.displayValueRangeMin = reader.float();
          continue;
        case 5:
          if (tag !== 45) {
            break;
          }

          message.displayValueRangeMax = reader.float();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.displayValueShading = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MapLayerVisibility {
    return {
      expressionID: isSet(object.expressionID) ? String(object.expressionID) : "",
      opacity: isSet(object.opacity) ? Number(object.opacity) : 0,
      visible: isSet(object.visible) ? Boolean(object.visible) : false,
      displayValueRangeMin: isSet(object.displayValueRangeMin) ? Number(object.displayValueRangeMin) : 0,
      displayValueRangeMax: isSet(object.displayValueRangeMax) ? Number(object.displayValueRangeMax) : 0,
      displayValueShading: isSet(object.displayValueShading) ? String(object.displayValueShading) : "",
    };
  },

  toJSON(message: MapLayerVisibility): unknown {
    const obj: any = {};
    message.expressionID !== undefined && (obj.expressionID = message.expressionID);
    message.opacity !== undefined && (obj.opacity = message.opacity);
    message.visible !== undefined && (obj.visible = message.visible);
    message.displayValueRangeMin !== undefined && (obj.displayValueRangeMin = message.displayValueRangeMin);
    message.displayValueRangeMax !== undefined && (obj.displayValueRangeMax = message.displayValueRangeMax);
    message.displayValueShading !== undefined && (obj.displayValueShading = message.displayValueShading);
    return obj;
  },

  create<I extends Exact<DeepPartial<MapLayerVisibility>, I>>(base?: I): MapLayerVisibility {
    return MapLayerVisibility.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MapLayerVisibility>, I>>(object: I): MapLayerVisibility {
    const message = createBaseMapLayerVisibility();
    message.expressionID = object.expressionID ?? "";
    message.opacity = object.opacity ?? 0;
    message.visible = object.visible ?? false;
    message.displayValueRangeMin = object.displayValueRangeMin ?? 0;
    message.displayValueRangeMax = object.displayValueRangeMax ?? 0;
    message.displayValueShading = object.displayValueShading ?? "";
    return message;
  },
};

function createBaseROILayerVisibility(): ROILayerVisibility {
  return { id: "", opacity: 0, visible: false, scanId: "" };
}

export const ROILayerVisibility = {
  encode(message: ROILayerVisibility, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.opacity !== 0) {
      writer.uint32(21).float(message.opacity);
    }
    if (message.visible === true) {
      writer.uint32(24).bool(message.visible);
    }
    if (message.scanId !== "") {
      writer.uint32(34).string(message.scanId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ROILayerVisibility {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseROILayerVisibility();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.opacity = reader.float();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.visible = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.scanId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ROILayerVisibility {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      opacity: isSet(object.opacity) ? Number(object.opacity) : 0,
      visible: isSet(object.visible) ? Boolean(object.visible) : false,
      scanId: isSet(object.scanId) ? String(object.scanId) : "",
    };
  },

  toJSON(message: ROILayerVisibility): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.opacity !== undefined && (obj.opacity = message.opacity);
    message.visible !== undefined && (obj.visible = message.visible);
    message.scanId !== undefined && (obj.scanId = message.scanId);
    return obj;
  },

  create<I extends Exact<DeepPartial<ROILayerVisibility>, I>>(base?: I): ROILayerVisibility {
    return ROILayerVisibility.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ROILayerVisibility>, I>>(object: I): ROILayerVisibility {
    const message = createBaseROILayerVisibility();
    message.id = object.id ?? "";
    message.opacity = object.opacity ?? 0;
    message.visible = object.visible ?? false;
    message.scanId = object.scanId ?? "";
    return message;
  },
};

function createBaseContextImageState(): ContextImageState {
  return {
    panX: 0,
    panY: 0,
    zoomX: 0,
    zoomY: 0,
    showPoints: false,
    showPointBBox: false,
    pointColourScheme: "",
    pointBBoxColourScheme: "",
    contextImage: "",
    contextImageSmoothing: "",
    mapLayers: [],
    roiLayers: [],
    elementRelativeShading: false,
    brightness: 0,
    rgbuChannels: "",
    unselectedOpacity: 0,
    unselectedGrayscale: false,
    colourRatioMin: 0,
    colourRatioMax: 0,
    removeTopSpecularArtifacts: false,
    removeBottomSpecularArtifacts: false,
    hideFootprintsForScans: [],
    hidePointsForScans: [],
    unlinkFromDataset: false,
    hideImage: false,
  };
}

export const ContextImageState = {
  encode(message: ContextImageState, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.panX !== 0) {
      writer.uint32(13).float(message.panX);
    }
    if (message.panY !== 0) {
      writer.uint32(21).float(message.panY);
    }
    if (message.zoomX !== 0) {
      writer.uint32(29).float(message.zoomX);
    }
    if (message.zoomY !== 0) {
      writer.uint32(37).float(message.zoomY);
    }
    if (message.showPoints === true) {
      writer.uint32(40).bool(message.showPoints);
    }
    if (message.showPointBBox === true) {
      writer.uint32(48).bool(message.showPointBBox);
    }
    if (message.pointColourScheme !== "") {
      writer.uint32(58).string(message.pointColourScheme);
    }
    if (message.pointBBoxColourScheme !== "") {
      writer.uint32(66).string(message.pointBBoxColourScheme);
    }
    if (message.contextImage !== "") {
      writer.uint32(74).string(message.contextImage);
    }
    if (message.contextImageSmoothing !== "") {
      writer.uint32(82).string(message.contextImageSmoothing);
    }
    for (const v of message.mapLayers) {
      MapLayerVisibility.encode(v!, writer.uint32(90).fork()).ldelim();
    }
    for (const v of message.roiLayers) {
      ROILayerVisibility.encode(v!, writer.uint32(98).fork()).ldelim();
    }
    if (message.elementRelativeShading === true) {
      writer.uint32(104).bool(message.elementRelativeShading);
    }
    if (message.brightness !== 0) {
      writer.uint32(117).float(message.brightness);
    }
    if (message.rgbuChannels !== "") {
      writer.uint32(122).string(message.rgbuChannels);
    }
    if (message.unselectedOpacity !== 0) {
      writer.uint32(133).float(message.unselectedOpacity);
    }
    if (message.unselectedGrayscale === true) {
      writer.uint32(136).bool(message.unselectedGrayscale);
    }
    if (message.colourRatioMin !== 0) {
      writer.uint32(149).float(message.colourRatioMin);
    }
    if (message.colourRatioMax !== 0) {
      writer.uint32(157).float(message.colourRatioMax);
    }
    if (message.removeTopSpecularArtifacts === true) {
      writer.uint32(160).bool(message.removeTopSpecularArtifacts);
    }
    if (message.removeBottomSpecularArtifacts === true) {
      writer.uint32(168).bool(message.removeBottomSpecularArtifacts);
    }
    for (const v of message.hideFootprintsForScans) {
      writer.uint32(178).string(v!);
    }
    for (const v of message.hidePointsForScans) {
      writer.uint32(186).string(v!);
    }
    if (message.unlinkFromDataset === true) {
      writer.uint32(200).bool(message.unlinkFromDataset);
    }
    if (message.hideImage === true) {
      writer.uint32(208).bool(message.hideImage);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ContextImageState {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseContextImageState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break;
          }

          message.panX = reader.float();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.panY = reader.float();
          continue;
        case 3:
          if (tag !== 29) {
            break;
          }

          message.zoomX = reader.float();
          continue;
        case 4:
          if (tag !== 37) {
            break;
          }

          message.zoomY = reader.float();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.showPoints = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.showPointBBox = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.pointColourScheme = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.pointBBoxColourScheme = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.contextImage = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.contextImageSmoothing = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.mapLayers.push(MapLayerVisibility.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.roiLayers.push(ROILayerVisibility.decode(reader, reader.uint32()));
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.elementRelativeShading = reader.bool();
          continue;
        case 14:
          if (tag !== 117) {
            break;
          }

          message.brightness = reader.float();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.rgbuChannels = reader.string();
          continue;
        case 16:
          if (tag !== 133) {
            break;
          }

          message.unselectedOpacity = reader.float();
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.unselectedGrayscale = reader.bool();
          continue;
        case 18:
          if (tag !== 149) {
            break;
          }

          message.colourRatioMin = reader.float();
          continue;
        case 19:
          if (tag !== 157) {
            break;
          }

          message.colourRatioMax = reader.float();
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.removeTopSpecularArtifacts = reader.bool();
          continue;
        case 21:
          if (tag !== 168) {
            break;
          }

          message.removeBottomSpecularArtifacts = reader.bool();
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.hideFootprintsForScans.push(reader.string());
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.hidePointsForScans.push(reader.string());
          continue;
        case 25:
          if (tag !== 200) {
            break;
          }

          message.unlinkFromDataset = reader.bool();
          continue;
        case 26:
          if (tag !== 208) {
            break;
          }

          message.hideImage = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ContextImageState {
    return {
      panX: isSet(object.panX) ? Number(object.panX) : 0,
      panY: isSet(object.panY) ? Number(object.panY) : 0,
      zoomX: isSet(object.zoomX) ? Number(object.zoomX) : 0,
      zoomY: isSet(object.zoomY) ? Number(object.zoomY) : 0,
      showPoints: isSet(object.showPoints) ? Boolean(object.showPoints) : false,
      showPointBBox: isSet(object.showPointBBox) ? Boolean(object.showPointBBox) : false,
      pointColourScheme: isSet(object.pointColourScheme) ? String(object.pointColourScheme) : "",
      pointBBoxColourScheme: isSet(object.pointBBoxColourScheme) ? String(object.pointBBoxColourScheme) : "",
      contextImage: isSet(object.contextImage) ? String(object.contextImage) : "",
      contextImageSmoothing: isSet(object.contextImageSmoothing) ? String(object.contextImageSmoothing) : "",
      mapLayers: Array.isArray(object?.mapLayers)
        ? object.mapLayers.map((e: any) => MapLayerVisibility.fromJSON(e))
        : [],
      roiLayers: Array.isArray(object?.roiLayers)
        ? object.roiLayers.map((e: any) => ROILayerVisibility.fromJSON(e))
        : [],
      elementRelativeShading: isSet(object.elementRelativeShading) ? Boolean(object.elementRelativeShading) : false,
      brightness: isSet(object.brightness) ? Number(object.brightness) : 0,
      rgbuChannels: isSet(object.rgbuChannels) ? String(object.rgbuChannels) : "",
      unselectedOpacity: isSet(object.unselectedOpacity) ? Number(object.unselectedOpacity) : 0,
      unselectedGrayscale: isSet(object.unselectedGrayscale) ? Boolean(object.unselectedGrayscale) : false,
      colourRatioMin: isSet(object.colourRatioMin) ? Number(object.colourRatioMin) : 0,
      colourRatioMax: isSet(object.colourRatioMax) ? Number(object.colourRatioMax) : 0,
      removeTopSpecularArtifacts: isSet(object.removeTopSpecularArtifacts)
        ? Boolean(object.removeTopSpecularArtifacts)
        : false,
      removeBottomSpecularArtifacts: isSet(object.removeBottomSpecularArtifacts)
        ? Boolean(object.removeBottomSpecularArtifacts)
        : false,
      hideFootprintsForScans: Array.isArray(object?.hideFootprintsForScans)
        ? object.hideFootprintsForScans.map((e: any) => String(e))
        : [],
      hidePointsForScans: Array.isArray(object?.hidePointsForScans)
        ? object.hidePointsForScans.map((e: any) => String(e))
        : [],
      unlinkFromDataset: isSet(object.unlinkFromDataset) ? Boolean(object.unlinkFromDataset) : false,
      hideImage: isSet(object.hideImage) ? Boolean(object.hideImage) : false,
    };
  },

  toJSON(message: ContextImageState): unknown {
    const obj: any = {};
    message.panX !== undefined && (obj.panX = message.panX);
    message.panY !== undefined && (obj.panY = message.panY);
    message.zoomX !== undefined && (obj.zoomX = message.zoomX);
    message.zoomY !== undefined && (obj.zoomY = message.zoomY);
    message.showPoints !== undefined && (obj.showPoints = message.showPoints);
    message.showPointBBox !== undefined && (obj.showPointBBox = message.showPointBBox);
    message.pointColourScheme !== undefined && (obj.pointColourScheme = message.pointColourScheme);
    message.pointBBoxColourScheme !== undefined && (obj.pointBBoxColourScheme = message.pointBBoxColourScheme);
    message.contextImage !== undefined && (obj.contextImage = message.contextImage);
    message.contextImageSmoothing !== undefined && (obj.contextImageSmoothing = message.contextImageSmoothing);
    if (message.mapLayers) {
      obj.mapLayers = message.mapLayers.map((e) => e ? MapLayerVisibility.toJSON(e) : undefined);
    } else {
      obj.mapLayers = [];
    }
    if (message.roiLayers) {
      obj.roiLayers = message.roiLayers.map((e) => e ? ROILayerVisibility.toJSON(e) : undefined);
    } else {
      obj.roiLayers = [];
    }
    message.elementRelativeShading !== undefined && (obj.elementRelativeShading = message.elementRelativeShading);
    message.brightness !== undefined && (obj.brightness = message.brightness);
    message.rgbuChannels !== undefined && (obj.rgbuChannels = message.rgbuChannels);
    message.unselectedOpacity !== undefined && (obj.unselectedOpacity = message.unselectedOpacity);
    message.unselectedGrayscale !== undefined && (obj.unselectedGrayscale = message.unselectedGrayscale);
    message.colourRatioMin !== undefined && (obj.colourRatioMin = message.colourRatioMin);
    message.colourRatioMax !== undefined && (obj.colourRatioMax = message.colourRatioMax);
    message.removeTopSpecularArtifacts !== undefined &&
      (obj.removeTopSpecularArtifacts = message.removeTopSpecularArtifacts);
    message.removeBottomSpecularArtifacts !== undefined &&
      (obj.removeBottomSpecularArtifacts = message.removeBottomSpecularArtifacts);
    if (message.hideFootprintsForScans) {
      obj.hideFootprintsForScans = message.hideFootprintsForScans.map((e) => e);
    } else {
      obj.hideFootprintsForScans = [];
    }
    if (message.hidePointsForScans) {
      obj.hidePointsForScans = message.hidePointsForScans.map((e) => e);
    } else {
      obj.hidePointsForScans = [];
    }
    message.unlinkFromDataset !== undefined && (obj.unlinkFromDataset = message.unlinkFromDataset);
    message.hideImage !== undefined && (obj.hideImage = message.hideImage);
    return obj;
  },

  create<I extends Exact<DeepPartial<ContextImageState>, I>>(base?: I): ContextImageState {
    return ContextImageState.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ContextImageState>, I>>(object: I): ContextImageState {
    const message = createBaseContextImageState();
    message.panX = object.panX ?? 0;
    message.panY = object.panY ?? 0;
    message.zoomX = object.zoomX ?? 0;
    message.zoomY = object.zoomY ?? 0;
    message.showPoints = object.showPoints ?? false;
    message.showPointBBox = object.showPointBBox ?? false;
    message.pointColourScheme = object.pointColourScheme ?? "";
    message.pointBBoxColourScheme = object.pointBBoxColourScheme ?? "";
    message.contextImage = object.contextImage ?? "";
    message.contextImageSmoothing = object.contextImageSmoothing ?? "";
    message.mapLayers = object.mapLayers?.map((e) => MapLayerVisibility.fromPartial(e)) || [];
    message.roiLayers = object.roiLayers?.map((e) => ROILayerVisibility.fromPartial(e)) || [];
    message.elementRelativeShading = object.elementRelativeShading ?? false;
    message.brightness = object.brightness ?? 0;
    message.rgbuChannels = object.rgbuChannels ?? "";
    message.unselectedOpacity = object.unselectedOpacity ?? 0;
    message.unselectedGrayscale = object.unselectedGrayscale ?? false;
    message.colourRatioMin = object.colourRatioMin ?? 0;
    message.colourRatioMax = object.colourRatioMax ?? 0;
    message.removeTopSpecularArtifacts = object.removeTopSpecularArtifacts ?? false;
    message.removeBottomSpecularArtifacts = object.removeBottomSpecularArtifacts ?? false;
    message.hideFootprintsForScans = object.hideFootprintsForScans?.map((e) => e) || [];
    message.hidePointsForScans = object.hidePointsForScans?.map((e) => e) || [];
    message.unlinkFromDataset = object.unlinkFromDataset ?? false;
    message.hideImage = object.hideImage ?? false;
    return message;
  },
};

function createBaseAnnotationPoint(): AnnotationPoint {
  return { x: 0, y: 0, screenWidth: 0, screenHeight: 0 };
}

export const AnnotationPoint = {
  encode(message: AnnotationPoint, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.x !== 0) {
      writer.uint32(13).float(message.x);
    }
    if (message.y !== 0) {
      writer.uint32(21).float(message.y);
    }
    if (message.screenWidth !== 0) {
      writer.uint32(29).float(message.screenWidth);
    }
    if (message.screenHeight !== 0) {
      writer.uint32(37).float(message.screenHeight);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AnnotationPoint {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAnnotationPoint();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break;
          }

          message.x = reader.float();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.y = reader.float();
          continue;
        case 3:
          if (tag !== 29) {
            break;
          }

          message.screenWidth = reader.float();
          continue;
        case 4:
          if (tag !== 37) {
            break;
          }

          message.screenHeight = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AnnotationPoint {
    return {
      x: isSet(object.x) ? Number(object.x) : 0,
      y: isSet(object.y) ? Number(object.y) : 0,
      screenWidth: isSet(object.screenWidth) ? Number(object.screenWidth) : 0,
      screenHeight: isSet(object.screenHeight) ? Number(object.screenHeight) : 0,
    };
  },

  toJSON(message: AnnotationPoint): unknown {
    const obj: any = {};
    message.x !== undefined && (obj.x = message.x);
    message.y !== undefined && (obj.y = message.y);
    message.screenWidth !== undefined && (obj.screenWidth = message.screenWidth);
    message.screenHeight !== undefined && (obj.screenHeight = message.screenHeight);
    return obj;
  },

  create<I extends Exact<DeepPartial<AnnotationPoint>, I>>(base?: I): AnnotationPoint {
    return AnnotationPoint.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AnnotationPoint>, I>>(object: I): AnnotationPoint {
    const message = createBaseAnnotationPoint();
    message.x = object.x ?? 0;
    message.y = object.y ?? 0;
    message.screenWidth = object.screenWidth ?? 0;
    message.screenHeight = object.screenHeight ?? 0;
    return message;
  },
};

function createBaseFullScreenAnnotationItem(): FullScreenAnnotationItem {
  return { type: "", points: [], colour: "", complete: false, text: "", fontSize: 0, id: 0 };
}

export const FullScreenAnnotationItem = {
  encode(message: FullScreenAnnotationItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== "") {
      writer.uint32(10).string(message.type);
    }
    for (const v of message.points) {
      AnnotationPoint.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.colour !== "") {
      writer.uint32(26).string(message.colour);
    }
    if (message.complete === true) {
      writer.uint32(32).bool(message.complete);
    }
    if (message.text !== "") {
      writer.uint32(42).string(message.text);
    }
    if (message.fontSize !== 0) {
      writer.uint32(48).int32(message.fontSize);
    }
    if (message.id !== 0) {
      writer.uint32(56).int32(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FullScreenAnnotationItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFullScreenAnnotationItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.type = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.points.push(AnnotationPoint.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.colour = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.complete = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.text = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.fontSize = reader.int32();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.id = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FullScreenAnnotationItem {
    return {
      type: isSet(object.type) ? String(object.type) : "",
      points: Array.isArray(object?.points) ? object.points.map((e: any) => AnnotationPoint.fromJSON(e)) : [],
      colour: isSet(object.colour) ? String(object.colour) : "",
      complete: isSet(object.complete) ? Boolean(object.complete) : false,
      text: isSet(object.text) ? String(object.text) : "",
      fontSize: isSet(object.fontSize) ? Number(object.fontSize) : 0,
      id: isSet(object.id) ? Number(object.id) : 0,
    };
  },

  toJSON(message: FullScreenAnnotationItem): unknown {
    const obj: any = {};
    message.type !== undefined && (obj.type = message.type);
    if (message.points) {
      obj.points = message.points.map((e) => e ? AnnotationPoint.toJSON(e) : undefined);
    } else {
      obj.points = [];
    }
    message.colour !== undefined && (obj.colour = message.colour);
    message.complete !== undefined && (obj.complete = message.complete);
    message.text !== undefined && (obj.text = message.text);
    message.fontSize !== undefined && (obj.fontSize = Math.round(message.fontSize));
    message.id !== undefined && (obj.id = Math.round(message.id));
    return obj;
  },

  create<I extends Exact<DeepPartial<FullScreenAnnotationItem>, I>>(base?: I): FullScreenAnnotationItem {
    return FullScreenAnnotationItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<FullScreenAnnotationItem>, I>>(object: I): FullScreenAnnotationItem {
    const message = createBaseFullScreenAnnotationItem();
    message.type = object.type ?? "";
    message.points = object.points?.map((e) => AnnotationPoint.fromPartial(e)) || [];
    message.colour = object.colour ?? "";
    message.complete = object.complete ?? false;
    message.text = object.text ?? "";
    message.fontSize = object.fontSize ?? 0;
    message.id = object.id ?? 0;
    return message;
  },
};

function createBaseAnnotationDisplayState(): AnnotationDisplayState {
  return { savedAnnotations: [] };
}

export const AnnotationDisplayState = {
  encode(message: AnnotationDisplayState, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.savedAnnotations) {
      FullScreenAnnotationItem.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AnnotationDisplayState {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAnnotationDisplayState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.savedAnnotations.push(FullScreenAnnotationItem.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AnnotationDisplayState {
    return {
      savedAnnotations: Array.isArray(object?.savedAnnotations)
        ? object.savedAnnotations.map((e: any) => FullScreenAnnotationItem.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AnnotationDisplayState): unknown {
    const obj: any = {};
    if (message.savedAnnotations) {
      obj.savedAnnotations = message.savedAnnotations.map((e) => e ? FullScreenAnnotationItem.toJSON(e) : undefined);
    } else {
      obj.savedAnnotations = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AnnotationDisplayState>, I>>(base?: I): AnnotationDisplayState {
    return AnnotationDisplayState.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AnnotationDisplayState>, I>>(object: I): AnnotationDisplayState {
    const message = createBaseAnnotationDisplayState();
    message.savedAnnotations = object.savedAnnotations?.map((e) => FullScreenAnnotationItem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseROIDisplayState(): ROIDisplayState {
  return { roiColours: {}, roiShapes: {} };
}

export const ROIDisplayState = {
  encode(message: ROIDisplayState, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.roiColours).forEach(([key, value]) => {
      ROIDisplayState_RoiColoursEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    Object.entries(message.roiShapes).forEach(([key, value]) => {
      ROIDisplayState_RoiShapesEntry.encode({ key: key as any, value }, writer.uint32(18).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ROIDisplayState {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseROIDisplayState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = ROIDisplayState_RoiColoursEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.roiColours[entry1.key] = entry1.value;
          }
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          const entry2 = ROIDisplayState_RoiShapesEntry.decode(reader, reader.uint32());
          if (entry2.value !== undefined) {
            message.roiShapes[entry2.key] = entry2.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ROIDisplayState {
    return {
      roiColours: isObject(object.roiColours)
        ? Object.entries(object.roiColours).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
      roiShapes: isObject(object.roiShapes)
        ? Object.entries(object.roiShapes).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: ROIDisplayState): unknown {
    const obj: any = {};
    obj.roiColours = {};
    if (message.roiColours) {
      Object.entries(message.roiColours).forEach(([k, v]) => {
        obj.roiColours[k] = v;
      });
    }
    obj.roiShapes = {};
    if (message.roiShapes) {
      Object.entries(message.roiShapes).forEach(([k, v]) => {
        obj.roiShapes[k] = v;
      });
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ROIDisplayState>, I>>(base?: I): ROIDisplayState {
    return ROIDisplayState.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ROIDisplayState>, I>>(object: I): ROIDisplayState {
    const message = createBaseROIDisplayState();
    message.roiColours = Object.entries(object.roiColours ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = String(value);
        }
        return acc;
      },
      {},
    );
    message.roiShapes = Object.entries(object.roiShapes ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = String(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseROIDisplayState_RoiColoursEntry(): ROIDisplayState_RoiColoursEntry {
  return { key: "", value: "" };
}

export const ROIDisplayState_RoiColoursEntry = {
  encode(message: ROIDisplayState_RoiColoursEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ROIDisplayState_RoiColoursEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseROIDisplayState_RoiColoursEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ROIDisplayState_RoiColoursEntry {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? String(object.value) : "" };
  },

  toJSON(message: ROIDisplayState_RoiColoursEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create<I extends Exact<DeepPartial<ROIDisplayState_RoiColoursEntry>, I>>(base?: I): ROIDisplayState_RoiColoursEntry {
    return ROIDisplayState_RoiColoursEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ROIDisplayState_RoiColoursEntry>, I>>(
    object: I,
  ): ROIDisplayState_RoiColoursEntry {
    const message = createBaseROIDisplayState_RoiColoursEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseROIDisplayState_RoiShapesEntry(): ROIDisplayState_RoiShapesEntry {
  return { key: "", value: "" };
}

export const ROIDisplayState_RoiShapesEntry = {
  encode(message: ROIDisplayState_RoiShapesEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ROIDisplayState_RoiShapesEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseROIDisplayState_RoiShapesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ROIDisplayState_RoiShapesEntry {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? String(object.value) : "" };
  },

  toJSON(message: ROIDisplayState_RoiShapesEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create<I extends Exact<DeepPartial<ROIDisplayState_RoiShapesEntry>, I>>(base?: I): ROIDisplayState_RoiShapesEntry {
    return ROIDisplayState_RoiShapesEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ROIDisplayState_RoiShapesEntry>, I>>(
    object: I,
  ): ROIDisplayState_RoiShapesEntry {
    const message = createBaseROIDisplayState_RoiShapesEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseRGBUPlotWidgetState(): RGBUPlotWidgetState {
  return {
    minerals: [],
    yChannelA: "",
    yChannelB: "",
    xChannelA: "",
    xChannelB: "",
    drawMonochrome: false,
    selectedMinXValue: 0,
    selectedMaxXValue: 0,
    selectedMinYValue: 0,
    selectedMaxYValue: 0,
    imageName: "",
    roiIds: [],
  };
}

export const RGBUPlotWidgetState = {
  encode(message: RGBUPlotWidgetState, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.minerals) {
      writer.uint32(10).string(v!);
    }
    if (message.yChannelA !== "") {
      writer.uint32(18).string(message.yChannelA);
    }
    if (message.yChannelB !== "") {
      writer.uint32(26).string(message.yChannelB);
    }
    if (message.xChannelA !== "") {
      writer.uint32(34).string(message.xChannelA);
    }
    if (message.xChannelB !== "") {
      writer.uint32(42).string(message.xChannelB);
    }
    if (message.drawMonochrome === true) {
      writer.uint32(48).bool(message.drawMonochrome);
    }
    if (message.selectedMinXValue !== 0) {
      writer.uint32(61).float(message.selectedMinXValue);
    }
    if (message.selectedMaxXValue !== 0) {
      writer.uint32(69).float(message.selectedMaxXValue);
    }
    if (message.selectedMinYValue !== 0) {
      writer.uint32(77).float(message.selectedMinYValue);
    }
    if (message.selectedMaxYValue !== 0) {
      writer.uint32(85).float(message.selectedMaxYValue);
    }
    if (message.imageName !== "") {
      writer.uint32(90).string(message.imageName);
    }
    for (const v of message.roiIds) {
      writer.uint32(98).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RGBUPlotWidgetState {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRGBUPlotWidgetState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.minerals.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.yChannelA = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.yChannelB = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.xChannelA = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.xChannelB = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.drawMonochrome = reader.bool();
          continue;
        case 7:
          if (tag !== 61) {
            break;
          }

          message.selectedMinXValue = reader.float();
          continue;
        case 8:
          if (tag !== 69) {
            break;
          }

          message.selectedMaxXValue = reader.float();
          continue;
        case 9:
          if (tag !== 77) {
            break;
          }

          message.selectedMinYValue = reader.float();
          continue;
        case 10:
          if (tag !== 85) {
            break;
          }

          message.selectedMaxYValue = reader.float();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.imageName = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.roiIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RGBUPlotWidgetState {
    return {
      minerals: Array.isArray(object?.minerals) ? object.minerals.map((e: any) => String(e)) : [],
      yChannelA: isSet(object.yChannelA) ? String(object.yChannelA) : "",
      yChannelB: isSet(object.yChannelB) ? String(object.yChannelB) : "",
      xChannelA: isSet(object.xChannelA) ? String(object.xChannelA) : "",
      xChannelB: isSet(object.xChannelB) ? String(object.xChannelB) : "",
      drawMonochrome: isSet(object.drawMonochrome) ? Boolean(object.drawMonochrome) : false,
      selectedMinXValue: isSet(object.selectedMinXValue) ? Number(object.selectedMinXValue) : 0,
      selectedMaxXValue: isSet(object.selectedMaxXValue) ? Number(object.selectedMaxXValue) : 0,
      selectedMinYValue: isSet(object.selectedMinYValue) ? Number(object.selectedMinYValue) : 0,
      selectedMaxYValue: isSet(object.selectedMaxYValue) ? Number(object.selectedMaxYValue) : 0,
      imageName: isSet(object.imageName) ? String(object.imageName) : "",
      roiIds: Array.isArray(object?.roiIds) ? object.roiIds.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: RGBUPlotWidgetState): unknown {
    const obj: any = {};
    if (message.minerals) {
      obj.minerals = message.minerals.map((e) => e);
    } else {
      obj.minerals = [];
    }
    message.yChannelA !== undefined && (obj.yChannelA = message.yChannelA);
    message.yChannelB !== undefined && (obj.yChannelB = message.yChannelB);
    message.xChannelA !== undefined && (obj.xChannelA = message.xChannelA);
    message.xChannelB !== undefined && (obj.xChannelB = message.xChannelB);
    message.drawMonochrome !== undefined && (obj.drawMonochrome = message.drawMonochrome);
    message.selectedMinXValue !== undefined && (obj.selectedMinXValue = message.selectedMinXValue);
    message.selectedMaxXValue !== undefined && (obj.selectedMaxXValue = message.selectedMaxXValue);
    message.selectedMinYValue !== undefined && (obj.selectedMinYValue = message.selectedMinYValue);
    message.selectedMaxYValue !== undefined && (obj.selectedMaxYValue = message.selectedMaxYValue);
    message.imageName !== undefined && (obj.imageName = message.imageName);
    if (message.roiIds) {
      obj.roiIds = message.roiIds.map((e) => e);
    } else {
      obj.roiIds = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RGBUPlotWidgetState>, I>>(base?: I): RGBUPlotWidgetState {
    return RGBUPlotWidgetState.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RGBUPlotWidgetState>, I>>(object: I): RGBUPlotWidgetState {
    const message = createBaseRGBUPlotWidgetState();
    message.minerals = object.minerals?.map((e) => e) || [];
    message.yChannelA = object.yChannelA ?? "";
    message.yChannelB = object.yChannelB ?? "";
    message.xChannelA = object.xChannelA ?? "";
    message.xChannelB = object.xChannelB ?? "";
    message.drawMonochrome = object.drawMonochrome ?? false;
    message.selectedMinXValue = object.selectedMinXValue ?? 0;
    message.selectedMaxXValue = object.selectedMaxXValue ?? 0;
    message.selectedMinYValue = object.selectedMinYValue ?? 0;
    message.selectedMaxYValue = object.selectedMaxYValue ?? 0;
    message.imageName = object.imageName ?? "";
    message.roiIds = object.roiIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseSingleAxisRGBUWidgetState(): SingleAxisRGBUWidgetState {
  return {
    minerals: [],
    channelA: "",
    channelB: "",
    roiStackedOverlap: false,
    imageName: "",
    selectedMinValue: 0,
    selectedMaxValue: 0,
    roiIds: [],
    showAllMineralLabels: false,
  };
}

export const SingleAxisRGBUWidgetState = {
  encode(message: SingleAxisRGBUWidgetState, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.minerals) {
      writer.uint32(10).string(v!);
    }
    if (message.channelA !== "") {
      writer.uint32(18).string(message.channelA);
    }
    if (message.channelB !== "") {
      writer.uint32(26).string(message.channelB);
    }
    if (message.roiStackedOverlap === true) {
      writer.uint32(32).bool(message.roiStackedOverlap);
    }
    if (message.imageName !== "") {
      writer.uint32(42).string(message.imageName);
    }
    if (message.selectedMinValue !== 0) {
      writer.uint32(53).float(message.selectedMinValue);
    }
    if (message.selectedMaxValue !== 0) {
      writer.uint32(61).float(message.selectedMaxValue);
    }
    for (const v of message.roiIds) {
      writer.uint32(66).string(v!);
    }
    if (message.showAllMineralLabels === true) {
      writer.uint32(72).bool(message.showAllMineralLabels);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SingleAxisRGBUWidgetState {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSingleAxisRGBUWidgetState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.minerals.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.channelA = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.channelB = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.roiStackedOverlap = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.imageName = reader.string();
          continue;
        case 6:
          if (tag !== 53) {
            break;
          }

          message.selectedMinValue = reader.float();
          continue;
        case 7:
          if (tag !== 61) {
            break;
          }

          message.selectedMaxValue = reader.float();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.roiIds.push(reader.string());
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.showAllMineralLabels = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SingleAxisRGBUWidgetState {
    return {
      minerals: Array.isArray(object?.minerals) ? object.minerals.map((e: any) => String(e)) : [],
      channelA: isSet(object.channelA) ? String(object.channelA) : "",
      channelB: isSet(object.channelB) ? String(object.channelB) : "",
      roiStackedOverlap: isSet(object.roiStackedOverlap) ? Boolean(object.roiStackedOverlap) : false,
      imageName: isSet(object.imageName) ? String(object.imageName) : "",
      selectedMinValue: isSet(object.selectedMinValue) ? Number(object.selectedMinValue) : 0,
      selectedMaxValue: isSet(object.selectedMaxValue) ? Number(object.selectedMaxValue) : 0,
      roiIds: Array.isArray(object?.roiIds) ? object.roiIds.map((e: any) => String(e)) : [],
      showAllMineralLabels: isSet(object.showAllMineralLabels) ? Boolean(object.showAllMineralLabels) : false,
    };
  },

  toJSON(message: SingleAxisRGBUWidgetState): unknown {
    const obj: any = {};
    if (message.minerals) {
      obj.minerals = message.minerals.map((e) => e);
    } else {
      obj.minerals = [];
    }
    message.channelA !== undefined && (obj.channelA = message.channelA);
    message.channelB !== undefined && (obj.channelB = message.channelB);
    message.roiStackedOverlap !== undefined && (obj.roiStackedOverlap = message.roiStackedOverlap);
    message.imageName !== undefined && (obj.imageName = message.imageName);
    message.selectedMinValue !== undefined && (obj.selectedMinValue = message.selectedMinValue);
    message.selectedMaxValue !== undefined && (obj.selectedMaxValue = message.selectedMaxValue);
    if (message.roiIds) {
      obj.roiIds = message.roiIds.map((e) => e);
    } else {
      obj.roiIds = [];
    }
    message.showAllMineralLabels !== undefined && (obj.showAllMineralLabels = message.showAllMineralLabels);
    return obj;
  },

  create<I extends Exact<DeepPartial<SingleAxisRGBUWidgetState>, I>>(base?: I): SingleAxisRGBUWidgetState {
    return SingleAxisRGBUWidgetState.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SingleAxisRGBUWidgetState>, I>>(object: I): SingleAxisRGBUWidgetState {
    const message = createBaseSingleAxisRGBUWidgetState();
    message.minerals = object.minerals?.map((e) => e) || [];
    message.channelA = object.channelA ?? "";
    message.channelB = object.channelB ?? "";
    message.roiStackedOverlap = object.roiStackedOverlap ?? false;
    message.imageName = object.imageName ?? "";
    message.selectedMinValue = object.selectedMinValue ?? 0;
    message.selectedMaxValue = object.selectedMaxValue ?? 0;
    message.roiIds = object.roiIds?.map((e) => e) || [];
    message.showAllMineralLabels = object.showAllMineralLabels ?? false;
    return message;
  },
};

function createBaseRGBUImagesWidgetState(): RGBUImagesWidgetState {
  return { brightness: 0, imageName: "" };
}

export const RGBUImagesWidgetState = {
  encode(message: RGBUImagesWidgetState, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.brightness !== 0) {
      writer.uint32(21).float(message.brightness);
    }
    if (message.imageName !== "") {
      writer.uint32(26).string(message.imageName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RGBUImagesWidgetState {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRGBUImagesWidgetState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 21) {
            break;
          }

          message.brightness = reader.float();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.imageName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RGBUImagesWidgetState {
    return {
      brightness: isSet(object.brightness) ? Number(object.brightness) : 0,
      imageName: isSet(object.imageName) ? String(object.imageName) : "",
    };
  },

  toJSON(message: RGBUImagesWidgetState): unknown {
    const obj: any = {};
    message.brightness !== undefined && (obj.brightness = message.brightness);
    message.imageName !== undefined && (obj.imageName = message.imageName);
    return obj;
  },

  create<I extends Exact<DeepPartial<RGBUImagesWidgetState>, I>>(base?: I): RGBUImagesWidgetState {
    return RGBUImagesWidgetState.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RGBUImagesWidgetState>, I>>(object: I): RGBUImagesWidgetState {
    const message = createBaseRGBUImagesWidgetState();
    message.brightness = object.brightness ?? 0;
    message.imageName = object.imageName ?? "";
    return message;
  },
};

function createBaseParallelogramWidgetState(): ParallelogramWidgetState {
  return { regions: [], channels: [], excludeZero: false, averageMode: "", sigmaLevel: "" };
}

export const ParallelogramWidgetState = {
  encode(message: ParallelogramWidgetState, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.regions) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.channels) {
      writer.uint32(18).string(v!);
    }
    if (message.excludeZero === true) {
      writer.uint32(32).bool(message.excludeZero);
    }
    if (message.averageMode !== "") {
      writer.uint32(42).string(message.averageMode);
    }
    if (message.sigmaLevel !== "") {
      writer.uint32(50).string(message.sigmaLevel);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ParallelogramWidgetState {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseParallelogramWidgetState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.regions.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.channels.push(reader.string());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.excludeZero = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.averageMode = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.sigmaLevel = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ParallelogramWidgetState {
    return {
      regions: Array.isArray(object?.regions) ? object.regions.map((e: any) => String(e)) : [],
      channels: Array.isArray(object?.channels) ? object.channels.map((e: any) => String(e)) : [],
      excludeZero: isSet(object.excludeZero) ? Boolean(object.excludeZero) : false,
      averageMode: isSet(object.averageMode) ? String(object.averageMode) : "",
      sigmaLevel: isSet(object.sigmaLevel) ? String(object.sigmaLevel) : "",
    };
  },

  toJSON(message: ParallelogramWidgetState): unknown {
    const obj: any = {};
    if (message.regions) {
      obj.regions = message.regions.map((e) => e);
    } else {
      obj.regions = [];
    }
    if (message.channels) {
      obj.channels = message.channels.map((e) => e);
    } else {
      obj.channels = [];
    }
    message.excludeZero !== undefined && (obj.excludeZero = message.excludeZero);
    message.averageMode !== undefined && (obj.averageMode = message.averageMode);
    message.sigmaLevel !== undefined && (obj.sigmaLevel = message.sigmaLevel);
    return obj;
  },

  create<I extends Exact<DeepPartial<ParallelogramWidgetState>, I>>(base?: I): ParallelogramWidgetState {
    return ParallelogramWidgetState.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ParallelogramWidgetState>, I>>(object: I): ParallelogramWidgetState {
    const message = createBaseParallelogramWidgetState();
    message.regions = object.regions?.map((e) => e) || [];
    message.channels = object.channels?.map((e) => e) || [];
    message.excludeZero = object.excludeZero ?? false;
    message.averageMode = object.averageMode ?? "";
    message.sigmaLevel = object.sigmaLevel ?? "";
    return message;
  },
};

function createBaseMarkdownViewState(): MarkdownViewState {
  return { content: "" };
}

export const MarkdownViewState = {
  encode(message: MarkdownViewState, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.content !== "") {
      writer.uint32(10).string(message.content);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MarkdownViewState {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMarkdownViewState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.content = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MarkdownViewState {
    return { content: isSet(object.content) ? String(object.content) : "" };
  },

  toJSON(message: MarkdownViewState): unknown {
    const obj: any = {};
    message.content !== undefined && (obj.content = message.content);
    return obj;
  },

  create<I extends Exact<DeepPartial<MarkdownViewState>, I>>(base?: I): MarkdownViewState {
    return MarkdownViewState.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MarkdownViewState>, I>>(object: I): MarkdownViewState {
    const message = createBaseMarkdownViewState();
    message.content = object.content ?? "";
    return message;
  },
};

function createBaseWidgetData(): WidgetData {
  return {
    id: "",
    spectrum: undefined,
    binary: undefined,
    ternary: undefined,
    histogram: undefined,
    contextImage: undefined,
    chord: undefined,
    table: undefined,
    roiQuantTable: undefined,
    variogram: undefined,
    rgbuPlot: undefined,
    singleAxisRGBU: undefined,
    rgbuImage: undefined,
    parallelogram: undefined,
    markdownView: undefined,
  };
}

export const WidgetData = {
  encode(message: WidgetData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.spectrum !== undefined) {
      SpectrumWidgetState.encode(message.spectrum, writer.uint32(18).fork()).ldelim();
    }
    if (message.binary !== undefined) {
      BinaryState.encode(message.binary, writer.uint32(26).fork()).ldelim();
    }
    if (message.ternary !== undefined) {
      TernaryState.encode(message.ternary, writer.uint32(34).fork()).ldelim();
    }
    if (message.histogram !== undefined) {
      HistogramState.encode(message.histogram, writer.uint32(42).fork()).ldelim();
    }
    if (message.contextImage !== undefined) {
      ContextImageState.encode(message.contextImage, writer.uint32(50).fork()).ldelim();
    }
    if (message.chord !== undefined) {
      ChordState.encode(message.chord, writer.uint32(58).fork()).ldelim();
    }
    if (message.table !== undefined) {
      TableState.encode(message.table, writer.uint32(66).fork()).ldelim();
    }
    if (message.roiQuantTable !== undefined) {
      ROIQuantTableState.encode(message.roiQuantTable, writer.uint32(74).fork()).ldelim();
    }
    if (message.variogram !== undefined) {
      VariogramState.encode(message.variogram, writer.uint32(82).fork()).ldelim();
    }
    if (message.rgbuPlot !== undefined) {
      RGBUPlotWidgetState.encode(message.rgbuPlot, writer.uint32(90).fork()).ldelim();
    }
    if (message.singleAxisRGBU !== undefined) {
      SingleAxisRGBUWidgetState.encode(message.singleAxisRGBU, writer.uint32(98).fork()).ldelim();
    }
    if (message.rgbuImage !== undefined) {
      RGBUImagesWidgetState.encode(message.rgbuImage, writer.uint32(106).fork()).ldelim();
    }
    if (message.parallelogram !== undefined) {
      ParallelogramWidgetState.encode(message.parallelogram, writer.uint32(114).fork()).ldelim();
    }
    if (message.markdownView !== undefined) {
      MarkdownViewState.encode(message.markdownView, writer.uint32(122).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WidgetData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWidgetData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.spectrum = SpectrumWidgetState.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.binary = BinaryState.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.ternary = TernaryState.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.histogram = HistogramState.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.contextImage = ContextImageState.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.chord = ChordState.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.table = TableState.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.roiQuantTable = ROIQuantTableState.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.variogram = VariogramState.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.rgbuPlot = RGBUPlotWidgetState.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.singleAxisRGBU = SingleAxisRGBUWidgetState.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.rgbuImage = RGBUImagesWidgetState.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.parallelogram = ParallelogramWidgetState.decode(reader, reader.uint32());
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.markdownView = MarkdownViewState.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WidgetData {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      spectrum: isSet(object.spectrum) ? SpectrumWidgetState.fromJSON(object.spectrum) : undefined,
      binary: isSet(object.binary) ? BinaryState.fromJSON(object.binary) : undefined,
      ternary: isSet(object.ternary) ? TernaryState.fromJSON(object.ternary) : undefined,
      histogram: isSet(object.histogram) ? HistogramState.fromJSON(object.histogram) : undefined,
      contextImage: isSet(object.contextImage) ? ContextImageState.fromJSON(object.contextImage) : undefined,
      chord: isSet(object.chord) ? ChordState.fromJSON(object.chord) : undefined,
      table: isSet(object.table) ? TableState.fromJSON(object.table) : undefined,
      roiQuantTable: isSet(object.roiQuantTable) ? ROIQuantTableState.fromJSON(object.roiQuantTable) : undefined,
      variogram: isSet(object.variogram) ? VariogramState.fromJSON(object.variogram) : undefined,
      rgbuPlot: isSet(object.rgbuPlot) ? RGBUPlotWidgetState.fromJSON(object.rgbuPlot) : undefined,
      singleAxisRGBU: isSet(object.singleAxisRGBU)
        ? SingleAxisRGBUWidgetState.fromJSON(object.singleAxisRGBU)
        : undefined,
      rgbuImage: isSet(object.rgbuImage) ? RGBUImagesWidgetState.fromJSON(object.rgbuImage) : undefined,
      parallelogram: isSet(object.parallelogram) ? ParallelogramWidgetState.fromJSON(object.parallelogram) : undefined,
      markdownView: isSet(object.markdownView) ? MarkdownViewState.fromJSON(object.markdownView) : undefined,
    };
  },

  toJSON(message: WidgetData): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.spectrum !== undefined &&
      (obj.spectrum = message.spectrum ? SpectrumWidgetState.toJSON(message.spectrum) : undefined);
    message.binary !== undefined && (obj.binary = message.binary ? BinaryState.toJSON(message.binary) : undefined);
    message.ternary !== undefined && (obj.ternary = message.ternary ? TernaryState.toJSON(message.ternary) : undefined);
    message.histogram !== undefined &&
      (obj.histogram = message.histogram ? HistogramState.toJSON(message.histogram) : undefined);
    message.contextImage !== undefined &&
      (obj.contextImage = message.contextImage ? ContextImageState.toJSON(message.contextImage) : undefined);
    message.chord !== undefined && (obj.chord = message.chord ? ChordState.toJSON(message.chord) : undefined);
    message.table !== undefined && (obj.table = message.table ? TableState.toJSON(message.table) : undefined);
    message.roiQuantTable !== undefined &&
      (obj.roiQuantTable = message.roiQuantTable ? ROIQuantTableState.toJSON(message.roiQuantTable) : undefined);
    message.variogram !== undefined &&
      (obj.variogram = message.variogram ? VariogramState.toJSON(message.variogram) : undefined);
    message.rgbuPlot !== undefined &&
      (obj.rgbuPlot = message.rgbuPlot ? RGBUPlotWidgetState.toJSON(message.rgbuPlot) : undefined);
    message.singleAxisRGBU !== undefined && (obj.singleAxisRGBU = message.singleAxisRGBU
      ? SingleAxisRGBUWidgetState.toJSON(message.singleAxisRGBU)
      : undefined);
    message.rgbuImage !== undefined &&
      (obj.rgbuImage = message.rgbuImage ? RGBUImagesWidgetState.toJSON(message.rgbuImage) : undefined);
    message.parallelogram !== undefined &&
      (obj.parallelogram = message.parallelogram ? ParallelogramWidgetState.toJSON(message.parallelogram) : undefined);
    message.markdownView !== undefined &&
      (obj.markdownView = message.markdownView ? MarkdownViewState.toJSON(message.markdownView) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<WidgetData>, I>>(base?: I): WidgetData {
    return WidgetData.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WidgetData>, I>>(object: I): WidgetData {
    const message = createBaseWidgetData();
    message.id = object.id ?? "";
    message.spectrum = (object.spectrum !== undefined && object.spectrum !== null)
      ? SpectrumWidgetState.fromPartial(object.spectrum)
      : undefined;
    message.binary = (object.binary !== undefined && object.binary !== null)
      ? BinaryState.fromPartial(object.binary)
      : undefined;
    message.ternary = (object.ternary !== undefined && object.ternary !== null)
      ? TernaryState.fromPartial(object.ternary)
      : undefined;
    message.histogram = (object.histogram !== undefined && object.histogram !== null)
      ? HistogramState.fromPartial(object.histogram)
      : undefined;
    message.contextImage = (object.contextImage !== undefined && object.contextImage !== null)
      ? ContextImageState.fromPartial(object.contextImage)
      : undefined;
    message.chord = (object.chord !== undefined && object.chord !== null)
      ? ChordState.fromPartial(object.chord)
      : undefined;
    message.table = (object.table !== undefined && object.table !== null)
      ? TableState.fromPartial(object.table)
      : undefined;
    message.roiQuantTable = (object.roiQuantTable !== undefined && object.roiQuantTable !== null)
      ? ROIQuantTableState.fromPartial(object.roiQuantTable)
      : undefined;
    message.variogram = (object.variogram !== undefined && object.variogram !== null)
      ? VariogramState.fromPartial(object.variogram)
      : undefined;
    message.rgbuPlot = (object.rgbuPlot !== undefined && object.rgbuPlot !== null)
      ? RGBUPlotWidgetState.fromPartial(object.rgbuPlot)
      : undefined;
    message.singleAxisRGBU = (object.singleAxisRGBU !== undefined && object.singleAxisRGBU !== null)
      ? SingleAxisRGBUWidgetState.fromPartial(object.singleAxisRGBU)
      : undefined;
    message.rgbuImage = (object.rgbuImage !== undefined && object.rgbuImage !== null)
      ? RGBUImagesWidgetState.fromPartial(object.rgbuImage)
      : undefined;
    message.parallelogram = (object.parallelogram !== undefined && object.parallelogram !== null)
      ? ParallelogramWidgetState.fromPartial(object.parallelogram)
      : undefined;
    message.markdownView = (object.markdownView !== undefined && object.markdownView !== null)
      ? MarkdownViewState.fromPartial(object.markdownView)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
