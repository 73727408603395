/* eslint-disable */
import * as _m0 from "protobufjs/minimal";

export const protobufPackage = "";

export enum NotificationType {
  /** NT_UNKNOWN - https://protobuf.dev/programming-guides/dos-donts/ says specify an unknown as 0 */
  NT_UNKNOWN = 0,
  NT_SYS_DATA_CHANGED = 1,
  NT_USER_MESSAGE = 2,
  NT_USER_MODAL = 3,
  NT_NEW_USER = 4,
  NT_JOIN_GROUP_REQUEST = 5,
  UNRECOGNIZED = -1,
}

export function notificationTypeFromJSON(object: any): NotificationType {
  switch (object) {
    case 0:
    case "NT_UNKNOWN":
      return NotificationType.NT_UNKNOWN;
    case 1:
    case "NT_SYS_DATA_CHANGED":
      return NotificationType.NT_SYS_DATA_CHANGED;
    case 2:
    case "NT_USER_MESSAGE":
      return NotificationType.NT_USER_MESSAGE;
    case 3:
    case "NT_USER_MODAL":
      return NotificationType.NT_USER_MODAL;
    case 4:
    case "NT_NEW_USER":
      return NotificationType.NT_NEW_USER;
    case 5:
    case "NT_JOIN_GROUP_REQUEST":
      return NotificationType.NT_JOIN_GROUP_REQUEST;
    case -1:
    case "UNRECOGNIZED":
    default:
      return NotificationType.UNRECOGNIZED;
  }
}

export function notificationTypeToJSON(object: NotificationType): string {
  switch (object) {
    case NotificationType.NT_UNKNOWN:
      return "NT_UNKNOWN";
    case NotificationType.NT_SYS_DATA_CHANGED:
      return "NT_SYS_DATA_CHANGED";
    case NotificationType.NT_USER_MESSAGE:
      return "NT_USER_MESSAGE";
    case NotificationType.NT_USER_MODAL:
      return "NT_USER_MODAL";
    case NotificationType.NT_NEW_USER:
      return "NT_NEW_USER";
    case NotificationType.NT_JOIN_GROUP_REQUEST:
      return "NT_JOIN_GROUP_REQUEST";
    case NotificationType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Notification {
  /** @gotags: bson:"_id,omitempty" */
  id: string;
  /** The user this notification is destined for */
  destUserId: string;
  /** The group this notification is destined for */
  destUserGroupId: string;
  maxSecToExpiry: number;
  /** The subject (shown on UI banner or email subject) */
  subject: string;
  /** The contents (if user clicks on banner, or email body) */
  contents: string;
  /** Shows where the notification came from */
  from: string;
  timeStampUnixSec: number;
  actionLink: string;
  notificationType: NotificationType;
  /** Optional, depends on what type is set */
  scanIds: string[];
  imageName: string;
  quantId: string;
  /** For example, in case of join group request */
  requestorUserId: string;
}

function createBaseNotification(): Notification {
  return {
    id: "",
    destUserId: "",
    destUserGroupId: "",
    maxSecToExpiry: 0,
    subject: "",
    contents: "",
    from: "",
    timeStampUnixSec: 0,
    actionLink: "",
    notificationType: 0,
    scanIds: [],
    imageName: "",
    quantId: "",
    requestorUserId: "",
  };
}

export const Notification = {
  encode(message: Notification, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.destUserId !== "") {
      writer.uint32(18).string(message.destUserId);
    }
    if (message.destUserGroupId !== "") {
      writer.uint32(26).string(message.destUserGroupId);
    }
    if (message.maxSecToExpiry !== 0) {
      writer.uint32(32).uint32(message.maxSecToExpiry);
    }
    if (message.subject !== "") {
      writer.uint32(42).string(message.subject);
    }
    if (message.contents !== "") {
      writer.uint32(50).string(message.contents);
    }
    if (message.from !== "") {
      writer.uint32(58).string(message.from);
    }
    if (message.timeStampUnixSec !== 0) {
      writer.uint32(64).uint32(message.timeStampUnixSec);
    }
    if (message.actionLink !== "") {
      writer.uint32(74).string(message.actionLink);
    }
    if (message.notificationType !== 0) {
      writer.uint32(80).int32(message.notificationType);
    }
    for (const v of message.scanIds) {
      writer.uint32(90).string(v!);
    }
    if (message.imageName !== "") {
      writer.uint32(98).string(message.imageName);
    }
    if (message.quantId !== "") {
      writer.uint32(106).string(message.quantId);
    }
    if (message.requestorUserId !== "") {
      writer.uint32(114).string(message.requestorUserId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Notification {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotification();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.destUserId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.destUserGroupId = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.maxSecToExpiry = reader.uint32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.subject = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.contents = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.from = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.timeStampUnixSec = reader.uint32();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.actionLink = reader.string();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.notificationType = reader.int32() as any;
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.scanIds.push(reader.string());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.imageName = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.quantId = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.requestorUserId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Notification {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      destUserId: isSet(object.destUserId) ? String(object.destUserId) : "",
      destUserGroupId: isSet(object.destUserGroupId) ? String(object.destUserGroupId) : "",
      maxSecToExpiry: isSet(object.maxSecToExpiry) ? Number(object.maxSecToExpiry) : 0,
      subject: isSet(object.subject) ? String(object.subject) : "",
      contents: isSet(object.contents) ? String(object.contents) : "",
      from: isSet(object.from) ? String(object.from) : "",
      timeStampUnixSec: isSet(object.timeStampUnixSec) ? Number(object.timeStampUnixSec) : 0,
      actionLink: isSet(object.actionLink) ? String(object.actionLink) : "",
      notificationType: isSet(object.notificationType) ? notificationTypeFromJSON(object.notificationType) : 0,
      scanIds: Array.isArray(object?.scanIds) ? object.scanIds.map((e: any) => String(e)) : [],
      imageName: isSet(object.imageName) ? String(object.imageName) : "",
      quantId: isSet(object.quantId) ? String(object.quantId) : "",
      requestorUserId: isSet(object.requestorUserId) ? String(object.requestorUserId) : "",
    };
  },

  toJSON(message: Notification): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.destUserId !== undefined && (obj.destUserId = message.destUserId);
    message.destUserGroupId !== undefined && (obj.destUserGroupId = message.destUserGroupId);
    message.maxSecToExpiry !== undefined && (obj.maxSecToExpiry = Math.round(message.maxSecToExpiry));
    message.subject !== undefined && (obj.subject = message.subject);
    message.contents !== undefined && (obj.contents = message.contents);
    message.from !== undefined && (obj.from = message.from);
    message.timeStampUnixSec !== undefined && (obj.timeStampUnixSec = Math.round(message.timeStampUnixSec));
    message.actionLink !== undefined && (obj.actionLink = message.actionLink);
    message.notificationType !== undefined && (obj.notificationType = notificationTypeToJSON(message.notificationType));
    if (message.scanIds) {
      obj.scanIds = message.scanIds.map((e) => e);
    } else {
      obj.scanIds = [];
    }
    message.imageName !== undefined && (obj.imageName = message.imageName);
    message.quantId !== undefined && (obj.quantId = message.quantId);
    message.requestorUserId !== undefined && (obj.requestorUserId = message.requestorUserId);
    return obj;
  },

  create<I extends Exact<DeepPartial<Notification>, I>>(base?: I): Notification {
    return Notification.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Notification>, I>>(object: I): Notification {
    const message = createBaseNotification();
    message.id = object.id ?? "";
    message.destUserId = object.destUserId ?? "";
    message.destUserGroupId = object.destUserGroupId ?? "";
    message.maxSecToExpiry = object.maxSecToExpiry ?? 0;
    message.subject = object.subject ?? "";
    message.contents = object.contents ?? "";
    message.from = object.from ?? "";
    message.timeStampUnixSec = object.timeStampUnixSec ?? 0;
    message.actionLink = object.actionLink ?? "";
    message.notificationType = object.notificationType ?? 0;
    message.scanIds = object.scanIds?.map((e) => e) || [];
    message.imageName = object.imageName ?? "";
    message.quantId = object.quantId ?? "";
    message.requestorUserId = object.requestorUserId ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
