/* eslint-disable */
import * as _m0 from "protobufjs/minimal";

export const protobufPackage = "";

/** Where it came from - the instrument or some other source */
export enum ScanImageSource {
  /** SI_UNKNOWN - https://protobuf.dev/programming-guides/dos-donts/ says specify an unknown as 0 */
  SI_UNKNOWN = 0,
  /** SI_INSTRUMENT - Instrument provided image, eg in PIXL case, it's an MCC image */
  SI_INSTRUMENT = 1,
  /** SI_UPLOAD - Any uploaded image that's not sourced from the instrument */
  SI_UPLOAD = 2,
  UNRECOGNIZED = -1,
}

export function scanImageSourceFromJSON(object: any): ScanImageSource {
  switch (object) {
    case 0:
    case "SI_UNKNOWN":
      return ScanImageSource.SI_UNKNOWN;
    case 1:
    case "SI_INSTRUMENT":
      return ScanImageSource.SI_INSTRUMENT;
    case 2:
    case "SI_UPLOAD":
      return ScanImageSource.SI_UPLOAD;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ScanImageSource.UNRECOGNIZED;
  }
}

export function scanImageSourceToJSON(object: ScanImageSource): string {
  switch (object) {
    case ScanImageSource.SI_UNKNOWN:
      return "SI_UNKNOWN";
    case ScanImageSource.SI_INSTRUMENT:
      return "SI_INSTRUMENT";
    case ScanImageSource.SI_UPLOAD:
      return "SI_UPLOAD";
    case ScanImageSource.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Image purpose - is it just for viewing, or perhaps it provides channel data to some algorithm */
export enum ScanImagePurpose {
  /** SIP_UNKNOWN - https://protobuf.dev/programming-guides/dos-donts/ says specify an unknown as 0 */
  SIP_UNKNOWN = 0,
  /** SIP_VIEWING - Just for viewing, as a background to scan points/other data */
  SIP_VIEWING = 1,
  /** SIP_MULTICHANNEL - For multi-channel analysis, so as source for binary plots, etc */
  SIP_MULTICHANNEL = 2,
  UNRECOGNIZED = -1,
}

export function scanImagePurposeFromJSON(object: any): ScanImagePurpose {
  switch (object) {
    case 0:
    case "SIP_UNKNOWN":
      return ScanImagePurpose.SIP_UNKNOWN;
    case 1:
    case "SIP_VIEWING":
      return ScanImagePurpose.SIP_VIEWING;
    case 2:
    case "SIP_MULTICHANNEL":
      return ScanImagePurpose.SIP_MULTICHANNEL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ScanImagePurpose.UNRECOGNIZED;
  }
}

export function scanImagePurposeToJSON(object: ScanImagePurpose): string {
  switch (object) {
    case ScanImagePurpose.SIP_UNKNOWN:
      return "SIP_UNKNOWN";
    case ScanImagePurpose.SIP_VIEWING:
      return "SIP_VIEWING";
    case ScanImagePurpose.SIP_MULTICHANNEL:
      return "SIP_MULTICHANNEL";
    case ScanImagePurpose.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Describes a single image that is associated with a scan */
export interface ScanImage {
  /**
   * NOTE: This has to be unique, so it contains the scanId subdir that it's in. Eg for image named A.jpg
   *       for scan 123 this will always be 123/A.jpg
   *       Anything wanting to display just the image file name will have to use what's at the end of this path
   */
  imagePath: string;
  source: ScanImageSource;
  width: number;
  height: number;
  fileSize: number;
  purpose: ScanImagePurpose;
  /** All scans associated with this image */
  associatedScanIds: string[];
  /**
   * The scan the image originates from (in case of images taken by the
   * XRF instruments own camera, eg with PIXL, images taken by MCC)
   * If this is empty, this image came from another source
   */
  originScanId: string;
  /**
   * Link to the original image - for PIXL this will be a link to Mars Viewer
   * but other devices/imports could define a link to some original image location
   */
  originImageURL: string;
  /** If its "matched" to another image, this should be filled out */
  matchInfo: ImageMatchTransform | undefined;
}

export interface ImageMatchTransform {
  /** Name of the image file whose beam locations this image has been "matched" (aka warped) to */
  beamImageFileName: string;
  /** The transform to allow rendering the image relative to the existing beam locations */
  xOffset: number;
  yOffset: number;
  xScale: number;
  yScale: number;
}

export interface ScanImageDefaultDB {
  /** @gotags: bson:"_id,omitempty" */
  scanId: string;
  defaultImageFileName: string;
}

function createBaseScanImage(): ScanImage {
  return {
    imagePath: "",
    source: 0,
    width: 0,
    height: 0,
    fileSize: 0,
    purpose: 0,
    associatedScanIds: [],
    originScanId: "",
    originImageURL: "",
    matchInfo: undefined,
  };
}

export const ScanImage = {
  encode(message: ScanImage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.imagePath !== "") {
      writer.uint32(10).string(message.imagePath);
    }
    if (message.source !== 0) {
      writer.uint32(16).int32(message.source);
    }
    if (message.width !== 0) {
      writer.uint32(24).uint32(message.width);
    }
    if (message.height !== 0) {
      writer.uint32(32).uint32(message.height);
    }
    if (message.fileSize !== 0) {
      writer.uint32(40).uint32(message.fileSize);
    }
    if (message.purpose !== 0) {
      writer.uint32(48).int32(message.purpose);
    }
    for (const v of message.associatedScanIds) {
      writer.uint32(58).string(v!);
    }
    if (message.originScanId !== "") {
      writer.uint32(66).string(message.originScanId);
    }
    if (message.originImageURL !== "") {
      writer.uint32(74).string(message.originImageURL);
    }
    if (message.matchInfo !== undefined) {
      ImageMatchTransform.encode(message.matchInfo, writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScanImage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScanImage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.imagePath = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.source = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.width = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.height = reader.uint32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.fileSize = reader.uint32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.purpose = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.associatedScanIds.push(reader.string());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.originScanId = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.originImageURL = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.matchInfo = ImageMatchTransform.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScanImage {
    return {
      imagePath: isSet(object.imagePath) ? String(object.imagePath) : "",
      source: isSet(object.source) ? scanImageSourceFromJSON(object.source) : 0,
      width: isSet(object.width) ? Number(object.width) : 0,
      height: isSet(object.height) ? Number(object.height) : 0,
      fileSize: isSet(object.fileSize) ? Number(object.fileSize) : 0,
      purpose: isSet(object.purpose) ? scanImagePurposeFromJSON(object.purpose) : 0,
      associatedScanIds: Array.isArray(object?.associatedScanIds)
        ? object.associatedScanIds.map((e: any) => String(e))
        : [],
      originScanId: isSet(object.originScanId) ? String(object.originScanId) : "",
      originImageURL: isSet(object.originImageURL) ? String(object.originImageURL) : "",
      matchInfo: isSet(object.matchInfo) ? ImageMatchTransform.fromJSON(object.matchInfo) : undefined,
    };
  },

  toJSON(message: ScanImage): unknown {
    const obj: any = {};
    message.imagePath !== undefined && (obj.imagePath = message.imagePath);
    message.source !== undefined && (obj.source = scanImageSourceToJSON(message.source));
    message.width !== undefined && (obj.width = Math.round(message.width));
    message.height !== undefined && (obj.height = Math.round(message.height));
    message.fileSize !== undefined && (obj.fileSize = Math.round(message.fileSize));
    message.purpose !== undefined && (obj.purpose = scanImagePurposeToJSON(message.purpose));
    if (message.associatedScanIds) {
      obj.associatedScanIds = message.associatedScanIds.map((e) => e);
    } else {
      obj.associatedScanIds = [];
    }
    message.originScanId !== undefined && (obj.originScanId = message.originScanId);
    message.originImageURL !== undefined && (obj.originImageURL = message.originImageURL);
    message.matchInfo !== undefined &&
      (obj.matchInfo = message.matchInfo ? ImageMatchTransform.toJSON(message.matchInfo) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ScanImage>, I>>(base?: I): ScanImage {
    return ScanImage.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScanImage>, I>>(object: I): ScanImage {
    const message = createBaseScanImage();
    message.imagePath = object.imagePath ?? "";
    message.source = object.source ?? 0;
    message.width = object.width ?? 0;
    message.height = object.height ?? 0;
    message.fileSize = object.fileSize ?? 0;
    message.purpose = object.purpose ?? 0;
    message.associatedScanIds = object.associatedScanIds?.map((e) => e) || [];
    message.originScanId = object.originScanId ?? "";
    message.originImageURL = object.originImageURL ?? "";
    message.matchInfo = (object.matchInfo !== undefined && object.matchInfo !== null)
      ? ImageMatchTransform.fromPartial(object.matchInfo)
      : undefined;
    return message;
  },
};

function createBaseImageMatchTransform(): ImageMatchTransform {
  return { beamImageFileName: "", xOffset: 0, yOffset: 0, xScale: 0, yScale: 0 };
}

export const ImageMatchTransform = {
  encode(message: ImageMatchTransform, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.beamImageFileName !== "") {
      writer.uint32(10).string(message.beamImageFileName);
    }
    if (message.xOffset !== 0) {
      writer.uint32(21).float(message.xOffset);
    }
    if (message.yOffset !== 0) {
      writer.uint32(29).float(message.yOffset);
    }
    if (message.xScale !== 0) {
      writer.uint32(37).float(message.xScale);
    }
    if (message.yScale !== 0) {
      writer.uint32(45).float(message.yScale);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImageMatchTransform {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImageMatchTransform();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.beamImageFileName = reader.string();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.xOffset = reader.float();
          continue;
        case 3:
          if (tag !== 29) {
            break;
          }

          message.yOffset = reader.float();
          continue;
        case 4:
          if (tag !== 37) {
            break;
          }

          message.xScale = reader.float();
          continue;
        case 5:
          if (tag !== 45) {
            break;
          }

          message.yScale = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ImageMatchTransform {
    return {
      beamImageFileName: isSet(object.beamImageFileName) ? String(object.beamImageFileName) : "",
      xOffset: isSet(object.xOffset) ? Number(object.xOffset) : 0,
      yOffset: isSet(object.yOffset) ? Number(object.yOffset) : 0,
      xScale: isSet(object.xScale) ? Number(object.xScale) : 0,
      yScale: isSet(object.yScale) ? Number(object.yScale) : 0,
    };
  },

  toJSON(message: ImageMatchTransform): unknown {
    const obj: any = {};
    message.beamImageFileName !== undefined && (obj.beamImageFileName = message.beamImageFileName);
    message.xOffset !== undefined && (obj.xOffset = message.xOffset);
    message.yOffset !== undefined && (obj.yOffset = message.yOffset);
    message.xScale !== undefined && (obj.xScale = message.xScale);
    message.yScale !== undefined && (obj.yScale = message.yScale);
    return obj;
  },

  create<I extends Exact<DeepPartial<ImageMatchTransform>, I>>(base?: I): ImageMatchTransform {
    return ImageMatchTransform.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ImageMatchTransform>, I>>(object: I): ImageMatchTransform {
    const message = createBaseImageMatchTransform();
    message.beamImageFileName = object.beamImageFileName ?? "";
    message.xOffset = object.xOffset ?? 0;
    message.yOffset = object.yOffset ?? 0;
    message.xScale = object.xScale ?? 0;
    message.yScale = object.yScale ?? 0;
    return message;
  },
};

function createBaseScanImageDefaultDB(): ScanImageDefaultDB {
  return { scanId: "", defaultImageFileName: "" };
}

export const ScanImageDefaultDB = {
  encode(message: ScanImageDefaultDB, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.scanId !== "") {
      writer.uint32(10).string(message.scanId);
    }
    if (message.defaultImageFileName !== "") {
      writer.uint32(18).string(message.defaultImageFileName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScanImageDefaultDB {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScanImageDefaultDB();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.scanId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.defaultImageFileName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScanImageDefaultDB {
    return {
      scanId: isSet(object.scanId) ? String(object.scanId) : "",
      defaultImageFileName: isSet(object.defaultImageFileName) ? String(object.defaultImageFileName) : "",
    };
  },

  toJSON(message: ScanImageDefaultDB): unknown {
    const obj: any = {};
    message.scanId !== undefined && (obj.scanId = message.scanId);
    message.defaultImageFileName !== undefined && (obj.defaultImageFileName = message.defaultImageFileName);
    return obj;
  },

  create<I extends Exact<DeepPartial<ScanImageDefaultDB>, I>>(base?: I): ScanImageDefaultDB {
    return ScanImageDefaultDB.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScanImageDefaultDB>, I>>(object: I): ScanImageDefaultDB {
    const message = createBaseScanImageDefaultDB();
    message.scanId = object.scanId ?? "";
    message.defaultImageFileName = object.defaultImageFileName ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
