<ng-template #settingsMenu>
  <div class="settings">
    <two-state-button leftLabel="Weight%" rightLabel="mmol" toolTip="Show results in mmol vs weight %" [active]="showMmol" (onToggle)="setShowMmol()">
    </two-state-button>
    <multi-state-button
      [stateNames]="selectionModes"
      [items]="['assets/button-icons/minus-yellow.svg', 'assets/button-icons/reset.svg', 'assets/button-icons/plus-white.svg']"
      [activeState]="currentSelectionMode"
      (onChange)="onChangeSelectionMode($event)">
    </multi-state-button>
    <!-- <two-state-push-button
        [active]="selectModeExcludeROI"
        (onToggle)="onToggleSelectModeExcludeROI()"
        title="Exclude Lasso: will not select points that are in an ROI"
    >Exclude
    </two-state-push-button> -->
  </div>
</ng-template>

<interactive-canvas [ngClass]="mdl.cursorShown" [drawer]="drawer" [drawNotifier]="mdl" [interactionHandler]="interactionHandler" [transform]="transform">
</interactive-canvas>

<scatter-plot-axis-switcher class="x-switcher" [info]="xAxisSwitcher" (click)="onAxisClick('X')"> </scatter-plot-axis-switcher>
<scatter-plot-axis-switcher class="y-switcher" [info]="yAxisSwitcher" (click)="onAxisClick('Y')"> </scatter-plot-axis-switcher>
