/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { ScanInstrument, scanInstrumentFromJSON, scanInstrumentToJSON } from "./scan";

export const protobufPackage = "";

/** Defines beam locations of an individual location */
export interface Coordinate2D {
  i: number;
  j: number;
}

/** Locations relative to the image */
export interface ImageLocationsForScan {
  /** Which scan these are for */
  scanId: string;
  beamVersion: number;
  instrument: ScanInstrument;
  /**
   * The coordinates per entry within the scan (entry Id but entry index)
   * NOTE: This must have the same size as the scan entry array, otherwise
   *       how do we know which coordinate maps to which scan entry?
   */
  locations: Coordinate2D[];
}

/**
 * Full structure of an image and all locations returned along with the scan
 * they are relative to
 */
export interface ImageLocations {
  /** @gotags: bson:"_id,omitempty" */
  imageName: string;
  locationPerScan: ImageLocationsForScan[];
}

function createBaseCoordinate2D(): Coordinate2D {
  return { i: 0, j: 0 };
}

export const Coordinate2D = {
  encode(message: Coordinate2D, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.i !== 0) {
      writer.uint32(13).float(message.i);
    }
    if (message.j !== 0) {
      writer.uint32(21).float(message.j);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Coordinate2D {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCoordinate2D();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break;
          }

          message.i = reader.float();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.j = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Coordinate2D {
    return { i: isSet(object.i) ? Number(object.i) : 0, j: isSet(object.j) ? Number(object.j) : 0 };
  },

  toJSON(message: Coordinate2D): unknown {
    const obj: any = {};
    message.i !== undefined && (obj.i = message.i);
    message.j !== undefined && (obj.j = message.j);
    return obj;
  },

  create<I extends Exact<DeepPartial<Coordinate2D>, I>>(base?: I): Coordinate2D {
    return Coordinate2D.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Coordinate2D>, I>>(object: I): Coordinate2D {
    const message = createBaseCoordinate2D();
    message.i = object.i ?? 0;
    message.j = object.j ?? 0;
    return message;
  },
};

function createBaseImageLocationsForScan(): ImageLocationsForScan {
  return { scanId: "", beamVersion: 0, instrument: 0, locations: [] };
}

export const ImageLocationsForScan = {
  encode(message: ImageLocationsForScan, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.scanId !== "") {
      writer.uint32(10).string(message.scanId);
    }
    if (message.beamVersion !== 0) {
      writer.uint32(24).uint32(message.beamVersion);
    }
    if (message.instrument !== 0) {
      writer.uint32(32).int32(message.instrument);
    }
    for (const v of message.locations) {
      Coordinate2D.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImageLocationsForScan {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImageLocationsForScan();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.scanId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.beamVersion = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.instrument = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.locations.push(Coordinate2D.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ImageLocationsForScan {
    return {
      scanId: isSet(object.scanId) ? String(object.scanId) : "",
      beamVersion: isSet(object.beamVersion) ? Number(object.beamVersion) : 0,
      instrument: isSet(object.instrument) ? scanInstrumentFromJSON(object.instrument) : 0,
      locations: Array.isArray(object?.locations) ? object.locations.map((e: any) => Coordinate2D.fromJSON(e)) : [],
    };
  },

  toJSON(message: ImageLocationsForScan): unknown {
    const obj: any = {};
    message.scanId !== undefined && (obj.scanId = message.scanId);
    message.beamVersion !== undefined && (obj.beamVersion = Math.round(message.beamVersion));
    message.instrument !== undefined && (obj.instrument = scanInstrumentToJSON(message.instrument));
    if (message.locations) {
      obj.locations = message.locations.map((e) => e ? Coordinate2D.toJSON(e) : undefined);
    } else {
      obj.locations = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ImageLocationsForScan>, I>>(base?: I): ImageLocationsForScan {
    return ImageLocationsForScan.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ImageLocationsForScan>, I>>(object: I): ImageLocationsForScan {
    const message = createBaseImageLocationsForScan();
    message.scanId = object.scanId ?? "";
    message.beamVersion = object.beamVersion ?? 0;
    message.instrument = object.instrument ?? 0;
    message.locations = object.locations?.map((e) => Coordinate2D.fromPartial(e)) || [];
    return message;
  },
};

function createBaseImageLocations(): ImageLocations {
  return { imageName: "", locationPerScan: [] };
}

export const ImageLocations = {
  encode(message: ImageLocations, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.imageName !== "") {
      writer.uint32(10).string(message.imageName);
    }
    for (const v of message.locationPerScan) {
      ImageLocationsForScan.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImageLocations {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImageLocations();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.imageName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.locationPerScan.push(ImageLocationsForScan.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ImageLocations {
    return {
      imageName: isSet(object.imageName) ? String(object.imageName) : "",
      locationPerScan: Array.isArray(object?.locationPerScan)
        ? object.locationPerScan.map((e: any) => ImageLocationsForScan.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ImageLocations): unknown {
    const obj: any = {};
    message.imageName !== undefined && (obj.imageName = message.imageName);
    if (message.locationPerScan) {
      obj.locationPerScan = message.locationPerScan.map((e) => e ? ImageLocationsForScan.toJSON(e) : undefined);
    } else {
      obj.locationPerScan = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ImageLocations>, I>>(base?: I): ImageLocations {
    return ImageLocations.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ImageLocations>, I>>(object: I): ImageLocations {
    const message = createBaseImageLocations();
    message.imageName = object.imageName ?? "";
    message.locationPerScan = object.locationPerScan?.map((e) => ImageLocationsForScan.fromPartial(e)) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
