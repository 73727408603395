/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { UserGroup } from "./user-group";

export const protobufPackage = "";

/**
 * //////////////////////////////////////////////////////////////
 * Creating, deleting and renaming entire groups
 * Should only be accessible to sys admins
 * requires(PIXLISE_ADMIN)
 */
export interface UserGroupCreateReq {
  name: string;
  description: string;
  joinable: boolean;
}

export interface UserGroupCreateResp {
  group: UserGroup | undefined;
}

/** requires(PIXLISE_ADMIN) */
export interface UserGroupDeleteReq {
  groupId: string;
}

export interface UserGroupDeleteResp {
}

/** requires(PIXLISE_ADMIN) */
export interface UserGroupEditDetailsReq {
  groupId: string;
  name: string;
  description: string;
  joinable: boolean;
}

/** requires(PIXLISE_ADMIN) */
export interface UserGroupEditDetailsResp {
  group: UserGroup | undefined;
}

function createBaseUserGroupCreateReq(): UserGroupCreateReq {
  return { name: "", description: "", joinable: false };
}

export const UserGroupCreateReq = {
  encode(message: UserGroupCreateReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(18).string(message.description);
    }
    if (message.joinable === true) {
      writer.uint32(24).bool(message.joinable);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserGroupCreateReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserGroupCreateReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.joinable = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserGroupCreateReq {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      description: isSet(object.description) ? String(object.description) : "",
      joinable: isSet(object.joinable) ? Boolean(object.joinable) : false,
    };
  },

  toJSON(message: UserGroupCreateReq): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.description !== undefined && (obj.description = message.description);
    message.joinable !== undefined && (obj.joinable = message.joinable);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserGroupCreateReq>, I>>(base?: I): UserGroupCreateReq {
    return UserGroupCreateReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserGroupCreateReq>, I>>(object: I): UserGroupCreateReq {
    const message = createBaseUserGroupCreateReq();
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.joinable = object.joinable ?? false;
    return message;
  },
};

function createBaseUserGroupCreateResp(): UserGroupCreateResp {
  return { group: undefined };
}

export const UserGroupCreateResp = {
  encode(message: UserGroupCreateResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.group !== undefined) {
      UserGroup.encode(message.group, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserGroupCreateResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserGroupCreateResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.group = UserGroup.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserGroupCreateResp {
    return { group: isSet(object.group) ? UserGroup.fromJSON(object.group) : undefined };
  },

  toJSON(message: UserGroupCreateResp): unknown {
    const obj: any = {};
    message.group !== undefined && (obj.group = message.group ? UserGroup.toJSON(message.group) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserGroupCreateResp>, I>>(base?: I): UserGroupCreateResp {
    return UserGroupCreateResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserGroupCreateResp>, I>>(object: I): UserGroupCreateResp {
    const message = createBaseUserGroupCreateResp();
    message.group = (object.group !== undefined && object.group !== null)
      ? UserGroup.fromPartial(object.group)
      : undefined;
    return message;
  },
};

function createBaseUserGroupDeleteReq(): UserGroupDeleteReq {
  return { groupId: "" };
}

export const UserGroupDeleteReq = {
  encode(message: UserGroupDeleteReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.groupId !== "") {
      writer.uint32(10).string(message.groupId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserGroupDeleteReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserGroupDeleteReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.groupId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserGroupDeleteReq {
    return { groupId: isSet(object.groupId) ? String(object.groupId) : "" };
  },

  toJSON(message: UserGroupDeleteReq): unknown {
    const obj: any = {};
    message.groupId !== undefined && (obj.groupId = message.groupId);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserGroupDeleteReq>, I>>(base?: I): UserGroupDeleteReq {
    return UserGroupDeleteReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserGroupDeleteReq>, I>>(object: I): UserGroupDeleteReq {
    const message = createBaseUserGroupDeleteReq();
    message.groupId = object.groupId ?? "";
    return message;
  },
};

function createBaseUserGroupDeleteResp(): UserGroupDeleteResp {
  return {};
}

export const UserGroupDeleteResp = {
  encode(_: UserGroupDeleteResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserGroupDeleteResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserGroupDeleteResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UserGroupDeleteResp {
    return {};
  },

  toJSON(_: UserGroupDeleteResp): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<UserGroupDeleteResp>, I>>(base?: I): UserGroupDeleteResp {
    return UserGroupDeleteResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserGroupDeleteResp>, I>>(_: I): UserGroupDeleteResp {
    const message = createBaseUserGroupDeleteResp();
    return message;
  },
};

function createBaseUserGroupEditDetailsReq(): UserGroupEditDetailsReq {
  return { groupId: "", name: "", description: "", joinable: false };
}

export const UserGroupEditDetailsReq = {
  encode(message: UserGroupEditDetailsReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.groupId !== "") {
      writer.uint32(10).string(message.groupId);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    if (message.joinable === true) {
      writer.uint32(32).bool(message.joinable);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserGroupEditDetailsReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserGroupEditDetailsReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.groupId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.joinable = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserGroupEditDetailsReq {
    return {
      groupId: isSet(object.groupId) ? String(object.groupId) : "",
      name: isSet(object.name) ? String(object.name) : "",
      description: isSet(object.description) ? String(object.description) : "",
      joinable: isSet(object.joinable) ? Boolean(object.joinable) : false,
    };
  },

  toJSON(message: UserGroupEditDetailsReq): unknown {
    const obj: any = {};
    message.groupId !== undefined && (obj.groupId = message.groupId);
    message.name !== undefined && (obj.name = message.name);
    message.description !== undefined && (obj.description = message.description);
    message.joinable !== undefined && (obj.joinable = message.joinable);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserGroupEditDetailsReq>, I>>(base?: I): UserGroupEditDetailsReq {
    return UserGroupEditDetailsReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserGroupEditDetailsReq>, I>>(object: I): UserGroupEditDetailsReq {
    const message = createBaseUserGroupEditDetailsReq();
    message.groupId = object.groupId ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.joinable = object.joinable ?? false;
    return message;
  },
};

function createBaseUserGroupEditDetailsResp(): UserGroupEditDetailsResp {
  return { group: undefined };
}

export const UserGroupEditDetailsResp = {
  encode(message: UserGroupEditDetailsResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.group !== undefined) {
      UserGroup.encode(message.group, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserGroupEditDetailsResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserGroupEditDetailsResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.group = UserGroup.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserGroupEditDetailsResp {
    return { group: isSet(object.group) ? UserGroup.fromJSON(object.group) : undefined };
  },

  toJSON(message: UserGroupEditDetailsResp): unknown {
    const obj: any = {};
    message.group !== undefined && (obj.group = message.group ? UserGroup.toJSON(message.group) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserGroupEditDetailsResp>, I>>(base?: I): UserGroupEditDetailsResp {
    return UserGroupEditDetailsResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserGroupEditDetailsResp>, I>>(object: I): UserGroupEditDetailsResp {
    const message = createBaseUserGroupEditDetailsResp();
    message.group = (object.group !== undefined && object.group !== null)
      ? UserGroup.fromPartial(object.group)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
