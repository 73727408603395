/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { MemoisedItem } from "./memoisation";

export const protobufPackage = "";

/** requires(NONE) */
export interface MemoiseWriteReq {
  key: string;
  data: Uint8Array;
}

export interface MemoiseWriteResp {
  memoTimeUnixSec: number;
}

/** requires(NONE) */
export interface MemoiseGetReq {
  key: string;
}

export interface MemoiseGetResp {
  item: MemoisedItem | undefined;
}

function createBaseMemoiseWriteReq(): MemoiseWriteReq {
  return { key: "", data: new Uint8Array(0) };
}

export const MemoiseWriteReq = {
  encode(message: MemoiseWriteReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.data.length !== 0) {
      writer.uint32(18).bytes(message.data);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MemoiseWriteReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMemoiseWriteReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.data = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MemoiseWriteReq {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      data: isSet(object.data) ? bytesFromBase64(object.data) : new Uint8Array(0),
    };
  },

  toJSON(message: MemoiseWriteReq): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.data !== undefined &&
      (obj.data = base64FromBytes(message.data !== undefined ? message.data : new Uint8Array(0)));
    return obj;
  },

  create<I extends Exact<DeepPartial<MemoiseWriteReq>, I>>(base?: I): MemoiseWriteReq {
    return MemoiseWriteReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MemoiseWriteReq>, I>>(object: I): MemoiseWriteReq {
    const message = createBaseMemoiseWriteReq();
    message.key = object.key ?? "";
    message.data = object.data ?? new Uint8Array(0);
    return message;
  },
};

function createBaseMemoiseWriteResp(): MemoiseWriteResp {
  return { memoTimeUnixSec: 0 };
}

export const MemoiseWriteResp = {
  encode(message: MemoiseWriteResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.memoTimeUnixSec !== 0) {
      writer.uint32(8).uint32(message.memoTimeUnixSec);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MemoiseWriteResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMemoiseWriteResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.memoTimeUnixSec = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MemoiseWriteResp {
    return { memoTimeUnixSec: isSet(object.memoTimeUnixSec) ? Number(object.memoTimeUnixSec) : 0 };
  },

  toJSON(message: MemoiseWriteResp): unknown {
    const obj: any = {};
    message.memoTimeUnixSec !== undefined && (obj.memoTimeUnixSec = Math.round(message.memoTimeUnixSec));
    return obj;
  },

  create<I extends Exact<DeepPartial<MemoiseWriteResp>, I>>(base?: I): MemoiseWriteResp {
    return MemoiseWriteResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MemoiseWriteResp>, I>>(object: I): MemoiseWriteResp {
    const message = createBaseMemoiseWriteResp();
    message.memoTimeUnixSec = object.memoTimeUnixSec ?? 0;
    return message;
  },
};

function createBaseMemoiseGetReq(): MemoiseGetReq {
  return { key: "" };
}

export const MemoiseGetReq = {
  encode(message: MemoiseGetReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MemoiseGetReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMemoiseGetReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MemoiseGetReq {
    return { key: isSet(object.key) ? String(object.key) : "" };
  },

  toJSON(message: MemoiseGetReq): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    return obj;
  },

  create<I extends Exact<DeepPartial<MemoiseGetReq>, I>>(base?: I): MemoiseGetReq {
    return MemoiseGetReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MemoiseGetReq>, I>>(object: I): MemoiseGetReq {
    const message = createBaseMemoiseGetReq();
    message.key = object.key ?? "";
    return message;
  },
};

function createBaseMemoiseGetResp(): MemoiseGetResp {
  return { item: undefined };
}

export const MemoiseGetResp = {
  encode(message: MemoiseGetResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.item !== undefined) {
      MemoisedItem.encode(message.item, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MemoiseGetResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMemoiseGetResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.item = MemoisedItem.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MemoiseGetResp {
    return { item: isSet(object.item) ? MemoisedItem.fromJSON(object.item) : undefined };
  },

  toJSON(message: MemoiseGetResp): unknown {
    const obj: any = {};
    message.item !== undefined && (obj.item = message.item ? MemoisedItem.toJSON(message.item) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<MemoiseGetResp>, I>>(base?: I): MemoiseGetResp {
    return MemoiseGetResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MemoiseGetResp>, I>>(object: I): MemoiseGetResp {
    const message = createBaseMemoiseGetResp();
    message.item = (object.item !== undefined && object.item !== null)
      ? MemoisedItem.fromPartial(object.item)
      : undefined;
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

function bytesFromBase64(b64: string): Uint8Array {
  if (tsProtoGlobalThis.Buffer) {
    return Uint8Array.from(tsProtoGlobalThis.Buffer.from(b64, "base64"));
  } else {
    const bin = tsProtoGlobalThis.atob(b64);
    const arr = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; ++i) {
      arr[i] = bin.charCodeAt(i);
    }
    return arr;
  }
}

function base64FromBytes(arr: Uint8Array): string {
  if (tsProtoGlobalThis.Buffer) {
    return tsProtoGlobalThis.Buffer.from(arr).toString("base64");
  } else {
    const bin: string[] = [];
    arr.forEach((byte) => {
      bin.push(String.fromCharCode(byte));
    });
    return tsProtoGlobalThis.btoa(bin.join(""));
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
