/* eslint-disable */
import * as _m0 from "protobufjs/minimal";

export const protobufPackage = "";

export interface ScanEntry {
  /** An ID for the entry. In PIXL, this is the "PMC" aka "PIXL motor count" value */
  id: number;
  /** Type agnostic timestamp, depends on the scan taken. In PIXL's case, it's SCLK */
  timestamp: number;
  /** What else is available for this entry */
  images: number;
  meta: boolean;
  location: boolean;
  pseudoIntensities: boolean;
  normalSpectra: number;
  dwellSpectra: number;
  bulkSpectra: number;
  maxSpectra: number;
}

function createBaseScanEntry(): ScanEntry {
  return {
    id: 0,
    timestamp: 0,
    images: 0,
    meta: false,
    location: false,
    pseudoIntensities: false,
    normalSpectra: 0,
    dwellSpectra: 0,
    bulkSpectra: 0,
    maxSpectra: 0,
  };
}

export const ScanEntry = {
  encode(message: ScanEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int32(message.id);
    }
    if (message.timestamp !== 0) {
      writer.uint32(16).uint32(message.timestamp);
    }
    if (message.images !== 0) {
      writer.uint32(24).uint32(message.images);
    }
    if (message.meta === true) {
      writer.uint32(40).bool(message.meta);
    }
    if (message.location === true) {
      writer.uint32(48).bool(message.location);
    }
    if (message.pseudoIntensities === true) {
      writer.uint32(56).bool(message.pseudoIntensities);
    }
    if (message.normalSpectra !== 0) {
      writer.uint32(64).uint32(message.normalSpectra);
    }
    if (message.dwellSpectra !== 0) {
      writer.uint32(72).uint32(message.dwellSpectra);
    }
    if (message.bulkSpectra !== 0) {
      writer.uint32(80).uint32(message.bulkSpectra);
    }
    if (message.maxSpectra !== 0) {
      writer.uint32(88).uint32(message.maxSpectra);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScanEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScanEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.timestamp = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.images = reader.uint32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.meta = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.location = reader.bool();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.pseudoIntensities = reader.bool();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.normalSpectra = reader.uint32();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.dwellSpectra = reader.uint32();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.bulkSpectra = reader.uint32();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.maxSpectra = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScanEntry {
    return {
      id: isSet(object.id) ? Number(object.id) : 0,
      timestamp: isSet(object.timestamp) ? Number(object.timestamp) : 0,
      images: isSet(object.images) ? Number(object.images) : 0,
      meta: isSet(object.meta) ? Boolean(object.meta) : false,
      location: isSet(object.location) ? Boolean(object.location) : false,
      pseudoIntensities: isSet(object.pseudoIntensities) ? Boolean(object.pseudoIntensities) : false,
      normalSpectra: isSet(object.normalSpectra) ? Number(object.normalSpectra) : 0,
      dwellSpectra: isSet(object.dwellSpectra) ? Number(object.dwellSpectra) : 0,
      bulkSpectra: isSet(object.bulkSpectra) ? Number(object.bulkSpectra) : 0,
      maxSpectra: isSet(object.maxSpectra) ? Number(object.maxSpectra) : 0,
    };
  },

  toJSON(message: ScanEntry): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = Math.round(message.id));
    message.timestamp !== undefined && (obj.timestamp = Math.round(message.timestamp));
    message.images !== undefined && (obj.images = Math.round(message.images));
    message.meta !== undefined && (obj.meta = message.meta);
    message.location !== undefined && (obj.location = message.location);
    message.pseudoIntensities !== undefined && (obj.pseudoIntensities = message.pseudoIntensities);
    message.normalSpectra !== undefined && (obj.normalSpectra = Math.round(message.normalSpectra));
    message.dwellSpectra !== undefined && (obj.dwellSpectra = Math.round(message.dwellSpectra));
    message.bulkSpectra !== undefined && (obj.bulkSpectra = Math.round(message.bulkSpectra));
    message.maxSpectra !== undefined && (obj.maxSpectra = Math.round(message.maxSpectra));
    return obj;
  },

  create<I extends Exact<DeepPartial<ScanEntry>, I>>(base?: I): ScanEntry {
    return ScanEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScanEntry>, I>>(object: I): ScanEntry {
    const message = createBaseScanEntry();
    message.id = object.id ?? 0;
    message.timestamp = object.timestamp ?? 0;
    message.images = object.images ?? 0;
    message.meta = object.meta ?? false;
    message.location = object.location ?? false;
    message.pseudoIntensities = object.pseudoIntensities ?? false;
    message.normalSpectra = object.normalSpectra ?? 0;
    message.dwellSpectra = object.dwellSpectra ?? 0;
    message.bulkSpectra = object.bulkSpectra ?? 0;
    message.maxSpectra = object.maxSpectra ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
