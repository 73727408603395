/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { UserInfo } from "./user";

export const protobufPackage = "";

export interface Tag {
  /** @gotags: bson:"_id,omitempty" */
  id: string;
  name: string;
  type: string;
  scanId: string;
  /** Only sent out by API, not stored in DB this way */
  owner: UserInfo | undefined;
}

export interface TagDB {
  /** @gotags: bson:"_id,omitempty" */
  id: string;
  name: string;
  type: string;
  scanId: string;
  ownerId: string;
}

function createBaseTag(): Tag {
  return { id: "", name: "", type: "", scanId: "", owner: undefined };
}

export const Tag = {
  encode(message: Tag, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.type !== "") {
      writer.uint32(26).string(message.type);
    }
    if (message.scanId !== "") {
      writer.uint32(34).string(message.scanId);
    }
    if (message.owner !== undefined) {
      UserInfo.encode(message.owner, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Tag {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTag();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.type = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.scanId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.owner = UserInfo.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Tag {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      name: isSet(object.name) ? String(object.name) : "",
      type: isSet(object.type) ? String(object.type) : "",
      scanId: isSet(object.scanId) ? String(object.scanId) : "",
      owner: isSet(object.owner) ? UserInfo.fromJSON(object.owner) : undefined,
    };
  },

  toJSON(message: Tag): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.type !== undefined && (obj.type = message.type);
    message.scanId !== undefined && (obj.scanId = message.scanId);
    message.owner !== undefined && (obj.owner = message.owner ? UserInfo.toJSON(message.owner) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<Tag>, I>>(base?: I): Tag {
    return Tag.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Tag>, I>>(object: I): Tag {
    const message = createBaseTag();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.type = object.type ?? "";
    message.scanId = object.scanId ?? "";
    message.owner = (object.owner !== undefined && object.owner !== null)
      ? UserInfo.fromPartial(object.owner)
      : undefined;
    return message;
  },
};

function createBaseTagDB(): TagDB {
  return { id: "", name: "", type: "", scanId: "", ownerId: "" };
}

export const TagDB = {
  encode(message: TagDB, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.type !== "") {
      writer.uint32(26).string(message.type);
    }
    if (message.scanId !== "") {
      writer.uint32(34).string(message.scanId);
    }
    if (message.ownerId !== "") {
      writer.uint32(42).string(message.ownerId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TagDB {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTagDB();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.type = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.scanId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.ownerId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TagDB {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      name: isSet(object.name) ? String(object.name) : "",
      type: isSet(object.type) ? String(object.type) : "",
      scanId: isSet(object.scanId) ? String(object.scanId) : "",
      ownerId: isSet(object.ownerId) ? String(object.ownerId) : "",
    };
  },

  toJSON(message: TagDB): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.type !== undefined && (obj.type = message.type);
    message.scanId !== undefined && (obj.scanId = message.scanId);
    message.ownerId !== undefined && (obj.ownerId = message.ownerId);
    return obj;
  },

  create<I extends Exact<DeepPartial<TagDB>, I>>(base?: I): TagDB {
    return TagDB.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TagDB>, I>>(object: I): TagDB {
    const message = createBaseTagDB();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.type = object.type ?? "";
    message.scanId = object.scanId ?? "";
    message.ownerId = object.ownerId ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
