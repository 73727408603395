/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { UserGroup } from "./user-group";

export const protobufPackage = "";

/**
 * //////////////////////////////////////////////////////////////
 * Adding and deleting admins from the group
 * Should only be accessible to group admins and sys admins
 * requires(NONE)
 */
export interface UserGroupAddAdminReq {
  groupId: string;
  adminUserId: string;
}

export interface UserGroupAddAdminResp {
  group: UserGroup | undefined;
}

/** requires(NONE) */
export interface UserGroupDeleteAdminReq {
  groupId: string;
  adminUserId: string;
}

export interface UserGroupDeleteAdminResp {
  group: UserGroup | undefined;
}

function createBaseUserGroupAddAdminReq(): UserGroupAddAdminReq {
  return { groupId: "", adminUserId: "" };
}

export const UserGroupAddAdminReq = {
  encode(message: UserGroupAddAdminReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.groupId !== "") {
      writer.uint32(10).string(message.groupId);
    }
    if (message.adminUserId !== "") {
      writer.uint32(18).string(message.adminUserId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserGroupAddAdminReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserGroupAddAdminReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.groupId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.adminUserId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserGroupAddAdminReq {
    return {
      groupId: isSet(object.groupId) ? String(object.groupId) : "",
      adminUserId: isSet(object.adminUserId) ? String(object.adminUserId) : "",
    };
  },

  toJSON(message: UserGroupAddAdminReq): unknown {
    const obj: any = {};
    message.groupId !== undefined && (obj.groupId = message.groupId);
    message.adminUserId !== undefined && (obj.adminUserId = message.adminUserId);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserGroupAddAdminReq>, I>>(base?: I): UserGroupAddAdminReq {
    return UserGroupAddAdminReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserGroupAddAdminReq>, I>>(object: I): UserGroupAddAdminReq {
    const message = createBaseUserGroupAddAdminReq();
    message.groupId = object.groupId ?? "";
    message.adminUserId = object.adminUserId ?? "";
    return message;
  },
};

function createBaseUserGroupAddAdminResp(): UserGroupAddAdminResp {
  return { group: undefined };
}

export const UserGroupAddAdminResp = {
  encode(message: UserGroupAddAdminResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.group !== undefined) {
      UserGroup.encode(message.group, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserGroupAddAdminResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserGroupAddAdminResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.group = UserGroup.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserGroupAddAdminResp {
    return { group: isSet(object.group) ? UserGroup.fromJSON(object.group) : undefined };
  },

  toJSON(message: UserGroupAddAdminResp): unknown {
    const obj: any = {};
    message.group !== undefined && (obj.group = message.group ? UserGroup.toJSON(message.group) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserGroupAddAdminResp>, I>>(base?: I): UserGroupAddAdminResp {
    return UserGroupAddAdminResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserGroupAddAdminResp>, I>>(object: I): UserGroupAddAdminResp {
    const message = createBaseUserGroupAddAdminResp();
    message.group = (object.group !== undefined && object.group !== null)
      ? UserGroup.fromPartial(object.group)
      : undefined;
    return message;
  },
};

function createBaseUserGroupDeleteAdminReq(): UserGroupDeleteAdminReq {
  return { groupId: "", adminUserId: "" };
}

export const UserGroupDeleteAdminReq = {
  encode(message: UserGroupDeleteAdminReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.groupId !== "") {
      writer.uint32(10).string(message.groupId);
    }
    if (message.adminUserId !== "") {
      writer.uint32(18).string(message.adminUserId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserGroupDeleteAdminReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserGroupDeleteAdminReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.groupId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.adminUserId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserGroupDeleteAdminReq {
    return {
      groupId: isSet(object.groupId) ? String(object.groupId) : "",
      adminUserId: isSet(object.adminUserId) ? String(object.adminUserId) : "",
    };
  },

  toJSON(message: UserGroupDeleteAdminReq): unknown {
    const obj: any = {};
    message.groupId !== undefined && (obj.groupId = message.groupId);
    message.adminUserId !== undefined && (obj.adminUserId = message.adminUserId);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserGroupDeleteAdminReq>, I>>(base?: I): UserGroupDeleteAdminReq {
    return UserGroupDeleteAdminReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserGroupDeleteAdminReq>, I>>(object: I): UserGroupDeleteAdminReq {
    const message = createBaseUserGroupDeleteAdminReq();
    message.groupId = object.groupId ?? "";
    message.adminUserId = object.adminUserId ?? "";
    return message;
  },
};

function createBaseUserGroupDeleteAdminResp(): UserGroupDeleteAdminResp {
  return { group: undefined };
}

export const UserGroupDeleteAdminResp = {
  encode(message: UserGroupDeleteAdminResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.group !== undefined) {
      UserGroup.encode(message.group, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserGroupDeleteAdminResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserGroupDeleteAdminResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.group = UserGroup.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserGroupDeleteAdminResp {
    return { group: isSet(object.group) ? UserGroup.fromJSON(object.group) : undefined };
  },

  toJSON(message: UserGroupDeleteAdminResp): unknown {
    const obj: any = {};
    message.group !== undefined && (obj.group = message.group ? UserGroup.toJSON(message.group) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserGroupDeleteAdminResp>, I>>(base?: I): UserGroupDeleteAdminResp {
    return UserGroupDeleteAdminResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserGroupDeleteAdminResp>, I>>(object: I): UserGroupDeleteAdminResp {
    const message = createBaseUserGroupDeleteAdminResp();
    message.group = (object.group !== undefined && object.group !== null)
      ? UserGroup.fromPartial(object.group)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
