<section class="page analysis-container">
  <analysis-sidepanel></analysis-sidepanel>
  <section
    class="screen-container"
    *ngIf="loadedScreenConfiguration && activeLayout && loadedScreenConfiguration.layouts.length > activeLayoutIndex && !soloViewWidget">
    <!-- *ngFor="let layout of loadedScreenConfiguration.layouts; let i = index; trackBy: trackByLayoutId" -->
    <section
      class="widgets-container"
      [ngStyle]="{
        'grid-template-columns': computedLayouts[activeLayoutIndex].templateColumns,
        'grid-template-rows': computedLayouts[activeLayoutIndex].templateRows
      }">
      <widget
        *ngFor="let widget of activeLayout.widgets; trackBy: trackByWidgetId"
        [widgetLayoutConfig]="widget"
        [layoutIndex]="activeLayoutIndex"
        [initWidget]="widget.type"
        [ngStyle]="{
          'grid-column-start': widget.startColumn,
          'grid-column-end': widget.endColumn,
          'grid-row-start': widget.startRow,
          'grid-row-end': widget.endRow
        }"></widget>
    </section>
  </section>
  <section class="screen-container" *ngIf="soloViewWidget">
    <section
      class="widgets-container"
      [ngStyle]="{
        'grid-template-columns': 'auto',
        'grid-template-rows': 'auto'
      }">
      <widget [widgetLayoutConfig]="soloViewWidget" [initWidget]="soloViewWidget.type"></widget>
    </section>
  </section>
</section>
