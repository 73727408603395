/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { ObjectType, objectTypeFromJSON, objectTypeToJSON, OwnershipItem, UserGroupList } from "./ownership-access";

export const protobufPackage = "";

/** requires(NONE) */
export interface GetOwnershipReq {
  objectId: string;
  /** Do we need this? */
  objectType: ObjectType;
}

export interface GetOwnershipResp {
  ownership: OwnershipItem | undefined;
}

/** requires(NONE) */
export interface ObjectEditAccessReq {
  objectId: string;
  /** Do we need this? */
  objectType: ObjectType;
  addViewers: UserGroupList | undefined;
  deleteViewers: UserGroupList | undefined;
  addEditors: UserGroupList | undefined;
  deleteEditors: UserGroupList | undefined;
}

export interface ObjectEditAccessResp {
  ownership: OwnershipItem | undefined;
}

function createBaseGetOwnershipReq(): GetOwnershipReq {
  return { objectId: "", objectType: 0 };
}

export const GetOwnershipReq = {
  encode(message: GetOwnershipReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.objectId !== "") {
      writer.uint32(10).string(message.objectId);
    }
    if (message.objectType !== 0) {
      writer.uint32(16).int32(message.objectType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetOwnershipReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetOwnershipReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.objectId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.objectType = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetOwnershipReq {
    return {
      objectId: isSet(object.objectId) ? String(object.objectId) : "",
      objectType: isSet(object.objectType) ? objectTypeFromJSON(object.objectType) : 0,
    };
  },

  toJSON(message: GetOwnershipReq): unknown {
    const obj: any = {};
    message.objectId !== undefined && (obj.objectId = message.objectId);
    message.objectType !== undefined && (obj.objectType = objectTypeToJSON(message.objectType));
    return obj;
  },

  create<I extends Exact<DeepPartial<GetOwnershipReq>, I>>(base?: I): GetOwnershipReq {
    return GetOwnershipReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetOwnershipReq>, I>>(object: I): GetOwnershipReq {
    const message = createBaseGetOwnershipReq();
    message.objectId = object.objectId ?? "";
    message.objectType = object.objectType ?? 0;
    return message;
  },
};

function createBaseGetOwnershipResp(): GetOwnershipResp {
  return { ownership: undefined };
}

export const GetOwnershipResp = {
  encode(message: GetOwnershipResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ownership !== undefined) {
      OwnershipItem.encode(message.ownership, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetOwnershipResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetOwnershipResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ownership = OwnershipItem.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetOwnershipResp {
    return { ownership: isSet(object.ownership) ? OwnershipItem.fromJSON(object.ownership) : undefined };
  },

  toJSON(message: GetOwnershipResp): unknown {
    const obj: any = {};
    message.ownership !== undefined &&
      (obj.ownership = message.ownership ? OwnershipItem.toJSON(message.ownership) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<GetOwnershipResp>, I>>(base?: I): GetOwnershipResp {
    return GetOwnershipResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetOwnershipResp>, I>>(object: I): GetOwnershipResp {
    const message = createBaseGetOwnershipResp();
    message.ownership = (object.ownership !== undefined && object.ownership !== null)
      ? OwnershipItem.fromPartial(object.ownership)
      : undefined;
    return message;
  },
};

function createBaseObjectEditAccessReq(): ObjectEditAccessReq {
  return {
    objectId: "",
    objectType: 0,
    addViewers: undefined,
    deleteViewers: undefined,
    addEditors: undefined,
    deleteEditors: undefined,
  };
}

export const ObjectEditAccessReq = {
  encode(message: ObjectEditAccessReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.objectId !== "") {
      writer.uint32(10).string(message.objectId);
    }
    if (message.objectType !== 0) {
      writer.uint32(16).int32(message.objectType);
    }
    if (message.addViewers !== undefined) {
      UserGroupList.encode(message.addViewers, writer.uint32(26).fork()).ldelim();
    }
    if (message.deleteViewers !== undefined) {
      UserGroupList.encode(message.deleteViewers, writer.uint32(34).fork()).ldelim();
    }
    if (message.addEditors !== undefined) {
      UserGroupList.encode(message.addEditors, writer.uint32(42).fork()).ldelim();
    }
    if (message.deleteEditors !== undefined) {
      UserGroupList.encode(message.deleteEditors, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ObjectEditAccessReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseObjectEditAccessReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.objectId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.objectType = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.addViewers = UserGroupList.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.deleteViewers = UserGroupList.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.addEditors = UserGroupList.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.deleteEditors = UserGroupList.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ObjectEditAccessReq {
    return {
      objectId: isSet(object.objectId) ? String(object.objectId) : "",
      objectType: isSet(object.objectType) ? objectTypeFromJSON(object.objectType) : 0,
      addViewers: isSet(object.addViewers) ? UserGroupList.fromJSON(object.addViewers) : undefined,
      deleteViewers: isSet(object.deleteViewers) ? UserGroupList.fromJSON(object.deleteViewers) : undefined,
      addEditors: isSet(object.addEditors) ? UserGroupList.fromJSON(object.addEditors) : undefined,
      deleteEditors: isSet(object.deleteEditors) ? UserGroupList.fromJSON(object.deleteEditors) : undefined,
    };
  },

  toJSON(message: ObjectEditAccessReq): unknown {
    const obj: any = {};
    message.objectId !== undefined && (obj.objectId = message.objectId);
    message.objectType !== undefined && (obj.objectType = objectTypeToJSON(message.objectType));
    message.addViewers !== undefined &&
      (obj.addViewers = message.addViewers ? UserGroupList.toJSON(message.addViewers) : undefined);
    message.deleteViewers !== undefined &&
      (obj.deleteViewers = message.deleteViewers ? UserGroupList.toJSON(message.deleteViewers) : undefined);
    message.addEditors !== undefined &&
      (obj.addEditors = message.addEditors ? UserGroupList.toJSON(message.addEditors) : undefined);
    message.deleteEditors !== undefined &&
      (obj.deleteEditors = message.deleteEditors ? UserGroupList.toJSON(message.deleteEditors) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ObjectEditAccessReq>, I>>(base?: I): ObjectEditAccessReq {
    return ObjectEditAccessReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ObjectEditAccessReq>, I>>(object: I): ObjectEditAccessReq {
    const message = createBaseObjectEditAccessReq();
    message.objectId = object.objectId ?? "";
    message.objectType = object.objectType ?? 0;
    message.addViewers = (object.addViewers !== undefined && object.addViewers !== null)
      ? UserGroupList.fromPartial(object.addViewers)
      : undefined;
    message.deleteViewers = (object.deleteViewers !== undefined && object.deleteViewers !== null)
      ? UserGroupList.fromPartial(object.deleteViewers)
      : undefined;
    message.addEditors = (object.addEditors !== undefined && object.addEditors !== null)
      ? UserGroupList.fromPartial(object.addEditors)
      : undefined;
    message.deleteEditors = (object.deleteEditors !== undefined && object.deleteEditors !== null)
      ? UserGroupList.fromPartial(object.deleteEditors)
      : undefined;
    return message;
  },
};

function createBaseObjectEditAccessResp(): ObjectEditAccessResp {
  return { ownership: undefined };
}

export const ObjectEditAccessResp = {
  encode(message: ObjectEditAccessResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ownership !== undefined) {
      OwnershipItem.encode(message.ownership, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ObjectEditAccessResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseObjectEditAccessResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ownership = OwnershipItem.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ObjectEditAccessResp {
    return { ownership: isSet(object.ownership) ? OwnershipItem.fromJSON(object.ownership) : undefined };
  },

  toJSON(message: ObjectEditAccessResp): unknown {
    const obj: any = {};
    message.ownership !== undefined &&
      (obj.ownership = message.ownership ? OwnershipItem.toJSON(message.ownership) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ObjectEditAccessResp>, I>>(base?: I): ObjectEditAccessResp {
    return ObjectEditAccessResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ObjectEditAccessResp>, I>>(object: I): ObjectEditAccessResp {
    const message = createBaseObjectEditAccessResp();
    message.ownership = (object.ownership !== undefined && object.ownership !== null)
      ? OwnershipItem.fromPartial(object.ownership)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
