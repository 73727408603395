/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { ScanEntryRange } from "./scan";
import { Spectra, Spectrum } from "./spectrum";

export const protobufPackage = "";

/** requires(NONE) */
export interface SpectrumReq {
  scanId: string;
  entries: ScanEntryRange | undefined;
  bulkSum: boolean;
  maxValue: boolean;
}

export interface SpectrumResp {
  /** Note a location may have 0 or more spectra */
  spectraPerLocation: Spectra[];
  /** One per detector */
  bulkSpectra: Spectrum[];
  /** One per detector */
  maxSpectra: Spectrum[];
  /** How many channels we have, for PIXL this is 4096 */
  channelCount: number;
  /** How many normal spectra for the entire scan, needed when we aggregate a spectrum */
  normalSpectraForScan: number;
  /** How many dwell spectra for the entire scan, needed when we aggregate a spectrum */
  dwellSpectraForScan: number;
  /** The index into the meta fields array for live time. This is sent so we have all data needed when we aggregate a spectrum */
  liveTimeMetaIndex: number;
  /** Scan import time stamp, older scans won't have one */
  timeStampUnixSec: number;
}

function createBaseSpectrumReq(): SpectrumReq {
  return { scanId: "", entries: undefined, bulkSum: false, maxValue: false };
}

export const SpectrumReq = {
  encode(message: SpectrumReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.scanId !== "") {
      writer.uint32(10).string(message.scanId);
    }
    if (message.entries !== undefined) {
      ScanEntryRange.encode(message.entries, writer.uint32(18).fork()).ldelim();
    }
    if (message.bulkSum === true) {
      writer.uint32(24).bool(message.bulkSum);
    }
    if (message.maxValue === true) {
      writer.uint32(32).bool(message.maxValue);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SpectrumReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSpectrumReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.scanId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.entries = ScanEntryRange.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.bulkSum = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.maxValue = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SpectrumReq {
    return {
      scanId: isSet(object.scanId) ? String(object.scanId) : "",
      entries: isSet(object.entries) ? ScanEntryRange.fromJSON(object.entries) : undefined,
      bulkSum: isSet(object.bulkSum) ? Boolean(object.bulkSum) : false,
      maxValue: isSet(object.maxValue) ? Boolean(object.maxValue) : false,
    };
  },

  toJSON(message: SpectrumReq): unknown {
    const obj: any = {};
    message.scanId !== undefined && (obj.scanId = message.scanId);
    message.entries !== undefined &&
      (obj.entries = message.entries ? ScanEntryRange.toJSON(message.entries) : undefined);
    message.bulkSum !== undefined && (obj.bulkSum = message.bulkSum);
    message.maxValue !== undefined && (obj.maxValue = message.maxValue);
    return obj;
  },

  create<I extends Exact<DeepPartial<SpectrumReq>, I>>(base?: I): SpectrumReq {
    return SpectrumReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SpectrumReq>, I>>(object: I): SpectrumReq {
    const message = createBaseSpectrumReq();
    message.scanId = object.scanId ?? "";
    message.entries = (object.entries !== undefined && object.entries !== null)
      ? ScanEntryRange.fromPartial(object.entries)
      : undefined;
    message.bulkSum = object.bulkSum ?? false;
    message.maxValue = object.maxValue ?? false;
    return message;
  },
};

function createBaseSpectrumResp(): SpectrumResp {
  return {
    spectraPerLocation: [],
    bulkSpectra: [],
    maxSpectra: [],
    channelCount: 0,
    normalSpectraForScan: 0,
    dwellSpectraForScan: 0,
    liveTimeMetaIndex: 0,
    timeStampUnixSec: 0,
  };
}

export const SpectrumResp = {
  encode(message: SpectrumResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.spectraPerLocation) {
      Spectra.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.bulkSpectra) {
      Spectrum.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.maxSpectra) {
      Spectrum.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.channelCount !== 0) {
      writer.uint32(32).uint32(message.channelCount);
    }
    if (message.normalSpectraForScan !== 0) {
      writer.uint32(40).uint32(message.normalSpectraForScan);
    }
    if (message.dwellSpectraForScan !== 0) {
      writer.uint32(48).uint32(message.dwellSpectraForScan);
    }
    if (message.liveTimeMetaIndex !== 0) {
      writer.uint32(56).uint32(message.liveTimeMetaIndex);
    }
    if (message.timeStampUnixSec !== 0) {
      writer.uint32(64).uint32(message.timeStampUnixSec);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SpectrumResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSpectrumResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.spectraPerLocation.push(Spectra.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.bulkSpectra.push(Spectrum.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.maxSpectra.push(Spectrum.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.channelCount = reader.uint32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.normalSpectraForScan = reader.uint32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.dwellSpectraForScan = reader.uint32();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.liveTimeMetaIndex = reader.uint32();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.timeStampUnixSec = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SpectrumResp {
    return {
      spectraPerLocation: Array.isArray(object?.spectraPerLocation)
        ? object.spectraPerLocation.map((e: any) => Spectra.fromJSON(e))
        : [],
      bulkSpectra: Array.isArray(object?.bulkSpectra) ? object.bulkSpectra.map((e: any) => Spectrum.fromJSON(e)) : [],
      maxSpectra: Array.isArray(object?.maxSpectra) ? object.maxSpectra.map((e: any) => Spectrum.fromJSON(e)) : [],
      channelCount: isSet(object.channelCount) ? Number(object.channelCount) : 0,
      normalSpectraForScan: isSet(object.normalSpectraForScan) ? Number(object.normalSpectraForScan) : 0,
      dwellSpectraForScan: isSet(object.dwellSpectraForScan) ? Number(object.dwellSpectraForScan) : 0,
      liveTimeMetaIndex: isSet(object.liveTimeMetaIndex) ? Number(object.liveTimeMetaIndex) : 0,
      timeStampUnixSec: isSet(object.timeStampUnixSec) ? Number(object.timeStampUnixSec) : 0,
    };
  },

  toJSON(message: SpectrumResp): unknown {
    const obj: any = {};
    if (message.spectraPerLocation) {
      obj.spectraPerLocation = message.spectraPerLocation.map((e) => e ? Spectra.toJSON(e) : undefined);
    } else {
      obj.spectraPerLocation = [];
    }
    if (message.bulkSpectra) {
      obj.bulkSpectra = message.bulkSpectra.map((e) => e ? Spectrum.toJSON(e) : undefined);
    } else {
      obj.bulkSpectra = [];
    }
    if (message.maxSpectra) {
      obj.maxSpectra = message.maxSpectra.map((e) => e ? Spectrum.toJSON(e) : undefined);
    } else {
      obj.maxSpectra = [];
    }
    message.channelCount !== undefined && (obj.channelCount = Math.round(message.channelCount));
    message.normalSpectraForScan !== undefined && (obj.normalSpectraForScan = Math.round(message.normalSpectraForScan));
    message.dwellSpectraForScan !== undefined && (obj.dwellSpectraForScan = Math.round(message.dwellSpectraForScan));
    message.liveTimeMetaIndex !== undefined && (obj.liveTimeMetaIndex = Math.round(message.liveTimeMetaIndex));
    message.timeStampUnixSec !== undefined && (obj.timeStampUnixSec = Math.round(message.timeStampUnixSec));
    return obj;
  },

  create<I extends Exact<DeepPartial<SpectrumResp>, I>>(base?: I): SpectrumResp {
    return SpectrumResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SpectrumResp>, I>>(object: I): SpectrumResp {
    const message = createBaseSpectrumResp();
    message.spectraPerLocation = object.spectraPerLocation?.map((e) => Spectra.fromPartial(e)) || [];
    message.bulkSpectra = object.bulkSpectra?.map((e) => Spectrum.fromPartial(e)) || [];
    message.maxSpectra = object.maxSpectra?.map((e) => Spectrum.fromPartial(e)) || [];
    message.channelCount = object.channelCount ?? 0;
    message.normalSpectraForScan = object.normalSpectraForScan ?? 0;
    message.dwellSpectraForScan = object.dwellSpectraForScan ?? 0;
    message.liveTimeMetaIndex = object.liveTimeMetaIndex ?? 0;
    message.timeStampUnixSec = object.timeStampUnixSec ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
