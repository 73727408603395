/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { Tag } from "./tags";

export const protobufPackage = "";

/** requires(NONE) */
export interface TagListReq {
}

export interface TagListResp {
  tags: Tag[];
}

/** requires(EDIT_TAGS) */
export interface TagCreateReq {
  name: string;
  type: string;
  /** Scan the tag was created on */
  scanId: string;
}

export interface TagCreateResp {
  tag: Tag | undefined;
}

/** requires(PIXLISE_ADMIN) */
export interface TagDeleteReq {
  tagId: string;
}

export interface TagDeleteResp {
}

function createBaseTagListReq(): TagListReq {
  return {};
}

export const TagListReq = {
  encode(_: TagListReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TagListReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTagListReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): TagListReq {
    return {};
  },

  toJSON(_: TagListReq): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<TagListReq>, I>>(base?: I): TagListReq {
    return TagListReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TagListReq>, I>>(_: I): TagListReq {
    const message = createBaseTagListReq();
    return message;
  },
};

function createBaseTagListResp(): TagListResp {
  return { tags: [] };
}

export const TagListResp = {
  encode(message: TagListResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.tags) {
      Tag.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TagListResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTagListResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tags.push(Tag.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TagListResp {
    return { tags: Array.isArray(object?.tags) ? object.tags.map((e: any) => Tag.fromJSON(e)) : [] };
  },

  toJSON(message: TagListResp): unknown {
    const obj: any = {};
    if (message.tags) {
      obj.tags = message.tags.map((e) => e ? Tag.toJSON(e) : undefined);
    } else {
      obj.tags = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TagListResp>, I>>(base?: I): TagListResp {
    return TagListResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TagListResp>, I>>(object: I): TagListResp {
    const message = createBaseTagListResp();
    message.tags = object.tags?.map((e) => Tag.fromPartial(e)) || [];
    return message;
  },
};

function createBaseTagCreateReq(): TagCreateReq {
  return { name: "", type: "", scanId: "" };
}

export const TagCreateReq = {
  encode(message: TagCreateReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.type !== "") {
      writer.uint32(18).string(message.type);
    }
    if (message.scanId !== "") {
      writer.uint32(26).string(message.scanId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TagCreateReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTagCreateReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.type = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.scanId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TagCreateReq {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      type: isSet(object.type) ? String(object.type) : "",
      scanId: isSet(object.scanId) ? String(object.scanId) : "",
    };
  },

  toJSON(message: TagCreateReq): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.type !== undefined && (obj.type = message.type);
    message.scanId !== undefined && (obj.scanId = message.scanId);
    return obj;
  },

  create<I extends Exact<DeepPartial<TagCreateReq>, I>>(base?: I): TagCreateReq {
    return TagCreateReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TagCreateReq>, I>>(object: I): TagCreateReq {
    const message = createBaseTagCreateReq();
    message.name = object.name ?? "";
    message.type = object.type ?? "";
    message.scanId = object.scanId ?? "";
    return message;
  },
};

function createBaseTagCreateResp(): TagCreateResp {
  return { tag: undefined };
}

export const TagCreateResp = {
  encode(message: TagCreateResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tag !== undefined) {
      Tag.encode(message.tag, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TagCreateResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTagCreateResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tag = Tag.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TagCreateResp {
    return { tag: isSet(object.tag) ? Tag.fromJSON(object.tag) : undefined };
  },

  toJSON(message: TagCreateResp): unknown {
    const obj: any = {};
    message.tag !== undefined && (obj.tag = message.tag ? Tag.toJSON(message.tag) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<TagCreateResp>, I>>(base?: I): TagCreateResp {
    return TagCreateResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TagCreateResp>, I>>(object: I): TagCreateResp {
    const message = createBaseTagCreateResp();
    message.tag = (object.tag !== undefined && object.tag !== null) ? Tag.fromPartial(object.tag) : undefined;
    return message;
  },
};

function createBaseTagDeleteReq(): TagDeleteReq {
  return { tagId: "" };
}

export const TagDeleteReq = {
  encode(message: TagDeleteReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tagId !== "") {
      writer.uint32(10).string(message.tagId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TagDeleteReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTagDeleteReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tagId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TagDeleteReq {
    return { tagId: isSet(object.tagId) ? String(object.tagId) : "" };
  },

  toJSON(message: TagDeleteReq): unknown {
    const obj: any = {};
    message.tagId !== undefined && (obj.tagId = message.tagId);
    return obj;
  },

  create<I extends Exact<DeepPartial<TagDeleteReq>, I>>(base?: I): TagDeleteReq {
    return TagDeleteReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TagDeleteReq>, I>>(object: I): TagDeleteReq {
    const message = createBaseTagDeleteReq();
    message.tagId = object.tagId ?? "";
    return message;
  },
};

function createBaseTagDeleteResp(): TagDeleteResp {
  return {};
}

export const TagDeleteResp = {
  encode(_: TagDeleteResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TagDeleteResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTagDeleteResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): TagDeleteResp {
    return {};
  },

  toJSON(_: TagDeleteResp): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<TagDeleteResp>, I>>(base?: I): TagDeleteResp {
    return TagDeleteResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TagDeleteResp>, I>>(_: I): TagDeleteResp {
    const message = createBaseTagDeleteResp();
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
