/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { Quantification } from "./quantification";
import { QuantificationSummary } from "./quantification-meta";
import { SearchParams } from "./search-params";

export const protobufPackage = "";

export enum QuantOutputType {
  /** QO_UNKNOWN - https://protobuf.dev/programming-guides/dos-donts/ says specify an unknown as 0 */
  QO_UNKNOWN = 0,
  QO_DATA = 1,
  QO_LOG = 2,
  UNRECOGNIZED = -1,
}

export function quantOutputTypeFromJSON(object: any): QuantOutputType {
  switch (object) {
    case 0:
    case "QO_UNKNOWN":
      return QuantOutputType.QO_UNKNOWN;
    case 1:
    case "QO_DATA":
      return QuantOutputType.QO_DATA;
    case 2:
    case "QO_LOG":
      return QuantOutputType.QO_LOG;
    case -1:
    case "UNRECOGNIZED":
    default:
      return QuantOutputType.UNRECOGNIZED;
  }
}

export function quantOutputTypeToJSON(object: QuantOutputType): string {
  switch (object) {
    case QuantOutputType.QO_UNKNOWN:
      return "QO_UNKNOWN";
    case QuantOutputType.QO_DATA:
      return "QO_DATA";
    case QuantOutputType.QO_LOG:
      return "QO_LOG";
    case QuantOutputType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** requires(NONE) */
export interface QuantListReq {
  searchParams: SearchParams | undefined;
}

export interface QuantListResp {
  quants: QuantificationSummary[];
}

/** requires(NONE) */
export interface QuantGetReq {
  quantId: string;
  summaryOnly: boolean;
}

export interface QuantGetResp {
  summary: QuantificationSummary | undefined;
  data: Quantification | undefined;
}

/** requires(NONE) */
export interface QuantLogListReq {
  quantId: string;
}

export interface QuantLogListResp {
  fileNames: string[];
}

/** requires(NONE) */
export interface QuantLogGetReq {
  quantId: string;
  logName: string;
}

export interface QuantLogGetResp {
  logData: string;
}

/** requires(NONE) */
export interface QuantRawDataGetReq {
  quantId: string;
}

export interface QuantRawDataGetResp {
  data: string;
}

/** requires(NONE) */
export interface QuantLastOutputGetReq {
  outputType: QuantOutputType;
  scanId: string;
  piquantCommand: string;
}

export interface QuantLastOutputGetResp {
  output: string;
}

function createBaseQuantListReq(): QuantListReq {
  return { searchParams: undefined };
}

export const QuantListReq = {
  encode(message: QuantListReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.searchParams !== undefined) {
      SearchParams.encode(message.searchParams, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuantListReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantListReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.searchParams = SearchParams.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuantListReq {
    return { searchParams: isSet(object.searchParams) ? SearchParams.fromJSON(object.searchParams) : undefined };
  },

  toJSON(message: QuantListReq): unknown {
    const obj: any = {};
    message.searchParams !== undefined &&
      (obj.searchParams = message.searchParams ? SearchParams.toJSON(message.searchParams) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<QuantListReq>, I>>(base?: I): QuantListReq {
    return QuantListReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuantListReq>, I>>(object: I): QuantListReq {
    const message = createBaseQuantListReq();
    message.searchParams = (object.searchParams !== undefined && object.searchParams !== null)
      ? SearchParams.fromPartial(object.searchParams)
      : undefined;
    return message;
  },
};

function createBaseQuantListResp(): QuantListResp {
  return { quants: [] };
}

export const QuantListResp = {
  encode(message: QuantListResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.quants) {
      QuantificationSummary.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuantListResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantListResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.quants.push(QuantificationSummary.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuantListResp {
    return {
      quants: Array.isArray(object?.quants) ? object.quants.map((e: any) => QuantificationSummary.fromJSON(e)) : [],
    };
  },

  toJSON(message: QuantListResp): unknown {
    const obj: any = {};
    if (message.quants) {
      obj.quants = message.quants.map((e) => e ? QuantificationSummary.toJSON(e) : undefined);
    } else {
      obj.quants = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<QuantListResp>, I>>(base?: I): QuantListResp {
    return QuantListResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuantListResp>, I>>(object: I): QuantListResp {
    const message = createBaseQuantListResp();
    message.quants = object.quants?.map((e) => QuantificationSummary.fromPartial(e)) || [];
    return message;
  },
};

function createBaseQuantGetReq(): QuantGetReq {
  return { quantId: "", summaryOnly: false };
}

export const QuantGetReq = {
  encode(message: QuantGetReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.quantId !== "") {
      writer.uint32(10).string(message.quantId);
    }
    if (message.summaryOnly === true) {
      writer.uint32(16).bool(message.summaryOnly);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuantGetReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantGetReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.quantId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.summaryOnly = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuantGetReq {
    return {
      quantId: isSet(object.quantId) ? String(object.quantId) : "",
      summaryOnly: isSet(object.summaryOnly) ? Boolean(object.summaryOnly) : false,
    };
  },

  toJSON(message: QuantGetReq): unknown {
    const obj: any = {};
    message.quantId !== undefined && (obj.quantId = message.quantId);
    message.summaryOnly !== undefined && (obj.summaryOnly = message.summaryOnly);
    return obj;
  },

  create<I extends Exact<DeepPartial<QuantGetReq>, I>>(base?: I): QuantGetReq {
    return QuantGetReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuantGetReq>, I>>(object: I): QuantGetReq {
    const message = createBaseQuantGetReq();
    message.quantId = object.quantId ?? "";
    message.summaryOnly = object.summaryOnly ?? false;
    return message;
  },
};

function createBaseQuantGetResp(): QuantGetResp {
  return { summary: undefined, data: undefined };
}

export const QuantGetResp = {
  encode(message: QuantGetResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.summary !== undefined) {
      QuantificationSummary.encode(message.summary, writer.uint32(10).fork()).ldelim();
    }
    if (message.data !== undefined) {
      Quantification.encode(message.data, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuantGetResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantGetResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.summary = QuantificationSummary.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.data = Quantification.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuantGetResp {
    return {
      summary: isSet(object.summary) ? QuantificationSummary.fromJSON(object.summary) : undefined,
      data: isSet(object.data) ? Quantification.fromJSON(object.data) : undefined,
    };
  },

  toJSON(message: QuantGetResp): unknown {
    const obj: any = {};
    message.summary !== undefined &&
      (obj.summary = message.summary ? QuantificationSummary.toJSON(message.summary) : undefined);
    message.data !== undefined && (obj.data = message.data ? Quantification.toJSON(message.data) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<QuantGetResp>, I>>(base?: I): QuantGetResp {
    return QuantGetResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuantGetResp>, I>>(object: I): QuantGetResp {
    const message = createBaseQuantGetResp();
    message.summary = (object.summary !== undefined && object.summary !== null)
      ? QuantificationSummary.fromPartial(object.summary)
      : undefined;
    message.data = (object.data !== undefined && object.data !== null)
      ? Quantification.fromPartial(object.data)
      : undefined;
    return message;
  },
};

function createBaseQuantLogListReq(): QuantLogListReq {
  return { quantId: "" };
}

export const QuantLogListReq = {
  encode(message: QuantLogListReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.quantId !== "") {
      writer.uint32(10).string(message.quantId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuantLogListReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantLogListReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.quantId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuantLogListReq {
    return { quantId: isSet(object.quantId) ? String(object.quantId) : "" };
  },

  toJSON(message: QuantLogListReq): unknown {
    const obj: any = {};
    message.quantId !== undefined && (obj.quantId = message.quantId);
    return obj;
  },

  create<I extends Exact<DeepPartial<QuantLogListReq>, I>>(base?: I): QuantLogListReq {
    return QuantLogListReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuantLogListReq>, I>>(object: I): QuantLogListReq {
    const message = createBaseQuantLogListReq();
    message.quantId = object.quantId ?? "";
    return message;
  },
};

function createBaseQuantLogListResp(): QuantLogListResp {
  return { fileNames: [] };
}

export const QuantLogListResp = {
  encode(message: QuantLogListResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.fileNames) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuantLogListResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantLogListResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fileNames.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuantLogListResp {
    return { fileNames: Array.isArray(object?.fileNames) ? object.fileNames.map((e: any) => String(e)) : [] };
  },

  toJSON(message: QuantLogListResp): unknown {
    const obj: any = {};
    if (message.fileNames) {
      obj.fileNames = message.fileNames.map((e) => e);
    } else {
      obj.fileNames = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<QuantLogListResp>, I>>(base?: I): QuantLogListResp {
    return QuantLogListResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuantLogListResp>, I>>(object: I): QuantLogListResp {
    const message = createBaseQuantLogListResp();
    message.fileNames = object.fileNames?.map((e) => e) || [];
    return message;
  },
};

function createBaseQuantLogGetReq(): QuantLogGetReq {
  return { quantId: "", logName: "" };
}

export const QuantLogGetReq = {
  encode(message: QuantLogGetReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.quantId !== "") {
      writer.uint32(10).string(message.quantId);
    }
    if (message.logName !== "") {
      writer.uint32(18).string(message.logName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuantLogGetReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantLogGetReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.quantId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.logName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuantLogGetReq {
    return {
      quantId: isSet(object.quantId) ? String(object.quantId) : "",
      logName: isSet(object.logName) ? String(object.logName) : "",
    };
  },

  toJSON(message: QuantLogGetReq): unknown {
    const obj: any = {};
    message.quantId !== undefined && (obj.quantId = message.quantId);
    message.logName !== undefined && (obj.logName = message.logName);
    return obj;
  },

  create<I extends Exact<DeepPartial<QuantLogGetReq>, I>>(base?: I): QuantLogGetReq {
    return QuantLogGetReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuantLogGetReq>, I>>(object: I): QuantLogGetReq {
    const message = createBaseQuantLogGetReq();
    message.quantId = object.quantId ?? "";
    message.logName = object.logName ?? "";
    return message;
  },
};

function createBaseQuantLogGetResp(): QuantLogGetResp {
  return { logData: "" };
}

export const QuantLogGetResp = {
  encode(message: QuantLogGetResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.logData !== "") {
      writer.uint32(10).string(message.logData);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuantLogGetResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantLogGetResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.logData = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuantLogGetResp {
    return { logData: isSet(object.logData) ? String(object.logData) : "" };
  },

  toJSON(message: QuantLogGetResp): unknown {
    const obj: any = {};
    message.logData !== undefined && (obj.logData = message.logData);
    return obj;
  },

  create<I extends Exact<DeepPartial<QuantLogGetResp>, I>>(base?: I): QuantLogGetResp {
    return QuantLogGetResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuantLogGetResp>, I>>(object: I): QuantLogGetResp {
    const message = createBaseQuantLogGetResp();
    message.logData = object.logData ?? "";
    return message;
  },
};

function createBaseQuantRawDataGetReq(): QuantRawDataGetReq {
  return { quantId: "" };
}

export const QuantRawDataGetReq = {
  encode(message: QuantRawDataGetReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.quantId !== "") {
      writer.uint32(10).string(message.quantId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuantRawDataGetReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantRawDataGetReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.quantId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuantRawDataGetReq {
    return { quantId: isSet(object.quantId) ? String(object.quantId) : "" };
  },

  toJSON(message: QuantRawDataGetReq): unknown {
    const obj: any = {};
    message.quantId !== undefined && (obj.quantId = message.quantId);
    return obj;
  },

  create<I extends Exact<DeepPartial<QuantRawDataGetReq>, I>>(base?: I): QuantRawDataGetReq {
    return QuantRawDataGetReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuantRawDataGetReq>, I>>(object: I): QuantRawDataGetReq {
    const message = createBaseQuantRawDataGetReq();
    message.quantId = object.quantId ?? "";
    return message;
  },
};

function createBaseQuantRawDataGetResp(): QuantRawDataGetResp {
  return { data: "" };
}

export const QuantRawDataGetResp = {
  encode(message: QuantRawDataGetResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.data !== "") {
      writer.uint32(10).string(message.data);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuantRawDataGetResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantRawDataGetResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.data = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuantRawDataGetResp {
    return { data: isSet(object.data) ? String(object.data) : "" };
  },

  toJSON(message: QuantRawDataGetResp): unknown {
    const obj: any = {};
    message.data !== undefined && (obj.data = message.data);
    return obj;
  },

  create<I extends Exact<DeepPartial<QuantRawDataGetResp>, I>>(base?: I): QuantRawDataGetResp {
    return QuantRawDataGetResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuantRawDataGetResp>, I>>(object: I): QuantRawDataGetResp {
    const message = createBaseQuantRawDataGetResp();
    message.data = object.data ?? "";
    return message;
  },
};

function createBaseQuantLastOutputGetReq(): QuantLastOutputGetReq {
  return { outputType: 0, scanId: "", piquantCommand: "" };
}

export const QuantLastOutputGetReq = {
  encode(message: QuantLastOutputGetReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.outputType !== 0) {
      writer.uint32(8).int32(message.outputType);
    }
    if (message.scanId !== "") {
      writer.uint32(18).string(message.scanId);
    }
    if (message.piquantCommand !== "") {
      writer.uint32(26).string(message.piquantCommand);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuantLastOutputGetReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantLastOutputGetReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.outputType = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.scanId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.piquantCommand = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuantLastOutputGetReq {
    return {
      outputType: isSet(object.outputType) ? quantOutputTypeFromJSON(object.outputType) : 0,
      scanId: isSet(object.scanId) ? String(object.scanId) : "",
      piquantCommand: isSet(object.piquantCommand) ? String(object.piquantCommand) : "",
    };
  },

  toJSON(message: QuantLastOutputGetReq): unknown {
    const obj: any = {};
    message.outputType !== undefined && (obj.outputType = quantOutputTypeToJSON(message.outputType));
    message.scanId !== undefined && (obj.scanId = message.scanId);
    message.piquantCommand !== undefined && (obj.piquantCommand = message.piquantCommand);
    return obj;
  },

  create<I extends Exact<DeepPartial<QuantLastOutputGetReq>, I>>(base?: I): QuantLastOutputGetReq {
    return QuantLastOutputGetReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuantLastOutputGetReq>, I>>(object: I): QuantLastOutputGetReq {
    const message = createBaseQuantLastOutputGetReq();
    message.outputType = object.outputType ?? 0;
    message.scanId = object.scanId ?? "";
    message.piquantCommand = object.piquantCommand ?? "";
    return message;
  },
};

function createBaseQuantLastOutputGetResp(): QuantLastOutputGetResp {
  return { output: "" };
}

export const QuantLastOutputGetResp = {
  encode(message: QuantLastOutputGetResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.output !== "") {
      writer.uint32(10).string(message.output);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuantLastOutputGetResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantLastOutputGetResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.output = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuantLastOutputGetResp {
    return { output: isSet(object.output) ? String(object.output) : "" };
  },

  toJSON(message: QuantLastOutputGetResp): unknown {
    const obj: any = {};
    message.output !== undefined && (obj.output = message.output);
    return obj;
  },

  create<I extends Exact<DeepPartial<QuantLastOutputGetResp>, I>>(base?: I): QuantLastOutputGetResp {
    return QuantLastOutputGetResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuantLastOutputGetResp>, I>>(object: I): QuantLastOutputGetResp {
    const message = createBaseQuantLastOutputGetResp();
    message.output = object.output ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
