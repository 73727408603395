/* eslint-disable */
import * as _m0 from "protobufjs/minimal";

export const protobufPackage = "";

export interface Quantification {
  labels: string[];
  types: Quantification_QuantDataType[];
  locationSet: Quantification_QuantLocationSet[];
}

export enum Quantification_QuantDataType {
  QT_FLOAT = 0,
  QT_INT = 1,
  UNRECOGNIZED = -1,
}

export function quantification_QuantDataTypeFromJSON(object: any): Quantification_QuantDataType {
  switch (object) {
    case 0:
    case "QT_FLOAT":
      return Quantification_QuantDataType.QT_FLOAT;
    case 1:
    case "QT_INT":
      return Quantification_QuantDataType.QT_INT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Quantification_QuantDataType.UNRECOGNIZED;
  }
}

export function quantification_QuantDataTypeToJSON(object: Quantification_QuantDataType): string {
  switch (object) {
    case Quantification_QuantDataType.QT_FLOAT:
      return "QT_FLOAT";
    case Quantification_QuantDataType.QT_INT:
      return "QT_INT";
    case Quantification_QuantDataType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Quantification_QuantLocation {
  pmc: number;
  rtt: number;
  /** string filename=4; */
  sclk: number;
  values: Quantification_QuantLocation_QuantDataItem[];
}

export interface Quantification_QuantLocation_QuantDataItem {
  fvalue: number;
  ivalue: number;
}

export interface Quantification_QuantLocationSet {
  detector: string;
  location: Quantification_QuantLocation[];
}

function createBaseQuantification(): Quantification {
  return { labels: [], types: [], locationSet: [] };
}

export const Quantification = {
  encode(message: Quantification, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.labels) {
      writer.uint32(10).string(v!);
    }
    writer.uint32(18).fork();
    for (const v of message.types) {
      writer.int32(v);
    }
    writer.ldelim();
    for (const v of message.locationSet) {
      Quantification_QuantLocationSet.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Quantification {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantification();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.labels.push(reader.string());
          continue;
        case 2:
          if (tag === 16) {
            message.types.push(reader.int32() as any);

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.types.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.locationSet.push(Quantification_QuantLocationSet.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Quantification {
    return {
      labels: Array.isArray(object?.labels) ? object.labels.map((e: any) => String(e)) : [],
      types: Array.isArray(object?.types) ? object.types.map((e: any) => quantification_QuantDataTypeFromJSON(e)) : [],
      locationSet: Array.isArray(object?.locationSet)
        ? object.locationSet.map((e: any) => Quantification_QuantLocationSet.fromJSON(e))
        : [],
    };
  },

  toJSON(message: Quantification): unknown {
    const obj: any = {};
    if (message.labels) {
      obj.labels = message.labels.map((e) => e);
    } else {
      obj.labels = [];
    }
    if (message.types) {
      obj.types = message.types.map((e) => quantification_QuantDataTypeToJSON(e));
    } else {
      obj.types = [];
    }
    if (message.locationSet) {
      obj.locationSet = message.locationSet.map((e) => e ? Quantification_QuantLocationSet.toJSON(e) : undefined);
    } else {
      obj.locationSet = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Quantification>, I>>(base?: I): Quantification {
    return Quantification.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Quantification>, I>>(object: I): Quantification {
    const message = createBaseQuantification();
    message.labels = object.labels?.map((e) => e) || [];
    message.types = object.types?.map((e) => e) || [];
    message.locationSet = object.locationSet?.map((e) => Quantification_QuantLocationSet.fromPartial(e)) || [];
    return message;
  },
};

function createBaseQuantification_QuantLocation(): Quantification_QuantLocation {
  return { pmc: 0, rtt: 0, sclk: 0, values: [] };
}

export const Quantification_QuantLocation = {
  encode(message: Quantification_QuantLocation, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pmc !== 0) {
      writer.uint32(8).int32(message.pmc);
    }
    if (message.rtt !== 0) {
      writer.uint32(16).int32(message.rtt);
    }
    if (message.sclk !== 0) {
      writer.uint32(24).int32(message.sclk);
    }
    for (const v of message.values) {
      Quantification_QuantLocation_QuantDataItem.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Quantification_QuantLocation {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantification_QuantLocation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.pmc = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.rtt = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.sclk = reader.int32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.values.push(Quantification_QuantLocation_QuantDataItem.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Quantification_QuantLocation {
    return {
      pmc: isSet(object.pmc) ? Number(object.pmc) : 0,
      rtt: isSet(object.rtt) ? Number(object.rtt) : 0,
      sclk: isSet(object.sclk) ? Number(object.sclk) : 0,
      values: Array.isArray(object?.values)
        ? object.values.map((e: any) => Quantification_QuantLocation_QuantDataItem.fromJSON(e))
        : [],
    };
  },

  toJSON(message: Quantification_QuantLocation): unknown {
    const obj: any = {};
    message.pmc !== undefined && (obj.pmc = Math.round(message.pmc));
    message.rtt !== undefined && (obj.rtt = Math.round(message.rtt));
    message.sclk !== undefined && (obj.sclk = Math.round(message.sclk));
    if (message.values) {
      obj.values = message.values.map((e) => e ? Quantification_QuantLocation_QuantDataItem.toJSON(e) : undefined);
    } else {
      obj.values = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Quantification_QuantLocation>, I>>(base?: I): Quantification_QuantLocation {
    return Quantification_QuantLocation.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Quantification_QuantLocation>, I>>(object: I): Quantification_QuantLocation {
    const message = createBaseQuantification_QuantLocation();
    message.pmc = object.pmc ?? 0;
    message.rtt = object.rtt ?? 0;
    message.sclk = object.sclk ?? 0;
    message.values = object.values?.map((e) => Quantification_QuantLocation_QuantDataItem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseQuantification_QuantLocation_QuantDataItem(): Quantification_QuantLocation_QuantDataItem {
  return { fvalue: 0, ivalue: 0 };
}

export const Quantification_QuantLocation_QuantDataItem = {
  encode(message: Quantification_QuantLocation_QuantDataItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fvalue !== 0) {
      writer.uint32(21).float(message.fvalue);
    }
    if (message.ivalue !== 0) {
      writer.uint32(24).int32(message.ivalue);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Quantification_QuantLocation_QuantDataItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantification_QuantLocation_QuantDataItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 21) {
            break;
          }

          message.fvalue = reader.float();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.ivalue = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Quantification_QuantLocation_QuantDataItem {
    return {
      fvalue: isSet(object.fvalue) ? Number(object.fvalue) : 0,
      ivalue: isSet(object.ivalue) ? Number(object.ivalue) : 0,
    };
  },

  toJSON(message: Quantification_QuantLocation_QuantDataItem): unknown {
    const obj: any = {};
    message.fvalue !== undefined && (obj.fvalue = message.fvalue);
    message.ivalue !== undefined && (obj.ivalue = Math.round(message.ivalue));
    return obj;
  },

  create<I extends Exact<DeepPartial<Quantification_QuantLocation_QuantDataItem>, I>>(
    base?: I,
  ): Quantification_QuantLocation_QuantDataItem {
    return Quantification_QuantLocation_QuantDataItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Quantification_QuantLocation_QuantDataItem>, I>>(
    object: I,
  ): Quantification_QuantLocation_QuantDataItem {
    const message = createBaseQuantification_QuantLocation_QuantDataItem();
    message.fvalue = object.fvalue ?? 0;
    message.ivalue = object.ivalue ?? 0;
    return message;
  },
};

function createBaseQuantification_QuantLocationSet(): Quantification_QuantLocationSet {
  return { detector: "", location: [] };
}

export const Quantification_QuantLocationSet = {
  encode(message: Quantification_QuantLocationSet, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.detector !== "") {
      writer.uint32(10).string(message.detector);
    }
    for (const v of message.location) {
      Quantification_QuantLocation.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Quantification_QuantLocationSet {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantification_QuantLocationSet();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.detector = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.location.push(Quantification_QuantLocation.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Quantification_QuantLocationSet {
    return {
      detector: isSet(object.detector) ? String(object.detector) : "",
      location: Array.isArray(object?.location)
        ? object.location.map((e: any) => Quantification_QuantLocation.fromJSON(e))
        : [],
    };
  },

  toJSON(message: Quantification_QuantLocationSet): unknown {
    const obj: any = {};
    message.detector !== undefined && (obj.detector = message.detector);
    if (message.location) {
      obj.location = message.location.map((e) => e ? Quantification_QuantLocation.toJSON(e) : undefined);
    } else {
      obj.location = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Quantification_QuantLocationSet>, I>>(base?: I): Quantification_QuantLocationSet {
    return Quantification_QuantLocationSet.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Quantification_QuantLocationSet>, I>>(
    object: I,
  ): Quantification_QuantLocationSet {
    const message = createBaseQuantification_QuantLocationSet();
    message.detector = object.detector ?? "";
    message.location = object.location?.map((e) => Quantification_QuantLocation.fromPartial(e)) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
