<div class="job-item {{selected ? 'selected' : '' }}">
    <div
        class="creator"
        #tooltip="matTooltip"
        [matTooltipShowDelay]="200"
        matTooltip="Creator: {{ creatorName || 'N/A' }}">
        <img *ngIf="icon" [src]="icon" />
        <span *ngIf="!icon" class="name-abbreviation">{{ creatorAbbreviation }}</span>
    </div>
    <span title="Job Name: {{job.name}}" class="name">{{job.name}}</span>
    <span title="Job started: {{job.startUnixTimeSec * 1000 | date: 'd MMM y, HH:mm:ss'}}">{{job.startUnixTimeSec * 1000 | date: "d MMM y"}}</span>
    <span *ngIf="status !== 'RUNNING'" [title]="job.message">{{status}}</span>
    <wait-spinner *ngIf="status === 'RUNNING'"></wait-spinner>
    <span *ngIf="elapsedTimeMs > 0" title="Job elapsed time">{{elapsedTimeMs | date: "mm:ss"}}</span>
</div>
