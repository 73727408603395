/* eslint-disable */
import * as Long from "long";
import * as _m0 from "protobufjs/minimal";
import { JobStatus } from "./job";
import {
  ScanAutoShareEntry,
  ScanItem,
  ScanMetaDataType,
  scanMetaDataTypeFromJSON,
  scanMetaDataTypeToJSON,
} from "./scan";

export const protobufPackage = "";

/**
 * Allows listing scans. Contains search fields. If these are all blank
 * all scans are returned
 * requires(NONE)
 */
export interface ScanListReq {
  /**
   * Allows flexible fields, because scans have flexible metadata
   * but for PIXL suggestions are:
   *
   * The scans ID:
   * - scanId
   *
   * Meta fields (member of db meta struct):
   * - Target, SiteId, Site, RTT, SCLK, Sol, DriveId, TargetId
   *
   * DB struct fields directly accessed:
   * - title
   * - description
   * - instrument
   * - instrumentConfig
   * - timeStampUnixSec
   *
   * Fields that look at counts:
   * (NOT IMPLEMENTED YET) - hasDwell
   * (NOT IMPLEMENTED YET) - hasNormal
   */
  searchFilters: { [key: string]: string };
}

export interface ScanListReq_SearchFiltersEntry {
  key: string;
  value: string;
}

export interface ScanListReq_MinMaxInt {
  min: number;
  max: number;
}

export interface ScanListResp {
  scans: ScanItem[];
}

/**
 * This just notifies client that something changed... we don't send out a list
 * of all scans because of the filtering possibilities in the request!
 * If we added/modified or deleted a scan, that has to be re-requested by the client
 * potentially after offering the user the option?
 */
export interface ScanListUpd {
}

/** requires(NONE) */
export interface ScanGetReq {
  id: string;
}

export interface ScanGetResp {
  scan: ScanItem | undefined;
}

/**
 * This should trigger a ScanListUpd to go out
 * requires(EDIT_SCAN)
 */
export interface ScanUploadReq {
  id: string;
  /** currently only allows jpl-breadboard */
  format: string;
  /**
   * NOTE: We used to send the zip data up here, but message size was never big enough
   *       so instead of increasing that, we now have a PUT /scan endpoint to pass up
   *       the zip first, then this can be called, passing the same scan id & file name
   * bytes zippedData = 3; // jpl-breadboard implies this is a zip file of MSA files
   */
  zipFileName: string;
}

export interface ScanUploadResp {
  jobId: string;
}

export interface ScanUploadUpd {
  status: JobStatus | undefined;
}

/**
 * Editing who to auto-share a scan with
 * requires(EDIT_SCAN)
 */
export interface ScanAutoShareReq {
  id: string;
}

export interface ScanAutoShareResp {
  entry: ScanAutoShareEntry | undefined;
}

/** requires(EDIT_SCAN) */
export interface ScanAutoShareWriteReq {
  entry: ScanAutoShareEntry | undefined;
}

export interface ScanAutoShareWriteResp {
}

/**
 * This should trigger a ScanListUpd to go out
 * requires(EDIT_SCAN)
 */
export interface ScanMetaWriteReq {
  scanId: string;
  title: string;
  description: string;
  /** map<string, string> metaFields = 4; */
  tags: string[];
}

export interface ScanMetaWriteResp {
}

/**
 * Triggering a re-import, should publish a ScanListUpd to go out
 * Useful really only if there is a pipeline hooked up for this kind of data that we
 * can re-trigger for. If it's a user-uploaded scan, we can't do anything really...
 * requires(EDIT_SCAN)
 */
export interface ScanTriggerReImportReq {
  scanId: string;
}

export interface ScanTriggerReImportResp {
  jobId: string;
}

export interface ScanTriggerReImportUpd {
  status: JobStatus | undefined;
}

/** requires(NONE) */
export interface ScanMetaLabelsAndTypesReq {
  scanId: string;
}

export interface ScanMetaLabelsAndTypesResp {
  metaLabels: string[];
  metaTypes: ScanMetaDataType[];
}

/** requires(EDIT_SCAN) */
export interface ScanDeleteReq {
  scanId: string;
  scanNameForVerification: string;
}

export interface ScanDeleteResp {
}

/** requires(QUANTIFY) */
export interface ScanTriggerAutoQuantReq {
  scanId: string;
}

export interface ScanTriggerAutoQuantResp {
}

function createBaseScanListReq(): ScanListReq {
  return { searchFilters: {} };
}

export const ScanListReq = {
  encode(message: ScanListReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.searchFilters).forEach(([key, value]) => {
      ScanListReq_SearchFiltersEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScanListReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScanListReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = ScanListReq_SearchFiltersEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.searchFilters[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScanListReq {
    return {
      searchFilters: isObject(object.searchFilters)
        ? Object.entries(object.searchFilters).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: ScanListReq): unknown {
    const obj: any = {};
    obj.searchFilters = {};
    if (message.searchFilters) {
      Object.entries(message.searchFilters).forEach(([k, v]) => {
        obj.searchFilters[k] = v;
      });
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ScanListReq>, I>>(base?: I): ScanListReq {
    return ScanListReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScanListReq>, I>>(object: I): ScanListReq {
    const message = createBaseScanListReq();
    message.searchFilters = Object.entries(object.searchFilters ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = String(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseScanListReq_SearchFiltersEntry(): ScanListReq_SearchFiltersEntry {
  return { key: "", value: "" };
}

export const ScanListReq_SearchFiltersEntry = {
  encode(message: ScanListReq_SearchFiltersEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScanListReq_SearchFiltersEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScanListReq_SearchFiltersEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScanListReq_SearchFiltersEntry {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? String(object.value) : "" };
  },

  toJSON(message: ScanListReq_SearchFiltersEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create<I extends Exact<DeepPartial<ScanListReq_SearchFiltersEntry>, I>>(base?: I): ScanListReq_SearchFiltersEntry {
    return ScanListReq_SearchFiltersEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScanListReq_SearchFiltersEntry>, I>>(
    object: I,
  ): ScanListReq_SearchFiltersEntry {
    const message = createBaseScanListReq_SearchFiltersEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseScanListReq_MinMaxInt(): ScanListReq_MinMaxInt {
  return { min: 0, max: 0 };
}

export const ScanListReq_MinMaxInt = {
  encode(message: ScanListReq_MinMaxInt, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.min !== 0) {
      writer.uint32(8).int64(message.min);
    }
    if (message.max !== 0) {
      writer.uint32(16).int64(message.max);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScanListReq_MinMaxInt {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScanListReq_MinMaxInt();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.min = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.max = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScanListReq_MinMaxInt {
    return { min: isSet(object.min) ? Number(object.min) : 0, max: isSet(object.max) ? Number(object.max) : 0 };
  },

  toJSON(message: ScanListReq_MinMaxInt): unknown {
    const obj: any = {};
    message.min !== undefined && (obj.min = Math.round(message.min));
    message.max !== undefined && (obj.max = Math.round(message.max));
    return obj;
  },

  create<I extends Exact<DeepPartial<ScanListReq_MinMaxInt>, I>>(base?: I): ScanListReq_MinMaxInt {
    return ScanListReq_MinMaxInt.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScanListReq_MinMaxInt>, I>>(object: I): ScanListReq_MinMaxInt {
    const message = createBaseScanListReq_MinMaxInt();
    message.min = object.min ?? 0;
    message.max = object.max ?? 0;
    return message;
  },
};

function createBaseScanListResp(): ScanListResp {
  return { scans: [] };
}

export const ScanListResp = {
  encode(message: ScanListResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.scans) {
      ScanItem.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScanListResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScanListResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.scans.push(ScanItem.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScanListResp {
    return { scans: Array.isArray(object?.scans) ? object.scans.map((e: any) => ScanItem.fromJSON(e)) : [] };
  },

  toJSON(message: ScanListResp): unknown {
    const obj: any = {};
    if (message.scans) {
      obj.scans = message.scans.map((e) => e ? ScanItem.toJSON(e) : undefined);
    } else {
      obj.scans = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ScanListResp>, I>>(base?: I): ScanListResp {
    return ScanListResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScanListResp>, I>>(object: I): ScanListResp {
    const message = createBaseScanListResp();
    message.scans = object.scans?.map((e) => ScanItem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseScanListUpd(): ScanListUpd {
  return {};
}

export const ScanListUpd = {
  encode(_: ScanListUpd, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScanListUpd {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScanListUpd();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ScanListUpd {
    return {};
  },

  toJSON(_: ScanListUpd): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ScanListUpd>, I>>(base?: I): ScanListUpd {
    return ScanListUpd.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScanListUpd>, I>>(_: I): ScanListUpd {
    const message = createBaseScanListUpd();
    return message;
  },
};

function createBaseScanGetReq(): ScanGetReq {
  return { id: "" };
}

export const ScanGetReq = {
  encode(message: ScanGetReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScanGetReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScanGetReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScanGetReq {
    return { id: isSet(object.id) ? String(object.id) : "" };
  },

  toJSON(message: ScanGetReq): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  create<I extends Exact<DeepPartial<ScanGetReq>, I>>(base?: I): ScanGetReq {
    return ScanGetReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScanGetReq>, I>>(object: I): ScanGetReq {
    const message = createBaseScanGetReq();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseScanGetResp(): ScanGetResp {
  return { scan: undefined };
}

export const ScanGetResp = {
  encode(message: ScanGetResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.scan !== undefined) {
      ScanItem.encode(message.scan, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScanGetResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScanGetResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.scan = ScanItem.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScanGetResp {
    return { scan: isSet(object.scan) ? ScanItem.fromJSON(object.scan) : undefined };
  },

  toJSON(message: ScanGetResp): unknown {
    const obj: any = {};
    message.scan !== undefined && (obj.scan = message.scan ? ScanItem.toJSON(message.scan) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ScanGetResp>, I>>(base?: I): ScanGetResp {
    return ScanGetResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScanGetResp>, I>>(object: I): ScanGetResp {
    const message = createBaseScanGetResp();
    message.scan = (object.scan !== undefined && object.scan !== null) ? ScanItem.fromPartial(object.scan) : undefined;
    return message;
  },
};

function createBaseScanUploadReq(): ScanUploadReq {
  return { id: "", format: "", zipFileName: "" };
}

export const ScanUploadReq = {
  encode(message: ScanUploadReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.format !== "") {
      writer.uint32(18).string(message.format);
    }
    if (message.zipFileName !== "") {
      writer.uint32(34).string(message.zipFileName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScanUploadReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScanUploadReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.format = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.zipFileName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScanUploadReq {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      format: isSet(object.format) ? String(object.format) : "",
      zipFileName: isSet(object.zipFileName) ? String(object.zipFileName) : "",
    };
  },

  toJSON(message: ScanUploadReq): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.format !== undefined && (obj.format = message.format);
    message.zipFileName !== undefined && (obj.zipFileName = message.zipFileName);
    return obj;
  },

  create<I extends Exact<DeepPartial<ScanUploadReq>, I>>(base?: I): ScanUploadReq {
    return ScanUploadReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScanUploadReq>, I>>(object: I): ScanUploadReq {
    const message = createBaseScanUploadReq();
    message.id = object.id ?? "";
    message.format = object.format ?? "";
    message.zipFileName = object.zipFileName ?? "";
    return message;
  },
};

function createBaseScanUploadResp(): ScanUploadResp {
  return { jobId: "" };
}

export const ScanUploadResp = {
  encode(message: ScanUploadResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.jobId !== "") {
      writer.uint32(10).string(message.jobId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScanUploadResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScanUploadResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.jobId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScanUploadResp {
    return { jobId: isSet(object.jobId) ? String(object.jobId) : "" };
  },

  toJSON(message: ScanUploadResp): unknown {
    const obj: any = {};
    message.jobId !== undefined && (obj.jobId = message.jobId);
    return obj;
  },

  create<I extends Exact<DeepPartial<ScanUploadResp>, I>>(base?: I): ScanUploadResp {
    return ScanUploadResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScanUploadResp>, I>>(object: I): ScanUploadResp {
    const message = createBaseScanUploadResp();
    message.jobId = object.jobId ?? "";
    return message;
  },
};

function createBaseScanUploadUpd(): ScanUploadUpd {
  return { status: undefined };
}

export const ScanUploadUpd = {
  encode(message: ScanUploadUpd, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== undefined) {
      JobStatus.encode(message.status, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScanUploadUpd {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScanUploadUpd();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.status = JobStatus.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScanUploadUpd {
    return { status: isSet(object.status) ? JobStatus.fromJSON(object.status) : undefined };
  },

  toJSON(message: ScanUploadUpd): unknown {
    const obj: any = {};
    message.status !== undefined && (obj.status = message.status ? JobStatus.toJSON(message.status) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ScanUploadUpd>, I>>(base?: I): ScanUploadUpd {
    return ScanUploadUpd.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScanUploadUpd>, I>>(object: I): ScanUploadUpd {
    const message = createBaseScanUploadUpd();
    message.status = (object.status !== undefined && object.status !== null)
      ? JobStatus.fromPartial(object.status)
      : undefined;
    return message;
  },
};

function createBaseScanAutoShareReq(): ScanAutoShareReq {
  return { id: "" };
}

export const ScanAutoShareReq = {
  encode(message: ScanAutoShareReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScanAutoShareReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScanAutoShareReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScanAutoShareReq {
    return { id: isSet(object.id) ? String(object.id) : "" };
  },

  toJSON(message: ScanAutoShareReq): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  create<I extends Exact<DeepPartial<ScanAutoShareReq>, I>>(base?: I): ScanAutoShareReq {
    return ScanAutoShareReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScanAutoShareReq>, I>>(object: I): ScanAutoShareReq {
    const message = createBaseScanAutoShareReq();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseScanAutoShareResp(): ScanAutoShareResp {
  return { entry: undefined };
}

export const ScanAutoShareResp = {
  encode(message: ScanAutoShareResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.entry !== undefined) {
      ScanAutoShareEntry.encode(message.entry, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScanAutoShareResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScanAutoShareResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.entry = ScanAutoShareEntry.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScanAutoShareResp {
    return { entry: isSet(object.entry) ? ScanAutoShareEntry.fromJSON(object.entry) : undefined };
  },

  toJSON(message: ScanAutoShareResp): unknown {
    const obj: any = {};
    message.entry !== undefined && (obj.entry = message.entry ? ScanAutoShareEntry.toJSON(message.entry) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ScanAutoShareResp>, I>>(base?: I): ScanAutoShareResp {
    return ScanAutoShareResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScanAutoShareResp>, I>>(object: I): ScanAutoShareResp {
    const message = createBaseScanAutoShareResp();
    message.entry = (object.entry !== undefined && object.entry !== null)
      ? ScanAutoShareEntry.fromPartial(object.entry)
      : undefined;
    return message;
  },
};

function createBaseScanAutoShareWriteReq(): ScanAutoShareWriteReq {
  return { entry: undefined };
}

export const ScanAutoShareWriteReq = {
  encode(message: ScanAutoShareWriteReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.entry !== undefined) {
      ScanAutoShareEntry.encode(message.entry, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScanAutoShareWriteReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScanAutoShareWriteReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.entry = ScanAutoShareEntry.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScanAutoShareWriteReq {
    return { entry: isSet(object.entry) ? ScanAutoShareEntry.fromJSON(object.entry) : undefined };
  },

  toJSON(message: ScanAutoShareWriteReq): unknown {
    const obj: any = {};
    message.entry !== undefined && (obj.entry = message.entry ? ScanAutoShareEntry.toJSON(message.entry) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ScanAutoShareWriteReq>, I>>(base?: I): ScanAutoShareWriteReq {
    return ScanAutoShareWriteReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScanAutoShareWriteReq>, I>>(object: I): ScanAutoShareWriteReq {
    const message = createBaseScanAutoShareWriteReq();
    message.entry = (object.entry !== undefined && object.entry !== null)
      ? ScanAutoShareEntry.fromPartial(object.entry)
      : undefined;
    return message;
  },
};

function createBaseScanAutoShareWriteResp(): ScanAutoShareWriteResp {
  return {};
}

export const ScanAutoShareWriteResp = {
  encode(_: ScanAutoShareWriteResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScanAutoShareWriteResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScanAutoShareWriteResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ScanAutoShareWriteResp {
    return {};
  },

  toJSON(_: ScanAutoShareWriteResp): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ScanAutoShareWriteResp>, I>>(base?: I): ScanAutoShareWriteResp {
    return ScanAutoShareWriteResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScanAutoShareWriteResp>, I>>(_: I): ScanAutoShareWriteResp {
    const message = createBaseScanAutoShareWriteResp();
    return message;
  },
};

function createBaseScanMetaWriteReq(): ScanMetaWriteReq {
  return { scanId: "", title: "", description: "", tags: [] };
}

export const ScanMetaWriteReq = {
  encode(message: ScanMetaWriteReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.scanId !== "") {
      writer.uint32(10).string(message.scanId);
    }
    if (message.title !== "") {
      writer.uint32(18).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    for (const v of message.tags) {
      writer.uint32(42).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScanMetaWriteReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScanMetaWriteReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.scanId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.title = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.tags.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScanMetaWriteReq {
    return {
      scanId: isSet(object.scanId) ? String(object.scanId) : "",
      title: isSet(object.title) ? String(object.title) : "",
      description: isSet(object.description) ? String(object.description) : "",
      tags: Array.isArray(object?.tags) ? object.tags.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: ScanMetaWriteReq): unknown {
    const obj: any = {};
    message.scanId !== undefined && (obj.scanId = message.scanId);
    message.title !== undefined && (obj.title = message.title);
    message.description !== undefined && (obj.description = message.description);
    if (message.tags) {
      obj.tags = message.tags.map((e) => e);
    } else {
      obj.tags = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ScanMetaWriteReq>, I>>(base?: I): ScanMetaWriteReq {
    return ScanMetaWriteReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScanMetaWriteReq>, I>>(object: I): ScanMetaWriteReq {
    const message = createBaseScanMetaWriteReq();
    message.scanId = object.scanId ?? "";
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.tags = object.tags?.map((e) => e) || [];
    return message;
  },
};

function createBaseScanMetaWriteResp(): ScanMetaWriteResp {
  return {};
}

export const ScanMetaWriteResp = {
  encode(_: ScanMetaWriteResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScanMetaWriteResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScanMetaWriteResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ScanMetaWriteResp {
    return {};
  },

  toJSON(_: ScanMetaWriteResp): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ScanMetaWriteResp>, I>>(base?: I): ScanMetaWriteResp {
    return ScanMetaWriteResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScanMetaWriteResp>, I>>(_: I): ScanMetaWriteResp {
    const message = createBaseScanMetaWriteResp();
    return message;
  },
};

function createBaseScanTriggerReImportReq(): ScanTriggerReImportReq {
  return { scanId: "" };
}

export const ScanTriggerReImportReq = {
  encode(message: ScanTriggerReImportReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.scanId !== "") {
      writer.uint32(10).string(message.scanId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScanTriggerReImportReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScanTriggerReImportReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.scanId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScanTriggerReImportReq {
    return { scanId: isSet(object.scanId) ? String(object.scanId) : "" };
  },

  toJSON(message: ScanTriggerReImportReq): unknown {
    const obj: any = {};
    message.scanId !== undefined && (obj.scanId = message.scanId);
    return obj;
  },

  create<I extends Exact<DeepPartial<ScanTriggerReImportReq>, I>>(base?: I): ScanTriggerReImportReq {
    return ScanTriggerReImportReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScanTriggerReImportReq>, I>>(object: I): ScanTriggerReImportReq {
    const message = createBaseScanTriggerReImportReq();
    message.scanId = object.scanId ?? "";
    return message;
  },
};

function createBaseScanTriggerReImportResp(): ScanTriggerReImportResp {
  return { jobId: "" };
}

export const ScanTriggerReImportResp = {
  encode(message: ScanTriggerReImportResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.jobId !== "") {
      writer.uint32(10).string(message.jobId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScanTriggerReImportResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScanTriggerReImportResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.jobId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScanTriggerReImportResp {
    return { jobId: isSet(object.jobId) ? String(object.jobId) : "" };
  },

  toJSON(message: ScanTriggerReImportResp): unknown {
    const obj: any = {};
    message.jobId !== undefined && (obj.jobId = message.jobId);
    return obj;
  },

  create<I extends Exact<DeepPartial<ScanTriggerReImportResp>, I>>(base?: I): ScanTriggerReImportResp {
    return ScanTriggerReImportResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScanTriggerReImportResp>, I>>(object: I): ScanTriggerReImportResp {
    const message = createBaseScanTriggerReImportResp();
    message.jobId = object.jobId ?? "";
    return message;
  },
};

function createBaseScanTriggerReImportUpd(): ScanTriggerReImportUpd {
  return { status: undefined };
}

export const ScanTriggerReImportUpd = {
  encode(message: ScanTriggerReImportUpd, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== undefined) {
      JobStatus.encode(message.status, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScanTriggerReImportUpd {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScanTriggerReImportUpd();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.status = JobStatus.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScanTriggerReImportUpd {
    return { status: isSet(object.status) ? JobStatus.fromJSON(object.status) : undefined };
  },

  toJSON(message: ScanTriggerReImportUpd): unknown {
    const obj: any = {};
    message.status !== undefined && (obj.status = message.status ? JobStatus.toJSON(message.status) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ScanTriggerReImportUpd>, I>>(base?: I): ScanTriggerReImportUpd {
    return ScanTriggerReImportUpd.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScanTriggerReImportUpd>, I>>(object: I): ScanTriggerReImportUpd {
    const message = createBaseScanTriggerReImportUpd();
    message.status = (object.status !== undefined && object.status !== null)
      ? JobStatus.fromPartial(object.status)
      : undefined;
    return message;
  },
};

function createBaseScanMetaLabelsAndTypesReq(): ScanMetaLabelsAndTypesReq {
  return { scanId: "" };
}

export const ScanMetaLabelsAndTypesReq = {
  encode(message: ScanMetaLabelsAndTypesReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.scanId !== "") {
      writer.uint32(10).string(message.scanId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScanMetaLabelsAndTypesReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScanMetaLabelsAndTypesReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.scanId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScanMetaLabelsAndTypesReq {
    return { scanId: isSet(object.scanId) ? String(object.scanId) : "" };
  },

  toJSON(message: ScanMetaLabelsAndTypesReq): unknown {
    const obj: any = {};
    message.scanId !== undefined && (obj.scanId = message.scanId);
    return obj;
  },

  create<I extends Exact<DeepPartial<ScanMetaLabelsAndTypesReq>, I>>(base?: I): ScanMetaLabelsAndTypesReq {
    return ScanMetaLabelsAndTypesReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScanMetaLabelsAndTypesReq>, I>>(object: I): ScanMetaLabelsAndTypesReq {
    const message = createBaseScanMetaLabelsAndTypesReq();
    message.scanId = object.scanId ?? "";
    return message;
  },
};

function createBaseScanMetaLabelsAndTypesResp(): ScanMetaLabelsAndTypesResp {
  return { metaLabels: [], metaTypes: [] };
}

export const ScanMetaLabelsAndTypesResp = {
  encode(message: ScanMetaLabelsAndTypesResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.metaLabels) {
      writer.uint32(10).string(v!);
    }
    writer.uint32(18).fork();
    for (const v of message.metaTypes) {
      writer.int32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScanMetaLabelsAndTypesResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScanMetaLabelsAndTypesResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.metaLabels.push(reader.string());
          continue;
        case 2:
          if (tag === 16) {
            message.metaTypes.push(reader.int32() as any);

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.metaTypes.push(reader.int32() as any);
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScanMetaLabelsAndTypesResp {
    return {
      metaLabels: Array.isArray(object?.metaLabels) ? object.metaLabels.map((e: any) => String(e)) : [],
      metaTypes: Array.isArray(object?.metaTypes) ? object.metaTypes.map((e: any) => scanMetaDataTypeFromJSON(e)) : [],
    };
  },

  toJSON(message: ScanMetaLabelsAndTypesResp): unknown {
    const obj: any = {};
    if (message.metaLabels) {
      obj.metaLabels = message.metaLabels.map((e) => e);
    } else {
      obj.metaLabels = [];
    }
    if (message.metaTypes) {
      obj.metaTypes = message.metaTypes.map((e) => scanMetaDataTypeToJSON(e));
    } else {
      obj.metaTypes = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ScanMetaLabelsAndTypesResp>, I>>(base?: I): ScanMetaLabelsAndTypesResp {
    return ScanMetaLabelsAndTypesResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScanMetaLabelsAndTypesResp>, I>>(object: I): ScanMetaLabelsAndTypesResp {
    const message = createBaseScanMetaLabelsAndTypesResp();
    message.metaLabels = object.metaLabels?.map((e) => e) || [];
    message.metaTypes = object.metaTypes?.map((e) => e) || [];
    return message;
  },
};

function createBaseScanDeleteReq(): ScanDeleteReq {
  return { scanId: "", scanNameForVerification: "" };
}

export const ScanDeleteReq = {
  encode(message: ScanDeleteReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.scanId !== "") {
      writer.uint32(10).string(message.scanId);
    }
    if (message.scanNameForVerification !== "") {
      writer.uint32(18).string(message.scanNameForVerification);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScanDeleteReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScanDeleteReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.scanId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.scanNameForVerification = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScanDeleteReq {
    return {
      scanId: isSet(object.scanId) ? String(object.scanId) : "",
      scanNameForVerification: isSet(object.scanNameForVerification) ? String(object.scanNameForVerification) : "",
    };
  },

  toJSON(message: ScanDeleteReq): unknown {
    const obj: any = {};
    message.scanId !== undefined && (obj.scanId = message.scanId);
    message.scanNameForVerification !== undefined && (obj.scanNameForVerification = message.scanNameForVerification);
    return obj;
  },

  create<I extends Exact<DeepPartial<ScanDeleteReq>, I>>(base?: I): ScanDeleteReq {
    return ScanDeleteReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScanDeleteReq>, I>>(object: I): ScanDeleteReq {
    const message = createBaseScanDeleteReq();
    message.scanId = object.scanId ?? "";
    message.scanNameForVerification = object.scanNameForVerification ?? "";
    return message;
  },
};

function createBaseScanDeleteResp(): ScanDeleteResp {
  return {};
}

export const ScanDeleteResp = {
  encode(_: ScanDeleteResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScanDeleteResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScanDeleteResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ScanDeleteResp {
    return {};
  },

  toJSON(_: ScanDeleteResp): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ScanDeleteResp>, I>>(base?: I): ScanDeleteResp {
    return ScanDeleteResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScanDeleteResp>, I>>(_: I): ScanDeleteResp {
    const message = createBaseScanDeleteResp();
    return message;
  },
};

function createBaseScanTriggerAutoQuantReq(): ScanTriggerAutoQuantReq {
  return { scanId: "" };
}

export const ScanTriggerAutoQuantReq = {
  encode(message: ScanTriggerAutoQuantReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.scanId !== "") {
      writer.uint32(10).string(message.scanId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScanTriggerAutoQuantReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScanTriggerAutoQuantReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.scanId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScanTriggerAutoQuantReq {
    return { scanId: isSet(object.scanId) ? String(object.scanId) : "" };
  },

  toJSON(message: ScanTriggerAutoQuantReq): unknown {
    const obj: any = {};
    message.scanId !== undefined && (obj.scanId = message.scanId);
    return obj;
  },

  create<I extends Exact<DeepPartial<ScanTriggerAutoQuantReq>, I>>(base?: I): ScanTriggerAutoQuantReq {
    return ScanTriggerAutoQuantReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScanTriggerAutoQuantReq>, I>>(object: I): ScanTriggerAutoQuantReq {
    const message = createBaseScanTriggerAutoQuantReq();
    message.scanId = object.scanId ?? "";
    return message;
  },
};

function createBaseScanTriggerAutoQuantResp(): ScanTriggerAutoQuantResp {
  return {};
}

export const ScanTriggerAutoQuantResp = {
  encode(_: ScanTriggerAutoQuantResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScanTriggerAutoQuantResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScanTriggerAutoQuantResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ScanTriggerAutoQuantResp {
    return {};
  },

  toJSON(_: ScanTriggerAutoQuantResp): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ScanTriggerAutoQuantResp>, I>>(base?: I): ScanTriggerAutoQuantResp {
    return ScanTriggerAutoQuantResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScanTriggerAutoQuantResp>, I>>(_: I): ScanTriggerAutoQuantResp {
    const message = createBaseScanTriggerAutoQuantResp();
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

// If you get a compile-error about 'Constructor<Long> and ... have no overlap',
// add '--ts_proto_opt=esModuleInterop=true' as a flag when calling 'protoc'.
if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
