/* eslint-disable */
import * as _m0 from "protobufjs/minimal";

export const protobufPackage = "";

export interface LogLine {
  timeStampUnixSec: number;
  timeStampMs: number;
  message: string;
}

function createBaseLogLine(): LogLine {
  return { timeStampUnixSec: 0, timeStampMs: 0, message: "" };
}

export const LogLine = {
  encode(message: LogLine, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.timeStampUnixSec !== 0) {
      writer.uint32(8).uint32(message.timeStampUnixSec);
    }
    if (message.timeStampMs !== 0) {
      writer.uint32(16).uint32(message.timeStampMs);
    }
    if (message.message !== "") {
      writer.uint32(26).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LogLine {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLogLine();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.timeStampUnixSec = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.timeStampMs = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.message = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LogLine {
    return {
      timeStampUnixSec: isSet(object.timeStampUnixSec) ? Number(object.timeStampUnixSec) : 0,
      timeStampMs: isSet(object.timeStampMs) ? Number(object.timeStampMs) : 0,
      message: isSet(object.message) ? String(object.message) : "",
    };
  },

  toJSON(message: LogLine): unknown {
    const obj: any = {};
    message.timeStampUnixSec !== undefined && (obj.timeStampUnixSec = Math.round(message.timeStampUnixSec));
    message.timeStampMs !== undefined && (obj.timeStampMs = Math.round(message.timeStampMs));
    message.message !== undefined && (obj.message = message.message);
    return obj;
  },

  create<I extends Exact<DeepPartial<LogLine>, I>>(base?: I): LogLine {
    return LogLine.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<LogLine>, I>>(object: I): LogLine {
    const message = createBaseLogLine();
    message.timeStampUnixSec = object.timeStampUnixSec ?? 0;
    message.timeStampMs = object.timeStampMs ?? 0;
    message.message = object.message ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
