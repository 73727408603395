/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { ScanEntryRange } from "./scan";

export const protobufPackage = "";

/** requires(NONE) */
export interface SelectedImagePixelsReq {
  image: string;
}

export interface SelectedImagePixelsResp {
  pixelIndexes: ScanEntryRange | undefined;
}

/** requires(NONE) */
export interface SelectedImagePixelsWriteReq {
  image: string;
  pixelIndexes: ScanEntryRange | undefined;
}

export interface SelectedImagePixelsWriteResp {
}

function createBaseSelectedImagePixelsReq(): SelectedImagePixelsReq {
  return { image: "" };
}

export const SelectedImagePixelsReq = {
  encode(message: SelectedImagePixelsReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.image !== "") {
      writer.uint32(10).string(message.image);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SelectedImagePixelsReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSelectedImagePixelsReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.image = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SelectedImagePixelsReq {
    return { image: isSet(object.image) ? String(object.image) : "" };
  },

  toJSON(message: SelectedImagePixelsReq): unknown {
    const obj: any = {};
    message.image !== undefined && (obj.image = message.image);
    return obj;
  },

  create<I extends Exact<DeepPartial<SelectedImagePixelsReq>, I>>(base?: I): SelectedImagePixelsReq {
    return SelectedImagePixelsReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SelectedImagePixelsReq>, I>>(object: I): SelectedImagePixelsReq {
    const message = createBaseSelectedImagePixelsReq();
    message.image = object.image ?? "";
    return message;
  },
};

function createBaseSelectedImagePixelsResp(): SelectedImagePixelsResp {
  return { pixelIndexes: undefined };
}

export const SelectedImagePixelsResp = {
  encode(message: SelectedImagePixelsResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pixelIndexes !== undefined) {
      ScanEntryRange.encode(message.pixelIndexes, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SelectedImagePixelsResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSelectedImagePixelsResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pixelIndexes = ScanEntryRange.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SelectedImagePixelsResp {
    return { pixelIndexes: isSet(object.pixelIndexes) ? ScanEntryRange.fromJSON(object.pixelIndexes) : undefined };
  },

  toJSON(message: SelectedImagePixelsResp): unknown {
    const obj: any = {};
    message.pixelIndexes !== undefined &&
      (obj.pixelIndexes = message.pixelIndexes ? ScanEntryRange.toJSON(message.pixelIndexes) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<SelectedImagePixelsResp>, I>>(base?: I): SelectedImagePixelsResp {
    return SelectedImagePixelsResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SelectedImagePixelsResp>, I>>(object: I): SelectedImagePixelsResp {
    const message = createBaseSelectedImagePixelsResp();
    message.pixelIndexes = (object.pixelIndexes !== undefined && object.pixelIndexes !== null)
      ? ScanEntryRange.fromPartial(object.pixelIndexes)
      : undefined;
    return message;
  },
};

function createBaseSelectedImagePixelsWriteReq(): SelectedImagePixelsWriteReq {
  return { image: "", pixelIndexes: undefined };
}

export const SelectedImagePixelsWriteReq = {
  encode(message: SelectedImagePixelsWriteReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.image !== "") {
      writer.uint32(10).string(message.image);
    }
    if (message.pixelIndexes !== undefined) {
      ScanEntryRange.encode(message.pixelIndexes, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SelectedImagePixelsWriteReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSelectedImagePixelsWriteReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.image = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pixelIndexes = ScanEntryRange.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SelectedImagePixelsWriteReq {
    return {
      image: isSet(object.image) ? String(object.image) : "",
      pixelIndexes: isSet(object.pixelIndexes) ? ScanEntryRange.fromJSON(object.pixelIndexes) : undefined,
    };
  },

  toJSON(message: SelectedImagePixelsWriteReq): unknown {
    const obj: any = {};
    message.image !== undefined && (obj.image = message.image);
    message.pixelIndexes !== undefined &&
      (obj.pixelIndexes = message.pixelIndexes ? ScanEntryRange.toJSON(message.pixelIndexes) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<SelectedImagePixelsWriteReq>, I>>(base?: I): SelectedImagePixelsWriteReq {
    return SelectedImagePixelsWriteReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SelectedImagePixelsWriteReq>, I>>(object: I): SelectedImagePixelsWriteReq {
    const message = createBaseSelectedImagePixelsWriteReq();
    message.image = object.image ?? "";
    message.pixelIndexes = (object.pixelIndexes !== undefined && object.pixelIndexes !== null)
      ? ScanEntryRange.fromPartial(object.pixelIndexes)
      : undefined;
    return message;
  },
};

function createBaseSelectedImagePixelsWriteResp(): SelectedImagePixelsWriteResp {
  return {};
}

export const SelectedImagePixelsWriteResp = {
  encode(_: SelectedImagePixelsWriteResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SelectedImagePixelsWriteResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSelectedImagePixelsWriteResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): SelectedImagePixelsWriteResp {
    return {};
  },

  toJSON(_: SelectedImagePixelsWriteResp): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<SelectedImagePixelsWriteResp>, I>>(base?: I): SelectedImagePixelsWriteResp {
    return SelectedImagePixelsWriteResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SelectedImagePixelsWriteResp>, I>>(_: I): SelectedImagePixelsWriteResp {
    const message = createBaseSelectedImagePixelsWriteResp();
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
