/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { JobStatus } from "./job";

export const protobufPackage = "";

/** requires(EDIT_SCAN) */
export interface ImportMarsViewerImageReq {
  triggerUrl: string;
}

export interface ImportMarsViewerImageResp {
  jobId: string;
}

export interface ImportMarsViewerImageUpd {
  status: JobStatus | undefined;
}

function createBaseImportMarsViewerImageReq(): ImportMarsViewerImageReq {
  return { triggerUrl: "" };
}

export const ImportMarsViewerImageReq = {
  encode(message: ImportMarsViewerImageReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.triggerUrl !== "") {
      writer.uint32(10).string(message.triggerUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImportMarsViewerImageReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImportMarsViewerImageReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.triggerUrl = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ImportMarsViewerImageReq {
    return { triggerUrl: isSet(object.triggerUrl) ? String(object.triggerUrl) : "" };
  },

  toJSON(message: ImportMarsViewerImageReq): unknown {
    const obj: any = {};
    message.triggerUrl !== undefined && (obj.triggerUrl = message.triggerUrl);
    return obj;
  },

  create<I extends Exact<DeepPartial<ImportMarsViewerImageReq>, I>>(base?: I): ImportMarsViewerImageReq {
    return ImportMarsViewerImageReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ImportMarsViewerImageReq>, I>>(object: I): ImportMarsViewerImageReq {
    const message = createBaseImportMarsViewerImageReq();
    message.triggerUrl = object.triggerUrl ?? "";
    return message;
  },
};

function createBaseImportMarsViewerImageResp(): ImportMarsViewerImageResp {
  return { jobId: "" };
}

export const ImportMarsViewerImageResp = {
  encode(message: ImportMarsViewerImageResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.jobId !== "") {
      writer.uint32(10).string(message.jobId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImportMarsViewerImageResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImportMarsViewerImageResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.jobId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ImportMarsViewerImageResp {
    return { jobId: isSet(object.jobId) ? String(object.jobId) : "" };
  },

  toJSON(message: ImportMarsViewerImageResp): unknown {
    const obj: any = {};
    message.jobId !== undefined && (obj.jobId = message.jobId);
    return obj;
  },

  create<I extends Exact<DeepPartial<ImportMarsViewerImageResp>, I>>(base?: I): ImportMarsViewerImageResp {
    return ImportMarsViewerImageResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ImportMarsViewerImageResp>, I>>(object: I): ImportMarsViewerImageResp {
    const message = createBaseImportMarsViewerImageResp();
    message.jobId = object.jobId ?? "";
    return message;
  },
};

function createBaseImportMarsViewerImageUpd(): ImportMarsViewerImageUpd {
  return { status: undefined };
}

export const ImportMarsViewerImageUpd = {
  encode(message: ImportMarsViewerImageUpd, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== undefined) {
      JobStatus.encode(message.status, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImportMarsViewerImageUpd {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImportMarsViewerImageUpd();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.status = JobStatus.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ImportMarsViewerImageUpd {
    return { status: isSet(object.status) ? JobStatus.fromJSON(object.status) : undefined };
  },

  toJSON(message: ImportMarsViewerImageUpd): unknown {
    const obj: any = {};
    message.status !== undefined && (obj.status = message.status ? JobStatus.toJSON(message.status) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ImportMarsViewerImageUpd>, I>>(base?: I): ImportMarsViewerImageUpd {
    return ImportMarsViewerImageUpd.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ImportMarsViewerImageUpd>, I>>(object: I): ImportMarsViewerImageUpd {
    const message = createBaseImportMarsViewerImageUpd();
    message.status = (object.status !== undefined && object.status !== null)
      ? JobStatus.fromPartial(object.status)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
