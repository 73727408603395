/* eslint-disable */
import * as _m0 from "protobufjs/minimal";

export const protobufPackage = "";

export interface DOIRelatedIdentifier {
  identifier: string;
  relation: string;
}

export interface DOICreator {
  name: string;
  affiliation: string;
  orcid: string;
}

export interface DOIContributor {
  name: string;
  affiliation: string;
  orcid: string;
  type: string;
}

export interface DOIMetadata {
  /** @gotags: bson:"_id,omitempty" */
  id: string;
  title: string;
  creators: DOICreator[];
  description: string;
  keywords: string;
  notes: string;
  relatedIdentifiers: DOIRelatedIdentifier[];
  contributors: DOIContributor[];
  references: string;
  version: string;
  doi: string;
  doiBadge: string;
  doiLink: string;
}

export interface ZenodoFileLink {
  download: string;
  self: string;
}

export interface ZenodoFile {
  checksum: string;
  filename: string;
  filesize: number;
  id: string;
  links: ZenodoFileLink | undefined;
}

export interface ZenodoLinks {
  badge: string;
  bucket: string;
  conceptBadge: string;
  conceptDOI: string;
  doi: string;
  latest: string;
  /** @gotags: json:"latest_html" */
  latestHTML: string;
  record: string;
  /** @gotags: json:"record_html" */
  recordHTML: string;
}

export interface ZenodoDraftLinks {
  bucket: string;
  discard: string;
  edit: string;
  files: string;
  html: string;
  /** @gotags: json:"latest_draft" */
  latestDraft: string;
  /** @gotags: json:"latest_draft_html" */
  latestDraftHTML: string;
  publish: string;
  self: string;
}

export interface ZenodoCommunity {
  identifier: string;
}

export interface ZenodoNameCreator {
  name: string;
}

export interface ZenodoNameAffiliationCreator {
  name: string;
  affiliation: string;
}

export interface ZenodoPrereserveDOI {
  doi: string;
  recid: number;
}

export interface ZenodoPublishResponseMetadata {
  /** @gotags: json:"access_right" */
  accessRight: string;
  communities: ZenodoCommunity[];
  creators: ZenodoNameCreator[];
  description: string;
  doi: string;
  license: string;
  /** @gotags: json:"prereserve_doi" */
  prereserveDOI:
    | ZenodoPrereserveDOI
    | undefined;
  /** @gotags: json:"publication_date" */
  publicationDate: string;
  title: string;
  /** @gotags: json:"upload_type" */
  uploadType: string;
}

export interface ZenodoPublishResponse {
  /** @gotags: json:"conceptdoi" */
  conceptDOI: string;
  /** @gotags: json:"conceptrecid" */
  conceptRecID: string;
  created: string;
  doi: string;
  /** @gotags: json:"doi_url" */
  doiURL: string;
  files: ZenodoFile[];
  id: number;
  links: ZenodoLinks | undefined;
  metadata: ZenodoPublishResponseMetadata | undefined;
  modified: string;
  owner: number;
  /** @gotags: json:"record_id" */
  recordID: number;
  state: string;
  submitted: boolean;
  title: string;
}

export interface ZenodoDepositionMetadata {
  /** @gotags: json:"access_right" */
  accessRight: string;
  communities: ZenodoCommunity[];
  creators: ZenodoNameAffiliationCreator[];
  description: string;
  doi: string;
  license: string;
  /** @gotags: json:"prereserve_doi" */
  prereserveDOI:
    | ZenodoPrereserveDOI
    | undefined;
  /** @gotags: json:"publication_date" */
  publicationDate: string;
  title: string;
  /** @gotags: json:"upload_type" */
  uploadType: string;
}

export interface ZenodoMetaResponse {
  /** @gotags: json:"conceptrecid" */
  conceptRecID: string;
  created: string;
  doi: string;
  /** @gotags: json:"doi_url" */
  doiURL: string;
  files: ZenodoFile[];
  id: number;
  links: ZenodoLinks | undefined;
  metadata: ZenodoDepositionMetadata | undefined;
  modified: string;
  owner: number;
  /** @gotags: json:"record_id" */
  recordID: number;
  state: string;
  submitted: boolean;
  title: string;
}

export interface ZenodoDepositionResponse {
  /** @gotags: json:"conceptrecid" */
  conceptRecID: string;
  created: string;
  files: ZenodoFile[];
  id: number;
  links: ZenodoDraftLinks | undefined;
  metadata: ZenodoPrereserveDOI | undefined;
  owner: number;
  /** @gotags: json:"record_id" */
  recordID: number;
  state: string;
  submitted: boolean;
  title: string;
}

export interface ZenodoFileUploadsLink {
  self: string;
  version: string;
  uploads: string;
}

export interface ZenodoFileUploadResponse {
  key: string;
  mimetype: string;
  checksum: string;
  /** @gotags: json:"version_id" */
  versionID: string;
  size: number;
  created: string;
  updated: string;
  links:
    | ZenodoFileUploadsLink
    | undefined;
  /** @gotags: json:"is_head" */
  isHead: boolean;
  /** @gotags: json:"delete_marker" */
  deleteMarker: boolean;
}

function createBaseDOIRelatedIdentifier(): DOIRelatedIdentifier {
  return { identifier: "", relation: "" };
}

export const DOIRelatedIdentifier = {
  encode(message: DOIRelatedIdentifier, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.identifier !== "") {
      writer.uint32(10).string(message.identifier);
    }
    if (message.relation !== "") {
      writer.uint32(18).string(message.relation);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DOIRelatedIdentifier {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDOIRelatedIdentifier();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.identifier = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.relation = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DOIRelatedIdentifier {
    return {
      identifier: isSet(object.identifier) ? String(object.identifier) : "",
      relation: isSet(object.relation) ? String(object.relation) : "",
    };
  },

  toJSON(message: DOIRelatedIdentifier): unknown {
    const obj: any = {};
    message.identifier !== undefined && (obj.identifier = message.identifier);
    message.relation !== undefined && (obj.relation = message.relation);
    return obj;
  },

  create<I extends Exact<DeepPartial<DOIRelatedIdentifier>, I>>(base?: I): DOIRelatedIdentifier {
    return DOIRelatedIdentifier.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DOIRelatedIdentifier>, I>>(object: I): DOIRelatedIdentifier {
    const message = createBaseDOIRelatedIdentifier();
    message.identifier = object.identifier ?? "";
    message.relation = object.relation ?? "";
    return message;
  },
};

function createBaseDOICreator(): DOICreator {
  return { name: "", affiliation: "", orcid: "" };
}

export const DOICreator = {
  encode(message: DOICreator, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.affiliation !== "") {
      writer.uint32(18).string(message.affiliation);
    }
    if (message.orcid !== "") {
      writer.uint32(26).string(message.orcid);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DOICreator {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDOICreator();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.affiliation = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.orcid = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DOICreator {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      affiliation: isSet(object.affiliation) ? String(object.affiliation) : "",
      orcid: isSet(object.orcid) ? String(object.orcid) : "",
    };
  },

  toJSON(message: DOICreator): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.affiliation !== undefined && (obj.affiliation = message.affiliation);
    message.orcid !== undefined && (obj.orcid = message.orcid);
    return obj;
  },

  create<I extends Exact<DeepPartial<DOICreator>, I>>(base?: I): DOICreator {
    return DOICreator.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DOICreator>, I>>(object: I): DOICreator {
    const message = createBaseDOICreator();
    message.name = object.name ?? "";
    message.affiliation = object.affiliation ?? "";
    message.orcid = object.orcid ?? "";
    return message;
  },
};

function createBaseDOIContributor(): DOIContributor {
  return { name: "", affiliation: "", orcid: "", type: "" };
}

export const DOIContributor = {
  encode(message: DOIContributor, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.affiliation !== "") {
      writer.uint32(18).string(message.affiliation);
    }
    if (message.orcid !== "") {
      writer.uint32(26).string(message.orcid);
    }
    if (message.type !== "") {
      writer.uint32(34).string(message.type);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DOIContributor {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDOIContributor();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.affiliation = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.orcid = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.type = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DOIContributor {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      affiliation: isSet(object.affiliation) ? String(object.affiliation) : "",
      orcid: isSet(object.orcid) ? String(object.orcid) : "",
      type: isSet(object.type) ? String(object.type) : "",
    };
  },

  toJSON(message: DOIContributor): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.affiliation !== undefined && (obj.affiliation = message.affiliation);
    message.orcid !== undefined && (obj.orcid = message.orcid);
    message.type !== undefined && (obj.type = message.type);
    return obj;
  },

  create<I extends Exact<DeepPartial<DOIContributor>, I>>(base?: I): DOIContributor {
    return DOIContributor.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DOIContributor>, I>>(object: I): DOIContributor {
    const message = createBaseDOIContributor();
    message.name = object.name ?? "";
    message.affiliation = object.affiliation ?? "";
    message.orcid = object.orcid ?? "";
    message.type = object.type ?? "";
    return message;
  },
};

function createBaseDOIMetadata(): DOIMetadata {
  return {
    id: "",
    title: "",
    creators: [],
    description: "",
    keywords: "",
    notes: "",
    relatedIdentifiers: [],
    contributors: [],
    references: "",
    version: "",
    doi: "",
    doiBadge: "",
    doiLink: "",
  };
}

export const DOIMetadata = {
  encode(message: DOIMetadata, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.title !== "") {
      writer.uint32(18).string(message.title);
    }
    for (const v of message.creators) {
      DOICreator.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.description !== "") {
      writer.uint32(34).string(message.description);
    }
    if (message.keywords !== "") {
      writer.uint32(42).string(message.keywords);
    }
    if (message.notes !== "") {
      writer.uint32(50).string(message.notes);
    }
    for (const v of message.relatedIdentifiers) {
      DOIRelatedIdentifier.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    for (const v of message.contributors) {
      DOIContributor.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    if (message.references !== "") {
      writer.uint32(74).string(message.references);
    }
    if (message.version !== "") {
      writer.uint32(82).string(message.version);
    }
    if (message.doi !== "") {
      writer.uint32(90).string(message.doi);
    }
    if (message.doiBadge !== "") {
      writer.uint32(98).string(message.doiBadge);
    }
    if (message.doiLink !== "") {
      writer.uint32(106).string(message.doiLink);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DOIMetadata {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDOIMetadata();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.title = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.creators.push(DOICreator.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.description = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.keywords = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.notes = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.relatedIdentifiers.push(DOIRelatedIdentifier.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.contributors.push(DOIContributor.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.references = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.version = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.doi = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.doiBadge = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.doiLink = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DOIMetadata {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      title: isSet(object.title) ? String(object.title) : "",
      creators: Array.isArray(object?.creators) ? object.creators.map((e: any) => DOICreator.fromJSON(e)) : [],
      description: isSet(object.description) ? String(object.description) : "",
      keywords: isSet(object.keywords) ? String(object.keywords) : "",
      notes: isSet(object.notes) ? String(object.notes) : "",
      relatedIdentifiers: Array.isArray(object?.relatedIdentifiers)
        ? object.relatedIdentifiers.map((e: any) => DOIRelatedIdentifier.fromJSON(e))
        : [],
      contributors: Array.isArray(object?.contributors)
        ? object.contributors.map((e: any) => DOIContributor.fromJSON(e))
        : [],
      references: isSet(object.references) ? String(object.references) : "",
      version: isSet(object.version) ? String(object.version) : "",
      doi: isSet(object.doi) ? String(object.doi) : "",
      doiBadge: isSet(object.doiBadge) ? String(object.doiBadge) : "",
      doiLink: isSet(object.doiLink) ? String(object.doiLink) : "",
    };
  },

  toJSON(message: DOIMetadata): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.title !== undefined && (obj.title = message.title);
    if (message.creators) {
      obj.creators = message.creators.map((e) => e ? DOICreator.toJSON(e) : undefined);
    } else {
      obj.creators = [];
    }
    message.description !== undefined && (obj.description = message.description);
    message.keywords !== undefined && (obj.keywords = message.keywords);
    message.notes !== undefined && (obj.notes = message.notes);
    if (message.relatedIdentifiers) {
      obj.relatedIdentifiers = message.relatedIdentifiers.map((e) => e ? DOIRelatedIdentifier.toJSON(e) : undefined);
    } else {
      obj.relatedIdentifiers = [];
    }
    if (message.contributors) {
      obj.contributors = message.contributors.map((e) => e ? DOIContributor.toJSON(e) : undefined);
    } else {
      obj.contributors = [];
    }
    message.references !== undefined && (obj.references = message.references);
    message.version !== undefined && (obj.version = message.version);
    message.doi !== undefined && (obj.doi = message.doi);
    message.doiBadge !== undefined && (obj.doiBadge = message.doiBadge);
    message.doiLink !== undefined && (obj.doiLink = message.doiLink);
    return obj;
  },

  create<I extends Exact<DeepPartial<DOIMetadata>, I>>(base?: I): DOIMetadata {
    return DOIMetadata.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DOIMetadata>, I>>(object: I): DOIMetadata {
    const message = createBaseDOIMetadata();
    message.id = object.id ?? "";
    message.title = object.title ?? "";
    message.creators = object.creators?.map((e) => DOICreator.fromPartial(e)) || [];
    message.description = object.description ?? "";
    message.keywords = object.keywords ?? "";
    message.notes = object.notes ?? "";
    message.relatedIdentifiers = object.relatedIdentifiers?.map((e) => DOIRelatedIdentifier.fromPartial(e)) || [];
    message.contributors = object.contributors?.map((e) => DOIContributor.fromPartial(e)) || [];
    message.references = object.references ?? "";
    message.version = object.version ?? "";
    message.doi = object.doi ?? "";
    message.doiBadge = object.doiBadge ?? "";
    message.doiLink = object.doiLink ?? "";
    return message;
  },
};

function createBaseZenodoFileLink(): ZenodoFileLink {
  return { download: "", self: "" };
}

export const ZenodoFileLink = {
  encode(message: ZenodoFileLink, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.download !== "") {
      writer.uint32(10).string(message.download);
    }
    if (message.self !== "") {
      writer.uint32(18).string(message.self);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ZenodoFileLink {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseZenodoFileLink();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.download = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.self = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ZenodoFileLink {
    return {
      download: isSet(object.download) ? String(object.download) : "",
      self: isSet(object.self) ? String(object.self) : "",
    };
  },

  toJSON(message: ZenodoFileLink): unknown {
    const obj: any = {};
    message.download !== undefined && (obj.download = message.download);
    message.self !== undefined && (obj.self = message.self);
    return obj;
  },

  create<I extends Exact<DeepPartial<ZenodoFileLink>, I>>(base?: I): ZenodoFileLink {
    return ZenodoFileLink.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ZenodoFileLink>, I>>(object: I): ZenodoFileLink {
    const message = createBaseZenodoFileLink();
    message.download = object.download ?? "";
    message.self = object.self ?? "";
    return message;
  },
};

function createBaseZenodoFile(): ZenodoFile {
  return { checksum: "", filename: "", filesize: 0, id: "", links: undefined };
}

export const ZenodoFile = {
  encode(message: ZenodoFile, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.checksum !== "") {
      writer.uint32(10).string(message.checksum);
    }
    if (message.filename !== "") {
      writer.uint32(18).string(message.filename);
    }
    if (message.filesize !== 0) {
      writer.uint32(24).uint32(message.filesize);
    }
    if (message.id !== "") {
      writer.uint32(34).string(message.id);
    }
    if (message.links !== undefined) {
      ZenodoFileLink.encode(message.links, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ZenodoFile {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseZenodoFile();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.checksum = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.filename = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.filesize = reader.uint32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.id = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.links = ZenodoFileLink.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ZenodoFile {
    return {
      checksum: isSet(object.checksum) ? String(object.checksum) : "",
      filename: isSet(object.filename) ? String(object.filename) : "",
      filesize: isSet(object.filesize) ? Number(object.filesize) : 0,
      id: isSet(object.id) ? String(object.id) : "",
      links: isSet(object.links) ? ZenodoFileLink.fromJSON(object.links) : undefined,
    };
  },

  toJSON(message: ZenodoFile): unknown {
    const obj: any = {};
    message.checksum !== undefined && (obj.checksum = message.checksum);
    message.filename !== undefined && (obj.filename = message.filename);
    message.filesize !== undefined && (obj.filesize = Math.round(message.filesize));
    message.id !== undefined && (obj.id = message.id);
    message.links !== undefined && (obj.links = message.links ? ZenodoFileLink.toJSON(message.links) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ZenodoFile>, I>>(base?: I): ZenodoFile {
    return ZenodoFile.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ZenodoFile>, I>>(object: I): ZenodoFile {
    const message = createBaseZenodoFile();
    message.checksum = object.checksum ?? "";
    message.filename = object.filename ?? "";
    message.filesize = object.filesize ?? 0;
    message.id = object.id ?? "";
    message.links = (object.links !== undefined && object.links !== null)
      ? ZenodoFileLink.fromPartial(object.links)
      : undefined;
    return message;
  },
};

function createBaseZenodoLinks(): ZenodoLinks {
  return {
    badge: "",
    bucket: "",
    conceptBadge: "",
    conceptDOI: "",
    doi: "",
    latest: "",
    latestHTML: "",
    record: "",
    recordHTML: "",
  };
}

export const ZenodoLinks = {
  encode(message: ZenodoLinks, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.badge !== "") {
      writer.uint32(10).string(message.badge);
    }
    if (message.bucket !== "") {
      writer.uint32(18).string(message.bucket);
    }
    if (message.conceptBadge !== "") {
      writer.uint32(26).string(message.conceptBadge);
    }
    if (message.conceptDOI !== "") {
      writer.uint32(34).string(message.conceptDOI);
    }
    if (message.doi !== "") {
      writer.uint32(42).string(message.doi);
    }
    if (message.latest !== "") {
      writer.uint32(50).string(message.latest);
    }
    if (message.latestHTML !== "") {
      writer.uint32(58).string(message.latestHTML);
    }
    if (message.record !== "") {
      writer.uint32(66).string(message.record);
    }
    if (message.recordHTML !== "") {
      writer.uint32(74).string(message.recordHTML);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ZenodoLinks {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseZenodoLinks();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.badge = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.bucket = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.conceptBadge = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.conceptDOI = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.doi = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.latest = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.latestHTML = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.record = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.recordHTML = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ZenodoLinks {
    return {
      badge: isSet(object.badge) ? String(object.badge) : "",
      bucket: isSet(object.bucket) ? String(object.bucket) : "",
      conceptBadge: isSet(object.conceptBadge) ? String(object.conceptBadge) : "",
      conceptDOI: isSet(object.conceptDOI) ? String(object.conceptDOI) : "",
      doi: isSet(object.doi) ? String(object.doi) : "",
      latest: isSet(object.latest) ? String(object.latest) : "",
      latestHTML: isSet(object.latestHTML) ? String(object.latestHTML) : "",
      record: isSet(object.record) ? String(object.record) : "",
      recordHTML: isSet(object.recordHTML) ? String(object.recordHTML) : "",
    };
  },

  toJSON(message: ZenodoLinks): unknown {
    const obj: any = {};
    message.badge !== undefined && (obj.badge = message.badge);
    message.bucket !== undefined && (obj.bucket = message.bucket);
    message.conceptBadge !== undefined && (obj.conceptBadge = message.conceptBadge);
    message.conceptDOI !== undefined && (obj.conceptDOI = message.conceptDOI);
    message.doi !== undefined && (obj.doi = message.doi);
    message.latest !== undefined && (obj.latest = message.latest);
    message.latestHTML !== undefined && (obj.latestHTML = message.latestHTML);
    message.record !== undefined && (obj.record = message.record);
    message.recordHTML !== undefined && (obj.recordHTML = message.recordHTML);
    return obj;
  },

  create<I extends Exact<DeepPartial<ZenodoLinks>, I>>(base?: I): ZenodoLinks {
    return ZenodoLinks.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ZenodoLinks>, I>>(object: I): ZenodoLinks {
    const message = createBaseZenodoLinks();
    message.badge = object.badge ?? "";
    message.bucket = object.bucket ?? "";
    message.conceptBadge = object.conceptBadge ?? "";
    message.conceptDOI = object.conceptDOI ?? "";
    message.doi = object.doi ?? "";
    message.latest = object.latest ?? "";
    message.latestHTML = object.latestHTML ?? "";
    message.record = object.record ?? "";
    message.recordHTML = object.recordHTML ?? "";
    return message;
  },
};

function createBaseZenodoDraftLinks(): ZenodoDraftLinks {
  return {
    bucket: "",
    discard: "",
    edit: "",
    files: "",
    html: "",
    latestDraft: "",
    latestDraftHTML: "",
    publish: "",
    self: "",
  };
}

export const ZenodoDraftLinks = {
  encode(message: ZenodoDraftLinks, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.bucket !== "") {
      writer.uint32(10).string(message.bucket);
    }
    if (message.discard !== "") {
      writer.uint32(18).string(message.discard);
    }
    if (message.edit !== "") {
      writer.uint32(26).string(message.edit);
    }
    if (message.files !== "") {
      writer.uint32(34).string(message.files);
    }
    if (message.html !== "") {
      writer.uint32(42).string(message.html);
    }
    if (message.latestDraft !== "") {
      writer.uint32(50).string(message.latestDraft);
    }
    if (message.latestDraftHTML !== "") {
      writer.uint32(58).string(message.latestDraftHTML);
    }
    if (message.publish !== "") {
      writer.uint32(66).string(message.publish);
    }
    if (message.self !== "") {
      writer.uint32(74).string(message.self);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ZenodoDraftLinks {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseZenodoDraftLinks();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.bucket = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.discard = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.edit = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.files = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.html = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.latestDraft = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.latestDraftHTML = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.publish = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.self = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ZenodoDraftLinks {
    return {
      bucket: isSet(object.bucket) ? String(object.bucket) : "",
      discard: isSet(object.discard) ? String(object.discard) : "",
      edit: isSet(object.edit) ? String(object.edit) : "",
      files: isSet(object.files) ? String(object.files) : "",
      html: isSet(object.html) ? String(object.html) : "",
      latestDraft: isSet(object.latestDraft) ? String(object.latestDraft) : "",
      latestDraftHTML: isSet(object.latestDraftHTML) ? String(object.latestDraftHTML) : "",
      publish: isSet(object.publish) ? String(object.publish) : "",
      self: isSet(object.self) ? String(object.self) : "",
    };
  },

  toJSON(message: ZenodoDraftLinks): unknown {
    const obj: any = {};
    message.bucket !== undefined && (obj.bucket = message.bucket);
    message.discard !== undefined && (obj.discard = message.discard);
    message.edit !== undefined && (obj.edit = message.edit);
    message.files !== undefined && (obj.files = message.files);
    message.html !== undefined && (obj.html = message.html);
    message.latestDraft !== undefined && (obj.latestDraft = message.latestDraft);
    message.latestDraftHTML !== undefined && (obj.latestDraftHTML = message.latestDraftHTML);
    message.publish !== undefined && (obj.publish = message.publish);
    message.self !== undefined && (obj.self = message.self);
    return obj;
  },

  create<I extends Exact<DeepPartial<ZenodoDraftLinks>, I>>(base?: I): ZenodoDraftLinks {
    return ZenodoDraftLinks.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ZenodoDraftLinks>, I>>(object: I): ZenodoDraftLinks {
    const message = createBaseZenodoDraftLinks();
    message.bucket = object.bucket ?? "";
    message.discard = object.discard ?? "";
    message.edit = object.edit ?? "";
    message.files = object.files ?? "";
    message.html = object.html ?? "";
    message.latestDraft = object.latestDraft ?? "";
    message.latestDraftHTML = object.latestDraftHTML ?? "";
    message.publish = object.publish ?? "";
    message.self = object.self ?? "";
    return message;
  },
};

function createBaseZenodoCommunity(): ZenodoCommunity {
  return { identifier: "" };
}

export const ZenodoCommunity = {
  encode(message: ZenodoCommunity, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.identifier !== "") {
      writer.uint32(10).string(message.identifier);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ZenodoCommunity {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseZenodoCommunity();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.identifier = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ZenodoCommunity {
    return { identifier: isSet(object.identifier) ? String(object.identifier) : "" };
  },

  toJSON(message: ZenodoCommunity): unknown {
    const obj: any = {};
    message.identifier !== undefined && (obj.identifier = message.identifier);
    return obj;
  },

  create<I extends Exact<DeepPartial<ZenodoCommunity>, I>>(base?: I): ZenodoCommunity {
    return ZenodoCommunity.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ZenodoCommunity>, I>>(object: I): ZenodoCommunity {
    const message = createBaseZenodoCommunity();
    message.identifier = object.identifier ?? "";
    return message;
  },
};

function createBaseZenodoNameCreator(): ZenodoNameCreator {
  return { name: "" };
}

export const ZenodoNameCreator = {
  encode(message: ZenodoNameCreator, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ZenodoNameCreator {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseZenodoNameCreator();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ZenodoNameCreator {
    return { name: isSet(object.name) ? String(object.name) : "" };
  },

  toJSON(message: ZenodoNameCreator): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  create<I extends Exact<DeepPartial<ZenodoNameCreator>, I>>(base?: I): ZenodoNameCreator {
    return ZenodoNameCreator.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ZenodoNameCreator>, I>>(object: I): ZenodoNameCreator {
    const message = createBaseZenodoNameCreator();
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseZenodoNameAffiliationCreator(): ZenodoNameAffiliationCreator {
  return { name: "", affiliation: "" };
}

export const ZenodoNameAffiliationCreator = {
  encode(message: ZenodoNameAffiliationCreator, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.affiliation !== "") {
      writer.uint32(18).string(message.affiliation);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ZenodoNameAffiliationCreator {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseZenodoNameAffiliationCreator();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.affiliation = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ZenodoNameAffiliationCreator {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      affiliation: isSet(object.affiliation) ? String(object.affiliation) : "",
    };
  },

  toJSON(message: ZenodoNameAffiliationCreator): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.affiliation !== undefined && (obj.affiliation = message.affiliation);
    return obj;
  },

  create<I extends Exact<DeepPartial<ZenodoNameAffiliationCreator>, I>>(base?: I): ZenodoNameAffiliationCreator {
    return ZenodoNameAffiliationCreator.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ZenodoNameAffiliationCreator>, I>>(object: I): ZenodoNameAffiliationCreator {
    const message = createBaseZenodoNameAffiliationCreator();
    message.name = object.name ?? "";
    message.affiliation = object.affiliation ?? "";
    return message;
  },
};

function createBaseZenodoPrereserveDOI(): ZenodoPrereserveDOI {
  return { doi: "", recid: 0 };
}

export const ZenodoPrereserveDOI = {
  encode(message: ZenodoPrereserveDOI, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.doi !== "") {
      writer.uint32(10).string(message.doi);
    }
    if (message.recid !== 0) {
      writer.uint32(16).uint32(message.recid);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ZenodoPrereserveDOI {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseZenodoPrereserveDOI();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.doi = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.recid = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ZenodoPrereserveDOI {
    return { doi: isSet(object.doi) ? String(object.doi) : "", recid: isSet(object.recid) ? Number(object.recid) : 0 };
  },

  toJSON(message: ZenodoPrereserveDOI): unknown {
    const obj: any = {};
    message.doi !== undefined && (obj.doi = message.doi);
    message.recid !== undefined && (obj.recid = Math.round(message.recid));
    return obj;
  },

  create<I extends Exact<DeepPartial<ZenodoPrereserveDOI>, I>>(base?: I): ZenodoPrereserveDOI {
    return ZenodoPrereserveDOI.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ZenodoPrereserveDOI>, I>>(object: I): ZenodoPrereserveDOI {
    const message = createBaseZenodoPrereserveDOI();
    message.doi = object.doi ?? "";
    message.recid = object.recid ?? 0;
    return message;
  },
};

function createBaseZenodoPublishResponseMetadata(): ZenodoPublishResponseMetadata {
  return {
    accessRight: "",
    communities: [],
    creators: [],
    description: "",
    doi: "",
    license: "",
    prereserveDOI: undefined,
    publicationDate: "",
    title: "",
    uploadType: "",
  };
}

export const ZenodoPublishResponseMetadata = {
  encode(message: ZenodoPublishResponseMetadata, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accessRight !== "") {
      writer.uint32(10).string(message.accessRight);
    }
    for (const v of message.communities) {
      ZenodoCommunity.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.creators) {
      ZenodoNameCreator.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.description !== "") {
      writer.uint32(34).string(message.description);
    }
    if (message.doi !== "") {
      writer.uint32(42).string(message.doi);
    }
    if (message.license !== "") {
      writer.uint32(50).string(message.license);
    }
    if (message.prereserveDOI !== undefined) {
      ZenodoPrereserveDOI.encode(message.prereserveDOI, writer.uint32(58).fork()).ldelim();
    }
    if (message.publicationDate !== "") {
      writer.uint32(66).string(message.publicationDate);
    }
    if (message.title !== "") {
      writer.uint32(74).string(message.title);
    }
    if (message.uploadType !== "") {
      writer.uint32(82).string(message.uploadType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ZenodoPublishResponseMetadata {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseZenodoPublishResponseMetadata();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accessRight = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.communities.push(ZenodoCommunity.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.creators.push(ZenodoNameCreator.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.description = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.doi = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.license = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.prereserveDOI = ZenodoPrereserveDOI.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.publicationDate = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.title = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.uploadType = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ZenodoPublishResponseMetadata {
    return {
      accessRight: isSet(object.accessRight) ? String(object.accessRight) : "",
      communities: Array.isArray(object?.communities)
        ? object.communities.map((e: any) => ZenodoCommunity.fromJSON(e))
        : [],
      creators: Array.isArray(object?.creators) ? object.creators.map((e: any) => ZenodoNameCreator.fromJSON(e)) : [],
      description: isSet(object.description) ? String(object.description) : "",
      doi: isSet(object.doi) ? String(object.doi) : "",
      license: isSet(object.license) ? String(object.license) : "",
      prereserveDOI: isSet(object.prereserveDOI) ? ZenodoPrereserveDOI.fromJSON(object.prereserveDOI) : undefined,
      publicationDate: isSet(object.publicationDate) ? String(object.publicationDate) : "",
      title: isSet(object.title) ? String(object.title) : "",
      uploadType: isSet(object.uploadType) ? String(object.uploadType) : "",
    };
  },

  toJSON(message: ZenodoPublishResponseMetadata): unknown {
    const obj: any = {};
    message.accessRight !== undefined && (obj.accessRight = message.accessRight);
    if (message.communities) {
      obj.communities = message.communities.map((e) => e ? ZenodoCommunity.toJSON(e) : undefined);
    } else {
      obj.communities = [];
    }
    if (message.creators) {
      obj.creators = message.creators.map((e) => e ? ZenodoNameCreator.toJSON(e) : undefined);
    } else {
      obj.creators = [];
    }
    message.description !== undefined && (obj.description = message.description);
    message.doi !== undefined && (obj.doi = message.doi);
    message.license !== undefined && (obj.license = message.license);
    message.prereserveDOI !== undefined &&
      (obj.prereserveDOI = message.prereserveDOI ? ZenodoPrereserveDOI.toJSON(message.prereserveDOI) : undefined);
    message.publicationDate !== undefined && (obj.publicationDate = message.publicationDate);
    message.title !== undefined && (obj.title = message.title);
    message.uploadType !== undefined && (obj.uploadType = message.uploadType);
    return obj;
  },

  create<I extends Exact<DeepPartial<ZenodoPublishResponseMetadata>, I>>(base?: I): ZenodoPublishResponseMetadata {
    return ZenodoPublishResponseMetadata.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ZenodoPublishResponseMetadata>, I>>(
    object: I,
  ): ZenodoPublishResponseMetadata {
    const message = createBaseZenodoPublishResponseMetadata();
    message.accessRight = object.accessRight ?? "";
    message.communities = object.communities?.map((e) => ZenodoCommunity.fromPartial(e)) || [];
    message.creators = object.creators?.map((e) => ZenodoNameCreator.fromPartial(e)) || [];
    message.description = object.description ?? "";
    message.doi = object.doi ?? "";
    message.license = object.license ?? "";
    message.prereserveDOI = (object.prereserveDOI !== undefined && object.prereserveDOI !== null)
      ? ZenodoPrereserveDOI.fromPartial(object.prereserveDOI)
      : undefined;
    message.publicationDate = object.publicationDate ?? "";
    message.title = object.title ?? "";
    message.uploadType = object.uploadType ?? "";
    return message;
  },
};

function createBaseZenodoPublishResponse(): ZenodoPublishResponse {
  return {
    conceptDOI: "",
    conceptRecID: "",
    created: "",
    doi: "",
    doiURL: "",
    files: [],
    id: 0,
    links: undefined,
    metadata: undefined,
    modified: "",
    owner: 0,
    recordID: 0,
    state: "",
    submitted: false,
    title: "",
  };
}

export const ZenodoPublishResponse = {
  encode(message: ZenodoPublishResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.conceptDOI !== "") {
      writer.uint32(10).string(message.conceptDOI);
    }
    if (message.conceptRecID !== "") {
      writer.uint32(18).string(message.conceptRecID);
    }
    if (message.created !== "") {
      writer.uint32(26).string(message.created);
    }
    if (message.doi !== "") {
      writer.uint32(34).string(message.doi);
    }
    if (message.doiURL !== "") {
      writer.uint32(42).string(message.doiURL);
    }
    for (const v of message.files) {
      ZenodoFile.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    if (message.id !== 0) {
      writer.uint32(56).uint32(message.id);
    }
    if (message.links !== undefined) {
      ZenodoLinks.encode(message.links, writer.uint32(66).fork()).ldelim();
    }
    if (message.metadata !== undefined) {
      ZenodoPublishResponseMetadata.encode(message.metadata, writer.uint32(74).fork()).ldelim();
    }
    if (message.modified !== "") {
      writer.uint32(82).string(message.modified);
    }
    if (message.owner !== 0) {
      writer.uint32(88).uint32(message.owner);
    }
    if (message.recordID !== 0) {
      writer.uint32(96).uint32(message.recordID);
    }
    if (message.state !== "") {
      writer.uint32(106).string(message.state);
    }
    if (message.submitted === true) {
      writer.uint32(112).bool(message.submitted);
    }
    if (message.title !== "") {
      writer.uint32(122).string(message.title);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ZenodoPublishResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseZenodoPublishResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.conceptDOI = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.conceptRecID = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.created = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.doi = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.doiURL = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.files.push(ZenodoFile.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.id = reader.uint32();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.links = ZenodoLinks.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.metadata = ZenodoPublishResponseMetadata.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.modified = reader.string();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.owner = reader.uint32();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.recordID = reader.uint32();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.state = reader.string();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.submitted = reader.bool();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.title = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ZenodoPublishResponse {
    return {
      conceptDOI: isSet(object.conceptDOI) ? String(object.conceptDOI) : "",
      conceptRecID: isSet(object.conceptRecID) ? String(object.conceptRecID) : "",
      created: isSet(object.created) ? String(object.created) : "",
      doi: isSet(object.doi) ? String(object.doi) : "",
      doiURL: isSet(object.doiURL) ? String(object.doiURL) : "",
      files: Array.isArray(object?.files) ? object.files.map((e: any) => ZenodoFile.fromJSON(e)) : [],
      id: isSet(object.id) ? Number(object.id) : 0,
      links: isSet(object.links) ? ZenodoLinks.fromJSON(object.links) : undefined,
      metadata: isSet(object.metadata) ? ZenodoPublishResponseMetadata.fromJSON(object.metadata) : undefined,
      modified: isSet(object.modified) ? String(object.modified) : "",
      owner: isSet(object.owner) ? Number(object.owner) : 0,
      recordID: isSet(object.recordID) ? Number(object.recordID) : 0,
      state: isSet(object.state) ? String(object.state) : "",
      submitted: isSet(object.submitted) ? Boolean(object.submitted) : false,
      title: isSet(object.title) ? String(object.title) : "",
    };
  },

  toJSON(message: ZenodoPublishResponse): unknown {
    const obj: any = {};
    message.conceptDOI !== undefined && (obj.conceptDOI = message.conceptDOI);
    message.conceptRecID !== undefined && (obj.conceptRecID = message.conceptRecID);
    message.created !== undefined && (obj.created = message.created);
    message.doi !== undefined && (obj.doi = message.doi);
    message.doiURL !== undefined && (obj.doiURL = message.doiURL);
    if (message.files) {
      obj.files = message.files.map((e) => e ? ZenodoFile.toJSON(e) : undefined);
    } else {
      obj.files = [];
    }
    message.id !== undefined && (obj.id = Math.round(message.id));
    message.links !== undefined && (obj.links = message.links ? ZenodoLinks.toJSON(message.links) : undefined);
    message.metadata !== undefined &&
      (obj.metadata = message.metadata ? ZenodoPublishResponseMetadata.toJSON(message.metadata) : undefined);
    message.modified !== undefined && (obj.modified = message.modified);
    message.owner !== undefined && (obj.owner = Math.round(message.owner));
    message.recordID !== undefined && (obj.recordID = Math.round(message.recordID));
    message.state !== undefined && (obj.state = message.state);
    message.submitted !== undefined && (obj.submitted = message.submitted);
    message.title !== undefined && (obj.title = message.title);
    return obj;
  },

  create<I extends Exact<DeepPartial<ZenodoPublishResponse>, I>>(base?: I): ZenodoPublishResponse {
    return ZenodoPublishResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ZenodoPublishResponse>, I>>(object: I): ZenodoPublishResponse {
    const message = createBaseZenodoPublishResponse();
    message.conceptDOI = object.conceptDOI ?? "";
    message.conceptRecID = object.conceptRecID ?? "";
    message.created = object.created ?? "";
    message.doi = object.doi ?? "";
    message.doiURL = object.doiURL ?? "";
    message.files = object.files?.map((e) => ZenodoFile.fromPartial(e)) || [];
    message.id = object.id ?? 0;
    message.links = (object.links !== undefined && object.links !== null)
      ? ZenodoLinks.fromPartial(object.links)
      : undefined;
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? ZenodoPublishResponseMetadata.fromPartial(object.metadata)
      : undefined;
    message.modified = object.modified ?? "";
    message.owner = object.owner ?? 0;
    message.recordID = object.recordID ?? 0;
    message.state = object.state ?? "";
    message.submitted = object.submitted ?? false;
    message.title = object.title ?? "";
    return message;
  },
};

function createBaseZenodoDepositionMetadata(): ZenodoDepositionMetadata {
  return {
    accessRight: "",
    communities: [],
    creators: [],
    description: "",
    doi: "",
    license: "",
    prereserveDOI: undefined,
    publicationDate: "",
    title: "",
    uploadType: "",
  };
}

export const ZenodoDepositionMetadata = {
  encode(message: ZenodoDepositionMetadata, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accessRight !== "") {
      writer.uint32(10).string(message.accessRight);
    }
    for (const v of message.communities) {
      ZenodoCommunity.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.creators) {
      ZenodoNameAffiliationCreator.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.description !== "") {
      writer.uint32(34).string(message.description);
    }
    if (message.doi !== "") {
      writer.uint32(42).string(message.doi);
    }
    if (message.license !== "") {
      writer.uint32(50).string(message.license);
    }
    if (message.prereserveDOI !== undefined) {
      ZenodoPrereserveDOI.encode(message.prereserveDOI, writer.uint32(58).fork()).ldelim();
    }
    if (message.publicationDate !== "") {
      writer.uint32(66).string(message.publicationDate);
    }
    if (message.title !== "") {
      writer.uint32(74).string(message.title);
    }
    if (message.uploadType !== "") {
      writer.uint32(82).string(message.uploadType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ZenodoDepositionMetadata {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseZenodoDepositionMetadata();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accessRight = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.communities.push(ZenodoCommunity.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.creators.push(ZenodoNameAffiliationCreator.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.description = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.doi = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.license = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.prereserveDOI = ZenodoPrereserveDOI.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.publicationDate = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.title = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.uploadType = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ZenodoDepositionMetadata {
    return {
      accessRight: isSet(object.accessRight) ? String(object.accessRight) : "",
      communities: Array.isArray(object?.communities)
        ? object.communities.map((e: any) => ZenodoCommunity.fromJSON(e))
        : [],
      creators: Array.isArray(object?.creators)
        ? object.creators.map((e: any) => ZenodoNameAffiliationCreator.fromJSON(e))
        : [],
      description: isSet(object.description) ? String(object.description) : "",
      doi: isSet(object.doi) ? String(object.doi) : "",
      license: isSet(object.license) ? String(object.license) : "",
      prereserveDOI: isSet(object.prereserveDOI) ? ZenodoPrereserveDOI.fromJSON(object.prereserveDOI) : undefined,
      publicationDate: isSet(object.publicationDate) ? String(object.publicationDate) : "",
      title: isSet(object.title) ? String(object.title) : "",
      uploadType: isSet(object.uploadType) ? String(object.uploadType) : "",
    };
  },

  toJSON(message: ZenodoDepositionMetadata): unknown {
    const obj: any = {};
    message.accessRight !== undefined && (obj.accessRight = message.accessRight);
    if (message.communities) {
      obj.communities = message.communities.map((e) => e ? ZenodoCommunity.toJSON(e) : undefined);
    } else {
      obj.communities = [];
    }
    if (message.creators) {
      obj.creators = message.creators.map((e) => e ? ZenodoNameAffiliationCreator.toJSON(e) : undefined);
    } else {
      obj.creators = [];
    }
    message.description !== undefined && (obj.description = message.description);
    message.doi !== undefined && (obj.doi = message.doi);
    message.license !== undefined && (obj.license = message.license);
    message.prereserveDOI !== undefined &&
      (obj.prereserveDOI = message.prereserveDOI ? ZenodoPrereserveDOI.toJSON(message.prereserveDOI) : undefined);
    message.publicationDate !== undefined && (obj.publicationDate = message.publicationDate);
    message.title !== undefined && (obj.title = message.title);
    message.uploadType !== undefined && (obj.uploadType = message.uploadType);
    return obj;
  },

  create<I extends Exact<DeepPartial<ZenodoDepositionMetadata>, I>>(base?: I): ZenodoDepositionMetadata {
    return ZenodoDepositionMetadata.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ZenodoDepositionMetadata>, I>>(object: I): ZenodoDepositionMetadata {
    const message = createBaseZenodoDepositionMetadata();
    message.accessRight = object.accessRight ?? "";
    message.communities = object.communities?.map((e) => ZenodoCommunity.fromPartial(e)) || [];
    message.creators = object.creators?.map((e) => ZenodoNameAffiliationCreator.fromPartial(e)) || [];
    message.description = object.description ?? "";
    message.doi = object.doi ?? "";
    message.license = object.license ?? "";
    message.prereserveDOI = (object.prereserveDOI !== undefined && object.prereserveDOI !== null)
      ? ZenodoPrereserveDOI.fromPartial(object.prereserveDOI)
      : undefined;
    message.publicationDate = object.publicationDate ?? "";
    message.title = object.title ?? "";
    message.uploadType = object.uploadType ?? "";
    return message;
  },
};

function createBaseZenodoMetaResponse(): ZenodoMetaResponse {
  return {
    conceptRecID: "",
    created: "",
    doi: "",
    doiURL: "",
    files: [],
    id: 0,
    links: undefined,
    metadata: undefined,
    modified: "",
    owner: 0,
    recordID: 0,
    state: "",
    submitted: false,
    title: "",
  };
}

export const ZenodoMetaResponse = {
  encode(message: ZenodoMetaResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.conceptRecID !== "") {
      writer.uint32(10).string(message.conceptRecID);
    }
    if (message.created !== "") {
      writer.uint32(18).string(message.created);
    }
    if (message.doi !== "") {
      writer.uint32(26).string(message.doi);
    }
    if (message.doiURL !== "") {
      writer.uint32(34).string(message.doiURL);
    }
    for (const v of message.files) {
      ZenodoFile.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.id !== 0) {
      writer.uint32(48).uint32(message.id);
    }
    if (message.links !== undefined) {
      ZenodoLinks.encode(message.links, writer.uint32(58).fork()).ldelim();
    }
    if (message.metadata !== undefined) {
      ZenodoDepositionMetadata.encode(message.metadata, writer.uint32(66).fork()).ldelim();
    }
    if (message.modified !== "") {
      writer.uint32(74).string(message.modified);
    }
    if (message.owner !== 0) {
      writer.uint32(80).uint32(message.owner);
    }
    if (message.recordID !== 0) {
      writer.uint32(88).uint32(message.recordID);
    }
    if (message.state !== "") {
      writer.uint32(98).string(message.state);
    }
    if (message.submitted === true) {
      writer.uint32(104).bool(message.submitted);
    }
    if (message.title !== "") {
      writer.uint32(114).string(message.title);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ZenodoMetaResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseZenodoMetaResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.conceptRecID = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.created = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.doi = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.doiURL = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.files.push(ZenodoFile.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.id = reader.uint32();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.links = ZenodoLinks.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.metadata = ZenodoDepositionMetadata.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.modified = reader.string();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.owner = reader.uint32();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.recordID = reader.uint32();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.state = reader.string();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.submitted = reader.bool();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.title = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ZenodoMetaResponse {
    return {
      conceptRecID: isSet(object.conceptRecID) ? String(object.conceptRecID) : "",
      created: isSet(object.created) ? String(object.created) : "",
      doi: isSet(object.doi) ? String(object.doi) : "",
      doiURL: isSet(object.doiURL) ? String(object.doiURL) : "",
      files: Array.isArray(object?.files) ? object.files.map((e: any) => ZenodoFile.fromJSON(e)) : [],
      id: isSet(object.id) ? Number(object.id) : 0,
      links: isSet(object.links) ? ZenodoLinks.fromJSON(object.links) : undefined,
      metadata: isSet(object.metadata) ? ZenodoDepositionMetadata.fromJSON(object.metadata) : undefined,
      modified: isSet(object.modified) ? String(object.modified) : "",
      owner: isSet(object.owner) ? Number(object.owner) : 0,
      recordID: isSet(object.recordID) ? Number(object.recordID) : 0,
      state: isSet(object.state) ? String(object.state) : "",
      submitted: isSet(object.submitted) ? Boolean(object.submitted) : false,
      title: isSet(object.title) ? String(object.title) : "",
    };
  },

  toJSON(message: ZenodoMetaResponse): unknown {
    const obj: any = {};
    message.conceptRecID !== undefined && (obj.conceptRecID = message.conceptRecID);
    message.created !== undefined && (obj.created = message.created);
    message.doi !== undefined && (obj.doi = message.doi);
    message.doiURL !== undefined && (obj.doiURL = message.doiURL);
    if (message.files) {
      obj.files = message.files.map((e) => e ? ZenodoFile.toJSON(e) : undefined);
    } else {
      obj.files = [];
    }
    message.id !== undefined && (obj.id = Math.round(message.id));
    message.links !== undefined && (obj.links = message.links ? ZenodoLinks.toJSON(message.links) : undefined);
    message.metadata !== undefined &&
      (obj.metadata = message.metadata ? ZenodoDepositionMetadata.toJSON(message.metadata) : undefined);
    message.modified !== undefined && (obj.modified = message.modified);
    message.owner !== undefined && (obj.owner = Math.round(message.owner));
    message.recordID !== undefined && (obj.recordID = Math.round(message.recordID));
    message.state !== undefined && (obj.state = message.state);
    message.submitted !== undefined && (obj.submitted = message.submitted);
    message.title !== undefined && (obj.title = message.title);
    return obj;
  },

  create<I extends Exact<DeepPartial<ZenodoMetaResponse>, I>>(base?: I): ZenodoMetaResponse {
    return ZenodoMetaResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ZenodoMetaResponse>, I>>(object: I): ZenodoMetaResponse {
    const message = createBaseZenodoMetaResponse();
    message.conceptRecID = object.conceptRecID ?? "";
    message.created = object.created ?? "";
    message.doi = object.doi ?? "";
    message.doiURL = object.doiURL ?? "";
    message.files = object.files?.map((e) => ZenodoFile.fromPartial(e)) || [];
    message.id = object.id ?? 0;
    message.links = (object.links !== undefined && object.links !== null)
      ? ZenodoLinks.fromPartial(object.links)
      : undefined;
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? ZenodoDepositionMetadata.fromPartial(object.metadata)
      : undefined;
    message.modified = object.modified ?? "";
    message.owner = object.owner ?? 0;
    message.recordID = object.recordID ?? 0;
    message.state = object.state ?? "";
    message.submitted = object.submitted ?? false;
    message.title = object.title ?? "";
    return message;
  },
};

function createBaseZenodoDepositionResponse(): ZenodoDepositionResponse {
  return {
    conceptRecID: "",
    created: "",
    files: [],
    id: 0,
    links: undefined,
    metadata: undefined,
    owner: 0,
    recordID: 0,
    state: "",
    submitted: false,
    title: "",
  };
}

export const ZenodoDepositionResponse = {
  encode(message: ZenodoDepositionResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.conceptRecID !== "") {
      writer.uint32(10).string(message.conceptRecID);
    }
    if (message.created !== "") {
      writer.uint32(18).string(message.created);
    }
    for (const v of message.files) {
      ZenodoFile.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.id !== 0) {
      writer.uint32(32).uint32(message.id);
    }
    if (message.links !== undefined) {
      ZenodoDraftLinks.encode(message.links, writer.uint32(42).fork()).ldelim();
    }
    if (message.metadata !== undefined) {
      ZenodoPrereserveDOI.encode(message.metadata, writer.uint32(50).fork()).ldelim();
    }
    if (message.owner !== 0) {
      writer.uint32(56).uint32(message.owner);
    }
    if (message.recordID !== 0) {
      writer.uint32(64).uint32(message.recordID);
    }
    if (message.state !== "") {
      writer.uint32(74).string(message.state);
    }
    if (message.submitted === true) {
      writer.uint32(80).bool(message.submitted);
    }
    if (message.title !== "") {
      writer.uint32(90).string(message.title);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ZenodoDepositionResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseZenodoDepositionResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.conceptRecID = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.created = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.files.push(ZenodoFile.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.id = reader.uint32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.links = ZenodoDraftLinks.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.metadata = ZenodoPrereserveDOI.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.owner = reader.uint32();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.recordID = reader.uint32();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.state = reader.string();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.submitted = reader.bool();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.title = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ZenodoDepositionResponse {
    return {
      conceptRecID: isSet(object.conceptRecID) ? String(object.conceptRecID) : "",
      created: isSet(object.created) ? String(object.created) : "",
      files: Array.isArray(object?.files) ? object.files.map((e: any) => ZenodoFile.fromJSON(e)) : [],
      id: isSet(object.id) ? Number(object.id) : 0,
      links: isSet(object.links) ? ZenodoDraftLinks.fromJSON(object.links) : undefined,
      metadata: isSet(object.metadata) ? ZenodoPrereserveDOI.fromJSON(object.metadata) : undefined,
      owner: isSet(object.owner) ? Number(object.owner) : 0,
      recordID: isSet(object.recordID) ? Number(object.recordID) : 0,
      state: isSet(object.state) ? String(object.state) : "",
      submitted: isSet(object.submitted) ? Boolean(object.submitted) : false,
      title: isSet(object.title) ? String(object.title) : "",
    };
  },

  toJSON(message: ZenodoDepositionResponse): unknown {
    const obj: any = {};
    message.conceptRecID !== undefined && (obj.conceptRecID = message.conceptRecID);
    message.created !== undefined && (obj.created = message.created);
    if (message.files) {
      obj.files = message.files.map((e) => e ? ZenodoFile.toJSON(e) : undefined);
    } else {
      obj.files = [];
    }
    message.id !== undefined && (obj.id = Math.round(message.id));
    message.links !== undefined && (obj.links = message.links ? ZenodoDraftLinks.toJSON(message.links) : undefined);
    message.metadata !== undefined &&
      (obj.metadata = message.metadata ? ZenodoPrereserveDOI.toJSON(message.metadata) : undefined);
    message.owner !== undefined && (obj.owner = Math.round(message.owner));
    message.recordID !== undefined && (obj.recordID = Math.round(message.recordID));
    message.state !== undefined && (obj.state = message.state);
    message.submitted !== undefined && (obj.submitted = message.submitted);
    message.title !== undefined && (obj.title = message.title);
    return obj;
  },

  create<I extends Exact<DeepPartial<ZenodoDepositionResponse>, I>>(base?: I): ZenodoDepositionResponse {
    return ZenodoDepositionResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ZenodoDepositionResponse>, I>>(object: I): ZenodoDepositionResponse {
    const message = createBaseZenodoDepositionResponse();
    message.conceptRecID = object.conceptRecID ?? "";
    message.created = object.created ?? "";
    message.files = object.files?.map((e) => ZenodoFile.fromPartial(e)) || [];
    message.id = object.id ?? 0;
    message.links = (object.links !== undefined && object.links !== null)
      ? ZenodoDraftLinks.fromPartial(object.links)
      : undefined;
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? ZenodoPrereserveDOI.fromPartial(object.metadata)
      : undefined;
    message.owner = object.owner ?? 0;
    message.recordID = object.recordID ?? 0;
    message.state = object.state ?? "";
    message.submitted = object.submitted ?? false;
    message.title = object.title ?? "";
    return message;
  },
};

function createBaseZenodoFileUploadsLink(): ZenodoFileUploadsLink {
  return { self: "", version: "", uploads: "" };
}

export const ZenodoFileUploadsLink = {
  encode(message: ZenodoFileUploadsLink, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.self !== "") {
      writer.uint32(10).string(message.self);
    }
    if (message.version !== "") {
      writer.uint32(18).string(message.version);
    }
    if (message.uploads !== "") {
      writer.uint32(26).string(message.uploads);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ZenodoFileUploadsLink {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseZenodoFileUploadsLink();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.self = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.version = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.uploads = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ZenodoFileUploadsLink {
    return {
      self: isSet(object.self) ? String(object.self) : "",
      version: isSet(object.version) ? String(object.version) : "",
      uploads: isSet(object.uploads) ? String(object.uploads) : "",
    };
  },

  toJSON(message: ZenodoFileUploadsLink): unknown {
    const obj: any = {};
    message.self !== undefined && (obj.self = message.self);
    message.version !== undefined && (obj.version = message.version);
    message.uploads !== undefined && (obj.uploads = message.uploads);
    return obj;
  },

  create<I extends Exact<DeepPartial<ZenodoFileUploadsLink>, I>>(base?: I): ZenodoFileUploadsLink {
    return ZenodoFileUploadsLink.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ZenodoFileUploadsLink>, I>>(object: I): ZenodoFileUploadsLink {
    const message = createBaseZenodoFileUploadsLink();
    message.self = object.self ?? "";
    message.version = object.version ?? "";
    message.uploads = object.uploads ?? "";
    return message;
  },
};

function createBaseZenodoFileUploadResponse(): ZenodoFileUploadResponse {
  return {
    key: "",
    mimetype: "",
    checksum: "",
    versionID: "",
    size: 0,
    created: "",
    updated: "",
    links: undefined,
    isHead: false,
    deleteMarker: false,
  };
}

export const ZenodoFileUploadResponse = {
  encode(message: ZenodoFileUploadResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.mimetype !== "") {
      writer.uint32(18).string(message.mimetype);
    }
    if (message.checksum !== "") {
      writer.uint32(26).string(message.checksum);
    }
    if (message.versionID !== "") {
      writer.uint32(34).string(message.versionID);
    }
    if (message.size !== 0) {
      writer.uint32(40).uint32(message.size);
    }
    if (message.created !== "") {
      writer.uint32(50).string(message.created);
    }
    if (message.updated !== "") {
      writer.uint32(58).string(message.updated);
    }
    if (message.links !== undefined) {
      ZenodoFileUploadsLink.encode(message.links, writer.uint32(66).fork()).ldelim();
    }
    if (message.isHead === true) {
      writer.uint32(72).bool(message.isHead);
    }
    if (message.deleteMarker === true) {
      writer.uint32(80).bool(message.deleteMarker);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ZenodoFileUploadResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseZenodoFileUploadResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.mimetype = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.checksum = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.versionID = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.size = reader.uint32();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.created = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.updated = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.links = ZenodoFileUploadsLink.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.isHead = reader.bool();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.deleteMarker = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ZenodoFileUploadResponse {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      mimetype: isSet(object.mimetype) ? String(object.mimetype) : "",
      checksum: isSet(object.checksum) ? String(object.checksum) : "",
      versionID: isSet(object.versionID) ? String(object.versionID) : "",
      size: isSet(object.size) ? Number(object.size) : 0,
      created: isSet(object.created) ? String(object.created) : "",
      updated: isSet(object.updated) ? String(object.updated) : "",
      links: isSet(object.links) ? ZenodoFileUploadsLink.fromJSON(object.links) : undefined,
      isHead: isSet(object.isHead) ? Boolean(object.isHead) : false,
      deleteMarker: isSet(object.deleteMarker) ? Boolean(object.deleteMarker) : false,
    };
  },

  toJSON(message: ZenodoFileUploadResponse): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.mimetype !== undefined && (obj.mimetype = message.mimetype);
    message.checksum !== undefined && (obj.checksum = message.checksum);
    message.versionID !== undefined && (obj.versionID = message.versionID);
    message.size !== undefined && (obj.size = Math.round(message.size));
    message.created !== undefined && (obj.created = message.created);
    message.updated !== undefined && (obj.updated = message.updated);
    message.links !== undefined &&
      (obj.links = message.links ? ZenodoFileUploadsLink.toJSON(message.links) : undefined);
    message.isHead !== undefined && (obj.isHead = message.isHead);
    message.deleteMarker !== undefined && (obj.deleteMarker = message.deleteMarker);
    return obj;
  },

  create<I extends Exact<DeepPartial<ZenodoFileUploadResponse>, I>>(base?: I): ZenodoFileUploadResponse {
    return ZenodoFileUploadResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ZenodoFileUploadResponse>, I>>(object: I): ZenodoFileUploadResponse {
    const message = createBaseZenodoFileUploadResponse();
    message.key = object.key ?? "";
    message.mimetype = object.mimetype ?? "";
    message.checksum = object.checksum ?? "";
    message.versionID = object.versionID ?? "";
    message.size = object.size ?? 0;
    message.created = object.created ?? "";
    message.updated = object.updated ?? "";
    message.links = (object.links !== undefined && object.links !== null)
      ? ZenodoFileUploadsLink.fromPartial(object.links)
      : undefined;
    message.isHead = object.isHead ?? false;
    message.deleteMarker = object.deleteMarker ?? false;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
