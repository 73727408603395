<div class="settings-container">
  <div class="user-groups">
    <div class="user-info">
      <user-icon size="26px"></user-icon>
      <h3 class="name">{{ name }}</h3>
    </div>
    <div class="groups my-groups"></div>
    <div class="groups admin-groups">
      <div class="header">
        <h2>Admin</h2>
        <img src="assets/icons/admin-badge.svg" />
      </div>
      <div class="content-container">
        <div
          (click)="onSelectGroup(group)"
          *ngFor="let group of groupsWithAdminAccess"
          class="group-row {{ selectedGroup?.info?.id === group.id ? 'selected' : '' }}">
          <h5 class="group-title">
            {{ group.name }}
            <span *ngIf="groupAccessRequests[group.id]?.length" class="request-count">{{ groupAccessRequests[group.id]?.length || 0 }}</span>
          </h5>
          <action-button
            color="#BCBCBC"
            *ngIf="canDeleteGroup"
            action="close"
            [disabled]="!group"
            confirmText="Are you sure you want to delete this group?"
            tooltipTitle="Delete"
            (onClick)="onDeleteGroup(group)"></action-button>
        </div>
        <div *ngIf="groupsWithAdminAccess.length === 0" class="group-row empty">
          <h5>None</h5>
        </div>
      </div>
    </div>
    <div class="groups member-groups">
      <div class="header">
        <h2>Member</h2>
        <img src="assets/icons/member-badge.svg" />
      </div>
      <div class="content-container">
        <div
          (click)="onSelectGroup(group)"
          *ngFor="let group of groupsWithMemberAccess"
          class="group-row {{ selectedGroup?.info?.id === group.id ? 'selected' : '' }}">
          <h5 class="group-title">
            {{ group.name }}
            <span *ngIf="groupAccessRequests[group.id]?.length" class="request-count">{{ groupAccessRequests[group.id]?.length || 0 }}</span>
          </h5>
          <action-button
            color="#BCBCBC"
            *ngIf="canDeleteGroup"
            action="close"
            [disabled]="!group"
            confirmText="Are you sure you want to delete this group?"
            tooltipTitle="Delete"
            (onClick)="onDeleteGroup(group)"></action-button>
        </div>
        <div *ngIf="groupsWithMemberAccess.length === 0" class="group-row empty">
          <h5>None</h5>
        </div>
      </div>
    </div>
    <div class="groups viewer-groups">
      <div class="header">
        <h2>Viewer</h2>
        <img src="assets/icons/viewer-badge.svg" />
      </div>
      <div class="content-container">
        <div
          (click)="onSelectGroup(group)"
          *ngFor="let group of groupsWithViewerAccess"
          class="group-row {{ selectedGroup?.info?.id === group.id ? 'selected' : '' }}">
          <h5 class="group-title">
            {{ group.name }}
            <span *ngIf="groupAccessRequests[group.id]?.length" class="request-count">{{ groupAccessRequests[group.id]?.length || 0 }}</span>
          </h5>
          <action-button
            color="#BCBCBC"
            *ngIf="canDeleteGroup"
            action="close"
            [disabled]="!group"
            confirmText="Are you sure you want to delete this group?"
            tooltipTitle="Delete"
            (onClick)="onDeleteGroup(group)"></action-button>
        </div>
        <div *ngIf="groupsWithViewerAccess.length === 0" class="group-row empty">
          <h5>None</h5>
        </div>
      </div>
    </div>
    <div class="content-container request">
      <push-button (onClick)="onOpenGroupRequestDialog()" buttonStyle="yellow">Request Group Access</push-button>
    </div>
    <div class="new-group">
      <action-button color="#BCBCBC" action="add" tooltipTitle="Create new group" (onClick)="onOpenCreateNewGroupDialog()"></action-button>
    </div>
  </div>
  <div class="group-container">
    <div class="selected-group">
      <div class="group-header">
        <h1 class="{{ selectedGroup?.info?.name ? 'active' : '' }}">{{ selectedGroup?.info?.name || "Select a Group ..." }}</h1>
        <action-button
          *ngIf="selectedGroup"
          action="add"
          [disabled]="!selectedGroup"
          tooltipTitle="Add User"
          (onClick)="onAddSubGroupToGroup(selectedGroup.info!.id)"></action-button>
      </div>
      <div class="selected-group-search-container">
        <input type="text" class="user-search" placeholder="Search Members ..." [(ngModel)]="userSearchString" />
        <div class="search-icon">
          <img src="assets/button-icons/search.svg" />
        </div>
      </div>
      <div *ngIf="selectedGroup" class="add-user-container">
        <push-button (onClick)="onAddUserToGroup(selectedGroup.info!.id)" buttonStyle="yellow">Add A New Team Member</push-button>
      </div>
      <div class="selected-group-card-container">
        <div *ngIf="isSelectedGroupAdmin" class="requested-access">
          <div class="user-header-section">
            <h2>New Requests</h2>
          </div>
          <div class="empty-requests" *ngIf="selectedGroupAccessRequestsCount === 0">
            <span class="requests-empty">No New Requests ...</span>
          </div>
          <div *ngFor="let request of selectedGroupAccessRequests" class="access-request">
            <div class="request-card-container">
              <div class="user-card">
                <div class="icon" (click)="request.details ? onSelectUser(request.details) : null">
                  <img *ngIf="request.details?.iconURL" src="{{ request.details?.iconURL }}" />
                  <img *ngIf="!request.details?.iconURL" src="assets/icons/default-user-icon.svg" />
                </div>
                <div class="details" (click)="request.details ? onSelectUser(request.details) : null">
                  <span class="info name">
                    <h3>{{ request.details?.name || request.details?.id }}</h3>
                  </span>
                  <span class="info email">
                    <h3>{{ request.details?.email }}</h3>
                  </span>
                  <span class="info as-member">
                    <h3>Requesting: {{ request.asMember ? "Member" : "Viewer" }} Access</h3>
                  </span>
                </div>
                <div class="new-request-actions">
                  <action-button action="deny" tooltipTitle="Dismiss Request" (onClick)="onDismissAccessRequest(request)"></action-button>
                  <action-button action="confirm" tooltipTitle="Confirm Request" (onClick)="onGrantAccessToGroup(request)"></action-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="user-header-section">
          <h2>Team Members</h2>
        </div>
        <div class="user-card-container">
          <div *ngFor="let user of selectedGroupUsers; let i = index" class="user-card">
            <div class="icon" (click)="onSelectUser(user)">
              <img *ngIf="user.iconURL" src="{{ user.iconURL }}" />
              <img *ngIf="!user.iconURL" src="assets/icons/default-user-icon.svg" />
            </div>
            <div class="details" (click)="onSelectUser(user)">
              <span class="info name">
                <h3>{{ user.name || user.id }}</h3>
              </span>
              <span class="info email">
                <h3>{{ user.email }}</h3>
              </span>
            </div>
            <div class="actions">
              <img
                class="user-type-badge"
                src="{{
                  selectedGroupUserRoles[user.id] === 'admin'
                    ? 'assets/icons/admin-badge.svg'
                    : selectedGroupUserRoles[user.id] === 'editor'
                    ? 'assets/icons/member-badge.svg'
                    : selectedGroupUserRoles[user.id] === 'viewer'
                    ? 'assets/icons/viewer-badge.svg'
                    : ''
                }}" />
              <action-button
                action="close"
                confirmText="Are you sure you want to remove user ({{ user.name }}) from group ({{ selectedGroup?.info?.name }})?"
                [disabled]="!user"
                tooltipTitle="Remove"
                (onClick)="onRemoveFromGroup(user.id)"></action-button>
            </div>
          </div>
        </div>
        <div class="empty-requests" *ngIf="selectedGroupUsers.length === 0">
          <span class="requests-empty">No Team Members ...</span>
        </div>
        <div class="user-header-section">
          <h2>Associated Sub-Groups</h2>
        </div>
        <div class="user-card-container">
          <div *ngFor="let group of selectedGroupSubGroups; let i = index" class="user-card">
            <div class="icon" (click)="onSelectGroup(group)">
              <img src="assets/icons/default-user-icon.svg" />
            </div>
            <div class="details" (click)="onSelectGroup(group)">
              <span class="info name">
                <h3>{{ group.name || group.id }}</h3>
              </span>
              <span class="info email">
                <h3>{{ group.description }}</h3>
              </span>
            </div>
            <div class="actions">
              <img
                class="user-type-badge"
                src="{{
                  selectedGroupSubGroupRoles[group.id] === 'editor'
                    ? 'assets/icons/member-badge.svg'
                    : selectedGroupSubGroupRoles[group.id] === 'viewer'
                    ? 'assets/icons/viewer-badge.svg'
                    : ''
                }}" />
              <action-button
                action="close"
                confirmText="Are you sure you want to remove sub-group ({{ group.name }}) from group ({{ selectedGroup?.info?.name }})?"
                [disabled]="!group"
                tooltipTitle="Remove"
                (onClick)="onRemoveSubGroupFromGroup(group.id)"></action-button>
            </div>
          </div>
        </div>
        <div class="empty-requests" *ngIf="selectedGroupSubGroups.length === 0">
          <span class="requests-empty">No Associated Sub Groups ...</span>
        </div>
      </div>
      <div *ngIf="!canAccessSelectedGroup && selectedGroupInfo" class="no-group-access">
        <push-button (onClick)="onRequestAccessToGroup(selectedGroupInfo, false)">Request Viewer Access</push-button>
        <push-button (onClick)="onRequestAccessToGroup(selectedGroupInfo, true)">Request Member Access</push-button>
      </div>
    </div>
  </div>
  <div class="selected-user">
    <div class="user-header">
      <h1 *ngIf="!selectedUser">Select a User ...</h1>
      <h1 *ngIf="selectedUser">{{ selectedUser.name }}</h1>
    </div>
    <div class="user-details">
      <div class="selected-user-details" *ngIf="selectedUser">
        <div class="icon">
          <img *ngIf="selectedUser?.iconURL" src="{{ selectedUser?.iconURL }}" />
          <img *ngIf="!selectedUser?.iconURL" src="assets/icons/default-user-icon.svg" />
        </div>
        <div class="details">
          <span class="info name">
            <span class="label">Name</span>
            <h3>{{ selectedUser?.name || selectedUser?.id }}</h3>
          </span>
          <span class="info email">
            <span class="label">Email</span>
            <h3>{{ selectedUser?.email }}</h3>
          </span>
          <span class="info role">
            <push-button (onClick)="onChangeGroupMembership(selectedUser, selectedUserRole)" buttonStyle="yellow">Change Group Membership</push-button>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
