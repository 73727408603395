/* eslint-disable */
import * as _m0 from "protobufjs/minimal";

export const protobufPackage = "";

export interface JobStatus {
  /** @gotags: bson:"_id,omitempty" */
  jobId: string;
  status: JobStatus_Status;
  /** Optionally a message can be stored with the status */
  message: string;
  /** The log ID - initially just AWS <log group name>_<log stream id> */
  logId: string;
  /**
   * What the job is operating on. Set depending on job type:
   * JT_IMPORT_SCAN => scan id
   * JT_IMPORT_IMAGE => image name
   * JT_RUN_QUANT => quant id
   */
  jobItemId: string;
  jobType: JobStatus_JobType;
  /** Job start time */
  startUnixTimeSec: number;
  /** Job update time (if this changes, we send out an update) */
  lastUpdateUnixTimeSec: number;
  /** Completion: */
  endUnixTimeSec: number;
  /** Where the job output its final result(s) */
  outputFilePath: string;
  /** Any other logs (eg PIQUANT log files) generated by the job */
  otherLogFiles: string[];
  /** Job requestor */
  requestorUserId: string;
  /** Some more info about the job itself */
  name: string;
  /** If it's a quant, the list of elements being quantified */
  elements: string[];
}

export enum JobStatus_Status {
  /** UNKNOWN - https://protobuf.dev/programming-guides/dos-donts/ says specify an unknown as 0 */
  UNKNOWN = 0,
  STARTING = 1,
  PREPARING_NODES = 2,
  RUNNING = 3,
  GATHERING_RESULTS = 4,
  COMPLETE = 5,
  ERROR = 6,
  UNRECOGNIZED = -1,
}

export function jobStatus_StatusFromJSON(object: any): JobStatus_Status {
  switch (object) {
    case 0:
    case "UNKNOWN":
      return JobStatus_Status.UNKNOWN;
    case 1:
    case "STARTING":
      return JobStatus_Status.STARTING;
    case 2:
    case "PREPARING_NODES":
      return JobStatus_Status.PREPARING_NODES;
    case 3:
    case "RUNNING":
      return JobStatus_Status.RUNNING;
    case 4:
    case "GATHERING_RESULTS":
      return JobStatus_Status.GATHERING_RESULTS;
    case 5:
    case "COMPLETE":
      return JobStatus_Status.COMPLETE;
    case 6:
    case "ERROR":
      return JobStatus_Status.ERROR;
    case -1:
    case "UNRECOGNIZED":
    default:
      return JobStatus_Status.UNRECOGNIZED;
  }
}

export function jobStatus_StatusToJSON(object: JobStatus_Status): string {
  switch (object) {
    case JobStatus_Status.UNKNOWN:
      return "UNKNOWN";
    case JobStatus_Status.STARTING:
      return "STARTING";
    case JobStatus_Status.PREPARING_NODES:
      return "PREPARING_NODES";
    case JobStatus_Status.RUNNING:
      return "RUNNING";
    case JobStatus_Status.GATHERING_RESULTS:
      return "GATHERING_RESULTS";
    case JobStatus_Status.COMPLETE:
      return "COMPLETE";
    case JobStatus_Status.ERROR:
      return "ERROR";
    case JobStatus_Status.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum JobStatus_JobType {
  /** JT_UNKNOWN - https://protobuf.dev/programming-guides/dos-donts/ says specify an unknown as 0 */
  JT_UNKNOWN = 0,
  JT_IMPORT_SCAN = 1,
  JT_REIMPORT_SCAN = 2,
  JT_IMPORT_IMAGE = 3,
  JT_RUN_QUANT = 4,
  JT_RUN_FIT = 5,
  UNRECOGNIZED = -1,
}

export function jobStatus_JobTypeFromJSON(object: any): JobStatus_JobType {
  switch (object) {
    case 0:
    case "JT_UNKNOWN":
      return JobStatus_JobType.JT_UNKNOWN;
    case 1:
    case "JT_IMPORT_SCAN":
      return JobStatus_JobType.JT_IMPORT_SCAN;
    case 2:
    case "JT_REIMPORT_SCAN":
      return JobStatus_JobType.JT_REIMPORT_SCAN;
    case 3:
    case "JT_IMPORT_IMAGE":
      return JobStatus_JobType.JT_IMPORT_IMAGE;
    case 4:
    case "JT_RUN_QUANT":
      return JobStatus_JobType.JT_RUN_QUANT;
    case 5:
    case "JT_RUN_FIT":
      return JobStatus_JobType.JT_RUN_FIT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return JobStatus_JobType.UNRECOGNIZED;
  }
}

export function jobStatus_JobTypeToJSON(object: JobStatus_JobType): string {
  switch (object) {
    case JobStatus_JobType.JT_UNKNOWN:
      return "JT_UNKNOWN";
    case JobStatus_JobType.JT_IMPORT_SCAN:
      return "JT_IMPORT_SCAN";
    case JobStatus_JobType.JT_REIMPORT_SCAN:
      return "JT_REIMPORT_SCAN";
    case JobStatus_JobType.JT_IMPORT_IMAGE:
      return "JT_IMPORT_IMAGE";
    case JobStatus_JobType.JT_RUN_QUANT:
      return "JT_RUN_QUANT";
    case JobStatus_JobType.JT_RUN_FIT:
      return "JT_RUN_FIT";
    case JobStatus_JobType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Which API instance wants to handle this job? This is only used
 * when it's an external request and APIs realise that an unknown
 * job got completed. Now one will have to handle things like
 * notification, so at that point all APIs will write their instance
 * ID here, wait a little, then read it back. The API matching the
 * written instance ID gets to do the job!
 */
export interface JobHandlerDBItem {
  /** @gotags: bson:"_id,omitempty" */
  id: string;
  jobId: string;
  handlerInstanceId: string;
  timeStampUnixSec: number;
}

function createBaseJobStatus(): JobStatus {
  return {
    jobId: "",
    status: 0,
    message: "",
    logId: "",
    jobItemId: "",
    jobType: 0,
    startUnixTimeSec: 0,
    lastUpdateUnixTimeSec: 0,
    endUnixTimeSec: 0,
    outputFilePath: "",
    otherLogFiles: [],
    requestorUserId: "",
    name: "",
    elements: [],
  };
}

export const JobStatus = {
  encode(message: JobStatus, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.jobId !== "") {
      writer.uint32(10).string(message.jobId);
    }
    if (message.status !== 0) {
      writer.uint32(16).int32(message.status);
    }
    if (message.message !== "") {
      writer.uint32(26).string(message.message);
    }
    if (message.logId !== "") {
      writer.uint32(34).string(message.logId);
    }
    if (message.jobItemId !== "") {
      writer.uint32(82).string(message.jobItemId);
    }
    if (message.jobType !== 0) {
      writer.uint32(88).int32(message.jobType);
    }
    if (message.startUnixTimeSec !== 0) {
      writer.uint32(40).uint32(message.startUnixTimeSec);
    }
    if (message.lastUpdateUnixTimeSec !== 0) {
      writer.uint32(48).uint32(message.lastUpdateUnixTimeSec);
    }
    if (message.endUnixTimeSec !== 0) {
      writer.uint32(56).uint32(message.endUnixTimeSec);
    }
    if (message.outputFilePath !== "") {
      writer.uint32(66).string(message.outputFilePath);
    }
    for (const v of message.otherLogFiles) {
      writer.uint32(74).string(v!);
    }
    if (message.requestorUserId !== "") {
      writer.uint32(98).string(message.requestorUserId);
    }
    if (message.name !== "") {
      writer.uint32(106).string(message.name);
    }
    for (const v of message.elements) {
      writer.uint32(114).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): JobStatus {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJobStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.jobId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.message = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.logId = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.jobItemId = reader.string();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.jobType = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.startUnixTimeSec = reader.uint32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.lastUpdateUnixTimeSec = reader.uint32();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.endUnixTimeSec = reader.uint32();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.outputFilePath = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.otherLogFiles.push(reader.string());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.requestorUserId = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.name = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.elements.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): JobStatus {
    return {
      jobId: isSet(object.jobId) ? String(object.jobId) : "",
      status: isSet(object.status) ? jobStatus_StatusFromJSON(object.status) : 0,
      message: isSet(object.message) ? String(object.message) : "",
      logId: isSet(object.logId) ? String(object.logId) : "",
      jobItemId: isSet(object.jobItemId) ? String(object.jobItemId) : "",
      jobType: isSet(object.jobType) ? jobStatus_JobTypeFromJSON(object.jobType) : 0,
      startUnixTimeSec: isSet(object.startUnixTimeSec) ? Number(object.startUnixTimeSec) : 0,
      lastUpdateUnixTimeSec: isSet(object.lastUpdateUnixTimeSec) ? Number(object.lastUpdateUnixTimeSec) : 0,
      endUnixTimeSec: isSet(object.endUnixTimeSec) ? Number(object.endUnixTimeSec) : 0,
      outputFilePath: isSet(object.outputFilePath) ? String(object.outputFilePath) : "",
      otherLogFiles: Array.isArray(object?.otherLogFiles) ? object.otherLogFiles.map((e: any) => String(e)) : [],
      requestorUserId: isSet(object.requestorUserId) ? String(object.requestorUserId) : "",
      name: isSet(object.name) ? String(object.name) : "",
      elements: Array.isArray(object?.elements) ? object.elements.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: JobStatus): unknown {
    const obj: any = {};
    message.jobId !== undefined && (obj.jobId = message.jobId);
    message.status !== undefined && (obj.status = jobStatus_StatusToJSON(message.status));
    message.message !== undefined && (obj.message = message.message);
    message.logId !== undefined && (obj.logId = message.logId);
    message.jobItemId !== undefined && (obj.jobItemId = message.jobItemId);
    message.jobType !== undefined && (obj.jobType = jobStatus_JobTypeToJSON(message.jobType));
    message.startUnixTimeSec !== undefined && (obj.startUnixTimeSec = Math.round(message.startUnixTimeSec));
    message.lastUpdateUnixTimeSec !== undefined &&
      (obj.lastUpdateUnixTimeSec = Math.round(message.lastUpdateUnixTimeSec));
    message.endUnixTimeSec !== undefined && (obj.endUnixTimeSec = Math.round(message.endUnixTimeSec));
    message.outputFilePath !== undefined && (obj.outputFilePath = message.outputFilePath);
    if (message.otherLogFiles) {
      obj.otherLogFiles = message.otherLogFiles.map((e) => e);
    } else {
      obj.otherLogFiles = [];
    }
    message.requestorUserId !== undefined && (obj.requestorUserId = message.requestorUserId);
    message.name !== undefined && (obj.name = message.name);
    if (message.elements) {
      obj.elements = message.elements.map((e) => e);
    } else {
      obj.elements = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<JobStatus>, I>>(base?: I): JobStatus {
    return JobStatus.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<JobStatus>, I>>(object: I): JobStatus {
    const message = createBaseJobStatus();
    message.jobId = object.jobId ?? "";
    message.status = object.status ?? 0;
    message.message = object.message ?? "";
    message.logId = object.logId ?? "";
    message.jobItemId = object.jobItemId ?? "";
    message.jobType = object.jobType ?? 0;
    message.startUnixTimeSec = object.startUnixTimeSec ?? 0;
    message.lastUpdateUnixTimeSec = object.lastUpdateUnixTimeSec ?? 0;
    message.endUnixTimeSec = object.endUnixTimeSec ?? 0;
    message.outputFilePath = object.outputFilePath ?? "";
    message.otherLogFiles = object.otherLogFiles?.map((e) => e) || [];
    message.requestorUserId = object.requestorUserId ?? "";
    message.name = object.name ?? "";
    message.elements = object.elements?.map((e) => e) || [];
    return message;
  },
};

function createBaseJobHandlerDBItem(): JobHandlerDBItem {
  return { id: "", jobId: "", handlerInstanceId: "", timeStampUnixSec: 0 };
}

export const JobHandlerDBItem = {
  encode(message: JobHandlerDBItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.jobId !== "") {
      writer.uint32(18).string(message.jobId);
    }
    if (message.handlerInstanceId !== "") {
      writer.uint32(26).string(message.handlerInstanceId);
    }
    if (message.timeStampUnixSec !== 0) {
      writer.uint32(32).uint32(message.timeStampUnixSec);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): JobHandlerDBItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJobHandlerDBItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.jobId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.handlerInstanceId = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.timeStampUnixSec = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): JobHandlerDBItem {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      jobId: isSet(object.jobId) ? String(object.jobId) : "",
      handlerInstanceId: isSet(object.handlerInstanceId) ? String(object.handlerInstanceId) : "",
      timeStampUnixSec: isSet(object.timeStampUnixSec) ? Number(object.timeStampUnixSec) : 0,
    };
  },

  toJSON(message: JobHandlerDBItem): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.jobId !== undefined && (obj.jobId = message.jobId);
    message.handlerInstanceId !== undefined && (obj.handlerInstanceId = message.handlerInstanceId);
    message.timeStampUnixSec !== undefined && (obj.timeStampUnixSec = Math.round(message.timeStampUnixSec));
    return obj;
  },

  create<I extends Exact<DeepPartial<JobHandlerDBItem>, I>>(base?: I): JobHandlerDBItem {
    return JobHandlerDBItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<JobHandlerDBItem>, I>>(object: I): JobHandlerDBItem {
    const message = createBaseJobHandlerDBItem();
    message.id = object.id ?? "";
    message.jobId = object.jobId ?? "";
    message.handlerInstanceId = object.handlerInstanceId ?? "";
    message.timeStampUnixSec = object.timeStampUnixSec ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
