/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { ExportFile } from "./export";

export const protobufPackage = "";

export enum ExportDataType {
  /** EDT_UNKNOWN - https://protobuf.dev/programming-guides/dos-donts/ says specify an unknown as 0 */
  EDT_UNKNOWN = 0,
  EDT_QUANT_CSV = 1,
  UNRECOGNIZED = -1,
}

export function exportDataTypeFromJSON(object: any): ExportDataType {
  switch (object) {
    case 0:
    case "EDT_UNKNOWN":
      return ExportDataType.EDT_UNKNOWN;
    case 1:
    case "EDT_QUANT_CSV":
      return ExportDataType.EDT_QUANT_CSV;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ExportDataType.UNRECOGNIZED;
  }
}

export function exportDataTypeToJSON(object: ExportDataType): string {
  switch (object) {
    case ExportDataType.EDT_UNKNOWN:
      return "EDT_UNKNOWN";
    case ExportDataType.EDT_QUANT_CSV:
      return "EDT_QUANT_CSV";
    case ExportDataType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** requires(EXPORT) */
export interface ExportFilesReq {
  /** What to export */
  exportTypes: ExportDataType[];
  scanId: string;
  quantId: string;
  roiIds: string[];
  imageFileNames: string[];
}

export interface ExportFilesResp {
  /**
   * Just contains the zipped exported data. File name is irrelevant because we expect the UI to present this
   * as needed, potentially unzipping this in memory and zipping UI-generated files into the final output
   */
  files: ExportFile[];
}

function createBaseExportFilesReq(): ExportFilesReq {
  return { exportTypes: [], scanId: "", quantId: "", roiIds: [], imageFileNames: [] };
}

export const ExportFilesReq = {
  encode(message: ExportFilesReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.exportTypes) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.scanId !== "") {
      writer.uint32(18).string(message.scanId);
    }
    if (message.quantId !== "") {
      writer.uint32(26).string(message.quantId);
    }
    for (const v of message.roiIds) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.imageFileNames) {
      writer.uint32(42).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExportFilesReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExportFilesReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.exportTypes.push(reader.int32() as any);

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.exportTypes.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.scanId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.quantId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.roiIds.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.imageFileNames.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExportFilesReq {
    return {
      exportTypes: Array.isArray(object?.exportTypes)
        ? object.exportTypes.map((e: any) => exportDataTypeFromJSON(e))
        : [],
      scanId: isSet(object.scanId) ? String(object.scanId) : "",
      quantId: isSet(object.quantId) ? String(object.quantId) : "",
      roiIds: Array.isArray(object?.roiIds) ? object.roiIds.map((e: any) => String(e)) : [],
      imageFileNames: Array.isArray(object?.imageFileNames) ? object.imageFileNames.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: ExportFilesReq): unknown {
    const obj: any = {};
    if (message.exportTypes) {
      obj.exportTypes = message.exportTypes.map((e) => exportDataTypeToJSON(e));
    } else {
      obj.exportTypes = [];
    }
    message.scanId !== undefined && (obj.scanId = message.scanId);
    message.quantId !== undefined && (obj.quantId = message.quantId);
    if (message.roiIds) {
      obj.roiIds = message.roiIds.map((e) => e);
    } else {
      obj.roiIds = [];
    }
    if (message.imageFileNames) {
      obj.imageFileNames = message.imageFileNames.map((e) => e);
    } else {
      obj.imageFileNames = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ExportFilesReq>, I>>(base?: I): ExportFilesReq {
    return ExportFilesReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ExportFilesReq>, I>>(object: I): ExportFilesReq {
    const message = createBaseExportFilesReq();
    message.exportTypes = object.exportTypes?.map((e) => e) || [];
    message.scanId = object.scanId ?? "";
    message.quantId = object.quantId ?? "";
    message.roiIds = object.roiIds?.map((e) => e) || [];
    message.imageFileNames = object.imageFileNames?.map((e) => e) || [];
    return message;
  },
};

function createBaseExportFilesResp(): ExportFilesResp {
  return { files: [] };
}

export const ExportFilesResp = {
  encode(message: ExportFilesResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.files) {
      ExportFile.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExportFilesResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExportFilesResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.files.push(ExportFile.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExportFilesResp {
    return { files: Array.isArray(object?.files) ? object.files.map((e: any) => ExportFile.fromJSON(e)) : [] };
  },

  toJSON(message: ExportFilesResp): unknown {
    const obj: any = {};
    if (message.files) {
      obj.files = message.files.map((e) => e ? ExportFile.toJSON(e) : undefined);
    } else {
      obj.files = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ExportFilesResp>, I>>(base?: I): ExportFilesResp {
    return ExportFilesResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ExportFilesResp>, I>>(object: I): ExportFilesResp {
    const message = createBaseExportFilesResp();
    message.files = object.files?.map((e) => ExportFile.fromPartial(e)) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
