/* eslint-disable */
import * as _m0 from "protobufjs/minimal";

export const protobufPackage = "";

/** requires(QUANTIFY) */
export interface QuantDeleteReq {
  quantId: string;
}

export interface QuantDeleteResp {
}

/** requires(NONE) */
export interface QuantBlessReq {
  quantId: string;
}

export interface QuantBlessResp {
}

/** requires(NONE) */
export interface QuantPublishReq {
  quantId: string;
}

export interface QuantPublishResp {
}

function createBaseQuantDeleteReq(): QuantDeleteReq {
  return { quantId: "" };
}

export const QuantDeleteReq = {
  encode(message: QuantDeleteReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.quantId !== "") {
      writer.uint32(10).string(message.quantId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuantDeleteReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantDeleteReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.quantId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuantDeleteReq {
    return { quantId: isSet(object.quantId) ? String(object.quantId) : "" };
  },

  toJSON(message: QuantDeleteReq): unknown {
    const obj: any = {};
    message.quantId !== undefined && (obj.quantId = message.quantId);
    return obj;
  },

  create<I extends Exact<DeepPartial<QuantDeleteReq>, I>>(base?: I): QuantDeleteReq {
    return QuantDeleteReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuantDeleteReq>, I>>(object: I): QuantDeleteReq {
    const message = createBaseQuantDeleteReq();
    message.quantId = object.quantId ?? "";
    return message;
  },
};

function createBaseQuantDeleteResp(): QuantDeleteResp {
  return {};
}

export const QuantDeleteResp = {
  encode(_: QuantDeleteResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuantDeleteResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantDeleteResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): QuantDeleteResp {
    return {};
  },

  toJSON(_: QuantDeleteResp): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<QuantDeleteResp>, I>>(base?: I): QuantDeleteResp {
    return QuantDeleteResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuantDeleteResp>, I>>(_: I): QuantDeleteResp {
    const message = createBaseQuantDeleteResp();
    return message;
  },
};

function createBaseQuantBlessReq(): QuantBlessReq {
  return { quantId: "" };
}

export const QuantBlessReq = {
  encode(message: QuantBlessReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.quantId !== "") {
      writer.uint32(10).string(message.quantId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuantBlessReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantBlessReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.quantId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuantBlessReq {
    return { quantId: isSet(object.quantId) ? String(object.quantId) : "" };
  },

  toJSON(message: QuantBlessReq): unknown {
    const obj: any = {};
    message.quantId !== undefined && (obj.quantId = message.quantId);
    return obj;
  },

  create<I extends Exact<DeepPartial<QuantBlessReq>, I>>(base?: I): QuantBlessReq {
    return QuantBlessReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuantBlessReq>, I>>(object: I): QuantBlessReq {
    const message = createBaseQuantBlessReq();
    message.quantId = object.quantId ?? "";
    return message;
  },
};

function createBaseQuantBlessResp(): QuantBlessResp {
  return {};
}

export const QuantBlessResp = {
  encode(_: QuantBlessResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuantBlessResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantBlessResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): QuantBlessResp {
    return {};
  },

  toJSON(_: QuantBlessResp): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<QuantBlessResp>, I>>(base?: I): QuantBlessResp {
    return QuantBlessResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuantBlessResp>, I>>(_: I): QuantBlessResp {
    const message = createBaseQuantBlessResp();
    return message;
  },
};

function createBaseQuantPublishReq(): QuantPublishReq {
  return { quantId: "" };
}

export const QuantPublishReq = {
  encode(message: QuantPublishReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.quantId !== "") {
      writer.uint32(10).string(message.quantId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuantPublishReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantPublishReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.quantId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuantPublishReq {
    return { quantId: isSet(object.quantId) ? String(object.quantId) : "" };
  },

  toJSON(message: QuantPublishReq): unknown {
    const obj: any = {};
    message.quantId !== undefined && (obj.quantId = message.quantId);
    return obj;
  },

  create<I extends Exact<DeepPartial<QuantPublishReq>, I>>(base?: I): QuantPublishReq {
    return QuantPublishReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuantPublishReq>, I>>(object: I): QuantPublishReq {
    const message = createBaseQuantPublishReq();
    message.quantId = object.quantId ?? "";
    return message;
  },
};

function createBaseQuantPublishResp(): QuantPublishResp {
  return {};
}

export const QuantPublishResp = {
  encode(_: QuantPublishResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuantPublishResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantPublishResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): QuantPublishResp {
    return {};
  },

  toJSON(_: QuantPublishResp): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<QuantPublishResp>, I>>(base?: I): QuantPublishResp {
    return QuantPublishResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuantPublishResp>, I>>(_: I): QuantPublishResp {
    const message = createBaseQuantPublishResp();
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
