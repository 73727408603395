/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { UserInfo } from "./user";

export const protobufPackage = "";

export enum ObjectType {
  /** OT_UNKNOWN - https://protobuf.dev/programming-guides/dos-donts/ says specify an unknown as 0 */
  OT_UNKNOWN = 0,
  OT_ROI = 1,
  OT_ELEMENT_SET = 2,
  OT_EXPRESSION = 3,
  OT_EXPRESSION_GROUP = 4,
  OT_DATA_MODULE = 5,
  OT_SCAN = 6,
  OT_QUANTIFICATION = 7,
  OT_SCREEN_CONFIG = 8,
  UNRECOGNIZED = -1,
}

export function objectTypeFromJSON(object: any): ObjectType {
  switch (object) {
    case 0:
    case "OT_UNKNOWN":
      return ObjectType.OT_UNKNOWN;
    case 1:
    case "OT_ROI":
      return ObjectType.OT_ROI;
    case 2:
    case "OT_ELEMENT_SET":
      return ObjectType.OT_ELEMENT_SET;
    case 3:
    case "OT_EXPRESSION":
      return ObjectType.OT_EXPRESSION;
    case 4:
    case "OT_EXPRESSION_GROUP":
      return ObjectType.OT_EXPRESSION_GROUP;
    case 5:
    case "OT_DATA_MODULE":
      return ObjectType.OT_DATA_MODULE;
    case 6:
    case "OT_SCAN":
      return ObjectType.OT_SCAN;
    case 7:
    case "OT_QUANTIFICATION":
      return ObjectType.OT_QUANTIFICATION;
    case 8:
    case "OT_SCREEN_CONFIG":
      return ObjectType.OT_SCREEN_CONFIG;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ObjectType.UNRECOGNIZED;
  }
}

export function objectTypeToJSON(object: ObjectType): string {
  switch (object) {
    case ObjectType.OT_UNKNOWN:
      return "OT_UNKNOWN";
    case ObjectType.OT_ROI:
      return "OT_ROI";
    case ObjectType.OT_ELEMENT_SET:
      return "OT_ELEMENT_SET";
    case ObjectType.OT_EXPRESSION:
      return "OT_EXPRESSION";
    case ObjectType.OT_EXPRESSION_GROUP:
      return "OT_EXPRESSION_GROUP";
    case ObjectType.OT_DATA_MODULE:
      return "OT_DATA_MODULE";
    case ObjectType.OT_SCAN:
      return "OT_SCAN";
    case ObjectType.OT_QUANTIFICATION:
      return "OT_QUANTIFICATION";
    case ObjectType.OT_SCREEN_CONFIG:
      return "OT_SCREEN_CONFIG";
    case ObjectType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface UserGroupList {
  userIds: string[];
  groupIds: string[];
}

export interface OwnershipItem {
  /** @gotags: bson:"_id,omitempty" */
  id: string;
  /** What kind of object we're created for */
  objectType: ObjectType;
  /** Creation info */
  creatorUserId: string;
  createdUnixSec: number;
  /** Who has view or edit access */
  viewers: UserGroupList | undefined;
  editors: UserGroupList | undefined;
}

export interface OwnershipSummary {
  creatorUser: UserInfo | undefined;
  createdUnixSec: number;
  viewerUserCount: number;
  viewerGroupCount: number;
  editorUserCount: number;
  editorGroupCount: number;
  sharedWithOthers: boolean;
  /** The UI needs to know at a glance whether the current user can edit the item */
  canEdit: boolean;
}

function createBaseUserGroupList(): UserGroupList {
  return { userIds: [], groupIds: [] };
}

export const UserGroupList = {
  encode(message: UserGroupList, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.userIds) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.groupIds) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserGroupList {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserGroupList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userIds.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.groupIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserGroupList {
    return {
      userIds: Array.isArray(object?.userIds) ? object.userIds.map((e: any) => String(e)) : [],
      groupIds: Array.isArray(object?.groupIds) ? object.groupIds.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: UserGroupList): unknown {
    const obj: any = {};
    if (message.userIds) {
      obj.userIds = message.userIds.map((e) => e);
    } else {
      obj.userIds = [];
    }
    if (message.groupIds) {
      obj.groupIds = message.groupIds.map((e) => e);
    } else {
      obj.groupIds = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserGroupList>, I>>(base?: I): UserGroupList {
    return UserGroupList.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserGroupList>, I>>(object: I): UserGroupList {
    const message = createBaseUserGroupList();
    message.userIds = object.userIds?.map((e) => e) || [];
    message.groupIds = object.groupIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseOwnershipItem(): OwnershipItem {
  return { id: "", objectType: 0, creatorUserId: "", createdUnixSec: 0, viewers: undefined, editors: undefined };
}

export const OwnershipItem = {
  encode(message: OwnershipItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.objectType !== 0) {
      writer.uint32(16).int32(message.objectType);
    }
    if (message.creatorUserId !== "") {
      writer.uint32(26).string(message.creatorUserId);
    }
    if (message.createdUnixSec !== 0) {
      writer.uint32(32).uint32(message.createdUnixSec);
    }
    if (message.viewers !== undefined) {
      UserGroupList.encode(message.viewers, writer.uint32(42).fork()).ldelim();
    }
    if (message.editors !== undefined) {
      UserGroupList.encode(message.editors, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OwnershipItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOwnershipItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.objectType = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.creatorUserId = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.createdUnixSec = reader.uint32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.viewers = UserGroupList.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.editors = UserGroupList.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OwnershipItem {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      objectType: isSet(object.objectType) ? objectTypeFromJSON(object.objectType) : 0,
      creatorUserId: isSet(object.creatorUserId) ? String(object.creatorUserId) : "",
      createdUnixSec: isSet(object.createdUnixSec) ? Number(object.createdUnixSec) : 0,
      viewers: isSet(object.viewers) ? UserGroupList.fromJSON(object.viewers) : undefined,
      editors: isSet(object.editors) ? UserGroupList.fromJSON(object.editors) : undefined,
    };
  },

  toJSON(message: OwnershipItem): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.objectType !== undefined && (obj.objectType = objectTypeToJSON(message.objectType));
    message.creatorUserId !== undefined && (obj.creatorUserId = message.creatorUserId);
    message.createdUnixSec !== undefined && (obj.createdUnixSec = Math.round(message.createdUnixSec));
    message.viewers !== undefined &&
      (obj.viewers = message.viewers ? UserGroupList.toJSON(message.viewers) : undefined);
    message.editors !== undefined &&
      (obj.editors = message.editors ? UserGroupList.toJSON(message.editors) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<OwnershipItem>, I>>(base?: I): OwnershipItem {
    return OwnershipItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<OwnershipItem>, I>>(object: I): OwnershipItem {
    const message = createBaseOwnershipItem();
    message.id = object.id ?? "";
    message.objectType = object.objectType ?? 0;
    message.creatorUserId = object.creatorUserId ?? "";
    message.createdUnixSec = object.createdUnixSec ?? 0;
    message.viewers = (object.viewers !== undefined && object.viewers !== null)
      ? UserGroupList.fromPartial(object.viewers)
      : undefined;
    message.editors = (object.editors !== undefined && object.editors !== null)
      ? UserGroupList.fromPartial(object.editors)
      : undefined;
    return message;
  },
};

function createBaseOwnershipSummary(): OwnershipSummary {
  return {
    creatorUser: undefined,
    createdUnixSec: 0,
    viewerUserCount: 0,
    viewerGroupCount: 0,
    editorUserCount: 0,
    editorGroupCount: 0,
    sharedWithOthers: false,
    canEdit: false,
  };
}

export const OwnershipSummary = {
  encode(message: OwnershipSummary, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.creatorUser !== undefined) {
      UserInfo.encode(message.creatorUser, writer.uint32(10).fork()).ldelim();
    }
    if (message.createdUnixSec !== 0) {
      writer.uint32(16).uint32(message.createdUnixSec);
    }
    if (message.viewerUserCount !== 0) {
      writer.uint32(24).uint32(message.viewerUserCount);
    }
    if (message.viewerGroupCount !== 0) {
      writer.uint32(32).uint32(message.viewerGroupCount);
    }
    if (message.editorUserCount !== 0) {
      writer.uint32(40).uint32(message.editorUserCount);
    }
    if (message.editorGroupCount !== 0) {
      writer.uint32(48).uint32(message.editorGroupCount);
    }
    if (message.sharedWithOthers === true) {
      writer.uint32(56).bool(message.sharedWithOthers);
    }
    if (message.canEdit === true) {
      writer.uint32(64).bool(message.canEdit);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OwnershipSummary {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOwnershipSummary();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.creatorUser = UserInfo.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.createdUnixSec = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.viewerUserCount = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.viewerGroupCount = reader.uint32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.editorUserCount = reader.uint32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.editorGroupCount = reader.uint32();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.sharedWithOthers = reader.bool();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.canEdit = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OwnershipSummary {
    return {
      creatorUser: isSet(object.creatorUser) ? UserInfo.fromJSON(object.creatorUser) : undefined,
      createdUnixSec: isSet(object.createdUnixSec) ? Number(object.createdUnixSec) : 0,
      viewerUserCount: isSet(object.viewerUserCount) ? Number(object.viewerUserCount) : 0,
      viewerGroupCount: isSet(object.viewerGroupCount) ? Number(object.viewerGroupCount) : 0,
      editorUserCount: isSet(object.editorUserCount) ? Number(object.editorUserCount) : 0,
      editorGroupCount: isSet(object.editorGroupCount) ? Number(object.editorGroupCount) : 0,
      sharedWithOthers: isSet(object.sharedWithOthers) ? Boolean(object.sharedWithOthers) : false,
      canEdit: isSet(object.canEdit) ? Boolean(object.canEdit) : false,
    };
  },

  toJSON(message: OwnershipSummary): unknown {
    const obj: any = {};
    message.creatorUser !== undefined &&
      (obj.creatorUser = message.creatorUser ? UserInfo.toJSON(message.creatorUser) : undefined);
    message.createdUnixSec !== undefined && (obj.createdUnixSec = Math.round(message.createdUnixSec));
    message.viewerUserCount !== undefined && (obj.viewerUserCount = Math.round(message.viewerUserCount));
    message.viewerGroupCount !== undefined && (obj.viewerGroupCount = Math.round(message.viewerGroupCount));
    message.editorUserCount !== undefined && (obj.editorUserCount = Math.round(message.editorUserCount));
    message.editorGroupCount !== undefined && (obj.editorGroupCount = Math.round(message.editorGroupCount));
    message.sharedWithOthers !== undefined && (obj.sharedWithOthers = message.sharedWithOthers);
    message.canEdit !== undefined && (obj.canEdit = message.canEdit);
    return obj;
  },

  create<I extends Exact<DeepPartial<OwnershipSummary>, I>>(base?: I): OwnershipSummary {
    return OwnershipSummary.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<OwnershipSummary>, I>>(object: I): OwnershipSummary {
    const message = createBaseOwnershipSummary();
    message.creatorUser = (object.creatorUser !== undefined && object.creatorUser !== null)
      ? UserInfo.fromPartial(object.creatorUser)
      : undefined;
    message.createdUnixSec = object.createdUnixSec ?? 0;
    message.viewerUserCount = object.viewerUserCount ?? 0;
    message.viewerGroupCount = object.viewerGroupCount ?? 0;
    message.editorUserCount = object.editorUserCount ?? 0;
    message.editorGroupCount = object.editorGroupCount ?? 0;
    message.sharedWithOthers = object.sharedWithOthers ?? false;
    message.canEdit = object.canEdit ?? false;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
