/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { DOIMetadata } from "./doi";

export const protobufPackage = "";

/** requires(EDIT_EXPRESSION) */
export interface PublishExpressionToZenodoReq {
  /** expression id */
  id: string;
  /** Zipped expression output */
  output: string;
  /** Filled out DOI metadata */
  metadata: DOIMetadata | undefined;
}

export interface PublishExpressionToZenodoResp {
  doi: DOIMetadata | undefined;
}

/** requires(NONE) */
export interface ZenodoDOIGetReq {
  /** expression id */
  id: string;
}

export interface ZenodoDOIGetResp {
  doi: DOIMetadata | undefined;
}

function createBasePublishExpressionToZenodoReq(): PublishExpressionToZenodoReq {
  return { id: "", output: "", metadata: undefined };
}

export const PublishExpressionToZenodoReq = {
  encode(message: PublishExpressionToZenodoReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.output !== "") {
      writer.uint32(18).string(message.output);
    }
    if (message.metadata !== undefined) {
      DOIMetadata.encode(message.metadata, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PublishExpressionToZenodoReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePublishExpressionToZenodoReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.output = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.metadata = DOIMetadata.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PublishExpressionToZenodoReq {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      output: isSet(object.output) ? String(object.output) : "",
      metadata: isSet(object.metadata) ? DOIMetadata.fromJSON(object.metadata) : undefined,
    };
  },

  toJSON(message: PublishExpressionToZenodoReq): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.output !== undefined && (obj.output = message.output);
    message.metadata !== undefined &&
      (obj.metadata = message.metadata ? DOIMetadata.toJSON(message.metadata) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<PublishExpressionToZenodoReq>, I>>(base?: I): PublishExpressionToZenodoReq {
    return PublishExpressionToZenodoReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PublishExpressionToZenodoReq>, I>>(object: I): PublishExpressionToZenodoReq {
    const message = createBasePublishExpressionToZenodoReq();
    message.id = object.id ?? "";
    message.output = object.output ?? "";
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? DOIMetadata.fromPartial(object.metadata)
      : undefined;
    return message;
  },
};

function createBasePublishExpressionToZenodoResp(): PublishExpressionToZenodoResp {
  return { doi: undefined };
}

export const PublishExpressionToZenodoResp = {
  encode(message: PublishExpressionToZenodoResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.doi !== undefined) {
      DOIMetadata.encode(message.doi, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PublishExpressionToZenodoResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePublishExpressionToZenodoResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.doi = DOIMetadata.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PublishExpressionToZenodoResp {
    return { doi: isSet(object.doi) ? DOIMetadata.fromJSON(object.doi) : undefined };
  },

  toJSON(message: PublishExpressionToZenodoResp): unknown {
    const obj: any = {};
    message.doi !== undefined && (obj.doi = message.doi ? DOIMetadata.toJSON(message.doi) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<PublishExpressionToZenodoResp>, I>>(base?: I): PublishExpressionToZenodoResp {
    return PublishExpressionToZenodoResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PublishExpressionToZenodoResp>, I>>(
    object: I,
  ): PublishExpressionToZenodoResp {
    const message = createBasePublishExpressionToZenodoResp();
    message.doi = (object.doi !== undefined && object.doi !== null) ? DOIMetadata.fromPartial(object.doi) : undefined;
    return message;
  },
};

function createBaseZenodoDOIGetReq(): ZenodoDOIGetReq {
  return { id: "" };
}

export const ZenodoDOIGetReq = {
  encode(message: ZenodoDOIGetReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ZenodoDOIGetReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseZenodoDOIGetReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ZenodoDOIGetReq {
    return { id: isSet(object.id) ? String(object.id) : "" };
  },

  toJSON(message: ZenodoDOIGetReq): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  create<I extends Exact<DeepPartial<ZenodoDOIGetReq>, I>>(base?: I): ZenodoDOIGetReq {
    return ZenodoDOIGetReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ZenodoDOIGetReq>, I>>(object: I): ZenodoDOIGetReq {
    const message = createBaseZenodoDOIGetReq();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseZenodoDOIGetResp(): ZenodoDOIGetResp {
  return { doi: undefined };
}

export const ZenodoDOIGetResp = {
  encode(message: ZenodoDOIGetResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.doi !== undefined) {
      DOIMetadata.encode(message.doi, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ZenodoDOIGetResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseZenodoDOIGetResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.doi = DOIMetadata.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ZenodoDOIGetResp {
    return { doi: isSet(object.doi) ? DOIMetadata.fromJSON(object.doi) : undefined };
  },

  toJSON(message: ZenodoDOIGetResp): unknown {
    const obj: any = {};
    message.doi !== undefined && (obj.doi = message.doi ? DOIMetadata.toJSON(message.doi) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ZenodoDOIGetResp>, I>>(base?: I): ZenodoDOIGetResp {
    return ZenodoDOIGetResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ZenodoDOIGetResp>, I>>(object: I): ZenodoDOIGetResp {
    const message = createBaseZenodoDOIGetResp();
    message.doi = (object.doi !== undefined && object.doi !== null) ? DOIMetadata.fromPartial(object.doi) : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
