/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { DetectedDiffractionPeakStatuses } from "./diffraction-data";

export const protobufPackage = "";

/** requires(NONE) */
export interface DiffractionPeakStatusListReq {
  scanId: string;
}

export interface DiffractionPeakStatusListResp {
  /** id->peak status */
  peakStatuses: DetectedDiffractionPeakStatuses | undefined;
}

/** requires(EDIT_DIFFRACTION) */
export interface DiffractionPeakStatusWriteReq {
  scanId: string;
  diffractionPeakId: string;
  /** We let the UI define these */
  status: string;
}

export interface DiffractionPeakStatusWriteResp {
}

/** requires(EDIT_DIFFRACTION) */
export interface DiffractionPeakStatusDeleteReq {
  scanId: string;
  diffractionPeakId: string;
}

export interface DiffractionPeakStatusDeleteResp {
}

function createBaseDiffractionPeakStatusListReq(): DiffractionPeakStatusListReq {
  return { scanId: "" };
}

export const DiffractionPeakStatusListReq = {
  encode(message: DiffractionPeakStatusListReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.scanId !== "") {
      writer.uint32(10).string(message.scanId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DiffractionPeakStatusListReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDiffractionPeakStatusListReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.scanId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DiffractionPeakStatusListReq {
    return { scanId: isSet(object.scanId) ? String(object.scanId) : "" };
  },

  toJSON(message: DiffractionPeakStatusListReq): unknown {
    const obj: any = {};
    message.scanId !== undefined && (obj.scanId = message.scanId);
    return obj;
  },

  create<I extends Exact<DeepPartial<DiffractionPeakStatusListReq>, I>>(base?: I): DiffractionPeakStatusListReq {
    return DiffractionPeakStatusListReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DiffractionPeakStatusListReq>, I>>(object: I): DiffractionPeakStatusListReq {
    const message = createBaseDiffractionPeakStatusListReq();
    message.scanId = object.scanId ?? "";
    return message;
  },
};

function createBaseDiffractionPeakStatusListResp(): DiffractionPeakStatusListResp {
  return { peakStatuses: undefined };
}

export const DiffractionPeakStatusListResp = {
  encode(message: DiffractionPeakStatusListResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.peakStatuses !== undefined) {
      DetectedDiffractionPeakStatuses.encode(message.peakStatuses, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DiffractionPeakStatusListResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDiffractionPeakStatusListResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.peakStatuses = DetectedDiffractionPeakStatuses.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DiffractionPeakStatusListResp {
    return {
      peakStatuses: isSet(object.peakStatuses)
        ? DetectedDiffractionPeakStatuses.fromJSON(object.peakStatuses)
        : undefined,
    };
  },

  toJSON(message: DiffractionPeakStatusListResp): unknown {
    const obj: any = {};
    message.peakStatuses !== undefined && (obj.peakStatuses = message.peakStatuses
      ? DetectedDiffractionPeakStatuses.toJSON(message.peakStatuses)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DiffractionPeakStatusListResp>, I>>(base?: I): DiffractionPeakStatusListResp {
    return DiffractionPeakStatusListResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DiffractionPeakStatusListResp>, I>>(
    object: I,
  ): DiffractionPeakStatusListResp {
    const message = createBaseDiffractionPeakStatusListResp();
    message.peakStatuses = (object.peakStatuses !== undefined && object.peakStatuses !== null)
      ? DetectedDiffractionPeakStatuses.fromPartial(object.peakStatuses)
      : undefined;
    return message;
  },
};

function createBaseDiffractionPeakStatusWriteReq(): DiffractionPeakStatusWriteReq {
  return { scanId: "", diffractionPeakId: "", status: "" };
}

export const DiffractionPeakStatusWriteReq = {
  encode(message: DiffractionPeakStatusWriteReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.scanId !== "") {
      writer.uint32(10).string(message.scanId);
    }
    if (message.diffractionPeakId !== "") {
      writer.uint32(18).string(message.diffractionPeakId);
    }
    if (message.status !== "") {
      writer.uint32(26).string(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DiffractionPeakStatusWriteReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDiffractionPeakStatusWriteReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.scanId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.diffractionPeakId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.status = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DiffractionPeakStatusWriteReq {
    return {
      scanId: isSet(object.scanId) ? String(object.scanId) : "",
      diffractionPeakId: isSet(object.diffractionPeakId) ? String(object.diffractionPeakId) : "",
      status: isSet(object.status) ? String(object.status) : "",
    };
  },

  toJSON(message: DiffractionPeakStatusWriteReq): unknown {
    const obj: any = {};
    message.scanId !== undefined && (obj.scanId = message.scanId);
    message.diffractionPeakId !== undefined && (obj.diffractionPeakId = message.diffractionPeakId);
    message.status !== undefined && (obj.status = message.status);
    return obj;
  },

  create<I extends Exact<DeepPartial<DiffractionPeakStatusWriteReq>, I>>(base?: I): DiffractionPeakStatusWriteReq {
    return DiffractionPeakStatusWriteReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DiffractionPeakStatusWriteReq>, I>>(
    object: I,
  ): DiffractionPeakStatusWriteReq {
    const message = createBaseDiffractionPeakStatusWriteReq();
    message.scanId = object.scanId ?? "";
    message.diffractionPeakId = object.diffractionPeakId ?? "";
    message.status = object.status ?? "";
    return message;
  },
};

function createBaseDiffractionPeakStatusWriteResp(): DiffractionPeakStatusWriteResp {
  return {};
}

export const DiffractionPeakStatusWriteResp = {
  encode(_: DiffractionPeakStatusWriteResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DiffractionPeakStatusWriteResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDiffractionPeakStatusWriteResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): DiffractionPeakStatusWriteResp {
    return {};
  },

  toJSON(_: DiffractionPeakStatusWriteResp): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<DiffractionPeakStatusWriteResp>, I>>(base?: I): DiffractionPeakStatusWriteResp {
    return DiffractionPeakStatusWriteResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DiffractionPeakStatusWriteResp>, I>>(_: I): DiffractionPeakStatusWriteResp {
    const message = createBaseDiffractionPeakStatusWriteResp();
    return message;
  },
};

function createBaseDiffractionPeakStatusDeleteReq(): DiffractionPeakStatusDeleteReq {
  return { scanId: "", diffractionPeakId: "" };
}

export const DiffractionPeakStatusDeleteReq = {
  encode(message: DiffractionPeakStatusDeleteReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.scanId !== "") {
      writer.uint32(10).string(message.scanId);
    }
    if (message.diffractionPeakId !== "") {
      writer.uint32(18).string(message.diffractionPeakId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DiffractionPeakStatusDeleteReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDiffractionPeakStatusDeleteReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.scanId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.diffractionPeakId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DiffractionPeakStatusDeleteReq {
    return {
      scanId: isSet(object.scanId) ? String(object.scanId) : "",
      diffractionPeakId: isSet(object.diffractionPeakId) ? String(object.diffractionPeakId) : "",
    };
  },

  toJSON(message: DiffractionPeakStatusDeleteReq): unknown {
    const obj: any = {};
    message.scanId !== undefined && (obj.scanId = message.scanId);
    message.diffractionPeakId !== undefined && (obj.diffractionPeakId = message.diffractionPeakId);
    return obj;
  },

  create<I extends Exact<DeepPartial<DiffractionPeakStatusDeleteReq>, I>>(base?: I): DiffractionPeakStatusDeleteReq {
    return DiffractionPeakStatusDeleteReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DiffractionPeakStatusDeleteReq>, I>>(
    object: I,
  ): DiffractionPeakStatusDeleteReq {
    const message = createBaseDiffractionPeakStatusDeleteReq();
    message.scanId = object.scanId ?? "";
    message.diffractionPeakId = object.diffractionPeakId ?? "";
    return message;
  },
};

function createBaseDiffractionPeakStatusDeleteResp(): DiffractionPeakStatusDeleteResp {
  return {};
}

export const DiffractionPeakStatusDeleteResp = {
  encode(_: DiffractionPeakStatusDeleteResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DiffractionPeakStatusDeleteResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDiffractionPeakStatusDeleteResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): DiffractionPeakStatusDeleteResp {
    return {};
  },

  toJSON(_: DiffractionPeakStatusDeleteResp): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<DiffractionPeakStatusDeleteResp>, I>>(base?: I): DiffractionPeakStatusDeleteResp {
    return DiffractionPeakStatusDeleteResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DiffractionPeakStatusDeleteResp>, I>>(_: I): DiffractionPeakStatusDeleteResp {
    const message = createBaseDiffractionPeakStatusDeleteResp();
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
