/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { JobStatus } from "./job";

export const protobufPackage = "";

/** requires(QUANTIFY) */
export interface JobListReq {
}

export interface JobListResp {
  jobs: JobStatus[];
}

export interface JobListUpd {
  job: JobStatus | undefined;
}

function createBaseJobListReq(): JobListReq {
  return {};
}

export const JobListReq = {
  encode(_: JobListReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): JobListReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJobListReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): JobListReq {
    return {};
  },

  toJSON(_: JobListReq): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<JobListReq>, I>>(base?: I): JobListReq {
    return JobListReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<JobListReq>, I>>(_: I): JobListReq {
    const message = createBaseJobListReq();
    return message;
  },
};

function createBaseJobListResp(): JobListResp {
  return { jobs: [] };
}

export const JobListResp = {
  encode(message: JobListResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.jobs) {
      JobStatus.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): JobListResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJobListResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.jobs.push(JobStatus.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): JobListResp {
    return { jobs: Array.isArray(object?.jobs) ? object.jobs.map((e: any) => JobStatus.fromJSON(e)) : [] };
  },

  toJSON(message: JobListResp): unknown {
    const obj: any = {};
    if (message.jobs) {
      obj.jobs = message.jobs.map((e) => e ? JobStatus.toJSON(e) : undefined);
    } else {
      obj.jobs = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<JobListResp>, I>>(base?: I): JobListResp {
    return JobListResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<JobListResp>, I>>(object: I): JobListResp {
    const message = createBaseJobListResp();
    message.jobs = object.jobs?.map((e) => JobStatus.fromPartial(e)) || [];
    return message;
  },
};

function createBaseJobListUpd(): JobListUpd {
  return { job: undefined };
}

export const JobListUpd = {
  encode(message: JobListUpd, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.job !== undefined) {
      JobStatus.encode(message.job, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): JobListUpd {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJobListUpd();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.job = JobStatus.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): JobListUpd {
    return { job: isSet(object.job) ? JobStatus.fromJSON(object.job) : undefined };
  },

  toJSON(message: JobListUpd): unknown {
    const obj: any = {};
    message.job !== undefined && (obj.job = message.job ? JobStatus.toJSON(message.job) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<JobListUpd>, I>>(base?: I): JobListUpd {
    return JobListUpd.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<JobListUpd>, I>>(object: I): JobListUpd {
    const message = createBaseJobListUpd();
    message.job = (object.job !== undefined && object.job !== null) ? JobStatus.fromPartial(object.job) : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
