/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { ScanEntryRange } from "./scan";
import { Coordinate3D } from "./scan-beam-location";

export const protobufPackage = "";

/** requires(NONE) */
export interface ScanBeamLocationsReq {
  scanId: string;
  entries: ScanEntryRange | undefined;
}

export interface ScanBeamLocationsResp {
  beamLocations: Coordinate3D[];
}

function createBaseScanBeamLocationsReq(): ScanBeamLocationsReq {
  return { scanId: "", entries: undefined };
}

export const ScanBeamLocationsReq = {
  encode(message: ScanBeamLocationsReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.scanId !== "") {
      writer.uint32(10).string(message.scanId);
    }
    if (message.entries !== undefined) {
      ScanEntryRange.encode(message.entries, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScanBeamLocationsReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScanBeamLocationsReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.scanId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.entries = ScanEntryRange.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScanBeamLocationsReq {
    return {
      scanId: isSet(object.scanId) ? String(object.scanId) : "",
      entries: isSet(object.entries) ? ScanEntryRange.fromJSON(object.entries) : undefined,
    };
  },

  toJSON(message: ScanBeamLocationsReq): unknown {
    const obj: any = {};
    message.scanId !== undefined && (obj.scanId = message.scanId);
    message.entries !== undefined &&
      (obj.entries = message.entries ? ScanEntryRange.toJSON(message.entries) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ScanBeamLocationsReq>, I>>(base?: I): ScanBeamLocationsReq {
    return ScanBeamLocationsReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScanBeamLocationsReq>, I>>(object: I): ScanBeamLocationsReq {
    const message = createBaseScanBeamLocationsReq();
    message.scanId = object.scanId ?? "";
    message.entries = (object.entries !== undefined && object.entries !== null)
      ? ScanEntryRange.fromPartial(object.entries)
      : undefined;
    return message;
  },
};

function createBaseScanBeamLocationsResp(): ScanBeamLocationsResp {
  return { beamLocations: [] };
}

export const ScanBeamLocationsResp = {
  encode(message: ScanBeamLocationsResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.beamLocations) {
      Coordinate3D.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScanBeamLocationsResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScanBeamLocationsResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.beamLocations.push(Coordinate3D.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScanBeamLocationsResp {
    return {
      beamLocations: Array.isArray(object?.beamLocations)
        ? object.beamLocations.map((e: any) => Coordinate3D.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ScanBeamLocationsResp): unknown {
    const obj: any = {};
    if (message.beamLocations) {
      obj.beamLocations = message.beamLocations.map((e) => e ? Coordinate3D.toJSON(e) : undefined);
    } else {
      obj.beamLocations = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ScanBeamLocationsResp>, I>>(base?: I): ScanBeamLocationsResp {
    return ScanBeamLocationsResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScanBeamLocationsResp>, I>>(object: I): ScanBeamLocationsResp {
    const message = createBaseScanBeamLocationsResp();
    message.beamLocations = object.beamLocations?.map((e) => Coordinate3D.fromPartial(e)) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
