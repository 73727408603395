/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { ScanMetaDataItem } from "./scan";

export const protobufPackage = "";

export interface ScanEntryMetadata {
  /** Any meta-data for the entry is stored here. For PIXL these are housekeeping values */
  meta: { [key: number]: ScanMetaDataItem };
}

export interface ScanEntryMetadata_MetaEntry {
  key: number;
  value: ScanMetaDataItem | undefined;
}

function createBaseScanEntryMetadata(): ScanEntryMetadata {
  return { meta: {} };
}

export const ScanEntryMetadata = {
  encode(message: ScanEntryMetadata, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.meta).forEach(([key, value]) => {
      ScanEntryMetadata_MetaEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScanEntryMetadata {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScanEntryMetadata();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = ScanEntryMetadata_MetaEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.meta[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScanEntryMetadata {
    return {
      meta: isObject(object.meta)
        ? Object.entries(object.meta).reduce<{ [key: number]: ScanMetaDataItem }>((acc, [key, value]) => {
          acc[Number(key)] = ScanMetaDataItem.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: ScanEntryMetadata): unknown {
    const obj: any = {};
    obj.meta = {};
    if (message.meta) {
      Object.entries(message.meta).forEach(([k, v]) => {
        obj.meta[k] = ScanMetaDataItem.toJSON(v);
      });
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ScanEntryMetadata>, I>>(base?: I): ScanEntryMetadata {
    return ScanEntryMetadata.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScanEntryMetadata>, I>>(object: I): ScanEntryMetadata {
    const message = createBaseScanEntryMetadata();
    message.meta = Object.entries(object.meta ?? {}).reduce<{ [key: number]: ScanMetaDataItem }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[Number(key)] = ScanMetaDataItem.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseScanEntryMetadata_MetaEntry(): ScanEntryMetadata_MetaEntry {
  return { key: 0, value: undefined };
}

export const ScanEntryMetadata_MetaEntry = {
  encode(message: ScanEntryMetadata_MetaEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== 0) {
      writer.uint32(8).int32(message.key);
    }
    if (message.value !== undefined) {
      ScanMetaDataItem.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScanEntryMetadata_MetaEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScanEntryMetadata_MetaEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.key = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = ScanMetaDataItem.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScanEntryMetadata_MetaEntry {
    return {
      key: isSet(object.key) ? Number(object.key) : 0,
      value: isSet(object.value) ? ScanMetaDataItem.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: ScanEntryMetadata_MetaEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = Math.round(message.key));
    message.value !== undefined && (obj.value = message.value ? ScanMetaDataItem.toJSON(message.value) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ScanEntryMetadata_MetaEntry>, I>>(base?: I): ScanEntryMetadata_MetaEntry {
    return ScanEntryMetadata_MetaEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScanEntryMetadata_MetaEntry>, I>>(object: I): ScanEntryMetadata_MetaEntry {
    const message = createBaseScanEntryMetadata_MetaEntry();
    message.key = object.key ?? 0;
    message.value = (object.value !== undefined && object.value !== null)
      ? ScanMetaDataItem.fromPartial(object.value)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
