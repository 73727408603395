/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { WidgetData } from "./widget-data";

export const protobufPackage = "";

/** requires(NONE) */
export interface WidgetDataGetReq {
  id: string;
}

export interface WidgetDataGetResp {
  widgetData: WidgetData | undefined;
}

/** requires(EDIT_VIEW_STATE) */
export interface WidgetDataWriteReq {
  widgetData: WidgetData | undefined;
}

export interface WidgetDataWriteResp {
  widgetData: WidgetData | undefined;
}

function createBaseWidgetDataGetReq(): WidgetDataGetReq {
  return { id: "" };
}

export const WidgetDataGetReq = {
  encode(message: WidgetDataGetReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WidgetDataGetReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWidgetDataGetReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WidgetDataGetReq {
    return { id: isSet(object.id) ? String(object.id) : "" };
  },

  toJSON(message: WidgetDataGetReq): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  create<I extends Exact<DeepPartial<WidgetDataGetReq>, I>>(base?: I): WidgetDataGetReq {
    return WidgetDataGetReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WidgetDataGetReq>, I>>(object: I): WidgetDataGetReq {
    const message = createBaseWidgetDataGetReq();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseWidgetDataGetResp(): WidgetDataGetResp {
  return { widgetData: undefined };
}

export const WidgetDataGetResp = {
  encode(message: WidgetDataGetResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.widgetData !== undefined) {
      WidgetData.encode(message.widgetData, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WidgetDataGetResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWidgetDataGetResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.widgetData = WidgetData.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WidgetDataGetResp {
    return { widgetData: isSet(object.widgetData) ? WidgetData.fromJSON(object.widgetData) : undefined };
  },

  toJSON(message: WidgetDataGetResp): unknown {
    const obj: any = {};
    message.widgetData !== undefined &&
      (obj.widgetData = message.widgetData ? WidgetData.toJSON(message.widgetData) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<WidgetDataGetResp>, I>>(base?: I): WidgetDataGetResp {
    return WidgetDataGetResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WidgetDataGetResp>, I>>(object: I): WidgetDataGetResp {
    const message = createBaseWidgetDataGetResp();
    message.widgetData = (object.widgetData !== undefined && object.widgetData !== null)
      ? WidgetData.fromPartial(object.widgetData)
      : undefined;
    return message;
  },
};

function createBaseWidgetDataWriteReq(): WidgetDataWriteReq {
  return { widgetData: undefined };
}

export const WidgetDataWriteReq = {
  encode(message: WidgetDataWriteReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.widgetData !== undefined) {
      WidgetData.encode(message.widgetData, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WidgetDataWriteReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWidgetDataWriteReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.widgetData = WidgetData.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WidgetDataWriteReq {
    return { widgetData: isSet(object.widgetData) ? WidgetData.fromJSON(object.widgetData) : undefined };
  },

  toJSON(message: WidgetDataWriteReq): unknown {
    const obj: any = {};
    message.widgetData !== undefined &&
      (obj.widgetData = message.widgetData ? WidgetData.toJSON(message.widgetData) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<WidgetDataWriteReq>, I>>(base?: I): WidgetDataWriteReq {
    return WidgetDataWriteReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WidgetDataWriteReq>, I>>(object: I): WidgetDataWriteReq {
    const message = createBaseWidgetDataWriteReq();
    message.widgetData = (object.widgetData !== undefined && object.widgetData !== null)
      ? WidgetData.fromPartial(object.widgetData)
      : undefined;
    return message;
  },
};

function createBaseWidgetDataWriteResp(): WidgetDataWriteResp {
  return { widgetData: undefined };
}

export const WidgetDataWriteResp = {
  encode(message: WidgetDataWriteResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.widgetData !== undefined) {
      WidgetData.encode(message.widgetData, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WidgetDataWriteResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWidgetDataWriteResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.widgetData = WidgetData.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WidgetDataWriteResp {
    return { widgetData: isSet(object.widgetData) ? WidgetData.fromJSON(object.widgetData) : undefined };
  },

  toJSON(message: WidgetDataWriteResp): unknown {
    const obj: any = {};
    message.widgetData !== undefined &&
      (obj.widgetData = message.widgetData ? WidgetData.toJSON(message.widgetData) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<WidgetDataWriteResp>, I>>(base?: I): WidgetDataWriteResp {
    return WidgetDataWriteResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WidgetDataWriteResp>, I>>(object: I): WidgetDataWriteResp {
    const message = createBaseWidgetDataWriteResp();
    message.widgetData = (object.widgetData !== undefined && object.widgetData !== null)
      ? WidgetData.fromPartial(object.widgetData)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
