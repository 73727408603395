/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { UserGroup, UserGroupInfo, UserGroupJoinSummaryInfo } from "./user-group";

export const protobufPackage = "";

/** requires(NONE) */
export interface UserGroupListReq {
}

export interface UserGroupListResp {
  groupInfos: UserGroupInfo[];
}

/** requires(NONE) */
export interface UserGroupListJoinableReq {
}

export interface UserGroupListJoinableResp {
  groups: UserGroupJoinSummaryInfo[];
}

/** requires(NONE) */
export interface UserGroupReq {
  groupId: string;
}

export interface UserGroupResp {
  group: UserGroup | undefined;
}

function createBaseUserGroupListReq(): UserGroupListReq {
  return {};
}

export const UserGroupListReq = {
  encode(_: UserGroupListReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserGroupListReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserGroupListReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UserGroupListReq {
    return {};
  },

  toJSON(_: UserGroupListReq): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<UserGroupListReq>, I>>(base?: I): UserGroupListReq {
    return UserGroupListReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserGroupListReq>, I>>(_: I): UserGroupListReq {
    const message = createBaseUserGroupListReq();
    return message;
  },
};

function createBaseUserGroupListResp(): UserGroupListResp {
  return { groupInfos: [] };
}

export const UserGroupListResp = {
  encode(message: UserGroupListResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.groupInfos) {
      UserGroupInfo.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserGroupListResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserGroupListResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.groupInfos.push(UserGroupInfo.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserGroupListResp {
    return {
      groupInfos: Array.isArray(object?.groupInfos) ? object.groupInfos.map((e: any) => UserGroupInfo.fromJSON(e)) : [],
    };
  },

  toJSON(message: UserGroupListResp): unknown {
    const obj: any = {};
    if (message.groupInfos) {
      obj.groupInfos = message.groupInfos.map((e) => e ? UserGroupInfo.toJSON(e) : undefined);
    } else {
      obj.groupInfos = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserGroupListResp>, I>>(base?: I): UserGroupListResp {
    return UserGroupListResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserGroupListResp>, I>>(object: I): UserGroupListResp {
    const message = createBaseUserGroupListResp();
    message.groupInfos = object.groupInfos?.map((e) => UserGroupInfo.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUserGroupListJoinableReq(): UserGroupListJoinableReq {
  return {};
}

export const UserGroupListJoinableReq = {
  encode(_: UserGroupListJoinableReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserGroupListJoinableReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserGroupListJoinableReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UserGroupListJoinableReq {
    return {};
  },

  toJSON(_: UserGroupListJoinableReq): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<UserGroupListJoinableReq>, I>>(base?: I): UserGroupListJoinableReq {
    return UserGroupListJoinableReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserGroupListJoinableReq>, I>>(_: I): UserGroupListJoinableReq {
    const message = createBaseUserGroupListJoinableReq();
    return message;
  },
};

function createBaseUserGroupListJoinableResp(): UserGroupListJoinableResp {
  return { groups: [] };
}

export const UserGroupListJoinableResp = {
  encode(message: UserGroupListJoinableResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.groups) {
      UserGroupJoinSummaryInfo.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserGroupListJoinableResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserGroupListJoinableResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.groups.push(UserGroupJoinSummaryInfo.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserGroupListJoinableResp {
    return {
      groups: Array.isArray(object?.groups) ? object.groups.map((e: any) => UserGroupJoinSummaryInfo.fromJSON(e)) : [],
    };
  },

  toJSON(message: UserGroupListJoinableResp): unknown {
    const obj: any = {};
    if (message.groups) {
      obj.groups = message.groups.map((e) => e ? UserGroupJoinSummaryInfo.toJSON(e) : undefined);
    } else {
      obj.groups = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserGroupListJoinableResp>, I>>(base?: I): UserGroupListJoinableResp {
    return UserGroupListJoinableResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserGroupListJoinableResp>, I>>(object: I): UserGroupListJoinableResp {
    const message = createBaseUserGroupListJoinableResp();
    message.groups = object.groups?.map((e) => UserGroupJoinSummaryInfo.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUserGroupReq(): UserGroupReq {
  return { groupId: "" };
}

export const UserGroupReq = {
  encode(message: UserGroupReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.groupId !== "") {
      writer.uint32(10).string(message.groupId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserGroupReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserGroupReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.groupId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserGroupReq {
    return { groupId: isSet(object.groupId) ? String(object.groupId) : "" };
  },

  toJSON(message: UserGroupReq): unknown {
    const obj: any = {};
    message.groupId !== undefined && (obj.groupId = message.groupId);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserGroupReq>, I>>(base?: I): UserGroupReq {
    return UserGroupReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserGroupReq>, I>>(object: I): UserGroupReq {
    const message = createBaseUserGroupReq();
    message.groupId = object.groupId ?? "";
    return message;
  },
};

function createBaseUserGroupResp(): UserGroupResp {
  return { group: undefined };
}

export const UserGroupResp = {
  encode(message: UserGroupResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.group !== undefined) {
      UserGroup.encode(message.group, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserGroupResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserGroupResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.group = UserGroup.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserGroupResp {
    return { group: isSet(object.group) ? UserGroup.fromJSON(object.group) : undefined };
  },

  toJSON(message: UserGroupResp): unknown {
    const obj: any = {};
    message.group !== undefined && (obj.group = message.group ? UserGroup.toJSON(message.group) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserGroupResp>, I>>(base?: I): UserGroupResp {
    return UserGroupResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserGroupResp>, I>>(object: I): UserGroupResp {
    const message = createBaseUserGroupResp();
    message.group = (object.group !== undefined && object.group !== null)
      ? UserGroup.fromPartial(object.group)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
