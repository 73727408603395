/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { UserDetails, UserInfo } from "./user";

export const protobufPackage = "";

/** requires(NONE) */
export interface UserDetailsReq {
}

export interface UserDetailsResp {
  details: UserDetails | undefined;
}

/**
 * Changing user details, this should publish a UserDetailsUpd
 * requires(EDIT_OWN_USER)
 */
export interface UserDetailsWriteReq {
  name: string;
  email: string;
  iconURL: string;
  dataCollectionVersion: string;
}

export interface UserDetailsWriteResp {
  details: UserDetails | undefined;
}

/** requires(NONE) */
export interface UserSearchReq {
  /** Searches names and the part of email before @ */
  searchString: string;
}

export interface UserSearchResp {
  users: UserInfo[];
}

function createBaseUserDetailsReq(): UserDetailsReq {
  return {};
}

export const UserDetailsReq = {
  encode(_: UserDetailsReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserDetailsReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserDetailsReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UserDetailsReq {
    return {};
  },

  toJSON(_: UserDetailsReq): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<UserDetailsReq>, I>>(base?: I): UserDetailsReq {
    return UserDetailsReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserDetailsReq>, I>>(_: I): UserDetailsReq {
    const message = createBaseUserDetailsReq();
    return message;
  },
};

function createBaseUserDetailsResp(): UserDetailsResp {
  return { details: undefined };
}

export const UserDetailsResp = {
  encode(message: UserDetailsResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.details !== undefined) {
      UserDetails.encode(message.details, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserDetailsResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserDetailsResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.details = UserDetails.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserDetailsResp {
    return { details: isSet(object.details) ? UserDetails.fromJSON(object.details) : undefined };
  },

  toJSON(message: UserDetailsResp): unknown {
    const obj: any = {};
    message.details !== undefined && (obj.details = message.details ? UserDetails.toJSON(message.details) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserDetailsResp>, I>>(base?: I): UserDetailsResp {
    return UserDetailsResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserDetailsResp>, I>>(object: I): UserDetailsResp {
    const message = createBaseUserDetailsResp();
    message.details = (object.details !== undefined && object.details !== null)
      ? UserDetails.fromPartial(object.details)
      : undefined;
    return message;
  },
};

function createBaseUserDetailsWriteReq(): UserDetailsWriteReq {
  return { name: "", email: "", iconURL: "", dataCollectionVersion: "" };
}

export const UserDetailsWriteReq = {
  encode(message: UserDetailsWriteReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.email !== "") {
      writer.uint32(18).string(message.email);
    }
    if (message.iconURL !== "") {
      writer.uint32(26).string(message.iconURL);
    }
    if (message.dataCollectionVersion !== "") {
      writer.uint32(34).string(message.dataCollectionVersion);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserDetailsWriteReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserDetailsWriteReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.email = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.iconURL = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.dataCollectionVersion = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserDetailsWriteReq {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      email: isSet(object.email) ? String(object.email) : "",
      iconURL: isSet(object.iconURL) ? String(object.iconURL) : "",
      dataCollectionVersion: isSet(object.dataCollectionVersion) ? String(object.dataCollectionVersion) : "",
    };
  },

  toJSON(message: UserDetailsWriteReq): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.email !== undefined && (obj.email = message.email);
    message.iconURL !== undefined && (obj.iconURL = message.iconURL);
    message.dataCollectionVersion !== undefined && (obj.dataCollectionVersion = message.dataCollectionVersion);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserDetailsWriteReq>, I>>(base?: I): UserDetailsWriteReq {
    return UserDetailsWriteReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserDetailsWriteReq>, I>>(object: I): UserDetailsWriteReq {
    const message = createBaseUserDetailsWriteReq();
    message.name = object.name ?? "";
    message.email = object.email ?? "";
    message.iconURL = object.iconURL ?? "";
    message.dataCollectionVersion = object.dataCollectionVersion ?? "";
    return message;
  },
};

function createBaseUserDetailsWriteResp(): UserDetailsWriteResp {
  return { details: undefined };
}

export const UserDetailsWriteResp = {
  encode(message: UserDetailsWriteResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.details !== undefined) {
      UserDetails.encode(message.details, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserDetailsWriteResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserDetailsWriteResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.details = UserDetails.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserDetailsWriteResp {
    return { details: isSet(object.details) ? UserDetails.fromJSON(object.details) : undefined };
  },

  toJSON(message: UserDetailsWriteResp): unknown {
    const obj: any = {};
    message.details !== undefined && (obj.details = message.details ? UserDetails.toJSON(message.details) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserDetailsWriteResp>, I>>(base?: I): UserDetailsWriteResp {
    return UserDetailsWriteResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserDetailsWriteResp>, I>>(object: I): UserDetailsWriteResp {
    const message = createBaseUserDetailsWriteResp();
    message.details = (object.details !== undefined && object.details !== null)
      ? UserDetails.fromPartial(object.details)
      : undefined;
    return message;
  },
};

function createBaseUserSearchReq(): UserSearchReq {
  return { searchString: "" };
}

export const UserSearchReq = {
  encode(message: UserSearchReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.searchString !== "") {
      writer.uint32(10).string(message.searchString);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserSearchReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserSearchReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.searchString = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserSearchReq {
    return { searchString: isSet(object.searchString) ? String(object.searchString) : "" };
  },

  toJSON(message: UserSearchReq): unknown {
    const obj: any = {};
    message.searchString !== undefined && (obj.searchString = message.searchString);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserSearchReq>, I>>(base?: I): UserSearchReq {
    return UserSearchReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserSearchReq>, I>>(object: I): UserSearchReq {
    const message = createBaseUserSearchReq();
    message.searchString = object.searchString ?? "";
    return message;
  },
};

function createBaseUserSearchResp(): UserSearchResp {
  return { users: [] };
}

export const UserSearchResp = {
  encode(message: UserSearchResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.users) {
      UserInfo.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserSearchResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserSearchResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.users.push(UserInfo.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserSearchResp {
    return { users: Array.isArray(object?.users) ? object.users.map((e: any) => UserInfo.fromJSON(e)) : [] };
  },

  toJSON(message: UserSearchResp): unknown {
    const obj: any = {};
    if (message.users) {
      obj.users = message.users.map((e) => e ? UserInfo.toJSON(e) : undefined);
    } else {
      obj.users = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserSearchResp>, I>>(base?: I): UserSearchResp {
    return UserSearchResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserSearchResp>, I>>(object: I): UserSearchResp {
    const message = createBaseUserSearchResp();
    message.users = object.users?.map((e) => UserInfo.fromPartial(e)) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
