/* eslint-disable */
import * as _m0 from "protobufjs/minimal";

export const protobufPackage = "";

export enum VersionField {
  /** MV_UNKNOWN - https://protobuf.dev/programming-guides/dos-donts/ says specify an unknown as 0 */
  MV_UNKNOWN = 0,
  MV_MAJOR = 1,
  MV_MINOR = 2,
  MV_PATCH = 3,
  UNRECOGNIZED = -1,
}

export function versionFieldFromJSON(object: any): VersionField {
  switch (object) {
    case 0:
    case "MV_UNKNOWN":
      return VersionField.MV_UNKNOWN;
    case 1:
    case "MV_MAJOR":
      return VersionField.MV_MAJOR;
    case 2:
    case "MV_MINOR":
      return VersionField.MV_MINOR;
    case 3:
    case "MV_PATCH":
      return VersionField.MV_PATCH;
    case -1:
    case "UNRECOGNIZED":
    default:
      return VersionField.UNRECOGNIZED;
  }
}

export function versionFieldToJSON(object: VersionField): string {
  switch (object) {
    case VersionField.MV_UNKNOWN:
      return "MV_UNKNOWN";
    case VersionField.MV_MAJOR:
      return "MV_MAJOR";
    case VersionField.MV_MINOR:
      return "MV_MINOR";
    case VersionField.MV_PATCH:
      return "MV_PATCH";
    case VersionField.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface SemanticVersion {
  major: number;
  minor: number;
  patch: number;
}

function createBaseSemanticVersion(): SemanticVersion {
  return { major: 0, minor: 0, patch: 0 };
}

export const SemanticVersion = {
  encode(message: SemanticVersion, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.major !== 0) {
      writer.uint32(8).int32(message.major);
    }
    if (message.minor !== 0) {
      writer.uint32(16).int32(message.minor);
    }
    if (message.patch !== 0) {
      writer.uint32(24).int32(message.patch);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SemanticVersion {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSemanticVersion();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.major = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.minor = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.patch = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SemanticVersion {
    return {
      major: isSet(object.major) ? Number(object.major) : 0,
      minor: isSet(object.minor) ? Number(object.minor) : 0,
      patch: isSet(object.patch) ? Number(object.patch) : 0,
    };
  },

  toJSON(message: SemanticVersion): unknown {
    const obj: any = {};
    message.major !== undefined && (obj.major = Math.round(message.major));
    message.minor !== undefined && (obj.minor = Math.round(message.minor));
    message.patch !== undefined && (obj.patch = Math.round(message.patch));
    return obj;
  },

  create<I extends Exact<DeepPartial<SemanticVersion>, I>>(base?: I): SemanticVersion {
    return SemanticVersion.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SemanticVersion>, I>>(object: I): SemanticVersion {
    const message = createBaseSemanticVersion();
    message.major = object.major ?? 0;
    message.minor = object.minor ?? 0;
    message.patch = object.patch ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
