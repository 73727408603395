<div class="filters-row">
  <div class="broad-filters">
    <mat-select [(ngModel)]="visibleScanId" class="scan-filter" placeholder="Scan ...">
      <mat-option *ngFor="let scan of configuredScans" [value]="scan.id">{{ scan.title }} </mat-option>
    </mat-select>
    <mat-select [multiple]="true" [(ngModel)]="selectedROITypes" class="roi-type" placeholder="ROI Type ...">
      <mat-option *ngFor="let option of roiTypeOptions" [value]="option.type">{{ option.name }} </mat-option>
    </mat-select>
  </div>
  <div class="option-filters">
    <filter-box placeholder="Filter Name..." (onFilterChanged)="onFilterText($event)"> </filter-box>
    <tag-picker type="roi" [selectedTagIDs]="filteredTagIDs" [showCurrentTagsSection]="false" (onTagSelectionChanged)="onTagFilterChanged($event)"> </tag-picker>
    <mat-select #tooltip="matTooltip" [matTooltip]="authorsTooltip" multiple [(ngModel)]="filteredAuthors" class="authors-filter" placeholder="Author ...">
      <mat-option *ngFor="let author of authors" [value]="author.id">{{ author.name }} </mat-option>
    </mat-select>
  </div>
</div>
